import React from 'react';
import { Column, Row } from 'react-table';
import { AnyMongoAbility } from '@casl/ability';

import { Formatter } from 'components';
import { tableDropdown } from 'utils/table';
import { displayAmount } from 'utils';
import { Salary } from 'models';
import { Option } from 'types';

import { Flex, StatusParagraph } from 'style';

export interface DropdownValue {
  type: 'delete' | 'payslip' | 'pay-salary';
  item: Salary;
}

interface DropdownArguments {
  handleDropdownSelect: (value: DropdownValue) => void;
  ability: AnyMongoAbility;
}

const prepareDropdownItems = (item: Salary, ability: AnyMongoAbility, showPayslip: boolean): Option<DropdownValue>[] => {
  const deleteItem = ability.can('delete', 'EmployeeSalary') ? [{ name: 'Remove', value: { type: 'delete', item }, type: 'delete' }] : [];
  const payslipItem = ability.can('generate', 'Payslip') ? [{ name: 'Download payslip', value: { type: 'payslip', item } }] : [];
  const paySalaryItem = ability.can('add', 'Payslip') && showPayslip ? [{ name: 'Pay salary', value: { type: 'pay-salary', item } }] : [];

  return [...deleteItem, ...payslipItem, ...paySalaryItem] as Option<DropdownValue>[];
};

export const prepareColumns = ({ handleDropdownSelect, ability }: DropdownArguments): Column<Salary>[] => {
  return [
    { Header: 'Date', accessor: 'date', Cell: ({ value }: { value: string }) => new Date(value).toLocaleDateString() },
    {
      Header: 'Base Pay (TZS)',
      accessor: 'baseTzs',
      width: 160,
      Cell: ({ value }: { value: number }) => <Formatter {...displayAmount(value, 'tzs')} />
    },
    ...(ability.can('manage', 'EmployeeSalaryUsd')
      ? [
          {
            Header: 'Base Pay (USD)',
            accessor: 'baseUsd',
            width: 160,
            Cell: ({ value }: { value: number }) => <Formatter {...displayAmount(value, 'usd')} />
          },
          {
            Header: 'Loans',
            accessor: 'loansTzs',
            Cell: ({ value, row }: { value: number; row: Row<Salary> }) => {
              const additionalLoanProps = row.original?.loansUsd ? displayAmount(row.original.loansUsd, 'usd') : null;

              return (
                <Flex gap={4} wrap={'wrap'}>
                  <Formatter {...displayAmount(value, 'tzs')} />
                  {additionalLoanProps && (
                    <>
                      +<Formatter {...additionalLoanProps} />
                    </>
                  )}
                </Flex>
              );
            }
          },
          { Header: 'Overtime', accessor: 'overtime', Cell: ({ value }: { value: number }) => <Formatter {...displayAmount(value, 'tzs')} /> },
          {
            Header: 'Public Holidays',
            accessor: 'publicHolidays',
            Cell: ({ value }: { value: number }) => <Formatter {...displayAmount(value, 'tzs')} />
          },
          {
            Header: 'Advance repayment',
            accessor: 'advanceRepayment',
            Cell: ({ value }: { value: number }) => <Formatter {...displayAmount(value, 'tzs')} />
          },
          { Header: 'Repayments', accessor: 'repayments', Cell: ({ value }: { value: number }) => <Formatter {...displayAmount(value, 'tzs')} /> },
          {
            Header: 'Travel Allowance',
            accessor: 'travelAllowance',
            Cell: ({ value }: { value: number }) => <Formatter {...displayAmount(value, 'tzs')} />
          },
          { Header: 'Bonus', accessor: 'bonus', Cell: ({ value }: { value: number }) => <Formatter {...displayAmount(value, 'tzs')} /> }
        ]
      : []),
    {
      Header: 'Status',
      accessor: 'isPaid',
      Cell: ({ value }: { value: boolean }) => <StatusParagraph active={value}>{value ? 'Paid' : 'Outstanding'}</StatusParagraph>
    },
    {
      Header: '',
      id: 'dropdown',
      width: 100,
      Cell: ({ row }: { row: Row<Salary> }) => {
        const showPayslip = !row.original.isPaid || true;
        const options = prepareDropdownItems(row.original, ability, showPayslip);

        return tableDropdown<DropdownValue>({ options, handleDropdownSelect });
      }
    }
  ];
};
