import React from 'react';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';

import LoginForm from './components/LoginForm/LoginForm';
import { View } from 'components';
import { useSubmit, useToast } from 'components/hooks';
import { login, LoginDto, LoginResponseDto } from 'api';
import { loginSchema } from './login.validation';

import { PositionBox, Card } from 'style';
import { Wrapper, MainHeading } from './login.styles';
import { REFRESH_TOKEN_STORAGE, TOKEN_STORAGE } from 'constants/auth.constants';

const initialValues: LoginDto = {
  email: '',
  password: ''
};

const Login: React.FC = () => {
  const history = useHistory();
  const { error } = useToast();

  const handleSuccess = ({ refreshToken, token }: LoginResponseDto) => {
    localStorage.setItem(TOKEN_STORAGE, token);
    localStorage.setItem(REFRESH_TOKEN_STORAGE, refreshToken);
    history.push('/companies');
  };

  const { onSubmit, onSubmitSuccess, onSubmitError } = useSubmit(login);
  onSubmitSuccess(handleSuccess);
  onSubmitError(error('Incorrect user name or password'));

  return (
    <View showMenu={false} showHeader={false} noVerticalPadding={true}>
      <PositionBox position={'center'} height={'100vh'}>
        <Wrapper>
          <MainHeading>Zanzibar</MainHeading>
          <Card padding={'24px 62px'}>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={loginSchema}>
              <LoginForm />
            </Formik>
          </Card>
        </Wrapper>
      </PositionBox>
    </View>
  );
};

export default Login;
