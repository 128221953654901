import React, { useMemo } from 'react';

import { Formatter } from 'components';
import { displayAmount } from 'utils';
import { CurrencyValue } from 'types';

import { colors, Paragraph, Flex } from 'style';
import { StyledParagraph } from './card-item.styles';

interface Props {
  name: string;
  noBorder?: boolean;
  bold?: boolean;
  moneyValue?: number;
  currency?: CurrencyValue;
  value?: string;
  children?: React.ReactNode;
  multiline?: boolean;
}

const CardItem: React.FC<Props> = ({ name, value, noBorder, bold, moneyValue, children, currency, multiline }) => {
  const moneyProps = useMemo(() => {
    if (moneyValue !== undefined && currency) {
      return displayAmount(moneyValue, currency);
    }
  }, [moneyValue, currency]);

  return (
    <Flex
      justify={'space-between'}
      padding={'15px 0'}
      borderColor={colors.gray.scale4}
      noBorder={noBorder}
      direction={multiline ? 'column' : 'row'}
      align={'stretch'}
    >
      <StyledParagraph bold={bold}>{name}</StyledParagraph>

      {moneyProps && <Formatter align={'right'} {...moneyProps} size={'p3'} />}
      {children}
      {value && (
        <Paragraph type={'p3'} textAlign={'right'}>
          {value}
        </Paragraph>
      )}
    </Flex>
  );
};

export default CardItem;
