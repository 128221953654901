import styled from 'styled-components';

import { Heading } from 'components';

import { Flex, Grid, theme } from 'style';

const StyledGrid = styled(Grid)`
  ${theme.mq.standard} {
    grid-template-columns: 1fr 2fr;
  }
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 10px;
`;

const HeaderFlex = styled(Flex)`
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0 12px 0;
  gap: 4px;

  h2 {
    margin: 0;
  }

  ${theme.mq.mobileL} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    button {
      width: auto;
      justify-content: flex-start;
    }
  }

  ${theme.mq.hdReady} {
    margin: 0 0 24px 0;
  }
`;

export { StyledGrid, StyledHeading, HeaderFlex };
