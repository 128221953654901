import { AbilityBuilder, Ability, AnyMongoAbility } from '@casl/ability';

import { shareholderPermissions } from './shareholder/shareholder.permissions';
import { employeePermissions } from './employee/employee.permissions';
import { controlPermissions } from './control/control.permissions';

type Actions = 'create' | 'select' | 'generate' | 'use' | 'download' | 'read' | 'read-tzs' | 'update' | 'delete' | 'manage';

type CashFlowSubjects =
  | 'CashStatistics'
  | 'Transaction'
  | 'TransactionPL'
  | 'AllTransactionBanks'
  | 'TransactionCash'
  | 'TransactionZnz'
  | 'TransactionReport'
  | 'PettyCash'
  | 'CashFlow'
  | 'CompanyLoan'
  | 'ExchangedTransaction';
type ReservationSubjects = 'Reservations' | 'ReservationDetails' | 'InvoiceCash' | 'InvoiceZnz' | 'InvoicePl';
type SalariesSubjects =
  | 'Salaries'
  | 'Payslip'
  | 'Employee'
  | 'EmployeeStatus'
  | 'EmployeeLoan'
  | 'EmployeeSalary'
  | 'EmployeeSalaryUsd'
  | 'EmployeePersonal'
  | 'EmployeeTaxes';
type RestaurantSubjects = 'Restaurant' | 'RestaurantDetails' | 'RestaurantReports';

type TaxSubjects = 'Tax' | 'TaxReports' | 'AllTaxBanks';
type SuppliersSubject = 'Supplier';
type UsersSubject = 'User';
type LogsSubject = 'Log';
type SettingsSubject = 'Settings' | 'TourOperators' | 'AccountValues';
type NotificationsSubject = 'Notifications';

export type CompanySubjects =
  | CashFlowSubjects
  | ReservationSubjects
  | SalariesSubjects
  | RestaurantSubjects
  | TaxSubjects
  | SuppliersSubject
  | UsersSubject
  | LogsSubject
  | NotificationsSubject
  | SettingsSubject
  | 'BankCheck'
  | 'BankCheckZnz'
  | 'BankCheckPl'
  | 'BankCheckCash'
  | 'User'
  | 'SinglePartner'
  | 'SelfPartner'
  | 'ShareholderView'
  | 'Dashboard'
  | 'Transaction'
  | 'Reservation'
  | 'Salary'
  | 'Restaurant'
  | 'RestaurantReports'
  | 'Tax'
  | 'Supplier'
  | 'all';

type AppAbility = Ability<[Actions, CompanySubjects]>;

type Role = 'admin' | 'employee' | 'shareholder' | 'control';

export const companyPermissionsUpdate = (ability: AnyMongoAbility, role: Role) => {
  const { can, cannot, rules } = new AbilityBuilder<AppAbility>(Ability);

  if (role === 'admin') {
    can('manage', 'all');
  }

  if (role === 'shareholder') {
    shareholderPermissions(can, cannot);
  }

  if (role === 'employee') {
    employeePermissions(can, cannot);
  }

  if (role === 'control') {
    controlPermissions(can, cannot);
  }

  ability.update(rules);
};
