import React, { useMemo } from 'react';
import { number, object, SchemaOf } from 'yup';
import { useParams } from 'react-router-dom';

import { FormField } from 'components';
import { useFetch, useShowContent } from 'components/hooks';
import { getEmployees } from 'api';
import { message } from 'utils';

import { Flex } from 'style';

export const managementWelfareSchema: SchemaOf<ManagementWelfareValues> = object({
  employeeId: number().required(message.required)
});

export type ManagementWelfareValues = {
  employeeId: number;
};

const ManagementWelfare: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();

  const employeesData = useFetch(getEmployees(companyId));
  const { showLoader, showNoContent } = useShowContent(employeesData);
  const { payload: generators } = employeesData;

  const options = useMemo(() => {
    if (generators) {
      return generators.map(({ name, id }) => ({ name, value: id }));
    }
  }, [generators]);

  const placeholder = showNoContent ? 'No employees to select' : 'Select an employee';
  const disabled = showLoader || showNoContent;

  return (
    <Flex direction={'column'} margin={'8px 0'} gap={8}>
      <FormField name={'accountData.employeeId'} type={'select'} items={options || []} width={'100%'} placeholder={placeholder} disabled={disabled} />
    </Flex>
  );
};

export default ManagementWelfare;
