import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { number, object, SchemaOf } from 'yup';

import { FormField } from 'components';
import { useFetch, useShowContent } from 'components/hooks';
import { getReservations } from 'api';
import { message } from 'utils';

import { Flex } from 'style';

export const refundSchema: SchemaOf<RefundValues> = object({
  reservationId: number().required(message.required)
});

export interface RefundValues {
  reservationId: number;
}

const Refund: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();

  const reservationsData = useFetch(getReservations({ companyId, isClosed: false }));
  const { showLoader, showNoContent } = useShowContent(reservationsData);
  const { payload: reservations } = reservationsData;

  const options = useMemo(() => {
    if (reservations) {
      return reservations.map(({ id, guestName, startDate }) => {
        const name = `${guestName} ${new Date(startDate).toLocaleDateString()}`;
        return { name, value: id };
      });
    }
  }, [reservations]);

  const placeholder = showNoContent ? 'No reservations to select' : 'Select a reservation';
  const disabled = showLoader || showNoContent;

  return (
    <Flex direction={'column'} margin={'8px 0'} gap={8}>
      <FormField name={'accountData.reservationId'} type={'select'} items={options || []} width={'100%'} placeholder={placeholder} disabled={disabled} />
    </Flex>
  );
};

export default Refund;
