import React from 'react';

import { Button } from 'components';
import { useToast } from 'components/hooks';

import { Flex, Paragraph } from 'style';
import { Wrapper } from './show-details.styles';

interface Props {
  name: string;
  type: string;
  redirectUri: string;
  handleNextPage: () => void;
}

const ShowDetails: React.FC<Props> = ({ name, type, redirectUri, handleNextPage }) => {
  const { success } = useToast();

  const copyText = (value: string) => async () => {
    await navigator.clipboard.writeText(value);
    success('Text has been copied');
  };

  return (
    <>
      <Wrapper direction={'column'} align={'flex-start'} gap={12}>
        <div>
          <Paragraph>Name: </Paragraph>
          <Paragraph onClick={copyText(name)} color={'scale3'}>
            {name}
          </Paragraph>
        </div>
        <div>
          <Paragraph>Integration type:</Paragraph>
          <Paragraph onClick={copyText(type)} color={'scale3'}>
            {type}
          </Paragraph>
        </div>
        <div>
          <Paragraph>Redirect uri: </Paragraph>
          <Paragraph onClick={copyText(redirectUri)} color={'scale3'}>
            {redirectUri}
          </Paragraph>
        </div>
      </Wrapper>
      <Flex justify={'flex-end'}>
        <Button type={'button'} onClick={handleNextPage}>
          Next
        </Button>
      </Flex>
    </>
  );
};

export default ShowDetails;
