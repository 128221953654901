import React from 'react';
import { useParams } from 'react-router-dom';

import { Heading, Table, Loader, Empty, Error } from 'components';
import { useFetch, useShowContent } from 'components/hooks';
import { getEmployeeTaxes } from 'api';
import { EmployeeTax } from 'models';
import { prepareColumns } from './taxes-columns';

import { Card } from 'style';

const Taxes: React.FC = () => {
  const { companyId, employeeId } = useParams<{ companyId: string; employeeId: string }>();

  const employeeTaxesData = useFetch(getEmployeeTaxes(companyId, employeeId));
  const { showContent, showLoader, showNoContent, showError } = useShowContent(employeeTaxesData);
  const { payload: employeeTaxes } = employeeTaxesData;

  const columns = prepareColumns();

  return (
    <Card>
      <Heading>Taxes</Heading>
      {showLoader && <Loader />}
      {showNoContent && <Empty>No data</Empty>}
      {showError && <Error>Failed to fetch</Error>}
      {showContent && employeeTaxes && <Table<EmployeeTax> columns={columns} data={employeeTaxes} />}
    </Card>
  );
};

export default Taxes;
