import fetchMiddleware from 'api/api.middleware';
import { dateRangeToISO } from 'utils';
import { IdParam } from 'types';
import { RestaurantReport } from 'models';
import { PostRestaurantReportDto, TokenDataDto } from 'api/restaurant/api.restaurant.dto';

export const getRestaurantStatus = (companyId: IdParam) => () => fetchMiddleware<boolean>({ method: 'get', url: `/companies/${companyId}/restaurant/status` });

export const getRestaurantIncome = (companyId: IdParam, dateRange: [Date, Date]) => () => {
  const [startDate, endDate] = dateRangeToISO(dateRange);

  return fetchMiddleware<number>({ method: 'get', url: `/companies/${companyId}/restaurant/income`, query: { startDate, endDate } });
};

export const getRestaurantReports = (companyId: IdParam) => () =>
  fetchMiddleware<RestaurantReport[]>({ method: 'get', url: `/companies/${companyId}/restaurant/reports` });

export const downloadReport = (companyId: IdParam) => (reportId: IdParam) =>
  fetchMiddleware<string | Blob>({ method: 'get', url: `/companies/${companyId}/restaurant/reports/${reportId}` });

export const deleteReport = (companyId: IdParam) => (reportId: IdParam) =>
  fetchMiddleware<{ id: number }>({ method: 'delete', url: `/companies/${companyId}/restaurant/reports/${reportId}`, data: {} });

export const postRestaurantReport =
  (companyId: IdParam) =>
  ({ startDate, endDate }: PostRestaurantReportDto) =>
    fetchMiddleware({ method: 'post', url: `/companies/${companyId}/restaurant/report`, data: { percentage: 100, startDate, endDate } });

export const putRestaurantToken = (companyId: IdParam) => (data: TokenDataDto) =>
  fetchMiddleware({ method: 'put', url: `/companies/${companyId}/restaurant/token`, data });
