import { Option } from 'types';
import { AccountValues } from '../types/cash-flow.types';

/*
  * optional account options
  { name: 'Salaries management', value: 'salaries-management' },
  { name: 'Salaries staff', value: 'salaries-staff' },
 */

export const accountOptions: Option<AccountValues>[] = [
  // { name: 'Advertising (incl. website)', value: 'advertising' },
  { name: 'Maintenance / Decoration', value: 'decoration-furniture' },
  { name: 'Diesel generators', value: 'diesel-generators' },
  // { name: 'Donation', value: 'donation' },
  { name: 'Electricity', value: 'electricity' },
  { name: 'Exchange', value: 'exchange' },
  { name: 'Excursion / Shop / Spa', value: 'excursion-commission' },
  // { name: 'Flights & hotel', value: 'flights-hotel' },
  // { name: 'Food & beverage', value: 'food-beverage' },
  // { name: 'Garbage & Waste water collection', value: 'garbage' },
  // { name: 'Gas', value: 'gas' },
  // { name: 'Grounds & landscaping', value: 'grounds' },
  // { name: 'Guest entertainment', value: 'guest-entertainment' },
  { name: 'Housekeeping and Laundry', value: 'housekeeping' },
  { name: 'Income cash', value: 'income-cash' },
  // { name: 'Income guests', value: 'income-guests' },
  { name: 'Income restaurant', value: 'income-restaurant' },
  { name: 'Kitchen equipment', value: 'kitchen-laundry' },
  // { name: 'Land lease', value: 'land-lease' },
  // { name: 'Linen', value: 'linen' },
  // { name: 'Maintenance', value: 'maintenance' },
  // { name: 'Management welfare', value: 'management-welfare' },
  { name: 'Marketing & promotion', value: 'marketing' },
  // { name: 'New building construction', value: 'building-construction' },
  // { name: 'Non-food supply', value: 'non-food-supply' },
  { name: 'Others', value: 'outcome-cash' },
  { name: 'Cars', value: 'petrol' },
  { name: 'Professional charges', value: 'professional-charges' },
  // { name: 'Shop & massages', value: 'shop-massages' },
  { name: 'Staff food', value: 'staff-food' },
  // { name: 'Staff welfare', value: 'staff-welfare' },
  // { name: 'Stationary & printing', value: 'stationary-printing' },
  { name: 'Swimming pool', value: 'swimming-pool' },
  // { name: 'Technical equipment', value: 'technical-equipment' },
  // { name: 'Telephone/Internet', value: 'telephone-internet' },
  // { name: 'Tours & transfers', value: 'tours-transfers' },
  // { name: 'Transport & parking', value: 'transport-parking' },
  // { name: 'Vehicle maintenance', value: 'vehicle-maintenance' },
  // { name: 'Water', value: 'water' },
  { name: 'Loan', value: 'loan' },
  { name: 'Insurance', value: 'insurance' },
  { name: 'Refund / Lost / Donations', value: 'refund' },
  // { name: 'Partner booking', value: 'partner-booking' }
  { name: 'Income from shareholders', value: 'income-shareholders' },
  { name: 'Utilities', value: 'utilities' },
  { name: 'Government Fees and Taxes', value: 'fees-taxes' },
  { name: 'Bank Charge', value: 'bank-charges' },
  { name: 'Back-office', value: 'backoffice' }
];

export const incomeAccounts: AccountValues[] = ['excursion-commission', 'income-cash', 'income-guests', 'income-restaurant', 'income-shareholders'];
export const incomeExpenseAccounts: AccountValues[] = ['exchange', 'loan'];
