import { FieldProps } from 'components';

type BankFields = {
  bank: string;
  fields: FieldProps[];
};

export const bankFields: BankFields[] = [
  {
    bank: 'Start amount - Bank PL',
    fields: [
      { name: 'startBalance.bankPlAmountPln', placeholder: 'Start amount', type: 'money', label: 'PLN', prefix: '' },
      { name: 'startBalance.bankPlAmountEur', placeholder: 'Start amount', type: 'money', label: 'EUR', prefix: '' },
      { name: 'startBalance.bankPlAmountUsd', placeholder: 'Start amount', type: 'money', label: 'USD', prefix: '' }
    ]
  },
  {
    bank: 'Start amount - Bank ZNZ',
    fields: [
      { name: 'startBalance.bankZnzAmountTzs', placeholder: 'Start amount', type: 'money', label: 'TZS', prefix: '' },
      { name: 'startBalance.bankZnzAmountEur', placeholder: 'Start amount', type: 'money', label: 'EUR', prefix: '' },
      { name: 'startBalance.bankZnzAmountUsd', placeholder: 'Start amount', type: 'money', label: 'USD', prefix: '' }
    ]
  }
];

export const cashBankFields: BankFields[] = [
  {
    bank: 'Start amount - Cash',
    fields: [
      { name: 'startBalance.cashAmountTzs', placeholder: 'Start amount', type: 'money', label: 'TZS', prefix: '' },
      { name: 'startBalance.cashAmountEur', placeholder: 'Start amount', type: 'money', label: 'EUR', prefix: '' },
      { name: 'startBalance.cashAmountUsd', placeholder: 'Start amount', type: 'money', label: 'USD', prefix: '' }
    ]
  }
];
