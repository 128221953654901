import React from 'react';

import DieselGenerators from './DieselGenerators/DieselGenerators';
import Cars from './Cars/Cars';
import Location from './Location/Location';
import { Heading } from 'components';

import { AccountsWrapper } from './accounts.styles';

const Accounts: React.FC = () => {
  return (
    <>
      <Heading type={'h4'} margin={'24px 0'}>
        Accounts Settings
      </Heading>
      <AccountsWrapper>
        <DieselGenerators />
        <Location type={'electricity'} name={'Electricity'} />
        <Cars />
        <Location type={'swimming-pool'} name={'Swimming pool'} />
        <Location type={'telephone-internet'} name={'Telephone/Internet'} />
      </AccountsWrapper>
    </>
  );
};

export default Accounts;
