import fetchMiddleware from 'api/api.middleware';
import { dateRangeToISO } from 'utils';
import { BankValue, IdParam } from 'types';
import { Reservation } from 'models';
import { ReservationDto, ReservationTourOperatorDto, ReservationShareholderDto } from './api.reservations.dto';

interface ReservationArguments {
  companyId: IdParam;
  dateRange?: [Date, Date];
  isClosed?: boolean;
}

export const getReservations =
  ({ companyId, dateRange, isClosed }: ReservationArguments) =>
  () => {
    const [startDate, endDate] = dateRange ? dateRangeToISO(dateRange) : [];

    return fetchMiddleware<Reservation[]>({ method: 'get', url: `/companies/${companyId}/reservations`, query: { startDate, endDate, isClosed } });
  };

export const getReservation = (companyId: IdParam, reservationId: IdParam) => () =>
  fetchMiddleware<Reservation>({ method: 'get', url: `/companies/${companyId}/reservations/${reservationId}` });

export const getReservationInvoice = (companyId: IdParam, reservationId: IdParam, bank: BankValue) =>
  fetchMiddleware<string | Blob>({
    method: 'get',
    url: `/companies/${companyId}/reservations/${reservationId}/invoice`,
    query: { bank },
    responseType: 'blob'
  });

export const putReservation = (companyId: IdParam, reservationId: IdParam) => (data: ReservationDto) =>
  fetchMiddleware<Reservation>({ method: 'put', url: `/companies/${companyId}/reservations/${reservationId}`, data });

export const putReservationOperator = (companyId: IdParam, reservationId: IdParam) => (data: ReservationTourOperatorDto) =>
  fetchMiddleware<Reservation>({ method: 'put', url: `/companies/${companyId}/reservations/${reservationId}/tour-operator`, data });

export const putReservationShareholder = (companyId: IdParam, reservationId: IdParam) => (data: ReservationShareholderDto) =>
  fetchMiddleware<Reservation>({ method: 'put', url: `/companies/${companyId}/reservations/${reservationId}/shareholder`, data });

export const getReservationReauthUrl = (companyId: IdParam) => () =>
  fetchMiddleware<string>({ method: 'get', url: `/companies/${companyId}/cloudbeds/reauth-url` });
