import styled from 'styled-components';

import { ArrowIcon, colors, Flex } from 'style';

/*
const StyledLink = styled(Link)`
  font-size: 10px;
  color: ${colors.gray.scale3};
  display: flex;
  flex-direction: row;
  text-transform: uppercase;
  align-items: center;

  p {
    font-size: 10px;
    color: ${colors.gray.scale3};
  }

  svg {
    path {
      fill: ${colors.gray.scale3};
    }
  }
`;
 */

const FlexWrapper = styled(Flex)`
  a {
    font-size: 10px;
    color: ${colors.gray.scale3};
    display: flex;
    flex-direction: row;
    text-transform: uppercase;
    align-items: center;

    p {
      font-size: 10px;
      color: ${colors.gray.scale3};
    }

    svg {
      path {
        fill: ${colors.gray.scale3};
      }
    }
  }
  position: relative;
  z-index: 50;
`;

const StyledBackArrow = styled(ArrowIcon)`
  width: 12px;
  height: 12px;
  transform: rotate(-180deg);
`;

const StyledFlex = styled(Flex)`
  button {
    padding: 0;
  }
`;

export { StyledFlex, StyledBackArrow, FlexWrapper };
