import { date, mixed, boolean, object, SchemaOf, array } from 'yup';

import { GenerateReportDto } from 'api';
import { BankValue } from 'types';
import { bankOneOf, message } from 'utils';

export const reportSchema: SchemaOf<GenerateReportDto> = object().shape({
  startDate: date().required(message.required),
  endDate: date().required(message.required),
  vat: boolean(),
  banks: array(mixed().oneOf<BankValue>(bankOneOf)).min(1, message.required)
});
