import fetchMiddleware from 'api/api.middleware';
import { dateRangeToISO } from 'utils';
import { mapToFormData } from 'utils';
import { IdParam, BankValue } from 'types';
import { Transaction, Balance, SingleTransaction } from 'models';
import { CashFlowDto } from 'form/transaction/cash-flow-form/types';
import { GenerateReportDto, PettyCashDto } from 'api/transaction/api.transaction.dto';

type ProgressFunction = (progressEvent: any) => void;

export const getTransactions = (companyId: IdParam, dateRange: [Date, Date]) => () => {
  const [startDate, endDate] = dateRangeToISO(dateRange);

  return fetchMiddleware<Transaction[]>({ method: 'get', url: `/companies/${companyId}/transactions`, query: { startDate, endDate } });
};

export const getSingleTransaction = (companyId: IdParam, transactionId: IdParam) => () =>
  fetchMiddleware<SingleTransaction>({ method: 'get', url: `/companies/${companyId}/transactions/${transactionId}` });

export const getBalance = (companyId: IdParam, bank: BankValue, dateRange: [Date, Date]) => () => {
  const [startDate, endDate] = dateRangeToISO(dateRange);

  return fetchMiddleware<Balance>({ method: 'get', url: `/companies/${companyId}/transactions/balance`, query: { bank, startDate, endDate } });
};

export const getTransactionFile = (companyId: IdParam, transactionId?: IdParam) => () =>
  fetchMiddleware<string | Blob>({ method: 'get', url: `/companies/${companyId}/transactions/${transactionId}/file`, responseType: 'blob' });

export const postTransaction = (companyId: IdParam, progressFunction?: ProgressFunction) => (data: CashFlowDto) => {
  const formData = mapToFormData(data);

  return fetchMiddleware<Transaction>({
    method: 'post',
    url: `/companies/${companyId}/transactions`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress: progressFunction
  });
};

export const generateReport = (companyId: IdParam) => (data: GenerateReportDto) =>
  fetchMiddleware<string | Blob>({
    method: 'post',
    url: `/companies/${companyId}/transactions/report`,
    data,
    responseType: 'blob'
  });

export const postPettyCash = (companyId: IdParam) => (data: PettyCashDto) =>
  fetchMiddleware<string | Blob>({
    method: 'post',
    url: `/companies/${companyId}/transactions/petty-cash`,
    data,
    responseType: 'blob'
  });

export const putTransaction = (companyId: IdParam, transactionId: IdParam, progressFunction?: ProgressFunction) => (data: CashFlowDto) => {
  const formData = mapToFormData(data);

  return fetchMiddleware<Transaction>({
    method: 'put',
    url: `/companies/${companyId}/transactions/${transactionId}`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress: progressFunction
  });
};

export const deleteTransaction = (companyId: IdParam) => (transactionId: IdParam) => {
  return fetchMiddleware<number[]>({ method: 'delete', url: `/companies/${companyId}/transactions/${transactionId}`, data: {} });
};
