import fetchMiddleware from 'api/api.middleware';
import { mapToFormData } from 'utils';
import { IdParam } from 'types';
import { Employee, EmployeeTax } from 'models';
import { EmployeeDto, DeleteEmployeeResponseDto } from './api.employees.dto';

export const getEmployees = (companyId: IdParam) => () => fetchMiddleware<Employee[]>({ method: 'get', url: `/companies/${companyId}/employees` });

export const getEmployee = (companyId: IdParam, employeeId: IdParam) => () =>
  fetchMiddleware<Employee>({ method: 'get', url: `/companies/${companyId}/employees/${employeeId}` });

export const getEmployeeFile = (companyId: IdParam, employeeId: IdParam) =>
  fetchMiddleware<string | Blob>({ method: 'get', url: `/companies/${companyId}/employees/${employeeId}/file`, responseType: 'blob' });

export const getEmployeeTaxes = (companyId: IdParam, employeeId: IdParam) => () =>
  fetchMiddleware<EmployeeTax[]>({ method: 'get', url: `/companies/${companyId}/employees/${employeeId}/taxes` });

export const postEmployee = (companyId: IdParam) => (data: EmployeeDto) => {
  const formData = mapToFormData(data);

  return fetchMiddleware<Employee>({
    method: 'post',
    url: `/companies/${companyId}/employees`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};

export const putEmployee = (companyId: IdParam, employeeId: IdParam) => (data: EmployeeDto) => {
  const formData = mapToFormData(data);

  return fetchMiddleware<Employee>({
    method: 'put',
    url: `/companies/${companyId}/employees/${employeeId}`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};

export const putEmployeeStatus = (companyId: IdParam, employeeId: IdParam) =>
  fetchMiddleware<Employee>({ method: 'put', url: `/companies/${companyId}/employees/${employeeId}/status`, data: {} });

export const deleteEmployee = (companyId: IdParam, employeeId: IdParam) =>
  fetchMiddleware<DeleteEmployeeResponseDto>({
    method: 'delete',
    url: `/companies/${companyId}/employees/${employeeId}`,
    data: {}
  });
