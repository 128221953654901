import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import Transaction from 'form/transaction/transaction';
import { useSubmit, useToast } from 'components/hooks';
import { postTransaction } from 'api';
import { mapTransactionData } from 'utils';
import { AddSingleItem } from 'types';
import { Transaction as TransactionInterface } from 'models';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  addTransaction: (args: AddSingleItem<TransactionInterface>) => void;
  successCallback: () => void;
}

const AddTransaction: React.FC<Props> = ({ isOpen, handleClose, addTransaction, successCallback }) => {
  const { success, error } = useToast();
  const { companyId } = useParams<{ companyId: string }>();

  const [progress, setProgress] = useState<number>(0);

  const handleProgress = (progressEvent: any) => {
    const progress = progressEvent.loaded / progressEvent.total;
    setProgress(progress * 100);
  };

  const { onSubmit, onSubmitSuccess, onSubmitError, mapData } = useSubmit(postTransaction(companyId, handleProgress));
  onSubmitError(error('Transaction error'));
  onSubmitSuccess((payload) => {
    handleClose();
    successCallback();
    addTransaction({ payload });
    success('Transaction has been added');
  });
  mapData((data) => {
    return mapTransactionData(data);
  });

  return <Transaction isOpen={isOpen} handleClose={handleClose} handleSubmit={onSubmit} progress={progress} />;
};

export default AddTransaction;
