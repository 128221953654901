import React from 'react';
import styled, { css } from 'styled-components';

import { InputSizes, InputType } from './Input';

import { colors, theme, borders, SearchIcon, CalendarIcon, font } from 'style';

interface InputInterface {
  inputSize?: InputSizes;
  leadingIcon?: React.ReactNode;
  inputType?: InputType;
  hasError?: boolean;
  width?: string;
  searchFullWidth?: boolean;
  searchHideBorder?: boolean;
}

const InputWrapper = styled.div<InputInterface>`
  position: relative;
  width: 100%;
  /* ------------------------------- Input sizes ------------------------------ */
  input {
    ${({ inputSize }) => {
      switch (inputSize) {
        case 'small':
          return css`
            padding: 7px 16px;
          `;
        case 'large':
          return css`
            padding: 15px 16px;
          `;
        case 'popup':
          return css`
            padding: 8px 0;
            font-size: 2.4rem;
            border: none;
            border-radius: unset;
            border-bottom: 2px solid #ccc;

            &:focus {
              box-shadow: none;
              border: none;
            }
          `;
      }
    }}
  }

  ${({ inputType, searchFullWidth }) =>
    inputType === 'search' &&
    css`
      width: 100%;
      margin-bottom: 0;

      svg {
        path {
          fill: ${colors.gray.scale3};
        }
      }

      ${theme.mq.tablet} {
        width: ${searchFullWidth ? '100%' : '228px'};
      }
    `}
`;

const StyledInput = styled.input<InputInterface>`
  width: ${({ width }) => width || '100%'};
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${colors.gray.scale2};
  padding: 0.8rem 1.2rem;
  height: 40px;
  background: #fff;
  border: ${borders.input.base};
  border-radius: 8px;

  &:focus {
    outline: none;
    border: ${borders.input.active};
  }

  &:disabled {
    opacity: 0.6;
  }

  &::placeholder {
    color: ${colors.gray.scale3};
  }

  &:-webkit-autofill,
  &:-webkit-autofill::first-line {
    font-size: 1.6rem;
    font-family: 'Inter', sans-serif;
  }

  ${({ hasError }) =>
    hasError &&
    css`
      border: ${borders.input.error};
    `}

  ${({ inputType, searchHideBorder }) =>
    inputType === 'search' &&
    css`
      background: #fff;
      border: ${searchHideBorder ? 'none' : borders.input};
      padding-left: 38px;
      border-radius: 8px;

      &::placeholder {
        font-size: 1.6rem;
        color: ${colors.gray.scale3};
      }
      &:focus {
        border: ${borders.input.base};
      }
    `}

  ${({ inputType, width }) =>
    (inputType === 'date' || inputType === 'date-full-width') &&
    css`
      width: ${inputType === 'date-full-width' ? '100%' : width || 'auto'};
      padding-left: ${inputType === 'date' ? '38px' : '12px'};
      border-radius: 8px;
      text-align: ${inputType === 'date' ? 'right' : 'left'};
      font-weight: ${font.weight.medium};

      &::placeholder {
        font-size: 1.6rem;
        color: ${colors.gray.scale3};
        font-weight: 400;
      }
      &:focus {
        border: ${borders.input.base};
      }
    `}
`;

const StyledRow = styled.div`
  justify-content: space-between;

  ${theme.mq.tabletS} {
    justify-content: flex-start;
    gap: 2rem;
  }
`;

const Search = styled(SearchIcon)`
  display: block;
  position: absolute;
  top: 50%;
  width: 14px;
  transform: translateY(-50%);
  height: 14px;
  left: 14px;
`;

const Calendar = styled(CalendarIcon)<{ type: InputType }>`
  display: block;
  position: absolute;
  top: 50%;
  width: 20px;
  height: 20px;
  transform: translateY(-50%);
  left: 14px;

  ${({ type }) =>
    type === 'date-full-width' &&
    css`
      left: auto;
      right: 14px;
    `}
`;

const TrailingComponentWrapper = styled.div`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
`;

export { InputWrapper, StyledInput, StyledRow, Search, TrailingComponentWrapper, Calendar };
