import React, { useContext } from 'react';
import { useFormikContext } from 'formik';

import { FormField } from 'components/index';
import { RadioOption } from 'types';
import { CashFlowDto } from 'form/transaction/cash-flow-form/types';
import { CashFlowContext } from 'form/transaction/context/cash-flow.context';

import { Flex } from 'style';

const lenderOptions: RadioOption[] = [
  { name: 'Employee', value: 'employee' },
  { name: 'Company', value: 'company' },
  { name: 'Shareholder', value: 'shareholder' }
];

const companyLenderOptions: RadioOption[] = lenderOptions.filter(({ name }) => name !== 'Employee');

const options: RadioOption[] = [
  { name: 'New', value: 'new' },
  { name: 'Pay', value: 'pay' }
];

const LoanForm: React.FC = () => {
  const {
    values: { accountData },
    setFieldValue
  } = useFormikContext<CashFlowDto>();
  const { defaultLoanData } = useContext(CashFlowContext);

  const resetLoanData = () => {
    setFieldValue('accountData.loanData', {});
  };

  const showPayLoan = accountData?.type === 'pay';

  /* this condition means, that user wants to add new loan from the main page -> remove employee from options */
  const removeEmployeeLenderOption = !!defaultLoanData?.type && !defaultLoanData?.lender;

  const disableTypeSelection = !!defaultLoanData?.type;
  const disableLenderSelection = !!defaultLoanData?.lender;

  return (
    <div>
      <Flex direction={'column'} margin={'0 0 12px 0'} justify={'flex-start'} align={'flex-start'} gap={1}>
        {!disableTypeSelection && (
          <FormField
            name={'accountData.type'}
            type={'radio'}
            options={options}
            mainLabel={'Entry type'}
            disabled={disableTypeSelection}
            onFieldChange={resetLoanData}
          />
        )}
        {/* on a pay loan view we don't have to select from company/employee/shareholder */}
        {/* when lender is selected in initial state (employee | company | shareholder) we cannot change it */}
        {!showPayLoan && !disableLenderSelection && (
          <FormField
            name={'accountData.lender'}
            type={'radio'}
            options={removeEmployeeLenderOption ? companyLenderOptions : lenderOptions}
            mainLabel={'Subject'}
            disabled={disableLenderSelection}
            onFieldChange={resetLoanData}
          />
        )}
      </Flex>
    </div>
  );
};

export default LoanForm;
