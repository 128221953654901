import React from 'react';
import gsap from 'gsap';

export const modalOpenAnimation = (
  tl: GSAPTimeline,
  mainWrapperRef: React.RefObject<any | null>,
  wrapperRef: React.RefObject<any | null>
) => {
  const mainWrapper: HTMLDivElement | null = mainWrapperRef.current;
  const wrapper: HTMLDivElement | null = wrapperRef.current;

  if (mainWrapper && wrapper) {
    gsap.set([mainWrapper, ...mainWrapper.children], { autoAlpha: 0 });
    gsap.set([wrapper], { transform: 'scale(0)', autoAlpha: 0 });

    tl.fromTo(mainWrapper, { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.06 })
      .fromTo(mainWrapper.children, { autoAlpha: 0, y: '+=20' }, { autoAlpha: 1, y: '0', duration: 0.12 })
      .fromTo(
        wrapper,
        { transform: 'scale(0)', autoAlpha: 0 },
        { autoAlpha: 1, transform: 'scale(1)', duration: 0.2, ease: 'power3.easeInOut' }
      );
  }
};
