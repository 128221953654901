import React from 'react';
import styled from 'styled-components';

import { Button } from 'components/index';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
`;

interface Props {
  error: Error;
  resetErrorBoundary: (...args: Array<unknown>) => void;
}

const Fallback: React.FC<Props> = ({ error, resetErrorBoundary }) => {
  return (
    <Wrapper>
      <div>
        <p>Something went wrong: {error.message}</p>
        <Button onClick={resetErrorBoundary}>Refresh</Button>
      </div>
    </Wrapper>
  );
};

export default Fallback;
