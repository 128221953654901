import React, { InputHTMLAttributes } from 'react';

import { StyledWrapper, StyledInput } from './toggle-checkbox.styles';

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  checked: boolean;
  children?: React.ReactNode;
}

const ToggleCheckbox: React.FC<Props> = ({ onChange, checked, children, ...props }) => {
  return (
    <StyledWrapper>
      {children}
      <StyledInput type='checkbox' onChange={onChange} checked={checked} {...props} />
    </StyledWrapper>
  );
};

export default ToggleCheckbox;
