import React from 'react';
import styled, { css } from 'styled-components';

import { colors, shadows, borders, theme } from 'style';

const ModalBackdrop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 55;
  background-color: ${colors.modal.background};
`;

type ModalBodyProps = {
  width?: string;
  fullMobile?: boolean;
  dropdown?: boolean;
  ref: React.RefObject<HTMLDivElement>;
};

const ModalBody = styled.section<ModalBodyProps>`
  background-color: #fff;
  box-shadow: ${shadows.modal};
  border: ${borders.default};
  position: relative;
  border-radius: 8px;
  width: 90%;
  max-height: 90%;
  overflow: scroll;

  ${({ fullMobile }) =>
    fullMobile &&
    css`
      width: 100%;
      height: 100vh;
      border-radius: 0;
      max-height: 100vh;
    `}

  ${theme.mq.tablet} {
    width: ${({ width }) => width || '460px'};
    height: auto;
    max-height: 90vh;

    border-radius: 8px;
  }

  ${theme.mq.standard} {
    overflow: ${({ dropdown }) => (dropdown ? 'visible' : 'scroll')};
  }
`;

const Content = styled.section<{ showPadding?: boolean }>`
  width: 100%;
  //88px - header height
  height: calc(100% - 88px);
  padding: ${({ showPadding }) => (showPadding ? '24px' : '0 24px 24px 24px')};
`;

export { ModalBackdrop, ModalBody, Content };
