import React from 'react';
import NumberFormat from 'react-number-format';

import { PHONE_FORMAT, BANK_ACCOUNT } from 'utils/format';

import { colors, font } from 'style';
import { TextWrapper } from './formatter.styles';

export type Align = 'left' | 'center' | 'right';

interface Props {
  value: number | string;
  align?: Align;
  customColor?: string;
  color?: keyof typeof colors.gray;
  weight?: keyof typeof font.weight;
  size?: keyof typeof font.size.paragraph;
}

interface MoneyProps extends Props {
  type?: 'money';
  prefix?: string | null | 'none';
  suffix?: string;
}

interface OtherFormatProps extends Props {
  type: 'phone' | 'bank';
  prefix?: never;
  suffix?: never;
}

type FormatterProps = MoneyProps | OtherFormatProps;

const Formatter: React.FC<FormatterProps> = ({ type = 'money', value, customColor, prefix, suffix, color, weight, size, align = 'left' }) => {
  return (
    <TextWrapper align={align} color={color} customColor={customColor} weight={weight} size={size}>
      {type === 'money' && (
        <NumberFormat value={value} displayType={'text'} prefix={prefix === 'none' ? '' : prefix || '$'} suffix={suffix} thousandSeparator={' '} />
      )}
      {type === 'bank' && <NumberFormat value={value} displayType={'text'} format={BANK_ACCOUNT} />}
      {type === 'phone' && <NumberFormat value={value} displayType={'text'} format={PHONE_FORMAT} />}
    </TextWrapper>
  );
};

export default Formatter;
