import React from 'react';

import { FormField } from 'components';

const Logo: React.FC = () => {
  return (
    <div>
      <FormField name={'logo'} type={'file'} label={'Logo'} formats={['image/*']} />
    </div>
  );
};

export default Logo;
