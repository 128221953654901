import React from 'react';
import styled from 'styled-components';

import { Heading } from 'components';

import { colors, Paragraph } from 'style';

const Wrapper = styled.div<{ seen?: boolean }>`
  width: 100%;
  padding: 8px 12px;
  background-color: ${({ seen }) => (seen ? colors.background.secondary : colors.blue.opacity)};
  border-radius: 8px;
`;

interface Props {
  title: string;
  text: string;
  seen?: boolean;
}

const Notification: React.FC<Props> = ({ title, text, seen = false }) => {
  return (
    <Wrapper seen={seen}>
      <Heading type={'h6'} margin={'0 0 10px 0'}>
        {title}
      </Heading>
      <Paragraph type={'p3'}>{text}</Paragraph>
    </Wrapper>
  );
};

export default Notification;
