import React, { useState } from 'react';

import CashTable from './components/CashTable/CashTable';
import Statistics from './components/Statistics/Statistics';
import { AddNewButton, PageHeader, Button } from 'components';
import { GenerateReport } from 'pages/Company/views/CashFlow/modals';
import { useDateRange, usePermissions } from 'components/hooks';

import { StyledPositionBox } from './cash-flow.styles';

const CashFlow: React.FC = () => {
  const { companyPermissions } = usePermissions();
  const { dateRange, handleDateRange } = useDateRange();

  const [isGenerateReportOpen, setGenerateReportOpen] = useState<boolean>(false);
  const [isAddTransactionOpen, setAddTransactionOpen] = useState<boolean>(false);
  const [cashRefreshDate, setCashRefreshDate] = useState<Date>(new Date());

  const handleAddTransactionOpen = (isOpen: boolean) => () => setAddTransactionOpen(isOpen);
  const handleGenerateReportOpen = (isOpen: boolean) => () => setGenerateReportOpen(isOpen);
  const refreshCashBalance = () => setCashRefreshDate(new Date());

  const showGenerateReport = companyPermissions.can('generate', 'TransactionReport');
  const showCreateTransaction = companyPermissions.can('create', 'Transaction');
  const showCashStatistics = companyPermissions.can('read', 'CashStatistics');

  const CustomButton = showCreateTransaction && <AddNewButton onClick={handleAddTransactionOpen(true)}>Add new transaction</AddNewButton>;

  return (
    <>
      <PageHeader heading={'Cash Flow'} description={'Overview'} handleDateChange={handleDateRange} dateRange={dateRange} customButton={CustomButton} />
      {showGenerateReport && (
        <StyledPositionBox>
          <Button onClick={handleGenerateReportOpen(true)}>Generate Report</Button>
        </StyledPositionBox>
      )}
      {showCashStatistics && <Statistics dateRange={dateRange} cashRefreshDate={cashRefreshDate} />}
      <CashTable
        isTransactionOpen={isAddTransactionOpen}
        handleTransactionClose={handleAddTransactionOpen(false)}
        dateRange={dateRange}
        refreshCashBalance={refreshCashBalance}
      />
      <GenerateReport isOpen={isGenerateReportOpen} handleClose={handleGenerateReportOpen(false)} dateRange={dateRange} />
    </>
  );
};

export default CashFlow;
