import styled from 'styled-components';

import { Flex, theme } from 'style';

const StyledFlex = styled(Flex)`
  position: static;

  ${theme.mq.standard} {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 0;
  }
`;

export { StyledFlex };
