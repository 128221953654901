import React from 'react';

import { Empty, Error, Loader } from 'components';

interface Props {
  showLoader: boolean;
  showNoContent: boolean;
  showError: boolean;
}

const States: React.FC<Props> = ({ showLoader, showError, showNoContent }) => {
  return (
    <div>
      {showLoader && <Loader />}
      {showNoContent && <Empty>No data</Empty>}
      {showError && <Error>Fetch error</Error>}
    </div>
  );
};

export default States;
