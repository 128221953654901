import { Currency } from 'types';

type MultiplyOrDivide = {
  [index in Currency]: 'multiply' | 'divide' | null;
};

export const multiplyOrDivide: { [index in Currency]: MultiplyOrDivide } = {
  USD: { USD: null, PLN: 'divide', TZS: 'multiply', EUR: 'multiply' },
  PLN: { PLN: null, USD: 'multiply', EUR: 'divide', TZS: 'multiply' },
  TZS: { TZS: null, USD: 'divide', EUR: 'divide', PLN: 'divide' },
  EUR: { EUR: null, USD: 'divide', PLN: 'multiply', TZS: 'multiply' }
};
