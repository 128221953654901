import styled from 'styled-components';

import { colors, Grid, theme } from 'style';

const GridWrapper = styled(Grid)<{ showMenu: boolean }>`
  gap: 0;

  ${theme.mq.hdReady} {
    grid-template-columns: ${({ showMenu }) => (showMenu ? '300px auto' : 'auto')};
    grid-template-rows: ${({ showMenu }) => (showMenu ? '100vh auto' : 'auto')};
  }
`;

const Wrapper = styled.section<{ background?: keyof typeof colors.background }>`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background-color: ${({ background }) => (background ? colors.background[background] : colors.background.secondary)};
`;

interface ContentProps {
  headerHeight?: number;
  noVerticalPadding?: boolean;
  width?: string;
}

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
`;

const Content = styled.main<ContentProps>`
  width: 100%;
  min-height: ${({ headerHeight }) => (headerHeight ? `calc(100% - ${headerHeight}px)` : '100%')};
  padding: ${({ noVerticalPadding }) => (noVerticalPadding ? '0 24px' : '24px')};

  ${theme.mq.hdReady} {
    width: ${({ width }) => width || '100%'};
    padding: ${({ noVerticalPadding }) => (noVerticalPadding ? '0 48px' : '24px 48px 48px 48px')};
  }
`;

export { GridWrapper, Wrapper, ContentWrapper, Content };
