import React, { useEffect } from 'react';
import { useField } from 'formik';

import { Radio } from 'components';
import { Props } from 'components/form/Radio/Radio';
import { RadioOption } from 'types';

type OmittedRadioProps = Omit<Props, 'onChange'>;

export interface RadioProps extends OmittedRadioProps {
  name: string;
  label?: string;
  onFieldChange?: (value: any) => void;
  onFieldError?: () => void;
  options: RadioOption[];
  currentValue?: boolean;
}

const RadioForm: React.FC<RadioProps> = ({ name, options, onFieldChange, currentValue, onFieldError, ...rest }) => {
  const [field, meta, helpers] = useField({ name });

  const { error, touched } = meta;
  const { setValue } = helpers;

  const showError = error && touched;

  const handleSetValue = (value: any) => {
    setValue(value);
    onFieldChange && onFieldChange(value);
  };

  const handleRadioChange = (option: RadioOption) => () => handleSetValue(option.value);

  useEffect(() => {
    if (showError) {
      onFieldError && onFieldError();
    }
  }, [showError]);

  return <Radio name={field.name} options={options} onChange={handleRadioChange} value={field.value} {...rest} />;
};

export default RadioForm;
