import fetchMiddleware from 'api/api.middleware';
import { Loan } from 'models';
import { IdParam } from 'types';
import { PostLoanDto, DeleteLoanResponseDto, GetCompanyLoansDto } from './api.loans.dto';

export const getLoans = (companyId: IdParam, employeeId: IdParam) => () =>
  fetchMiddleware<Loan[]>({ method: 'get', url: `/companies/${companyId}/loans/employees/${employeeId}` });

export const getBorrowings = (companyId: IdParam) => () =>
  fetchMiddleware<GetCompanyLoansDto>({ method: 'get', url: `/companies/${companyId}/loans/borrowings` });

export const getCompanyLoans = (companyId: IdParam) => () => fetchMiddleware<Loan[]>({ method: 'get', url: `/companies/${companyId}/loans/commitments` });

export const getShareholderLoans = (companyId: IdParam, shareholderId: IdParam) => () =>
  fetchMiddleware<Loan[]>({ method: 'get', url: `/companies/${companyId}/loans/shareholders/${shareholderId}` });

export const postLoan = (companyId: IdParam, employeeId: IdParam) => (data: PostLoanDto) =>
  fetchMiddleware<Loan>({ method: 'post', url: `/companies/${companyId}/employees/${employeeId}/loans`, data });

export const deleteLoan = (companyId: IdParam, employeeId: IdParam, loanId: IdParam) =>
  fetchMiddleware<DeleteLoanResponseDto>({
    method: 'delete',
    url: `/companies/${companyId}/employees/${employeeId}/loans/${loanId}`,
    data: {}
  });
