import React from 'react';

import { FormField } from 'components';
import { usePermissions } from 'components/hooks';
import { RadioOption } from 'types';
import { currencySelectItems } from 'utils';

import { Paragraph } from 'style';
import { FormWrapper, StyledGrid, ParagraphWrapper } from '../payment.styles';

interface Props {
  radioOptions: RadioOption[];
  editable: boolean;
}

const CashForm: React.FC<Props> = ({ radioOptions, editable }) => {
  const { companyPermissions } = usePermissions();

  const disabledForm = !companyPermissions.can('update', 'ReservationDetails') || !editable;

  return (
    <FormWrapper>
      <ParagraphWrapper>
        <Paragraph marginBottom={'8px'}>Cash</Paragraph>
      </ParagraphWrapper>
      <StyledGrid>
        <FormField name={'cashAmount'} type={'money'} prefix={' '} placeholder={'Cash amount'} disabled={disabledForm} />
        <FormField name={'cashCurrency'} type={'select'} items={currencySelectItems} alignPosition={'right'} disabled={disabledForm} />
      </StyledGrid>
    </FormWrapper>
  );
};

export default CashForm;
