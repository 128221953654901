import React, { useMemo } from 'react';

import EmployeeManagement from '../EmployeeManagement/EmployeeManagement';
import { useFetch, useShowContent, useSubmit, useToast } from 'components/hooks';
import { useParams } from 'react-router-dom';
import { getEmployee, putEmployee, EmployeeDto } from 'api';
import { Employee } from 'models';
import { UpdateSingleItem } from 'types';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  item: Employee;
  updateEmployee?: (args: UpdateSingleItem<Employee[]>) => void;
  updateSingleEmployee?: (item: Employee) => void;
}

const EditEmployee: React.FC<Props> = ({ isOpen, handleClose, item, updateEmployee, updateSingleEmployee }) => {
  const { success, error } = useToast();
  const { companyId } = useParams<{ companyId: string }>();

  const employeeData = useFetch(getEmployee(companyId, item.id));
  const { showLoader, showError, showNoContent } = useShowContent(employeeData);
  const { payload: car } = employeeData;

  const initialValues: EmployeeDto | undefined = useMemo(() => {
    if (car) {
      return {
        name: item.name,
        baseTzs: item.baseTzs,
        baseUsd: item.baseUsd,
        position: item.position,
        contractStart: item.contractStart ? new Date(item.contractStart) : new Date(),
        contractEnd: item.contractEnd ? new Date(item.contractEnd) : new Date(),
        file: null
      };
    }
  }, [car]);

  const { onSubmit, onSubmitSuccess, onSubmitError, mapData } = useSubmit(putEmployee(companyId, item.id));
  onSubmitError(error('Cannot update employee'));
  onSubmitSuccess((employee) => {
    updateEmployee && updateEmployee({ updatedItem: employee });
    updateSingleEmployee && updateSingleEmployee(employee);
    success(`${employee.name} has been updated`);
    handleClose();
  });
  mapData((data) => ({
    ...data,
    contractStart: (data.contractStart ? new Date(data.contractStart) : new Date()).toISOString(),
    contractEnd: (data.contractEnd ? new Date(data.contractEnd) : new Date()).toISOString()
  }));

  const disableEdit = showError || showNoContent;

  return (
    <EmployeeManagement
      isOpen={isOpen}
      handleClose={handleClose}
      handleSubmit={onSubmit}
      buttonText={'Save'}
      initialValues={initialValues}
      isLoading={showLoader}
      showError={disableEdit}
      title={'Edit employee'}
    />
  );
};

export default EditEmployee;
