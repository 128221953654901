import React, { useContext, useEffect } from 'react';
import { Switch, Route, Redirect, useHistory, useLocation } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { useAbility } from '@casl/react';

import { useFetch, useIsAuthenticated } from 'components/hooks';
import { getUserData } from 'api';
import { Layout, Fallback } from 'components';
import { globalPermissionsUpdate } from 'permissions';
import { routes, publicRoutes } from 'config';
import { GlobalAbilityContext } from 'permissions/global/global-permissions.context';
import { AuthenticateContext } from 'context/authenticate.context';
import { TOKEN_STORAGE } from 'constants/auth.constants';

function App() {
  const history = useHistory();
  const ability = useAbility(GlobalAbilityContext);
  const token = localStorage.getItem(TOKEN_STORAGE);
  const { pathname } = useLocation();

  const { isAuthenticated } = useIsAuthenticated();
  const { setUser, user } = useContext(AuthenticateContext);

  useEffect(() => {
    if (!token) {
      setUser(null);
    }
  }, [token]);

  useFetch(getUserData, {
    dependencies: [token],
    conditions: Boolean(token) && !isAuthenticated,
    onSuccess: (user) => {
      setUser(user);
      globalPermissionsUpdate(ability, user.isAdmin);
      if (pathname === '/login') {
        history.push('/companies');
      }
    },
    onError: () => {
      setUser(null);
    }
  });

  const handleLocationReload = () => window.location.reload();

  return (
    <ErrorBoundary FallbackComponent={Fallback} onReset={handleLocationReload}>
      <Layout>
        <GlobalAbilityContext.Provider value={ability}>
          <Switch>
            {publicRoutes.map((route) => (
              <Route key={route.path} {...route} />
            ))}

            {routes
              .filter((route) => !route.disabled)
              .map((route) => (
                <Route key={route.path} {...route} />
              ))}
            {user ? <Redirect to={'/companies'} /> : <Redirect to={'/login'} />}
          </Switch>
        </GlobalAbilityContext.Provider>
      </Layout>
    </ErrorBoundary>
  );
}

export default App;
