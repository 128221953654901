import React from 'react';
import { Formik, Form, FormikHelpers, FormikErrors } from 'formik';

import Restaurant from './Restaurant/Restaurant';
import MainFields from './MainFields/MainFields';
import BankFields from './BankFields/BankFields';
import CashBankFields from './CashBankFields/CashBankFields';
import Partners from './Partners/Partners';
import VfmsIntegration from './VFMSintegration/VFMSintegration';
import Logo from './Logo/Logo';
import { Button, Error, Loader, Modal } from 'components';
import { useToast } from 'components/hooks';
import { CompanyDto } from 'api';
import { emptyValues } from './company.initial';
import { companySchema } from './company.validation';

import { Flex, SimpleWrapper } from 'style';
import { StyledGrid } from './company-management.styles';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (values: CompanyDto, helpers: FormikHelpers<CompanyDto>) => void;
  setLogo: (logo: File) => void;
  initialValues?: CompanyDto;
  title: 'New company' | 'Edit company';
  isLoading?: boolean;
  showError?: boolean;
  isEdit?: boolean;
}

const CompanyManagement: React.FC<Props> = ({ isOpen, initialValues, handleClose, handleSubmit, setLogo, isEdit, title, isLoading, showError }) => {
  const values: CompanyDto = initialValues || emptyValues;

  const { error } = useToast();

  const handleErrors = (errors: FormikErrors<CompanyDto>) => () => {
    if (typeof errors.shareholders === 'string') {
      error(errors.shareholders)();
    }
  };

  const closeWithReset = (resetForm: () => void) => () => {
    handleClose();
    resetForm();
  };

  const handleFormSubmit = (values: CompanyDto, helpers: FormikHelpers<CompanyDto>) => {
    handleSubmit(values, helpers);
    if (values?.logo && values.logo instanceof File) {
      setLogo(values.logo);
    }
  };

  const showContent = !isLoading && !showError;

  return (
    <Formik initialValues={values} onSubmit={handleFormSubmit} validationSchema={companySchema} enableReinitialize>
      {({ errors, values, resetForm, isSubmitting }) => {
        return (
          <Modal isOpen={isOpen} handleClose={closeWithReset(resetForm)} title={title} fullMobile={true} width={'712px'}>
            <SimpleWrapper>
              <Form>
                {isLoading && <Loader />}
                {showError && <Error>Cannot fetch data</Error>}
                {showContent && (
                  <StyledGrid>
                    <div>
                      <MainFields />
                      <BankFields />
                    </div>
                    <Flex direction={'column'} justify={'space-between'} align={'stretch'} width={'100%'}>
                      <CashBankFields />
                      <Partners isEdit={isEdit} />
                      <Restaurant />
                      <VfmsIntegration />
                      <Logo />
                      <Flex justify={'flex-end'} margin={'24px 0'}>
                        <Button buttonType={'secondary'} onClick={closeWithReset(resetForm)} disabled={isSubmitting}>
                          Cancel
                        </Button>
                        <Button type={'submit'} onClick={handleErrors(errors)} disabled={isSubmitting}>
                          Save
                        </Button>
                      </Flex>
                    </Flex>
                  </StyledGrid>
                )}
              </Form>
            </SimpleWrapper>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default CompanyManagement;
