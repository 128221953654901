import React, { useContext } from 'react';

import Details from './components/Details/Details';
import Financial from './components/Financial/Financial';
import Partners from './components/Partners/Partners';
import Borrowings from './components/Borrowings/Borrowings';
import Loans from './components/Loans/Loans';
import { PageHeader } from 'components';
import { CompanyContext } from 'context/company.context';
import { CompanyCan as Can } from 'permissions';

import { Grid } from 'style';
import { DoubleGrid } from './main.styles';

const Main: React.FC = () => {
  const { companyName } = useContext(CompanyContext);

  return (
    <>
      <PageHeader heading={companyName || 'Loading...'} description={'Overview'} />
      <DoubleGrid>
        <Details />
        <Financial />
      </DoubleGrid>
      <Can I={'manage'} a={'CompanyLoan'}>
        <Grid columns={'1fr'} margin={'24px 0 0 0'}>
          <Loans />
        </Grid>
        <Grid columns={'1fr'} margin={'24px 0 0 0'}>
          <Borrowings />
        </Grid>
        <Grid margin={'24px 0'} columns={'1fr'}>
          <Partners />
        </Grid>
      </Can>
    </>
  );
};

export default Main;
