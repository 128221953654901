import React from 'react';

import { Button } from 'components';

import { BottomWrapper, PlusIcon } from 'style';

interface Props {
  handleOpen: () => void;
}

const AddButton: React.FC<Props> = ({ handleOpen }) => {
  return (
    <BottomWrapper>
      <Button buttonType={'borderless'} onClick={handleOpen}>
        <PlusIcon />
        Add
      </Button>
    </BottomWrapper>
  );
};

export default AddButton;
