import React from 'react';
import { useParams } from 'react-router-dom';

import { Heading, Loader, Empty, Error, DeleteModal, Table } from 'components';
import { useFetch, useShowContent, usePermissions, useDelete, useCall, useToast } from 'components/hooks';
import { getRestaurantReports, deleteReport, downloadReport } from 'api';
import { prepareColumns, DropdownValue } from './restaurant.columns';
import { downloadFile } from 'utils';
import { RestaurantReport } from 'models';

import { Card } from 'style';

interface Props {
  reportsRefreshDate: Date;
}

const RestaurantTable: React.FC<Props> = ({ reportsRefreshDate }) => {
  const { companyPermissions } = usePermissions();
  const { success, error } = useToast();
  const { companyId } = useParams<{ companyId: string }>();
  const { itemToDelete, setItemToDelete, closeDeletePopup, isDeletePopupOpen } = useDelete<RestaurantReport>();

  const reportsData = useFetch(getRestaurantReports(companyId), { dependencies: [reportsRefreshDate] });
  const { showContent, showNoContent, showError, showLoader } = useShowContent(reportsData);
  const { payload: reports, deleteSingleItem } = reportsData;

  const { submit, onCallSuccess, onCallError } = useCall(deleteReport(companyId));
  onCallError(error('Failed to delete the report'));
  onCallSuccess(({ id }) => {
    deleteSingleItem({ itemId: id });
    closeDeletePopup();
    success('Report has been deleted');
  });

  const { submit: download, onCallSuccess: onDownloadSuccess, onCallError: onDownloadError } = useCall(downloadReport(companyId));
  onDownloadError(error('Failed to download the report'));
  onDownloadSuccess((payload, headers) => {
    const fileName = headers['x-file-name'];
    downloadFile(payload, fileName);
  });

  const handleReportDownload = (id: number) => download(id);

  const handleReportDelete = async () => {
    if (itemToDelete) {
      await submit(itemToDelete.id);
    }
  };

  const handleDropdownSelect = ({ type, item }: DropdownValue) => {
    type === 'delete' && setItemToDelete(item);
  };

  const columns = prepareColumns({ handleDropdownSelect, ability: companyPermissions, handleReportDownload });

  return (
    <Card height={'auto'}>
      <Heading>Past reports</Heading>
      {showLoader && <Loader />}
      {showError && <Error>Failed to fetch</Error>}
      {showNoContent && <Empty>No data</Empty>}
      {showContent && reports && <Table<RestaurantReport> columns={columns} data={reports} />}
      <DeleteModal handleClose={closeDeletePopup} isOpen={isDeletePopupOpen} handleDelete={handleReportDelete} />
    </Card>
  );
};

export default RestaurantTable;
