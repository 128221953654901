import React, { useContext, useLayoutEffect, useMemo, useRef } from 'react';
import { useFormikContext } from 'formik';

import AccountBuilder, { renderKeys } from '../account-builder/account-builder';
import { Button, FormField } from 'components';
import { Option } from 'types';
import { CashFlowDto } from '../types/cash-flow.model';
import { accountOptions } from '../utils/account.select';
import { CashFlowContext } from 'form/transaction/context/cash-flow.context';
import { Supplier } from 'models';

import { Flex, Grid, PlusIcon } from 'style';

interface Props {
  onOpenAddSupplierModal: () => void;
  suppliers: Supplier[];
}

const Accounts = ({ onOpenAddSupplierModal, suppliers }: Props) => {
  const {
    values: { account, accountData },
    setFieldValue
  } = useFormikContext<CashFlowDto>();
  const { defaultLoanData } = useContext(CashFlowContext);

  const initialRender = useRef(true);

  useLayoutEffect(() => {
    initialRender.current = false;
  }, []);

  const suppliersOptions: Option[] = useMemo(() => {
    if (suppliers) {
      return suppliers.map(({ id, name }) => ({ name, value: id }));
    } else {
      return [];
    }
  }, [suppliers]);

  const resetAccountData = () => {
    const type = accountData.type;
    const lender = accountData.lender;

    setFieldValue('splitType', null);
    setFieldValue('splitData', []);
    // setFieldValue('accountData', {});

    // setFieldValue('accountData.supplierId', undefined);
    setFieldValue('accountData.type', type);
    setFieldValue('accountData.lender', lender);
  };

  const disableSupplier = suppliers.length < 1;
  const disableAccountSelect = !!defaultLoanData?.type;
  const placeholder = suppliers.length < 1 ? 'No suppliers to select' : 'Select a supplier';

  const renderSupplier = account ? !renderKeys.includes(account) : true;

  return (
    <>
      <Flex direction={'column'} gap={8} align={'stretch'}>
        <FormField name={'description'} type={'text'} placeholder={'Description'} label={'Description'} />
        {!disableAccountSelect && (
          <FormField
            name={'account'}
            type={'combobox'}
            items={accountOptions}
            placeholder={'Account'}
            label={'Account'}
            width={'100%'}
            onFieldChange={resetAccountData}
            disabled={disableAccountSelect}
          />
        )}
        {renderSupplier && (
          <Grid columns={'1fr 60px'} gap={0} align={'end'} justify={'center'}>
            <FormField
              name={'accountData.supplierId'}
              type={'select'}
              items={suppliersOptions}
              placeholder={placeholder}
              label={'Supplier'}
              disabled={disableSupplier}
            />
            <Button buttonType={'secondary-accent'} onClick={onOpenAddSupplierModal}>
              <PlusIcon />
            </Button>
          </Grid>
        )}
        <AccountBuilder />
      </Flex>
    </>
  );
};

export default Accounts;
