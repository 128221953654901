import fetchMiddleware from 'api/api.middleware';
import { IdParam } from 'types';
import { Generator } from 'models';
import { GeneratorsDto, DeleteGeneratorResponseDto } from './api.generators.dto';

export const getGenerators = (companyId: IdParam) => () => fetchMiddleware<Generator[]>({ method: 'get', url: `/companies/${companyId}/generators` });

export const getGenerator = (companyId: IdParam, generatorId: IdParam) => () =>
  fetchMiddleware<Generator>({ method: 'get', url: `/companies/${companyId}/generators/${generatorId}` });

export const postGenerator = (companyId: IdParam) => (data: GeneratorsDto) =>
  fetchMiddleware<Generator>({ method: 'post', url: `/companies/${companyId}/generators`, data });

export const putGenerator = (companyId: IdParam, generatorId: IdParam) => (data: GeneratorsDto) =>
  fetchMiddleware<Generator>({ method: 'put', url: `/companies/${companyId}/generators/${generatorId}`, data });

export const deleteGenerator = (companyId: IdParam, generatorId: IdParam) =>
  fetchMiddleware<DeleteGeneratorResponseDto>({
    method: 'delete',
    url: `/companies/${companyId}/generators/${generatorId}`,
    data: {}
  });
