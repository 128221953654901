import React from 'react';
import { Form, Formik, FormikHelpers } from 'formik';

import MainFields from './MainFields/MainFields';
import AdditionalPay from './AdditionalPay/AdditionalPay';
import { Button, Error, FormField, Loader, Modal } from 'components';
import { usePermissions } from 'components/hooks';
import { SalaryDto } from 'api';
import { salarySchema } from './salary.validation';

import { Flex, SimpleWrapper } from 'style';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (values: SalaryDto, helpers: FormikHelpers<SalaryDto>) => void;
  title: 'New salary' | 'Edit salary';
  initialValues?: SalaryDto;
  isLoading?: boolean;
  showError?: boolean;
}

const emptyValues: SalaryDto = {
  date: new Date(),
  baseTzs: 0,
  baseUsd: 0,
  loansTzs: 0,
  loansUsd: 0,
  overtime: 0,
  publicHolidays: 0,
  advanceRepayment: 0,
  repayments: 0,
  travelAllowance: 0,
  bonus: 0,
  bankTzs: 'znz',
  bankUsd: 'znz',
  comment: ''
};

const SalaryManagement: React.FC<Props> = ({ isOpen, handleClose, handleSubmit, initialValues, title, isLoading, showError }) => {
  const values: SalaryDto = initialValues || emptyValues;
  const { companyPermissions } = usePermissions();

  const closeWithReset = (resetForm: () => void) => () => {
    handleClose();
    resetForm();
  };

  const showContent = !isLoading && !showError;
  const canManageUsdSalaries = companyPermissions.can('manage', 'EmployeeSalaryUsd');
  const submitButtonText = canManageUsdSalaries ? 'Save & Download Payslip' : 'Save';

  return (
    <Formik<SalaryDto> initialValues={values} onSubmit={handleSubmit} enableReinitialize validationSchema={salarySchema}>
      {({ resetForm, isSubmitting }) => {
        return (
          <Modal isOpen={isOpen} handleClose={closeWithReset(resetForm)} title={title} dropdown={false} width={'420px'}>
            <SimpleWrapper>
              <Form>
                {isLoading && <Loader />}
                {showError && <Error>Cannot fetch employee</Error>}
                {showContent && (
                  <>
                    <MainFields isOpen={isOpen} canManageUsdSalaries={canManageUsdSalaries} />
                    {canManageUsdSalaries && <AdditionalPay />}
                    <FormField name={'comment'} label={'Comment'} type={'textarea'} />
                    <Flex justify={'flex-end'} margin={'24px 0'}>
                      <Button buttonType={'secondary'} onClick={closeWithReset(resetForm)}>
                        Cancel
                      </Button>
                      <Button type={'submit'} loading={isSubmitting}>
                        {submitButtonText}
                      </Button>
                    </Flex>
                  </>
                )}
              </Form>
            </SimpleWrapper>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default SalaryManagement;
