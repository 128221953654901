import { createGlobalStyle } from 'styled-components';

import { reset } from './reset';
import { theme, colors, font } from './theme';

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
  }
  
  ${reset};
  
  html {
    font-size: 62.5%;
  }
  
  body {
    margin: 0;
    font-weight: 400;
    font-size: 1.6rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: ${font.family.Inter};
    overflow-x: hidden;
    color: ${colors.gray.scale2};
    box-sizing: border-box;
  }
  
  input, button, textarea {
    font-family: Inter, sans-serif;
  }

  p {
    font-size: 14px;
  }
  
  button{
    cursor: pointer;
  }
  
  a {
    color: ${colors.gray.scale1};
    text-decoration: none;
    font-size: 14px;
    
    ${theme.mq.standard}{
      font-size: 16px;
    }
  }
  
  textarea{
    border: none;
  }
  
  textarea:focus{
    outline: none;
  }
 
 &::-webkit-scrollbar {
    width: 2px;
    height: 6px;
  }
  
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: #eee;
    border-radius: 20px;
  }

  .no-select {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  }
  
  #dropdown {
    z-index:2;
    position: relative;
  }
  .portal-elevated-1 {
    z-index: 3;
  }
  .portal-elevated-2 {
    z-index: 4;
  }
  .portal-elevated-3 {
    z-index: 5;
  }
`;

export default GlobalStyle;
