import React from 'react';
import { useRouteMatch, Switch, Route } from 'react-router-dom';

import { usePermissions } from 'components/hooks';
import { handlePreparePath } from 'utils/routes';
import { companyRoutes } from 'config';

const CompanyNestedRoutes: React.FC = () => {
  const { companyPermissions } = usePermissions();
  const { path: routePath } = useRouteMatch();

  return (
    <Switch>
      {companyRoutes(companyPermissions)
        .filter((route) => !route.disabled)
        .map(({ path, ...props }) => (
          <Route key={path} path={handlePreparePath(routePath, path)} {...props} />
        ))}
    </Switch>
  );
};

export default CompanyNestedRoutes;
