import React from 'react';
import { useParams } from 'react-router-dom';

import CurrencyCells from './CurrencyCells/CurrencyCells';
import SingleValueCells from './SingleValueCells/SingleValueCells';
import { Loader, Error, Empty } from 'components';
import { useFetch, usePermissions, useShowContent } from 'components/hooks';
import { getTaxes } from 'api';
import { Tax } from 'models';

import { Card } from 'style';
import { Table, Row, LeftCell } from './summary.styles';
import { TableWrapper } from 'components/ui/Table/table.styles';

type Summary = {
  name: string;
  key: keyof Tax;
};

interface Props {
  dateRange: [Date, Date];
}

const taxes: Summary[] = [
  { name: 'Infrastructure TAX', key: 'infrastructureTax' },
  { name: 'VRN', key: 'vat' },
  { name: 'VRN deducted', key: 'vatDeducted' },
  { name: 'VRN to be paid', key: 'vatFinal' },
  { name: 'ZSFF', key: 'zsff' },
  { name: 'PAYE', key: 'paye' },
  { name: 'SDL', key: 'sdl' },
  { name: 'Summary', key: 'summaryTzs' }
];

const Summary: React.FC<Props> = ({ dateRange }) => {
  const { companyId } = useParams<{ companyId: string }>();
  const { companyPermissions } = usePermissions();

  const taxData = useFetch(getTaxes(companyId, dateRange), { dependencies: [dateRange] });
  const { showContent, showLoader, showError, showNoContent } = useShowContent(taxData);
  const { payload: tax } = taxData;

  const canReadTaxInPln = companyPermissions.can('read', 'TaxPln');

  return (
    <Card>
      {showLoader && <Loader />}
      {showNoContent && <Empty>No data</Empty>}
      {showError && <Error>Failed to fetch</Error>}
      {showContent && tax && (
        <TableWrapper>
          <Table>
            <tbody>
              {taxes.map(({ name, key }) => {
                const value = tax[key];

                return (
                  <Row key={name}>
                    <LeftCell>{name}</LeftCell>
                    {typeof value === 'object' && <CurrencyCells value={value} canReadTaxInPln={canReadTaxInPln} />}
                    {typeof value !== 'object' && <SingleValueCells key={key} value={value} canReadTaxInPln={canReadTaxInPln} />}
                  </Row>
                );
              })}
            </tbody>
          </Table>
        </TableWrapper>
      )}
    </Card>
  );
};

export default Summary;
