import React, { useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { useParams } from 'react-router-dom';

import { FormField, Button, InputError } from 'components';
import { useFetch, useShowContent } from 'components/hooks';
import { getCompanies } from 'api';
import { RadioOption, Option } from 'types';
import { CashFlowDto } from '../types';
import { emptySplit } from '../cash-flow.values';
import { incomeAccounts, incomeExpenseAccounts } from '../utils/account.select';
import { bankSelectItems } from 'utils';

import { Grid, Flex, Paragraph } from 'style';

const options: RadioOption[] = [
  { name: 'No', value: null },
  { name: 'Pay for others', value: 'pay' },
  { name: 'Loan from others', value: 'loan' }
];

const PaymentSplit: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const {
    values: { splitType, splitData, account, amount },
    errors,
    setFieldValue
  } = useFormikContext<CashFlowDto>();

  const companiesData = useFetch(getCompanies);
  const { showLoader } = useShowContent(companiesData);
  const { payload: companies } = companiesData;

  const companyOptions: Option[] = useMemo(() => {
    if (companies) {
      return companies.map(({ id, name }) => ({ name, value: id }));
    } else {
      return [];
    }
  }, [companies]);

  useEffect(() => {
    if (splitType === null) {
      setFieldValue('splitData', []);
    } else {
      setFieldValue('splitData', [emptySplit]);
    }
  }, [splitType]);

  const handleAddSplit = () => setFieldValue('splitData', [...splitData, emptySplit]);

  const handleCompanyOptions = (removeCurrentCompany: boolean): Option[] => {
    const companyIds = splitData.map(({ companyId }) => companyId);
    const removeSelectedCompanies = (option: Option) => !companyIds.includes(option.value);

    if (removeCurrentCompany) {
      const removeCurrent = (option: Option) => option.value !== +companyId;

      return companyOptions.filter(removeSelectedCompanies).filter(removeCurrent);
    } else {
      return companyOptions.filter(removeSelectedCompanies);
    }
  };

  //* only expense accounts
  const showSplitForm = account && !incomeAccounts.includes(account) && !incomeExpenseAccounts.includes(account);

  const filteredCompanyOptions = handleCompanyOptions(splitType === 'loan');

  const splitSum = splitData.reduce((total, split) => total + (split.amount || 0), 0);
  const leftToPay = amount ? (amount - splitSum).toFixed(2) : 0;

  return (
    <>
      {showSplitForm && (
        <>
          <Grid columns={'1fr 2fr'} align={'start'} justify={'start'}>
            <p>Payment split</p>
            <div>
              <FormField name={'splitType'} type={'radio'} options={options} verticalOptions={true} gap={2} radioMargin={'0'} />
            </div>
          </Grid>
          {splitType && (
            <Flex direction={'column'} gap={8} margin={'16px 0 42px 0'} align={'flex-start'}>
              {splitData.map((data, index) => (
                <>
                  <FormField
                    name={`splitData[${index}].companyId`}
                    type={'select'}
                    items={filteredCompanyOptions}
                    placeholder={'Company'}
                    disabled={showLoader}
                    width={'100%'}
                  />
                  <FormField name={`splitData[${index}].amount`} type={'money'} prefix={''} placeholder={'Split'} />
                  <FormField name={`splitData[${index}].bank`} type={'select'} items={bankSelectItems} placeholder={'Bank'} disabled={true} width={'100%'} />
                </>
              ))}
              {typeof errors.splitData === 'string' && <InputError message={errors.splitData} />}
              <Flex justify={'space-between'} width={'100%'}>
                <Button onClick={handleAddSplit} disabled={splitData.length === filteredCompanyOptions.length}>
                  Add more split
                </Button>
                <Paragraph>{leftToPay}</Paragraph>
              </Flex>
            </Flex>
          )}
        </>
      )}
    </>
  );
};

export default PaymentSplit;
