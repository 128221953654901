import React, { useContext } from 'react';
import { useFormikContext } from 'formik';

// import { Button } from 'components';
// import Button from '../../../../components/form/Button/Button';
import Button from 'components/form/Button/Button';

// import { CashFlowDto } from 'form/transaction/cash-flow-form/types';
import { CashFlowDto } from '../../cash-flow-form/types/cash-flow.model';

import { StyledFlex } from './submit-buttons.styles';
// import { CashFlowContext } from 'form/transaction/context/cash-flow.context';
import { CashFlowContext } from '../../context/cash-flow.context';

interface Props {
  handleClose: () => void;
  isEdit?: boolean;
}

const SubmitButtons: React.FC<Props> = ({ handleClose, isEdit }) => {
  const { isSubmitting, submitForm } = useFormikContext<CashFlowDto>();
  const { setReinitialize } = useContext(CashFlowContext);

  const handleFormSubmit = async () => {
    await submitForm();
    if (isEdit) {
      setReinitialize(false);
    }
  };

  return (
    <StyledFlex justify={'flex-end'} margin={'24px 0'}>
      <Button buttonType={'secondary'} onClick={handleClose} disabled={isSubmitting}>
        Cancel
      </Button>
      <Button type={'button'} onClick={handleFormSubmit} disabled={isSubmitting} loading={isSubmitting}>
        Save
      </Button>
    </StyledFlex>
  );
};

export default SubmitButtons;
