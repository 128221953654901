export const shareholderPermissions = (can: any, cannot: any) => {
  can('read', 'all');
  can('manage', 'Dashboard');

  /* Dashboard */
  can('read', 'BankCheck');
  can('read', 'BankCheckZnz');
  can('read', 'BankCheckPl');
  can('read', 'BankCheckCash');
  can('read', 'SelfPartner');

  cannot('read', 'SinglePartner');

  /* Cash Flow */
  can('read', 'CashFlow');
  can('manage', 'Transaction');
  can('read', 'TransactionCash');
  can('read', 'TransactionZnz');
  can('read', 'TransactionPL');
  can('select', 'AllTransactionBanks');
  can('read', 'CashStatistics');
  can('generate', 'TransactionReport');
  can('generate', 'PettyCash');
  can('manage', 'CompanyLoan');
  can('create', 'ExchangedTransaction');

  /* Reservations */
  can('read', 'Reservations');
  can('generate', 'InvoiceCash');
  can('generate', 'InvoiceZnz');
  can('generate', 'InvoicePl');
  can('read', 'ReservationDetails');

  cannot('update', 'ReservationDetails');

  /* Salaries */
  can('manage', 'Salaries');
  can('manage', 'EmployeePersonal');
  can('manage', 'EmployeeTaxes');
  can('manage', 'EmployeeLoan');
  can('manage', 'EmployeeSalary');
  can(['create', 'read', 'update', 'delete'], 'Employee');
  can('update', 'EmployeeStatus');
  can('generate', 'Payslip');
  can('add', 'Payslip');
  can('manage', 'EmployeeSalaryUsd');

  cannot('delete', 'EmployeeLoan');

  /* Restaurant */
  can('read', 'Restaurant');
  can(['read', 'download'], 'RestaurantReports');
  can(['read', 'update'], 'RestaurantDetails');

  cannot('generate', 'RestaurantReports');
  cannot('read', 'RestaurantRealRevenue');

  /* Tax */
  can('read', 'Tax');
  can('read', 'TaxReports');
  can('read', 'TaxPln');

  cannot('select', 'AllTaxBanks');
  cannot('generate', 'TaxReports');

  /* Suppliers */
  can('manage', 'Supplier');
  can('read', ['SupplierDetails', 'SupplierInvoices']);

  /* Settings */
  can('manage', 'Settings'); //show in menu when read === true
  can('manage', 'TourOperators');
  can('manage', 'AccountValues');

  /* Logs */
  can(['manage', 'read'], 'Log');

  /* Notifications */
  can('manage', 'Notifications');

  /* Users */
  can('read', 'User');

  cannot(['create', 'update', 'delete'], 'User');
};
