import styled from 'styled-components';

import { Paragraph } from 'style';

const Wrapper = styled.div`
  margin-bottom: 16px;

  button {
    padding: 0;
  }
`;

const StyledParagraph = styled(Paragraph)`
  width: 170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export { Wrapper, StyledParagraph };
