import { useSelect, UseSelectProps } from 'downshift';

import { itemToString } from 'utils';
import { Option } from 'types';

interface OptionsArguments {
  items: Option[];
  selectedItem: Option | undefined | null;
  onChange: (value: any) => void;
}

export const options = ({ items, selectedItem, onChange }: OptionsArguments): UseSelectProps<Option> => ({
  items,
  itemToString,
  initialSelectedItem: selectedItem,
  selectedItem: selectedItem,
  onSelectedItemChange: ({ selectedItem }) => onChange(selectedItem?.value),
  stateReducer: (state, actionAndChanges) => {
    const { changes, type } = actionAndChanges;
    switch (type) {
      case useSelect.stateChangeTypes.MenuKeyDownEnter:
      case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
      case useSelect.stateChangeTypes.ItemClick:
        return {
          ...changes,
          isOpen: false
        };
    }
    return changes;
  }
});
