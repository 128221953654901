import { object, date, number, SchemaOf } from 'yup';

import { PostRestaurantReportDto } from 'api';
import { message } from 'utils';

export const reportValidationSchema: SchemaOf<PostRestaurantReportDto> = object().shape({
  percentage: number().min(0, message.min(0)).max(100, message.max(100)).required(message.required),
  startDate: date().required(message.required),
  endDate: date().required(message.required)
});
