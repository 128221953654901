import React from 'react';
import { Formik, Form, FormikHelpers } from 'formik';

import { Button, Error, FormField, Loader, Modal } from 'components';
import { accountSchema } from './account.validation';

import { Flex, SimpleWrapper } from 'style';

interface AccountsDto {
  name: string;
}

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (values: AccountsDto, helpers: FormikHelpers<AccountsDto>) => void;
  title: string;
  initialValues?: AccountsDto;
  buttonText?: string;
  isLoading?: boolean;
  showError?: boolean;
}

const emptyValues: AccountsDto = {
  name: ''
};

const AccountManagement: React.FC<Props> = ({ isOpen, handleClose, handleSubmit, initialValues, title, buttonText, isLoading, showError }) => {
  const values: AccountsDto = initialValues || emptyValues;

  const closeWithReset = (resetForm: () => void) => () => {
    handleClose();
    resetForm();
  };

  const showContent = !isLoading && !showError;

  return (
    <Formik initialValues={values} onSubmit={handleSubmit} enableReinitialize={true} validationSchema={accountSchema}>
      {({ resetForm, isSubmitting }) => (
        <Modal isOpen={isOpen} handleClose={closeWithReset(resetForm)} title={title}>
          <SimpleWrapper>
            <Form>
              {isLoading && <Loader />}
              {showError && <Error>Cannot fetch data</Error>}
              {showContent && (
                <>
                  <Flex direction={'column'} gap={8}>
                    <FormField type={'text'} name={'name'} placeholder={'Name'} />
                  </Flex>
                  <Flex justify={'flex-end'} margin={'24px 0 0 0'}>
                    <Button buttonType={'secondary'} onClick={closeWithReset(resetForm)}>
                      Cancel
                    </Button>
                    <Button type={'submit'} disabled={isSubmitting}>
                      {isSubmitting ? <Loader /> : buttonText || 'Add'}
                    </Button>
                  </Flex>
                </>
              )}
            </Form>
          </SimpleWrapper>
        </Modal>
      )}
    </Formik>
  );
};

export default AccountManagement;
