import React from 'react';

import { Heading, Formatter } from 'components';
import { displayAmount } from 'utils';

import { BalanceWrapper, StyledGrid, Wrapper } from './balance-values.styles';

interface Props {
  heading: string;
  tzs?: number;
  pln?: number;
  eur: number;
  usd: number;
  background?: boolean;
}

interface TzsProps extends Props {
  tzs: number;
  pln?: never;
}

interface PlnProps extends Props {
  pln: number;
  tzs?: never;
}

type ValuesProps = TzsProps | PlnProps;

const BalanceValues: React.FC<ValuesProps> = ({ tzs, pln, eur, usd, heading, background }) => {
  const headingText = heading || 'BALANCE ON START';

  return (
    <Wrapper background={background}>
      <Heading type={'h6'} margin={'0 0 12px 0'} color={'scale1'}>
        {headingText}
      </Heading>
      <StyledGrid columns={'repeat(3, 1fr)'}>
        {typeof pln !== 'undefined' && (
          <BalanceWrapper>
            <Formatter {...displayAmount(pln, 'pln')} size={'p3'} />
          </BalanceWrapper>
        )}
        {typeof tzs !== 'undefined' && (
          <BalanceWrapper>
            <Formatter {...displayAmount(tzs, 'tzs')} />
          </BalanceWrapper>
        )}
        {typeof eur !== 'undefined' && (
          <BalanceWrapper>
            <Formatter {...displayAmount(eur, 'eur')} />
          </BalanceWrapper>
        )}
        {typeof usd !== 'undefined' && (
          <BalanceWrapper>
            <Formatter {...displayAmount(usd, 'usd')} />
          </BalanceWrapper>
        )}
      </StyledGrid>
    </Wrapper>
  );
};

export default BalanceValues;
