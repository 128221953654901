import React, { useState } from 'react';

import { User } from 'models';

type AuthenticateContextType = {
  user: User | null;
  setUser: (user: User | null) => void;
};

export const AuthenticateContext = React.createContext<AuthenticateContextType>({
  user: null,
  setUser: (user: User | null) => {}
});

interface Props {
  children: React.ReactNode;
}

const AuthenticateContextProvider: React.FC<Props> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);

  return (
    <AuthenticateContext.Provider
      value={{
        user,
        setUser
      }}
    >
      {children}
    </AuthenticateContext.Provider>
  );
};

export default AuthenticateContextProvider;
