import React from 'react';

import BankPL from './components/BankPL/BankPL';
import BankZNZ from './components/BankZNZ/BankZNZ';
import CashBank from './components/CashBank/CashBank';
import { PageHeader } from 'components';
import { useDateRange, usePermissions } from 'components/hooks';

import { StyledGrid } from './BankCheck.styles';

const BankCheck: React.FC = () => {
  const { dateRange, handleDateRange } = useDateRange();

  const { companyPermissions } = usePermissions();

  const canReadPl = companyPermissions.can('read', 'BankCheckPl');
  const canReadZnz = companyPermissions.can('read', 'BankCheckZnz');
  const canReadCash = companyPermissions.can('read', 'BankCheckCash');

  return (
    <>
      <PageHeader heading={'Bank Check'} handleDateChange={handleDateRange} />
      <StyledGrid columns={'1fr'} gap={24}>
        {canReadPl && <BankPL dateRange={dateRange} />}
        {canReadZnz && <BankZNZ dateRange={dateRange} />}
        {canReadCash && <CashBank dateRange={dateRange} />}
      </StyledGrid>
    </>
  );
};

export default BankCheck;
