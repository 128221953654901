import React from 'react';
import { useParams } from 'react-router-dom';
import { useAbility } from '@casl/react';

import CompanyNestedRoutes from './company.nested-routes';
import { View } from 'components';
import { getCompanyRole } from 'api';
import { useFetch } from 'components/hooks';
import { companyPermissionsUpdate } from 'permissions';
import { CompanyAbilityContext } from 'permissions/company/company-permissions.context';

const Company: React.FC = () => {
  const ability = useAbility(CompanyAbilityContext);

  const { companyId } = useParams<{ companyId: string }>();

  useFetch(getCompanyRole(companyId), {
    onSuccess: ({ role, isAdmin }) => {
      if (isAdmin) {
        //always admin
        companyPermissionsUpdate(ability, 'admin');
      } else {
        companyPermissionsUpdate(ability, role);
      }
    }
  });

  return (
    <View>
      <CompanyNestedRoutes />
    </View>
  );
};

export default Company;
