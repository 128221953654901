import { FieldProps } from 'components';
import { SupplierDto } from 'api';

export const supplierFields: FieldProps[] = [
  { name: 'name', type: 'text', placeholder: 'Name', label: 'Name' },
  { name: 'address', type: 'text', placeholder: 'Address', label: 'Address' },
  { name: 'phone', type: 'phone', placeholder: 'Phone', label: 'Phone' },
  { name: 'vatId', type: 'text', placeholder: 'VRN no.', label: 'VRN no.' },
  { name: 'tinId', type: 'text', placeholder: 'TIN no.', label: 'TIN no.' },
  { name: 'zrbId', type: 'text', placeholder: 'ZRB no.', label: 'ZRB no.' },
  { name: 'bankAccount', type: 'bank', placeholder: 'Account no.', label: 'Account no.' },
  { name: 'bankName', type: 'text', placeholder: 'Bank Name', label: 'Bank Name' },
  { name: 'branchName', type: 'text', placeholder: 'Branch Name', label: 'Branch Name' },
  { name: 'description', type: 'textarea', placeholder: 'Description', label: 'Description' }
];

export const emptyValues: SupplierDto = {
  name: '',
  address: '',
  phone: '',
  vatId: '',
  tinId: '',
  bankAccount: '',
  description: ''
};
