import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import PersonalData from './PersonalData/PersonalData';
import Taxes from './Taxes/Taxes';
import Loans from './Loans/Loans';
import Salaries from './Salaries/Salaries';
import { Button, PageHeader } from 'components';
import { useFetch, usePermissions } from 'components/hooks';
import { EditEmployee } from 'pages/Company/views/Employee/modals';
import { getEmployee } from 'api';

import { StyledGrid, MarginGrid, SalariesWrapper } from './single-employee.styles';

const SingleEmployee: React.FC = () => {
  const { companyPermissions } = usePermissions();
  const { companyId, employeeId } = useParams<{ companyId: string; employeeId: string }>();

  const [loansRefreshDate, setRefreshDate] = useState<Date>(new Date());
  const [employeeName, setEmployeeName] = useState<string>('');
  const [editModalOpen, setEditModalOpen] = useState(false);

  const handleLoansRefresh = () => setRefreshDate(new Date());
  const closeEditModal = () => setEditModalOpen(false);
  const openEditModal = () => setEditModalOpen(true);

  const showPersonalData = companyPermissions.can('read', 'EmployeePersonal');
  const showTaxes = companyPermissions.can('read', 'EmployeeTaxes');
  const showLoans = companyPermissions.can('read', 'EmployeeLoan');
  const showSalaries = companyPermissions.can('read', 'EmployeeSalary');
  const showEditEmployee = companyPermissions.can('update', 'Employee');

  const employeeData = useFetch(getEmployee(companyId, employeeId), { onSuccess: ({ name }) => setEmployeeName(name) });
  const { payload: employee, updateElement } = employeeData;

  return (
    <>
      <PageHeader
        heading={`Employee: ${employeeName}`}
        customSelect={
          <Button buttonType={'secondary'} onClick={openEditModal}>
            Edit employee
          </Button>
        }
      />
      <StyledGrid>
        {showPersonalData ? <PersonalData setEmployeeName={setEmployeeName} /> : <div />}
        {showTaxes ? <Taxes /> : <div />}
      </StyledGrid>

      {showLoans && (
        <MarginGrid>
          <Loans refreshDateFromSalary={loansRefreshDate} />
        </MarginGrid>
      )}

      {showSalaries && (
        <SalariesWrapper>
          <Salaries refreshLoans={handleLoansRefresh} />
        </SalariesWrapper>
      )}

      {employee && showEditEmployee && (
        <EditEmployee isOpen={editModalOpen} handleClose={closeEditModal} item={employee} updateSingleEmployee={updateElement} />
      )}
    </>
  );
};

export default SingleEmployee;
