import { Reservation } from 'models';
import { ReservationDto } from 'api';

export const prepareValues = (reservation: Reservation): ReservationDto => ({
  cashAmount: reservation.cashAmount || 0,
  cashCurrency: reservation.cashCurrency || 'usd',
  reportCash: reservation.reportCash || true,

  bankPlAmount: reservation.bankPlAmount || 0,
  bankPlCurrency: reservation.bankPlCurrency || 'usd',
  reportBankPl: reservation.reportBankPl || true,

  bankZnzAmount: reservation.bankZnzAmount || 0,
  bankZnzCurrency: reservation.bankZnzCurrency || 'usd',
  reportBankZnz: reservation.reportBankZnz || true
});
