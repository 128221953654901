import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { number, object, SchemaOf } from 'yup';

import { FormField } from 'components/index';
import { useFetch, useShowContent } from 'components/hooks';
import { getEmployees } from 'api';
import { message } from 'utils';

import { Flex } from 'style';

export const flightsHotelSchema: SchemaOf<FlightsHotelValue> = object({
  employeeId: number().required(message.required)
});

export type FlightsHotelValue = {
  employeeId: number;
};

const FlightsHotel: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();

  const employeesData = useFetch(getEmployees(companyId));
  const { showLoader, showNoContent } = useShowContent(employeesData);
  const { payload: employees } = employeesData;

  const options = useMemo(() => {
    if (employees) {
      return employees.map(({ name, id }) => ({ name, value: id }));
    }
  }, [employees]);

  const placeholder = showNoContent ? 'No employees to select' : 'Select an employee';
  const disabled = showLoader || showNoContent;

  return (
    <Flex direction={'column'} margin={'8px 0'} gap={8}>
      <FormField name={'accountData.employeeId'} type={'select'} items={options || []} width={'100%'} placeholder={placeholder} disabled={disabled} />
    </Flex>
  );
};

export default FlightsHotel;
