import React, { useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { object, number, mixed, SchemaOf } from 'yup';

import { FormField, InputError } from 'components/index';
import { CurrencyValue } from 'types';
import { CashFlowDto, Exchange as ExchangeInterface } from '../types';
import { currenciesOneOf, currencySelectItems, message } from 'utils';

import { Flex, Grid } from 'style';

type OmitExchangedValues = Omit<ExchangeInterface, 'exchangeRate'>;

export const accountExchangeSchema: SchemaOf<OmitExchangedValues> = object({
  originalAmount: number().positive(message.positive).required(message.required),
  originalCurrency: mixed().oneOf<CurrencyValue>(currenciesOneOf).required(message.currency)
});

const Exchange: React.FC = () => {
  const {
    setFieldValue,
    errors: { currency: currencyError },
    values: { exchangeData, currency }
  } = useFormikContext<CashFlowDto>();

  const selectedOriginalCurrency = exchangeData?.originalCurrency;
  const currenciesToSelectFrom = useMemo(
    () => currencySelectItems.map((item) => (item.value === selectedOriginalCurrency ? { ...item, blocked: true } : item)),
    [selectedOriginalCurrency, currencySelectItems]
  );

  useEffect(() => {
    setFieldValue('exchange', false);
  }, []);
  useEffect(() => {
    if (selectedOriginalCurrency === currency) setFieldValue('currency', currenciesToSelectFrom.find(({ blocked }) => !blocked)?.value);
  }, [selectedOriginalCurrency]);

  return (
    <Flex direction={'column'} gap={8} margin={'8px 0'} align={'stretch'}>
      <Grid columns={'3fr 1fr'} gap={1} rowGap={1} columnGap={1}>
        <FormField name={'exchangeData.originalAmount'} type={'money'} prefix={''} placeholder={'Original amount'} label={'Original amount'} />
        <FormField name={'exchangeData.originalCurrency'} type={'select'} items={currencySelectItems} label={'Currency'} />
      </Grid>
      <Grid columns={'3fr 1fr'}>
        <FormField name={'amount'} type={'money'} prefix={''} placeholder={'exchanged amount'} label={'Exchanged amount'} />
        <FormField name={'currency'} type={'select'} items={currenciesToSelectFrom} label={'Currency'} hideError />
      </Grid>
      {currencyError && <InputError message={currencyError} />}
    </Flex>
  );
};

export default Exchange;
