import React from 'react';
import { useParams } from 'react-router-dom';

import { AddTransaction, EditTransaction } from 'pages/Company/views/CashFlow/modals';
import { Table, Loader, Error, Empty, DeleteModal } from 'components';
import { useFetch, useShowContent, useEdit, usePermissions, useCall, useToast, useDelete } from 'components/hooks';
import { getTransactions, deleteTransaction } from 'api';
import { prepareColumns, DropdownValue } from './cash-table.columns';
import { Transaction } from 'models';
import { PAGE_ITEMS } from 'utils/table';

import { Card, Grid } from 'style';

interface Props {
  isTransactionOpen: boolean;
  handleTransactionClose: () => void;
  dateRange: [Date, Date];
  refreshCashBalance: () => void;
}

const CashTable: React.FC<Props> = ({ isTransactionOpen, handleTransactionClose, dateRange, refreshCashBalance }) => {
  const { companyPermissions } = usePermissions();
  const { success, error } = useToast();
  const { companyId } = useParams<{ companyId: string }>();
  const { itemToEdit, setItemToEdit, isEditPopupOpen, closeEditPopup } = useEdit<Transaction>();
  const { itemToDelete, setItemToDelete, isDeletePopupOpen, closeDeletePopup } = useDelete<Transaction>();

  const transactionsData = useFetch(getTransactions(companyId, dateRange), { dependencies: [dateRange] });
  const { showLoader, showContent, showNoContent, showError } = useShowContent(transactionsData);
  const { payload: transactions, addSingleItem, deleteSingleItem, refresh } = transactionsData;

  const { submit, onCallSuccess, onCallError } = useCall(deleteTransaction(companyId));
  onCallError(error('Cannot delete the transaction'));
  onCallSuccess((response) => {
    response.map((id) => deleteSingleItem({ itemId: id }));
    closeDeletePopup();
    success('Transaction has been deleted');
  });

  const handleDeleteTransaction = async () => {
    if (itemToDelete) {
      await submit(itemToDelete.id);
    }
  };

  const handleDropdownSelect = ({ item, type }: DropdownValue) => {
    type === 'edit' && setItemToEdit(item);
    type === 'delete' && setItemToDelete(item);
  };

  const showCreateTransaction = companyPermissions.can('create', 'Transaction');
  const showUpdateTransaction = companyPermissions.can('update', 'Transaction');

  const columns = prepareColumns({ handleDropdownSelect, ability: companyPermissions });

  return (
    <Grid columns={'1fr'} margin={'24px 0 0 0'}>
      <Card>
        {showLoader && <Loader />}
        {showNoContent && <Empty>No transactions</Empty>}
        {showError && <Error />}
        {showContent && transactions && (
          <Table<Transaction>
            columns={columns}
            data={transactions}
            showGlobalFilter={true}
            showColumnsFilter={true}
            pageItems={PAGE_ITEMS}
            hiddenColumns={['currency']}
            haveDropdown={true}
          />
        )}
      </Card>
      <DeleteModal isOpen={isDeletePopupOpen} handleClose={closeDeletePopup} handleDelete={handleDeleteTransaction} />
      {showCreateTransaction && (
        <AddTransaction isOpen={isTransactionOpen} handleClose={handleTransactionClose} addTransaction={addSingleItem} successCallback={refreshCashBalance} />
      )}
      {itemToEdit && showUpdateTransaction && (
        <EditTransaction
          transactionId={itemToEdit?.parentId ? itemToEdit.parentId : itemToEdit?.id}
          isOpen={isEditPopupOpen}
          handleClose={closeEditPopup}
          successCallback={refresh}
        />
      )}
    </Grid>
  );
};

export default CashTable;
