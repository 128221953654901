import styled from 'styled-components';

import { colors, Flex, theme } from 'style';

const Wrapper = styled.div`
  width: 100%;
  background-color: ${colors.background.blue};
  display: flex;
  flex-direction: row;
  padding: 16px;
  justify-content: space-between;
  margin-bottom: 16px;
  border-radius: 8px;
`;

const StyledFlex = styled(Flex)`
  ${theme.mq.standard} {
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  button {
    color: ${colors.blue.base};
  }
`;

const WrapperFlex = styled(Flex)`
  ${theme.mq.standard} {
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

export { Wrapper, StyledFlex, WrapperFlex };
