import React from 'react';

import Partners from './components/Companies/Companies';
import Loans from './components/Loans/Loans';
import { PageHeader } from 'components';
import { useFetch } from 'components/hooks';
import { getCompanyUser } from 'api';

import { Grid } from 'style';
import { Wrapper } from './partner.styles';
import { useParams } from 'react-router-dom';

const Partner: React.FC = () => {
  const { companyId, partnerId } = useParams<{ companyId: string; partnerId: string }>();

  const partnerData = useFetch(getCompanyUser(companyId, partnerId));
  const { payload: partner } = partnerData;

  const heading = partner ? `Partner: ${partner.name} ${partner.surname}` : 'Partner: loading...';

  return (
    <>
      <PageHeader heading={heading} description={'Overview'} />
      <Wrapper columns={'1fr'}>
        <Partners />
      </Wrapper>
      <Grid columns={'1fr'} margin={'24px 0 0 0'}>
        <Loans />
      </Grid>
    </>
  );
};

export default Partner;
