import styled from 'styled-components';

import { Flex, theme } from 'style';

interface HeaderProps {
  padding?: string;
}

const StyledHeader = styled(Flex)<HeaderProps>`
  margin-bottom: 16px;
  padding: 0;

  ${theme.mq.standard} {
    flex-direction: row;
    align-items: flex-start;
    padding: ${({ padding }) => padding || 0};
    margin-bottom: 24px;
  }
`;

const StyledFlex = styled(Flex)<{ showGap: boolean }>`
  width: 100%;
  margin-top: 10px;
  gap: ${({ showGap }) => (showGap ? '8px' : 0)};

  ${theme.mq.standard} {
    justify-content: flex-end;
  }
`;

export { StyledHeader, StyledFlex };
