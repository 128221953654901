import { AbilityBuilder, Ability, AnyMongoAbility } from '@casl/ability';

type Actions = 'create' | 'read' | 'update' | 'delete' | 'manage';
type Subjects = 'Company' | 'Users' | 'all';

type AppAbility = Ability<[Actions, Subjects]>;

export const globalPermissionsUpdate = (ability: AnyMongoAbility, isAdmin: boolean) => {
  const { can, cannot, rules } = new AbilityBuilder<AppAbility>(Ability);

  if (isAdmin) {
    can('manage', 'all');
  }

  if (!isAdmin) {
    can('read', 'all');

    cannot('manage', 'all');
    cannot(['create', 'update', 'delete'], 'Users');
    cannot(['create', 'update', 'delete'], 'Company');
  }

  ability.update(rules);
};
