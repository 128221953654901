import { useReducer, useEffect, useRef } from 'react';

import { ErrorResponse, Status } from 'api/api.middleware';
import { UpdateSingleItem, DeleteSingleItem, AddSingleItem } from 'types';

const SET_REFRESH_CALL = 'SET_REFRESH_CALL';
const SET_LOADING = 'SET_LOADING';
const SET_DATA = 'SET_DATA';
const SET_ERROR = 'SET_ERROR';
const SET_STATUS = 'SET_STATUS';

type ApiCall = () => Promise<[any | null, ErrorResponse | null, Status]>;
type ResolvedPromise<T extends ApiCall> = T extends () => Promise<[infer R, ErrorResponse | null, Status]> ? R : never;

type ArrayItemType<T extends any[]> = T extends (infer R)[] ? R : T;

interface Options<T extends ApiCall> {
  onSuccess?: (payload: NonNullable<ResolvedPromise<T>>) => void;
  onError?: (error: NonNullable<ErrorResponse>) => void;
  dependencies?: any[];
  conditions?: boolean;
}

export interface UseFetchReturnData<T extends ApiCall> {
  payload: ResolvedPromise<T>;
  loading: boolean;
  error: null | ErrorResponse;
  status: Status;
  actions: Actions;
  refresh: () => Promise<void>;
  isRefreshCall: boolean;
  updateElement: (item: ResolvedPromise<T>) => void;
  updateSingleItem: (args: UpdateSingleItem<NonNullable<ResolvedPromise<T>>>) => void;
  deleteSingleItem: (args: DeleteSingleItem<NonNullable<ResolvedPromise<T>>>) => void;
  addSingleItem: (args: AddSingleItem<NonNullable<ArrayItemType<ResolvedPromise<T>>>>) => void;
}

interface Actions {
  setRefreshCall: (isRefreshCall: boolean) => void;
  setLoading: (loading: boolean) => void;
  setData: (data: any) => void;
  setError: (error: any) => void;
  setStatus: (status: Status) => void;
}

type Action =
  | { type: typeof SET_REFRESH_CALL; isRefreshCall: boolean }
  | { type: typeof SET_LOADING; loading: boolean }
  | { type: typeof SET_DATA; data: any }
  | { type: typeof SET_ERROR; error: null | ErrorResponse }
  | { type: typeof SET_STATUS; status: Status };

type State = {
  data: any;
  error: null | ErrorResponse;
  loading: boolean;
  status: Status;
  isRefreshCall: boolean;
};

const initialState: State = {
  data: null,
  error: null,
  loading: true,
  isRefreshCall: false,
  status: { status: 0, isCanceled: false }
};

const initialOptions = {
  dependencies: [],
  conditions: true,
  onSuccess: () => null,
  onError: () => null
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case SET_REFRESH_CALL:
      return { ...state, isRefreshCall: action.isRefreshCall };
    case SET_LOADING:
      return { ...state, loading: action.loading };
    case SET_DATA:
      return { ...state, data: action.data };
    case SET_ERROR:
      return { ...state, error: action.error };
    case SET_STATUS:
      return { ...state, status: action.status };
  }
};

function useFetch<T extends ApiCall>(
  asyncApiCall: T,
  { dependencies = [], conditions = true, onSuccess = () => null, onError = () => null }: Options<T> = initialOptions
): UseFetchReturnData<T> {
  type ResponseType = NonNullable<ResolvedPromise<T>>;

  type ArraySingleItem = ArrayItemType<ResponseType>;

  const componentIsMounted = useRef(true);

  const handleComponentMount = () => {
    componentIsMounted.current = true;
  };
  const handleWillComponentUnmount = () => {
    componentIsMounted.current = false;
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const actions: Actions = {
    setRefreshCall: (isRefreshCall: boolean) => dispatch({ type: SET_REFRESH_CALL, isRefreshCall }),
    setLoading: (loading) => dispatch({ type: SET_LOADING, loading }),
    setData: (data) => dispatch({ type: SET_DATA, data }),
    setError: (error) => dispatch({ type: SET_ERROR, error }),
    setStatus: (status) => dispatch({ type: SET_STATUS, status })
  };

  const handleFetch = (isRefreshCall: boolean) => async () => {
    const { setLoading, setData, setError, setStatus, setRefreshCall } = actions;

    if (conditions) {
      setRefreshCall(isRefreshCall);
      setLoading(true);

      const [data, error, { status, isCanceled }] = await asyncApiCall();

      if (!componentIsMounted.current) {
        return;
      }
      if (error && !isCanceled && status !== 0 && status !== 401) {
        setError(error);
        onError && onError(error);
        setLoading(false);
        setData(null);
        setStatus({ status, isCanceled });
      }
      if (error && !isCanceled && status === 401) {
        /* do not update the state, axios interceptor will redirect user to the login page */
        onError && onError(error);
      }
      if (!isCanceled && !error) {
        setData(data);
        onSuccess && onSuccess(data);
        setError(null);
        setStatus({ status, isCanceled });
        setLoading(false);
      }
    }
  };

  /*
    itemKey is used to find an element which we should update (in most cases it will be an id)

    arrayKey is used to extract an array from the payload:
    {
      directoryId: number,
      files: [{id: number, name: string}, {id: number, name: string}]
    }
    if we update a single file, 'files' is an arrayKey (payload[files])
  */

  const updateElement = (item: any) => {
    const { data } = state;
    const { setData } = actions;

    const updateItemCondition = !Array.isArray(data) && !Array.isArray(item);

    if (updateItemCondition) {
      setData(item);
    }
  };

  const updateSingleItem = async ({ updatedItem, itemKey = 'id', arrayKey }: UpdateSingleItem<ResponseType>) => {
    const { data, error, loading } = state;
    const { setData } = actions;

    const updateElement = (arrayToUpdate: any[]) => {
      const stateCopy = [...arrayToUpdate];
      const index = arrayToUpdate.findIndex((item) => item[itemKey] === updatedItem[itemKey]);

      if (index !== -1) {
        stateCopy[index] = { ...stateCopy[index], ...updatedItem };

        const updated = arrayKey ? { ...data, [arrayKey]: stateCopy } : stateCopy;
        setData(updated);
      } else {
        handleFetch(true)();
      }
    };

    const updateCondition = !error && !loading;

    if (arrayKey) {
      const itemsArray = data[arrayKey];
      if (itemsArray && Array.isArray(itemsArray) && updateCondition) {
        updateElement(itemsArray);
      }
    } else {
      if (data && Array.isArray(data) && updateCondition) {
        updateElement(data);
      }
    }
  };

  const deleteSingleItem = ({ itemId, itemKey = 'id', arrayKey }: DeleteSingleItem<ResponseType>) => {
    const { data, error, loading } = state;
    const { setData } = actions;

    const deleteCondition = !error && !loading;

    const deleteElement = (array: any[]) => {
      const filteredArray = array.filter((item) => item[itemKey] !== itemId);

      const updated = arrayKey ? { ...data, [arrayKey]: filteredArray } : filteredArray;
      setData(updated);
      onSuccess && onSuccess(updated);
    };

    if (arrayKey) {
      const itemsArray = data[arrayKey];
      if (itemsArray && Array.isArray(itemsArray) && deleteCondition) {
        deleteElement(itemsArray);
      }
    } else {
      if (data && Array.isArray(data) && deleteCondition) {
        deleteElement(data);
      }
    }
  };

  const addSingleItem = ({ payload, arrayKey }: AddSingleItem<NonNullable<ArrayItemType<ResolvedPromise<T>>>>) => {
    const { data, error, loading } = state;
    const { setData } = actions;

    const addCondition = !error && !loading;

    if (arrayKey) {
      const itemsArray = data[arrayKey];
      if (itemsArray && Array.isArray(itemsArray) && addCondition) {
        const newArray = [payload, ...itemsArray];
        const updatedData = { ...data, [arrayKey]: newArray };
        setData(updatedData);
        onSuccess && onSuccess(updatedData);
      }
    } else {
      if (data && Array.isArray(data) && addCondition) {
        const newArray = [payload, ...data];
        setData(newArray);
      }
    }
  };

  useEffect(() => {
    handleComponentMount();

    return () => handleWillComponentUnmount();
  }, []);

  useEffect(() => {
    (async () => {
      await handleFetch(false)();
    })();
  }, dependencies || []);

  return {
    payload: state.data,
    loading: state.loading,
    error: state.error,
    status: state.status,
    actions,
    refresh: handleFetch(true),
    isRefreshCall: state.isRefreshCall,
    updateSingleItem,
    deleteSingleItem,
    addSingleItem,
    updateElement
  };
}

export default useFetch;
