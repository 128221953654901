import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import States from './States/States';
import AddButton from '../AddButton/AddButton';
import { Heading, Table, DeleteModal } from 'components';
import { prepareColumns, DropdownValue } from './cars.columns';
import { useCall, useDelete, useEdit, useFetch, usePermissions, useShowContent, useToast } from 'components/hooks';
import { deleteCar, getCars, DeleteCarResponseDto } from 'api';
import { AddCar, EditCar } from 'pages/Company/views/Settings/modals';
import { Car } from 'models';

import { Card } from 'style';

const Cars: React.FC = () => {
  const { success, error } = useToast();
  const { companyPermissions } = usePermissions();
  const { companyId } = useParams<{ companyId: string }>();
  const [isAddCarsOpen, setAddCarsOpen] = useState<boolean>(false);

  const { itemToEdit, setItemToEdit, isEditPopupOpen, closeEditPopup } = useEdit<Car>();
  const { itemToDelete, setItemToDelete, isDeletePopupOpen, closeDeletePopup } = useDelete<Car>();

  const carsData = useFetch(getCars(companyId));
  const { showLoader, showContent, showNoContent, showError } = useShowContent(carsData);
  const { payload: cars, addSingleItem, updateSingleItem, deleteSingleItem } = carsData;

  const handleDeleteSuccess = ({ id }: DeleteCarResponseDto) => {
    const message = itemToDelete ? `Car ${itemToDelete.name} has been deleted` : 'Car has been deleted';
    success(message);
    deleteSingleItem({ itemId: id });
    closeDeletePopup();
  };

  const { submit, onCallSuccess, onCallError } = useCall(deleteCar);
  onCallError(error('Cannot delete car'));
  onCallSuccess(handleDeleteSuccess);

  const handleDeleteCar = async () => {
    if (itemToDelete) {
      await submit(companyId, itemToDelete.id);
    }
  };

  const handleAddCarsOpen = (isOpen: boolean) => () => setAddCarsOpen(isOpen);

  const handleDropdownSelect = ({ item, type }: DropdownValue) => {
    type === 'edit' && setItemToEdit(item);
    type === 'delete' && setItemToDelete(item);
  };

  const columns = prepareColumns({ handleDropdownSelect, ability: companyPermissions });
  const canAddAccountValue = companyPermissions.can('create', 'AccountValues');
  const canEditAccountValue = companyPermissions.can('update', 'AccountValues');
  const canDeleteAccountValue = companyPermissions.can('delete', 'AccountValues');

  return (
    <>
      <Card height={'auto'}>
        <Heading margin={'8px 0 16px 0'}>Cars</Heading>
        <States showLoader={showLoader} showNoContent={showNoContent} showError={showError} />
        {showContent && cars && <Table<Car> columns={columns} data={cars} pageItems={5} showHeader={false} haveDropdown={false} />}
        {canAddAccountValue && <AddButton handleOpen={handleAddCarsOpen(true)} />}
      </Card>
      {canAddAccountValue && <AddCar isOpen={isAddCarsOpen} handleClose={handleAddCarsOpen(false)} addCar={addSingleItem} />}
      {canDeleteAccountValue && <DeleteModal isOpen={isDeletePopupOpen} handleClose={closeDeletePopup} handleDelete={handleDeleteCar} />}
      {itemToEdit && canEditAccountValue && <EditCar item={itemToEdit} isOpen={isEditPopupOpen} handleClose={closeEditPopup} updateCar={updateSingleItem} />}
    </>
  );
};

export default Cars;
