import { RouteModel } from 'types';
import { AnyMongoAbility } from '@casl/ability';

import Suppliers from './views/Suppliers/Suppliers';
import SingleSupplier from './views/SingleSupplier/SingleSupplier';

export const supplierRoutes = (ability: AnyMongoAbility): RouteModel[] => {
  const showSupplierRoute = ability.can('read', 'Supplier');
  return [
    {
      name: 'Suppliers',
      path: '/',
      exact: true,
      component: Suppliers,
      protected: true,
      disabled: !showSupplierRoute
    },
    {
      name: 'Supplier',
      path: '/:supplierId',
      exact: true,
      component: SingleSupplier,
      protected: true,
      disabled: !showSupplierRoute
    }
  ];
};
