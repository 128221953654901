import React from 'react';

import { FormField } from 'components';
import { mainFields } from './main.fields';

import { Flex } from 'style';

const MainFields: React.FC = () => {
  return (
    <Flex direction={'column'} gap={8} margin={'0 0 16px 0'}>
      {mainFields.map((field) => (
        <FormField key={field.name} {...field} />
      ))}
    </Flex>
  );
};

export default MainFields;
