/* pages -> [1, ..., allPages.length] */

export const paginationLogic = (pages: number[], currentPage: number): number[] => {
  if (pages.length <= 3) {
    /* pages length less than 3 -> return all pages ([1] | [1,2] | [1,2,3]) */
    return pages;
  }
  if (currentPage === 1) {
    /* pages length greater that 3 and first page -> return [1,2,3] */
    return pages.slice(0, 3);
  }
  if (currentPage === pages.length) {
    /* pages length greater than 3 and last page -> return 3 last pages */
    return pages.slice(-3);
  }

  /* pages length greater than 3 -> return [currentPage - 1, currentPage, currentPage + 1] */
  const currentPageIndex = currentPage - 1;
  return [pages[currentPageIndex - 1], pages[currentPageIndex], pages[currentPageIndex + 1]];
};
