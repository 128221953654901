import styled from 'styled-components';

import { borders, Flex, Card, theme } from 'style';

const StyledCard = styled(Card)`
  ${theme.mq.standard} {
    padding: 0 24px;
  }
`;

const FlexWrapper = styled(Flex)`
  border-bottom: ${borders.default};
  padding: 2rem 0;

  ${theme.mq.standard} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export { StyledCard, FlexWrapper };
