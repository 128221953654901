import { useEffect, useState } from 'react';

const RESET_STATE_TIME = 500;

interface EditReturnData<T> {
  isEditPopupOpen: boolean;
  itemToEdit: T | null;
  closeEditPopup: () => void;
  setItemToEdit: (item: T) => void;
}

function useEdit<T>(): EditReturnData<T> {
  const [isEditPopupOpen, setEditPopupOpen] = useState<boolean>(false);
  const [editItem, setEditItem] = useState<T | null>(null);

  const setItemToEdit = (item: T) => setEditItem(item);

  const closeEditPopup = () => {
    setEditPopupOpen(false);

    setTimeout(() => {
      setEditItem(null);
    }, RESET_STATE_TIME);
  };

  useEffect(() => {
    if (editItem !== null) {
      setEditPopupOpen(true);
    }
  }, [editItem]);

  return {
    itemToEdit: editItem,
    isEditPopupOpen,
    closeEditPopup,
    setItemToEdit
  };
}

export default useEdit;
