import React, { useEffect, useMemo } from 'react';
import { FilterProps } from 'react-table';

import { Select, Button } from 'components';
import { Transaction } from 'models';
import { Option } from 'types';
import { AccountValues } from 'form/transaction/cash-flow-form/types';
import { accountOptions } from 'form/transaction/cash-flow-form/utils/account.select';

import { Wrapper, StyledParagraph } from '../filters.styles';

const SelectAccountsFilter = ({ column: { filterValue, setFilter } }: FilterProps<Transaction>) => {
  useEffect(() => {
    setFilter(undefined);
  }, []);

  const handleSelectChange = (value: AccountValues) => setFilter(value);

  const allOption = { name: 'All', value: undefined };
  const options: Option<AccountValues>[] = [allOption, ...accountOptions];

  const status = useMemo(() => {
    if (filterValue === undefined) {
      return 'all';
    } else {
      return filterValue;
    }
  }, [filterValue]);

  return (
    <Wrapper>
      <Select<AccountValues>
        items={options}
        value={filterValue}
        onChange={handleSelectChange}
        size={'small'}
        placeholder={'Status'}
        hideRadioButtons={false}
        tableSelect={true}
        customButton={
          <Button buttonType={'wrapper'} title={status}>
            <StyledParagraph color={'scale3'} weight={'medium'}>
              Account: {status}
            </StyledParagraph>
          </Button>
        }
      />
    </Wrapper>
  );
};

export default SelectAccountsFilter;
