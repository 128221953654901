import React from 'react';
import { useParams } from 'react-router-dom';

import InvoiceManagement from './InvoiceManagement/InvoiceManagement';
import { useFetch, useSubmit, useToast, useShowContent } from 'components/hooks';
import { getSingleInvoice, InvoiceDto, putInvoice } from 'api';
import { prepareMultiline } from 'utils';
import { UpdateSingleItem } from 'types';
import { Invoice } from 'models';

interface Props {
  invoiceId: number;
  isOpen: boolean;
  handleClose: () => void;
  onEditInvoice: (args: UpdateSingleItem<Invoice>) => void;
}

const EditInvoice: React.FC<Props> = ({ invoiceId, isOpen, handleClose, onEditInvoice }) => {
  const { success, error } = useToast();
  const { companyId } = useParams<{ companyId: string }>();

  const invoiceData = useFetch(getSingleInvoice(companyId, invoiceId));
  const { showLoader } = useShowContent(invoiceData);
  const { payload } = invoiceData;

  const initialValues: InvoiceDto = {
    buyerAddress: payload?.buyerAddress || '',
    buyerName: payload?.buyerName || '',
    buyerTin: payload?.buyerTin || '',
    buyerVrn: payload?.buyerVrn || '',
    buyerZrb: payload?.buyerZrb || '',
    currency: payload?.currency || 'usd',
    items: payload?.items || [],
    notes: payload?.notes || ''
  };

  const { onSubmit, onSubmitSuccess, onSubmitError, mapData } = useSubmit(putInvoice(companyId, invoiceId));
  mapData((data) => ({
    ...data,
    notes: prepareMultiline(data.notes || '')
  }));
  onSubmitError(error('Invoice cannot be updated'));
  onSubmitSuccess((invoice) => {
    onEditInvoice({ updatedItem: invoice });
    success(`Invoice has been updated`);
    handleClose();
  });

  return (
    <InvoiceManagement
      isOpen={isOpen}
      handleClose={handleClose}
      handleSubmit={onSubmit}
      buttonText={'Save'}
      title={'Edit invoice'}
      initialValues={initialValues}
      isLoading={showLoader}
    />
  );
};

export default EditInvoice;
