import styled from 'styled-components';

import { Grid, theme } from 'style';

const StyledGrid = styled(Grid)`
  ${theme.mq.standard} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export { StyledGrid };
