import React from 'react';
import { Form, Formik, FormikHelpers } from 'formik';

import { FormField, Button, Loader, Error, Modal, ModalProps } from 'components';
import { SupplierDto } from 'api';
import { emptyValues, supplierFields } from './supplier.fields';
import { supplierSchema } from './supplier.validation';

import { Flex, SimpleWrapper } from 'style';

interface Props extends ModalProps {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (values: SupplierDto, helpers: FormikHelpers<SupplierDto>) => void;
  title: 'New supplier' | 'Edit supplier';
  initialValues?: SupplierDto;
  buttonText?: string;
  isLoading?: boolean;
  showError?: boolean;
}

const SupplierManagement: React.FC<Props> = ({ isOpen, handleClose, handleSubmit, initialValues, buttonText, showError, title, isLoading, ...rest }) => {
  const values: SupplierDto = initialValues || emptyValues;

  const closeWithReset = (resetForm: () => void) => () => {
    handleClose();
    resetForm();
  };

  const showContent = !isLoading && !showError;

  return (
    <Formik initialValues={values} onSubmit={handleSubmit} enableReinitialize validationSchema={supplierSchema}>
      {({ resetForm, isSubmitting }) => (
        <Modal isOpen={isOpen} handleClose={closeWithReset(resetForm)} title={title} {...rest}>
          <SimpleWrapper>
            <Form>
              {isLoading || (isSubmitting && <Loader />)}
              {showError && <Error>Cannot fetch supplier</Error>}
              {showContent && !isSubmitting && (
                <>
                  <Flex direction={'column'} gap={8}>
                    {supplierFields.map((field) => (
                      <FormField key={field.name} {...field} />
                    ))}
                  </Flex>
                  <Flex justify={'flex-end'} margin={'24px 0'}>
                    <Button buttonType={'secondary'} onClick={closeWithReset(resetForm)}>
                      Cancel
                    </Button>
                    <Button type={'submit'} disabled={isSubmitting}>
                      {buttonText || 'Save'}
                    </Button>
                  </Flex>
                </>
              )}
            </Form>
          </SimpleWrapper>
        </Modal>
      )}
    </Formik>
  );
};

export default SupplierManagement;
