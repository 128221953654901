import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import States from './States/States';
import { AddNewButton, Heading, Table, DeleteModal } from 'components';
import { useFetch, useShowContent, useCall, useDelete, useEdit, useToast, usePermissions } from 'components/hooks';
import { getOperators, deleteOperator, DeleteOperatorResponseDto } from 'api';
import { AddOperator, EditOperator } from 'pages/Company/views/Settings/modals';
import { prepareColumns, DropdownValue } from './tour-operators.columns';
import { TourOperator } from 'models';

import { Card, Flex } from 'style';
import { TourOperatorWrapper } from './tour-operators.styles';

const TourOperators: React.FC = () => {
  const { success, error } = useToast();
  const { companyPermissions } = usePermissions();
  const { companyId } = useParams<{ companyId: string }>();
  const { itemToDelete, setItemToDelete, isDeletePopupOpen, closeDeletePopup } = useDelete<TourOperator>();
  const { itemToEdit, setItemToEdit, isEditPopupOpen, closeEditPopup } = useEdit<TourOperator>();

  const [isAddOperatorOpen, setAddOperatorOpen] = useState<boolean>(false);

  const operatorsData = useFetch(getOperators(companyId));
  const { showContent, showLoader, showNoContent, showError } = useShowContent(operatorsData);
  const { payload: operators, addSingleItem, deleteSingleItem, updateSingleItem } = operatorsData;

  const handleDeleteSuccess = ({ id }: DeleteOperatorResponseDto) => {
    const message = itemToDelete ? `Tour operator ${itemToDelete.name} has been deleted` : 'Tour operator has been deleted';
    success(message);
    deleteSingleItem({ itemId: id });
    closeDeletePopup();
  };

  const { submit, onCallSuccess, onCallError } = useCall(deleteOperator);
  onCallError(error('Cannot delete tour operator'));
  onCallSuccess(handleDeleteSuccess);

  const handleAddOperatorOpen = (isOpen: boolean) => () => setAddOperatorOpen(isOpen);
  const handleDropdownSelect = ({ type, item }: DropdownValue) => {
    type === 'edit' && setItemToEdit(item);
    type === 'delete' && setItemToDelete(item);
  };

  const handleDeleteOperator = async () => {
    if (itemToDelete && canAddTourOperator) {
      await submit(companyId, itemToDelete.id);
    }
  };

  const columns = prepareColumns({ handleDropdownSelect, ability: companyPermissions });
  const canAddTourOperator = companyPermissions.can('create', 'TourOperators');
  const canReadTourOperators = companyPermissions.can('read', 'TourOperators');
  const canUpdateTourOperator = companyPermissions.can('update', 'TourOperators');
  const canDeleteTourOperator = companyPermissions.can('delete', 'TourOperators');

  const showTable = showContent && canReadTourOperators;

  return (
    <>
      <TourOperatorWrapper>
        <Card dropdown={false}>
          <Flex justify={'space-between'} margin={'8px 0 16px 0'}>
            <Heading margin={'0'}>Tour operators</Heading>
            {canAddTourOperator && <AddNewButton onClick={handleAddOperatorOpen(true)}>Add tour operator</AddNewButton>}
          </Flex>
          <States showLoader={showLoader} showNoContent={showNoContent} showError={showError} />
          {showTable && operators && <Table<TourOperator> columns={columns} data={operators} pageItems={4} haveDropdown={false} showHeader={true} />}
        </Card>
      </TourOperatorWrapper>
      {canAddTourOperator && <AddOperator isOpen={isAddOperatorOpen} handleClose={handleAddOperatorOpen(false)} addOperator={addSingleItem} />}
      {itemToEdit && canUpdateTourOperator && (
        <EditOperator isOpen={isEditPopupOpen} handleClose={closeEditPopup} item={itemToEdit} updateOperator={updateSingleItem} />
      )}
      {canDeleteTourOperator && (
        <DeleteModal isOpen={isDeletePopupOpen} handleDelete={handleDeleteOperator} handleClose={closeDeletePopup} itemName={itemToDelete?.name} />
      )}
    </>
  );
};

export default TourOperators;
