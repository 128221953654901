import React, { useMemo } from 'react';

import { Button, FormField, Heading } from 'components';
import { Option } from 'types';
import { Supplier } from 'models';

import { Grid, PlusIcon } from 'style';

interface Props {
  onOpenAddSupplierModal: () => void;
  suppliers: Supplier[];
}

const Suppliers = ({ onOpenAddSupplierModal, suppliers }: Props) => {
  const suppliersOptions: Option<number>[] = useMemo(() => {
    if (suppliers) {
      return suppliers.map(({ name, id }) => ({ name, value: id }));
    } else {
      return [];
    }
  }, [suppliers]);

  const disableSelect = !suppliers || !suppliers.length;
  const placeholder = !suppliers.length ? 'No suppliers to select' : 'Search Supplier Database';

  return (
    <div>
      <Heading type={'h5'} margin={'12px 0'}>
        Contractor details
      </Heading>
      <Grid columns={'1fr 60px'} gap={0} align={'end'} justify={'center'}>
        <FormField name={'supplierId'} type={'select'} items={suppliersOptions} placeholder={placeholder} label={'Supplier'} disabled={disableSelect} />
        <Button buttonType={'secondary-accent'} onClick={onOpenAddSupplierModal}>
          <PlusIcon />
        </Button>
      </Grid>
    </div>
  );
};

export default Suppliers;
