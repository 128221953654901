import React from 'react';
import { Column, Row } from 'react-table';

import { Formatter } from 'components';
import { displayAmount } from 'utils';
import { Loan } from 'models';

import { StatusParagraph } from 'style';

export const columns: Column<Loan>[] = [
  { Header: 'Name', accessor: 'name' },
  { Header: 'Loan Date', accessor: 'date', Cell: ({ value }: { value: string }) => new Date(value).toLocaleDateString() },
  {
    Header: 'Amount',
    accessor: 'amount',
    Cell: ({ value, row }: { value: number; row: Row<Loan> }) => {
      const currency = row.original.currency;

      return <Formatter {...displayAmount(value, currency)} />;
    }
  },
  { Header: 'Provision', accessor: 'provision', Cell: ({ value }: { value: number }) => <Formatter value={value} prefix={' '} suffix={'%'} /> },
  {
    Header: 'Left to pay',
    accessor: 'paidAmount',
    Cell: ({ value, row }: { value: number; row: Row<Loan> }) => {
      const leftToPay = row.original.amount - value;
      const currency = row.original.currency;

      return <Formatter {...displayAmount(leftToPay, currency)} />;
    }
  },
  {
    Header: 'Status',
    id: 'status',
    Cell: ({ row }: { row: Row<Loan> }) => {
      const leftToPay = row.original.amount - row.original.paidAmount;
      const isPayed = leftToPay <= 0;

      return <StatusParagraph active={isPayed}>{isPayed ? 'Paid' : 'Outstanding'}</StatusParagraph>;
    }
  }
];
