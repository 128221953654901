import React, { useState } from 'react';

import ReservationTable from './ReservationTable/ReservationTable';
import { Button, MultipleSelect, PageHeader } from 'components';
import { useDateRange, useWindowDimensions } from 'components/hooks';
import { ReservationType } from 'models';
import { statusOptions } from './reservations.utils';

import { RefreshIcon } from 'style';

const Reservations: React.FC = () => {
  const [statusFilter, setStatusFilter] = useState<ReservationType[]>([]);
  const { dateRange, handleDateRange } = useDateRange();
  const { width } = useWindowDimensions();
  const [reservationsRefresh, setReservationsRefresh] = useState(new Date());

  const resynchronize = async () => {
    // const url = await submit();

    window
      .open(
        `https://hotels.cloudbeds.com/api/v1.1/oauth?client_id=live1_191246_ZYX8atQ97csvDUnbKwz5R6Fx&redirect_uri=https://api.zanos.pl/companies/4/cloudbeds/auth&response_type=code`
      )
      ?.focus();
    window
      .open(
        `https://hotels.cloudbeds.com/api/v1.1/oauth?client_id=live1_21848_ieHvUEQI9XBJjRW62lbGpThM&redirect_uri=https://api.zanos.pl/companies/1/cloudbeds/auth&response_type=code`
      )
      ?.focus();
    window
      .open(
        `https://hotels.cloudbeds.com/api/v1.1/oauth?client_id=live1_23257_fCmZ8EUHqxpwlhazAg0vIibV&redirect_uri=https://api.zanos.pl/companies/2/cloudbeds/auth&response_type=code`
      )
      ?.focus();

    // setReservationsRefresh(new Date());
  };

  const customFilters = (
    <>
      <MultipleSelect
        placeholder={'Filter by status'}
        items={statusOptions}
        value={statusFilter}
        onChange={setStatusFilter}
        alignPosition={width <= 1024 ? 'left' : 'right'}
        showSelectedItemsCount
      />
    </>
  );

  return (
    <>
      <PageHeader
        heading={'Reservations'}
        handleDateChange={handleDateRange}
        customFilters={customFilters}
        customButton={
          <Button onClick={resynchronize}>
            <RefreshIcon />
            Synchronize manually
          </Button>
        }
      />
      <ReservationTable dateRange={dateRange} statusFilter={statusFilter} reservationsRefresh={reservationsRefresh} />
    </>
  );
};

export default Reservations;
