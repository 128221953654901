import React, { useMemo, useState } from 'react';
import { number, object, SchemaOf } from 'yup';
import { useParams } from 'react-router-dom';

import { useFetch, useShowContent } from 'components/hooks';
import { getEmployees, getSalaries } from 'api';
import { FormField, Select } from 'components';
import { dateFormat, message } from 'utils';

import { Flex } from 'style';

export const salariesStaffSchema: SchemaOf<SalariesStaffValues> = object({
  salaryId: number().required(message.required)
});

export type SalariesStaffValues = {
  salaryId: number;
};

const SalariesStaff: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();

  const [employeeId, setEmployeeId] = useState<number | null>(null);

  const employeesData = useFetch(getEmployees(companyId));
  const { showLoader, showNoContent: showEmptyEmployees } = useShowContent(employeesData);
  const { payload: employees } = employeesData;

  const salariesData = useFetch(getSalaries(companyId, employeeId), { dependencies: [employeeId], conditions: !!employeeId });
  const { showLoader: showSalariesLoader, showNoContent: showEmptySalaries } = useShowContent(salariesData);
  const { payload: salaries } = salariesData;

  const options = useMemo(() => {
    if (employees) {
      return employees.map(({ name, id }) => ({ name, value: id }));
    }
  }, [employees]);

  const salariesOptions = useMemo(() => {
    if (salaries) {
      return salaries.map(({ id, date, base }) => ({ name: `${dateFormat(date)}, ${base}`, value: id }));
    }
  }, [employees, salaries]);

  const handleSelectChange = (value: number) => setEmployeeId(value);

  const employeePlaceholder = showEmptyEmployees ? 'No employees to select' : 'Select an employee';
  const employeeDisabled = showLoader || showEmptyEmployees;

  const salaryPlaceholder = showEmptySalaries ? 'No salaries to select' : 'Select a salary';
  const salaryDisabled = showSalariesLoader || showEmptySalaries;

  return (
    <Flex direction={'column'} margin={'8px 0'} gap={8}>
      <Select<number>
        items={options || []}
        onChange={handleSelectChange}
        value={employeeId || 0}
        width={'100%'}
        placeholder={employeePlaceholder}
        disabled={employeeDisabled}
      />
      <FormField
        name={'accountData.salaryId'}
        type={'select'}
        items={salariesOptions || []}
        width={'100%'}
        placeholder={salaryPlaceholder}
        disabled={salaryDisabled}
      />
    </Flex>
  );
};

export default SalariesStaff;
