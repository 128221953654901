import styled from 'styled-components';

import { Placement } from 'types';

import { placement } from 'style';

const Wrapper = styled.div<{ height?: string; position?: Placement }>`
  width: auto;
  height: ${({ height }) => (height ? height : 'auto')};

  ${({ position }) => placement(position || 'top-left')}
`;

export { Wrapper };
