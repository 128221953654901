import styled, { css } from 'styled-components';

import { DropzoneType } from './FileUpload';

import { Card, colors, Paragraph } from 'style';

const Wrapper = styled.div<{ fullWidth: boolean }>`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
`;

const DropzoneArea = styled(Card)<{ dropzoneType: DropzoneType; fullWidth: boolean }>`
  width: 100%;
  cursor: pointer;
  border: 2px dashed #eee;
  box-shadow: none;
  padding: 16px;

  background-color: ${colors.background.blue};

  ${({ dropzoneType, fullWidth }) =>
    dropzoneType === 'simple' &&
    css`
      width: ${fullWidth ? '100%' : 'auto'};
      background-color: transparent;
      padding: 0;
      border: none;
    `}
`;

const DownloadButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${colors.blue.base};
  font-size: 13px;
  padding-left: 0;
  font-weight: 500;
`;

const FileName = styled(Paragraph)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`;

export { DropzoneArea, Wrapper, DownloadButton, FileName };
