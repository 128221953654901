import styled from 'styled-components';

import { Flex } from 'style';

const HeaderFlex = styled(Flex)`
  width: 100%;
  background-color: #fff;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  padding: 24px;
`;

export { HeaderFlex };
