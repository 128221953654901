import { number, object, array, SchemaOf, string, mixed } from 'yup';

import { UserDto } from 'api';
import { message, permissionsOneOf } from 'utils';

export const userSchema: SchemaOf<UserDto> = object().shape({
  name: string().required(message.required),
  surname: string().required(message.required),
  email: string().email(message.email).required(message.required),
  role: mixed().oneOf(permissionsOneOf).required(message.required),
  companies: array().of(number().required())
});

//mixed().oneOf<CurrencyValue>(currenciesOneOf).required(message.currency)
