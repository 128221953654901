import React, { useEffect, useState } from 'react';
import { useCombobox, UseComboboxGetInputPropsOptions } from 'downshift';

import { Input, Loader } from 'components';
import { itemToString } from 'utils';
import { Option } from 'types';
import { InputProps } from 'components/form/Input/Input';

import { SelectedItemIcon, StyledSelectItem, StyledSelectOptions, StyledSelectWrapper } from '../Select/select.styles';

type InputPropsOmitted = Omit<InputProps, 'onChange'>;

export interface Props extends InputPropsOmitted {
  items: Option[];
  onChange: (value: any) => void;
  value?: any;
  isLoading?: boolean;
  width?: string;
  disabled?: boolean;
}

const Combobox: React.FC<Props> = ({ items, onChange, width, isLoading, value, ...inputProps }) => {
  const [filteredOptions, setFilteredOptions] = useState<Option[]>([]);

  const currentValue = items.find((item) => item.value === value);

  const itemSelected = value ? currentValue : null;

  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    getItemProps,
    selectedItem: selected,
    highlightedIndex,
    openMenu
  } = useCombobox({
    items: filteredOptions,
    itemToString,
    selectedItem: itemSelected,
    initialSelectedItem: itemSelected,
    onInputValueChange: ({ inputValue }) =>
      setFilteredOptions(inputValue ? items.filter((item) => item.name.toLowerCase().includes(inputValue.toLowerCase())) : items),

    onStateChange: ({ type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          if (selectedItem) {
            onChange(selectedItem.value);
          }
          break;
        default:
          break;
      }
    }
  });

  useEffect(() => {
    setFilteredOptions(items);
  }, [items]);

  const isItemSelected = (item: Option) => selected === item;

  const inputOptions: UseComboboxGetInputPropsOptions = {
    onFocus: () => {
      if (!isOpen) openMenu();
    }
  };

  const isSelectOpen = isOpen && !!filteredOptions.length;

  return (
    <StyledSelectWrapper width={width}>
      <div {...getComboboxProps()}>
        <Input {...getInputProps(inputOptions)} {...inputProps} />
      </div>
      <StyledSelectOptions isOpen={isSelectOpen} {...getMenuProps()} alignPosition={'left'} offsetTop={20}>
        {isLoading && <Loader accent />}
        {!isLoading &&
          filteredOptions.map((item, index) => {
            return (
              <StyledSelectItem
                key={`${index}`}
                {...getItemProps({ item, index })}
                isSelected={isItemSelected(item)}
                disabled={item.blocked}
                highlighted={highlightedIndex === index}
              >
                {isItemSelected(item) && <SelectedItemIcon />}
                {item.name}
              </StyledSelectItem>
            );
          })}
      </StyledSelectOptions>
    </StyledSelectWrapper>
  );
};

export default Combobox;
