import styled from 'styled-components';

import { Grid, theme } from 'style';

const TourOperatorWrapper = styled(Grid)`
  ${theme.mq.standard} {
    grid-template-columns: 2fr 1fr;
  }
`;

export { TourOperatorWrapper };
