import React, { useMemo } from 'react';
import { Column, Row } from 'react-table';
import { AnyMongoAbility } from '@casl/ability';

import { Formatter, SelectActiveFilter } from 'components';
import { tableDropdown } from 'utils/table';
import { dateFormat, displayAmount } from 'utils';
import { currencies, Option } from 'types';
import { Employee } from 'models';

import { Paragraph, StatusParagraph, Flex } from 'style';

export interface DropdownValue {
  type: 'delete' | 'edit' | 'status';
  item: Employee;
}

interface DropdownArguments {
  handleDropdownSelect: (value: DropdownValue) => void;
  ability: AnyMongoAbility;
}

const prepareDropdownItems = (item: Employee, ability: AnyMongoAbility): Option<DropdownValue>[] => {
  const editItem = ability.can('update', 'Employee') ? [{ name: 'Edit', value: { type: 'edit', item } }] : [];
  const changeStatusItem = ability.can('update', 'EmployeeStatus') ? [{ name: 'Change status', value: { type: 'status', item } }] : [];
  const deleteItem = ability.can('delete', 'Employee') ? [{ name: 'Remove', value: { type: 'delete', item }, type: 'delete' }] : [];

  return [...editItem, ...changeStatusItem, ...deleteItem] as Option<DropdownValue>[];
};

const handleTotalAmount = (rows: Row<Employee>[]) => {
  return rows.reduce((summary, row) => row.values.salary + Number(summary), 0);
};

export const prepareColumns = ({ handleDropdownSelect, ability }: DropdownArguments): Column<Employee>[] => {
  return [
    {
      Header: 'Name',
      accessor: 'name',
      sortType: 'string',
      disableFilters: true,
      Footer: () => (
        <Paragraph weight={'medium'} color={'scale1'}>
          Summary
        </Paragraph>
      )
    },
    {
      Header: 'End of Contract',
      accessor: 'contractEnd',
      sortType: 'string',
      disableFilters: true,
      Cell: ({ value }: { value: string }) => dateFormat(value)
    },
    {
      Header: 'Base Pay',
      accessor: 'baseTzs',
      sortType: 'number',
      width: 200,
      disableFilters: true,
      Cell: ({ value, row }: { value: number; row: Row<Employee> }) => {
        const moneyProps = displayAmount(value, 'tzs');
        const additionalBaseProps = row.original?.baseUsd && ability.can('manage', 'EmployeeSalaryUsd') ? displayAmount(row.original.baseUsd, 'usd') : null;

        return (
          <Flex gap={4} wrap={'wrap'}>
            <Formatter {...moneyProps} />
            {additionalBaseProps && (
              <>
                +<Formatter {...additionalBaseProps} />
              </>
            )}
          </Flex>
        );
      },
      Footer: ({ rows }) => {
        const total = useMemo(() => {
          return handleTotalAmount(rows);
        }, [rows]);

        return <Formatter value={total} weight={'medium'} color={'scale1'} prefix={' '} suffix={` ${currencies['tzs']}`} />;
      }
    },
    {
      accessor: 'isActive',
      disableSortBy: true,
      filter: 'equals',
      disableGlobalFilter: true,
      Filter: SelectActiveFilter,
      Cell: ({ value }: { value: boolean }) => <StatusParagraph active={value}>{value ? 'Active' : 'Inactive'}</StatusParagraph>
    },
    {
      Header: '',
      id: 'dropdown',
      width: 10,
      disableFilters: true,
      Cell: ({ row }: { row: Row<Employee> }) => {
        const options = prepareDropdownItems(row.original, ability);

        return tableDropdown<DropdownValue>({ options, handleDropdownSelect });
      }
    }
  ];
};
