import React from 'react';

import { FormField, Heading, FieldProps } from 'components';

import { Flex } from 'style';
import { StyledParagraph } from './single-bank-field.styles';

interface Props {
  heading: string;
  fields: FieldProps[];
}

const SingleBankField: React.FC<Props> = ({ heading, fields }) => {
  return (
    <>
      <Heading margin={'10px 0'}>{heading}</Heading>
      <Flex direction={'column'} gap={8} align={'flex-start'} width={'70%'}>
        {fields.map(({ label, ...field }) => (
          <Flex key={field.name} width={'100%'} align={'flex-start'}>
            <FormField {...field} />
            <StyledParagraph>{label}</StyledParagraph>
          </Flex>
        ))}
      </Flex>
    </>
  );
};

export default SingleBankField;
