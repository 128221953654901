import React, { useEffect, useMemo } from 'react';
import { FilterProps } from 'react-table';

import { Select, Button } from 'components';
import { Option } from 'types';
import { Employee } from 'models';

import { Paragraph } from 'style';
import { Wrapper } from '../filters.styles';

type SelectFilterValue = 'active' | 'inactive' | 'all';

const items: Option<SelectFilterValue>[] = [
  { name: 'Active', value: 'active' },
  { name: 'Inactive', value: 'inactive' },
  { name: 'All', value: 'all' }
];

const SelectActiveFilter = ({ column: { filterValue, setFilter } }: FilterProps<Employee>) => {
  useEffect(() => {
    setFilter(true);
  }, []);

  const handleSelectChange = (value: SelectFilterValue) => {
    if (value === 'active') setFilter(true);
    if (value === 'inactive') setFilter(false);
    if (value === 'all') setFilter(undefined);
  };

  const status = useMemo(() => {
    if (filterValue === true) return 'active';
    if (filterValue === false) return 'inactive';
    if (filterValue === undefined) return 'all';
  }, [filterValue]);

  return (
    <Wrapper>
      <Select<SelectFilterValue>
        items={items}
        value={filterValue}
        onChange={handleSelectChange}
        size={'small'}
        placeholder={'Status'}
        hideRadioButtons={false}
        alignPosition={'center'}
        tableSelect={true}
        customButton={
          <Button buttonType={'wrapper'}>
            <Paragraph color={'scale3'} weight={'medium'}>
              Status: {status}
            </Paragraph>
          </Button>
        }
      />
    </Wrapper>
  );
};

export default SelectActiveFilter;
