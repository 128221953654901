import React from 'react';
import { Form, useFormikContext } from 'formik';
import { useHistory } from 'react-router-dom';

import { Button, FormField, Loader } from 'components';
import { LoginDto } from 'api';

import { Flex } from 'style';
import { ForgotPassword } from 'pages/Login/login.styles';

const LoginForm: React.FC = () => {
  const history = useHistory();
  const { isSubmitting } = useFormikContext<LoginDto>();

  const redirectToReset = () => {
    history.push('/forgot-password');
  };

  return (
    <div>
      {isSubmitting && <Loader />}
      {!isSubmitting && (
        <Form>
          <Flex direction={'column'} gap={12}>
            <FormField name={'email'} type={'email'} placeholder={'E-mail'} />
            <FormField name={'password'} type={'password'} placeholder={'Password'} />
            <Button fullWidth={true} type={'submit'}>
              Log in
            </Button>
            <Button buttonType={'wrapper'} onClick={redirectToReset}>
              <ForgotPassword type={'h6'}>Forgot password?</ForgotPassword>
            </Button>
          </Flex>
        </Form>
      )}
    </div>
  );
};

export default LoginForm;
