import React from 'react';
import { Column, Row } from 'react-table';

import { Button } from 'components';
import { useCall } from 'components/hooks';
import { downloadInvoice } from 'api';
import { tableDropdown } from 'utils/table';
import { dateFormat, downloadFile } from 'utils';
import { Option } from 'types';
import { Invoice } from 'models';

import { CheckIcon, DownloadIcon, Paragraph, colors } from 'style';

export interface DropdownValue {
  type: 'delete' | 'edit' | 'status';
  item: Invoice;
}

const prepareDropdownItems = (item: Invoice): Option<DropdownValue>[] => {
  const changeStatusItem = { name: item.isPaid ? 'Mark as unpaid' : 'Mark as paid', value: { type: 'status', item } };
  const deleteItem = { name: 'Remove', value: { type: 'delete', item }, type: 'delete' };
  const editItem = { name: 'Edit', value: { type: 'edit', item } };

  return [changeStatusItem, editItem, deleteItem] as Option<DropdownValue>[];
};

export const prepareColumns = (handleDropdownSelect: (value: DropdownValue) => void): Column<Invoice>[] => {
  return [
    {
      Header: 'Name',
      accessor: 'name',
      sortType: 'string',
      disableFilters: true
    },
    {
      Header: 'Buyer',
      accessor: 'buyerName',
      sortType: 'string',
      disableFilters: true
    },
    {
      Header: 'Date',
      accessor: 'date',
      sortType: 'string',
      disableFilters: true,
      Cell: ({ value }: { value: string }) => dateFormat(value, true)
    },
    {
      Header: 'Status',
      accessor: 'isPaid',
      disableFilters: true,
      Cell: ({ value: isPaid }: { value: boolean }) =>
        isPaid ? (
          <Paragraph style={{ color: colors.green }}>
            <CheckIcon /> Paid
          </Paragraph>
        ) : (
          <span>Unpaid</span>
        )
    },
    {
      Header: '',
      id: 'download',
      width: 150,
      disableFilters: true,
      Cell: ({ row }: { value: string; row: Row<Invoice> }) => {
        const { id, companyId } = row.original;

        const { submit, onCallSuccess } = useCall(downloadInvoice(companyId, id));
        onCallSuccess((data, headers) => {
          const fileName = headers['x-file-name'];
          downloadFile(data, fileName);
        });

        return (
          <Button buttonType={'borderless'} onClick={submit}>
            <DownloadIcon />
            Download
          </Button>
        );
      }
    },
    {
      Header: '',
      id: 'dropdown',
      width: 10,
      disableFilters: true,
      Cell: ({ row }: { row: Row<Invoice> }) => {
        const options = prepareDropdownItems(row.original);

        return tableDropdown<DropdownValue>({ options, handleDropdownSelect });
      }
    }
  ];
};
