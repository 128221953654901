import { toast, Zoom, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const options: ToastOptions = {
  position: 'bottom-right',
  autoClose: 4000,
  hideProgressBar: false,
  closeOnClick: true,
  draggable: true,
  progress: undefined,
  transition: Zoom,
  pauseOnHover: false,
  pauseOnFocusLoss: false
};

type ToastReturn = {
  success: (message: string) => void;
  info: (message: string) => void;
  warning: (message: string) => void;
  error: (message: string) => () => void;
};

const useToast = (): ToastReturn => {
  toast.configure();

  const success = (message: string) => toast.success(message, options);
  const info = (message: string) => toast.info(message, options);
  const warning = (message: string) => toast.warning(message, options);
  const error = (message: string) => () => toast.error(message, options);

  return { success, info, warning, error };
};

export default useToast;
