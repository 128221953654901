import React from 'react';
import { useParams } from 'react-router-dom';

import PersonalPdf from './PersonalPdf/PersonalPdf';
import { Heading, CardItem, Formatter, Loader, Error } from 'components';
import { useFetch, useShowContent, useCall, useToast, usePermissions } from 'components/hooks';
import { getEmployee, getEmployeeFile } from 'api';
import { downloadFile } from 'utils';
import { currencies } from 'types';

import { Card, Paragraph, StatusParagraph } from 'style';

interface Props {
  setEmployeeName: (name: string) => void;
}

const PersonalData: React.FC<Props> = ({ setEmployeeName }) => {
  const { error } = useToast();
  const { companyId, employeeId } = useParams<{ companyId: string; employeeId: string }>();
  const { companyPermissions } = usePermissions();

  const employeeData = useFetch(getEmployee(companyId, employeeId), { onSuccess: ({ name }) => setEmployeeName(name) });
  const { showLoader, showContent, showNoContent, showError } = useShowContent(employeeData);
  const { payload: employee } = employeeData;

  const { submit: download, onCallSuccess, onCallError } = useCall(getEmployeeFile);
  onCallSuccess((payload) => {
    const fileName = employee ? `${employee.name}_personal_data.pdf` : 'employee_personal_data.pdf';
    downloadFile(payload, fileName);
  });
  onCallError(error('Cannot download employee pdf file'));

  const isActive = (active: boolean) => (active ? 'Active' : 'Inactive');
  const handleDownloadPdf = () => download(companyId, employeeId);
  const showBaseUsd = companyPermissions.can('manage', 'EmployeeSalaryUsd');

  return (
    <Card height={'auto'}>
      <Heading>Personal data</Heading>
      {showLoader && <Loader height={'100px'} />}
      {(showError || showNoContent) && <Error height={'100px'}>Fetch error</Error>}
      {showContent && employee && (
        <>
          {employee.filePath && <PersonalPdf userName={employee.name} onClick={handleDownloadPdf} />}
          <CardItem name={'Position'}>
            <Paragraph>{employee.position}</Paragraph>
          </CardItem>
          <CardItem name={'Base Pay (TZS)'}>
            <Formatter value={employee.baseTzs} prefix={' '} suffix={` ${currencies['tzs']}`} />
          </CardItem>
          {showBaseUsd && (
            <CardItem name={'Base Pay (USD)'}>
              <Formatter value={employee.baseUsd || 0} prefix={' '} suffix={` ${currencies['usd']}`} />
            </CardItem>
          )}
          <CardItem name={'Start contract'}>
            <Paragraph>{employee.contractStart ? new Date(employee.contractStart).toLocaleDateString() : '-'}</Paragraph>
          </CardItem>
          <CardItem name={'End contract'}>
            <Paragraph>{new Date(employee.contractEnd).toLocaleDateString()}</Paragraph>
          </CardItem>
          <CardItem name={'Status'}>
            <StatusParagraph active={employee.isActive}>{isActive(employee.isActive)}</StatusParagraph>
          </CardItem>
        </>
      )}
    </Card>
  );
};

export default PersonalData;
