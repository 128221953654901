import React from 'react';

import { FormField, Heading } from 'components';
import { currencySelectItems, restrictedCurrencyItems } from 'utils';
import { accountOptions } from 'form/transaction/cash-flow-form/utils/account.select';
import { usePermissions } from 'components/hooks';

import { Grid } from 'style';

const Details: React.FC = () => {
  const { companyPermissions } = usePermissions();

  const currencyOptions = companyPermissions.can('create', 'ExchangedTransaction') ? currencySelectItems : restrictedCurrencyItems;

  return (
    <div>
      <Heading type={'h5'}>Petty Cash Details</Heading>
      <FormField name={'account'} type={'combobox'} items={accountOptions} placeholder={'Account'} label={'Account'} width={'100%'} />
      <Grid columns={'4fr 2fr'} margin={'12px 0'} align={'center'}>
        <FormField name={'date'} type={'date'} placeholder={'Date'} fullWidth={true} />
        <FormField name={'currency'} type={'select'} items={currencyOptions} placeholder={'Currency'} />
      </Grid>
    </div>
  );
};

export default Details;
