import styled from 'styled-components';

import { colors } from 'style';

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledInput = styled.input`
  cursor: pointer;
  position: relative;
  width: 48px;
  height: 24px;
  transition: all 0.8s ease;
  z-index: 1;
  -moz-appearance: initial;

  &:before {
    content: '';
    position: absolute;
    background-color: #fff;
    border: 2px solid #d2d2d2;
    border-radius: 20px;
    display: block;
    width: 48px;
    height: 24px;
    transition: all 0.8s ease;
  }

  &:after {
    content: '';
    position: absolute;
    background-color: #d2d2d2;
    border-radius: 50%;
    display: block;
    width: 12px;
    height: 12px;
    left: 6px;
    transform: translateY(-50%);
    top: 50%;
    transition: all 0.5s ease;
  }

  &:checked {
    &::before {
      background-color: ${colors.blue.base};
    }
    &:after {
      background-color: #fff;
      left: 30px;
    }
  }

  &:disabled {
    opacity: 0.6;
  }
`;

export { StyledWrapper, StyledInput };
