import React, { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { number, object, date, SchemaOf } from 'yup';

import NewLoanShareholderForm from './new-loan-shareholder-form';
import { FormField } from 'components/index';
import { useFetch, useShowContent } from 'components/hooks';
import { getCompanyUsers } from 'api';
import { Option } from 'types';
import { message } from 'utils';
import { CashFlowContext } from 'form/transaction/context/cash-flow.context';

import { Flex } from 'style';

export const shareholderLoanSchema: SchemaOf<ShareholderLoanValues> = object({
  shareholderId: number().required(message.required).nullable(),
  endDate: date().required(message.required),
  provision: number().required(message.required)
});

export type ShareholderLoanValues = {
  shareholderId: number;
  endDate: Date;
  provision: number;
};

const NewLoanShareholder: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const { defaultLoanData } = useContext(CashFlowContext);

  const shareholdersData = useFetch(getCompanyUsers(companyId, 'shareholder'));
  const { showLoader, showError } = useShowContent(shareholdersData);
  const { payload: shareholders } = shareholdersData;

  const shareholdersOptions: Option[] = useMemo(() => {
    if (shareholders) return shareholders.map(({ id, name, surname }) => ({ name: `${name} ${surname}`, value: id }));
    else return [];
  }, [shareholders]);

  const disableSelect = showLoader || showError || !!defaultLoanData?.loanLenderId;

  return (
    <Flex direction={'column'} justify={'flex-start'} align={'flex-start'}>
      <FormField
        name={'accountData.loanData.shareholderId'}
        type={'select'}
        items={shareholdersOptions}
        label={'Select shareholder'}
        placeholder={'Select shareholder'}
        width={'100%'}
        disabled={disableSelect}
      />
      <NewLoanShareholderForm />
    </Flex>
  );
};

export default NewLoanShareholder;
