import React from 'react';
import { Column, Row } from 'react-table';
import { AnyMongoAbility } from '@casl/ability';

import { SelectTransactionStatus, SelectAccountsFilter } from 'components';
import { Formatter } from 'components';
import { renderAmount, tableDropdown } from 'utils/table';
import { Transaction } from 'models';
import { CurrencyValue, Option, SecuredTableDropdownArguments } from 'types';

import { StatusParagraph } from 'style';

export interface DropdownValue {
  type: 'edit' | 'delete';
  item: Transaction;
}

const prepareDropdownItems = (item: Transaction, ability: AnyMongoAbility, isEditable: boolean): Option<DropdownValue>[] => {
  const items: Option<DropdownValue>[] = [];

  const editItem = ability.can('update', 'Transaction') && isEditable ? [{ name: 'Edit', value: { type: 'edit', item } }] : [];
  const deleteItem = ability.can('delete', 'Transaction') ? [{ name: 'Delete', type: 'delete', value: { type: 'delete', item } }] : [];

  return [...items, ...editItem, ...deleteItem] as Option<DropdownValue>[];
};

export const prepareColumns = ({ handleDropdownSelect, ability }: SecuredTableDropdownArguments<DropdownValue>): Column<Transaction>[] => [
  {
    Header: 'Date',
    accessor: 'date',
    sortType: 'datetime',
    sortDescFirst: false,
    disableFilters: true,
    Cell: ({ value }: { value: string }) => {
      return new Date(value).toLocaleDateString();
    }
  },
  { Header: 'Description', accessor: 'description', sortType: 'string', disableFilters: true },
  { accessor: 'account', sortType: 'string', disableGlobalFilter: false, Filter: SelectAccountsFilter },
  { accessor: 'currency', Header: 'Currency', sortType: 'string', disableFilters: true },
  {
    accessor: 'amount',
    Header: 'Amount',
    sortType: 'number',
    disableFilters: true,
    Cell: (data: any) => {
      const type = data.row.original.type;
      const currency: CurrencyValue = data.row.original.currency;
      const value = `${data.value} ${currency}`;

      const { prefix, suffix, color } = renderAmount(type === 'income', currency);
      return <Formatter value={value} weight={'medium'} customColor={color} prefix={prefix} suffix={suffix} />;
    }
  },
  {
    accessor: 'isPaid',
    disableSortBy: true,
    filter: 'equals',
    disableGlobalFilter: true,
    Filter: SelectTransactionStatus,
    Cell: ({ value }: { value: boolean }) => {
      const text = value ? 'Paid' : 'Outstanding';

      return <StatusParagraph active={value}>{text}</StatusParagraph>;
    }
  },
  {
    id: 'dropdown',
    width: 20,
    disableFilters: true,
    Cell: ({ row }: { row: Row<Transaction> }) => {
      const isEditable = Boolean(row.original.isEditable);

      const options = prepareDropdownItems(row.original, ability, isEditable);

      return tableDropdown<DropdownValue>({ options, handleDropdownSelect });
    }
  }
];
