import React, { useContext } from 'react';
import { Formik, Form } from 'formik';
import { useParams } from 'react-router-dom';

import Details from './details/details';
import Products from './products/products';
import Suppliers from './suppliers/suppliers';
import SubmitButtons from './submit-buttons/submit-buttons';
import { Modal } from 'components';
import { useSubmit, useToast } from 'components/hooks';
import { postPettyCash } from 'api';
import { downloadFile } from 'utils';
import { PettyCashDto, PettyCashProduct } from 'api';
import { CashFlowContext } from 'form/transaction/context/cash-flow.context';
import { pettyCashSchema } from './petty-cash-form.validation';
import { Supplier } from 'models';
import { CashFlowDto } from '../cash-flow-form/types';

export const emptyProduct: PettyCashProduct = { name: '', amount: 1, quantity: 1 };

const initialValues = ({ savedFormValues }: { savedFormValues: (Partial<CashFlowDto> & { supplierId?: number }) | null }) => ({
  account: savedFormValues?.account,
  date: new Date(),
  currency: savedFormValues?.currency || 'tzs',
  items: [emptyProduct],
  supplierId: savedFormValues?.supplierId || 0
});

interface Props {
  onOpenAddSupplierModal: () => void;
  suppliers: Supplier[];
}

const PettyCashForm = ({ onOpenAddSupplierModal, suppliers }: Props) => {
  const { success, error } = useToast();
  const { companyId } = useParams<{ companyId: string }>();
  const { handleFormChange, disseminatePettyCashValues, savedFormValues, pettyCashValues } = useContext(CashFlowContext);

  const { onSubmit, onSubmitSuccess, onSubmitError, mapData } = useSubmit(postPettyCash(companyId));
  onSubmitError(error('Failed to generate petty cash file'));
  onSubmitSuccess((data, values, headers) => {
    const fileName = headers['x-file-name'];
    downloadFile(data, fileName);

    success('Petty cash file has been generated');
    handleFormChange('cash-flow')();
  });
  mapData((data) => {
    disseminatePettyCashValues(data);
    return {
      ...data,
      date: new Date(data.date).toISOString(),
      account: undefined
    };
  });

  const handleClose = (resetForm: () => void) => () => {
    handleFormChange('cash-flow')();
    resetForm();
  };

  return (
    <Formik<PettyCashDto>
      initialValues={initialValues({ savedFormValues: { ...savedFormValues, supplierId: pettyCashValues?.supplierId } })}
      onSubmit={onSubmit}
      validationSchema={pettyCashSchema}
      enableReinitialize
    >
      {({ resetForm }) => {
        return (
          <Modal isOpen={true} handleClose={handleClose(resetForm)} title={'Generate Petty Cash'} width={'700px'}>
            <Form>
              <Details />
              <Products />
              <Suppliers onOpenAddSupplierModal={onOpenAddSupplierModal} suppliers={suppliers} />
              <SubmitButtons />
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default PettyCashForm;
