import styled, { css } from 'styled-components';

import { ButtonTypes, ButtonSizes } from './Button';

import { colors, shadows, font, borders } from 'style';

interface ButtonType {
  buttonType: ButtonTypes;
  size?: ButtonSizes;
  iconPosition?: 'leading' | 'trailing' | null;
  fullWidth?: boolean;
  svgMargin?: boolean;
}

const StyledButton = styled.button<ButtonType>`
  display: flex;
  align-items: center;
  justify-content: ${({ fullWidth }) => (fullWidth ? 'center' : 'unset')};
  padding: 0.8rem 1.2rem;
  height: 40px;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  border-radius: 8px;
  font-size: 1.6rem;
  line-height: calc(1em + 4px);
  z-index: 1;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.05s ease;
  white-space: nowrap;
  font-weight: ${font.weight.medium};

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  svg {
    height: 1.2em;
    width: 1.2em;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.6;
    cursor: auto;
  }

  /* ------------------------------ Icons ------------------------------ */

  ${({ iconPosition }) => {
    switch (iconPosition) {
      case 'trailing':
        return css`
          svg {
            margin-left: 1rem;
          }
        `;
      case 'leading':
        return css`
          svg {
            margin-right: 1rem;
          }
        `;
      default:
        return css`
          svg {
            margin: 0;
          }
        `;
    }
  }}

  /* ------------------------------ Button sizes ------------------------------ */

  ${({ size }) => {
    switch (size) {
      case 'small':
        return css`
          font-size: 1.3rem;
          padding: 0.4rem 0.8rem;
          height: 2.8rem;
        `;
      case 'large':
        return css`
          padding: 1.2rem 1.6rem;
          font-size: 2rem;
          height: 5.2rem;

          svg {
            height: 1.5em;
            width: 1.5em;
          }
        `;
    }
  }}

  /* ------------------------------ Button types ------------------------------ */

  ${({ buttonType }) =>
    buttonType === 'primary' &&
    css`
      background-color: ${colors.blue.base};
      color: #fff;
      border: 1px solid transparent;
      box-shadow: ${shadows.button};

      &:hover {
        background: ${colors.blue.medium};
      }

      &:active {
        background: ${colors.blue.darker};
      }
    `}

  ${({ buttonType }) =>
    buttonType === 'secondary' &&
    css`
      background-color: #fff;
      color: ${colors.gray.scale1};
      border: ${borders.button.secondary};
      box-shadow: ${shadows.default};
      font-weight: ${font.weight.medium};
      justify-content: center;
    `}

  ${({ buttonType }) =>
    buttonType === 'remove' &&
    css`
      background-color: ${colors.red};
      color: #fff;
      border: none;
    `}

  ${({ buttonType }) =>
    buttonType === 'borderless' &&
    css`
      background-color: #fff;
      color: ${colors.blue.base};
      border-color: transparent;
      box-shadow: none;

      svg {
        width: 16px;
        height: 16px;

        path {
          fill: ${colors.blue.base};
        }
      }
    `}
  
  ${({ buttonType }) =>
    buttonType === 'secondary-accent' &&
    css`
      background-color: #fff;
      color: ${colors.blue.base};
      border: ${borders.button.secondary};
      box-shadow: ${shadows.default};
      font-weight: ${font.weight.medium};
      justify-content: center;
      svg {
        color: ${colors.blue.base};
      }
    `}

  ${({ buttonType, svgMargin }) =>
    buttonType === 'wrapper' &&
    css`
      background-color: transparent;
      border: none;
      box-shadow: none;
      color: ${colors.gray.scale1};
      display: grid;
      place-items: center;
      padding: 0;
      overflow: visible;
      cursor: pointer;

      svg {
        width: auto;
        height: auto;
        margin: ${svgMargin ? '0 10px' : 0};
      }
    `}
`;

export { StyledButton };
