import React from 'react';
import { Form, Formik, FormikHelpers } from 'formik';

import { Button, FormField } from 'components';
import { PostLoanDto } from 'api';
import { loanSchema } from './loan.validation';

import { Flex, Paragraph } from 'style';

interface Props {
  handleClose: () => void;
  handleSubmit: (values: PostLoanDto, helpers: FormikHelpers<PostLoanDto>) => void;
  initialValues?: PostLoanDto;
}

const emptyValues: PostLoanDto = {
  date: new Date(),
  ratesNumber: 1,
  amount: 0
};

const LoanManagement: React.FC<Props> = ({ handleClose, handleSubmit, initialValues }) => {
  const values: PostLoanDto = initialValues || emptyValues;

  return (
    <Formik initialValues={values} onSubmit={handleSubmit} enableReinitialize={true} validationSchema={loanSchema}>
      <Form>
        <Flex justify={'space-between'} margin={'10px 0'}>
          <Paragraph>Date</Paragraph>
          <FormField name={'date'} type={'date'} />
        </Flex>
        <Flex justify={'space-between'} margin={'10px 0'}>
          <Paragraph>Rates number</Paragraph>
          <FormField name={'ratesNumber'} type={'number'} />
        </Flex>
        <FormField name={'amount'} type={'money'} placeholder={'Amount'} />
        <Flex justify={'flex-end'} margin={'10px 0'}>
          <Button buttonType={'secondary'} onClick={handleClose}>
            Cancel
          </Button>
          <Button type={'submit'}>Save & Download Payslip</Button>
        </Flex>
      </Form>
    </Formik>
  );
};

export default LoanManagement;
