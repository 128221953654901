import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import RestaurantReport from './components/RestaurantReport/RestaurantReport';
import RestaurantTable from './components/RestaurantTable/RestaurantTable';
import { EditRestaurantToken } from 'pages/Company/views/Restaurant/modals';
import { PageHeader, Loader } from 'components';
import { useFetch, useShowContent, useDateRange, usePermissions } from 'components/hooks';
import { getRestaurantStatus } from 'api';

import { StyledGrid } from './restaurant.styles';

const Restaurant: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const { companyPermissions } = usePermissions();
  const { dateRange, handleDateRange } = useDateRange();

  const [showRestaurant, setShowRestaurant] = useState<boolean | null>(null);
  const [reportsRefreshDate, setRefreshDate] = useState<Date>(new Date());

  const restaurantStatus = useFetch(getRestaurantStatus(companyId), { onSuccess: (payload) => setShowRestaurant(payload) });
  const { showLoader } = useShowContent(restaurantStatus);
  const { refresh } = restaurantStatus;

  const handleReportsRefresh = () => setRefreshDate(new Date());

  const canGenerateReport = companyPermissions.can('generate', 'RestaurantReports');
  const showRestaurantReports = companyPermissions.can('read', 'RestaurantReports');

  return (
    <>
      {showLoader && <Loader />}
      {!showLoader && showRestaurant === false && <EditRestaurantToken successCallback={refresh} />}
      {!showLoader && showRestaurant && (
        <>
          <PageHeader heading={'Restaurant'} handleDateChange={handleDateRange} />
          <StyledGrid fullWidth={!canGenerateReport || !showRestaurantReports}>
            {canGenerateReport ? <RestaurantReport dateRange={dateRange} handleReportsRefresh={handleReportsRefresh} /> : <div />}
            {showRestaurantReports ? <RestaurantTable reportsRefreshDate={reportsRefreshDate} /> : <div />}
          </StyledGrid>
        </>
      )}
    </>
  );
};

export default Restaurant;
