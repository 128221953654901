import { CompanyDto } from 'api';

const emptyValues: CompanyDto = {
  name: '',
  address: '',
  vatId: '',
  tinId: '',
  posterToken: '',
  restaurant: true,
  startBalance: {
    bankPlAmountPln: null,
    bankPlAmountEur: null,
    bankPlAmountUsd: null,

    bankZnzAmountTzs: null,
    bankZnzAmountEur: null,
    bankZnzAmountUsd: null,

    cashAmountTzs: null,
    cashAmountEur: null,
    cashAmountUsd: null
  },
  shareholders: [],
  vfmsIntergrationId: '',
  vfmsTokenId: ''
};

export { emptyValues };
