import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import AddShareholderLoan from '../../modals/AddShareholderLoan/AddShareholderLoan';
import { Table, Heading, AddNewButton, Loader, Empty, Error } from 'components';
import { getShareholderLoans } from 'api';
import { useFetch, useShowContent } from 'components/hooks';
import { Loan } from 'models';
import { columns } from './loans.columns';

import { Card, Flex } from 'style';

const Loans: React.FC = () => {
  const { companyId, partnerId } = useParams<{ companyId: string; partnerId: string }>();

  const [isAddLoanOpen, setAddLoanOpen] = useState<boolean>(false);
  const [loansRefreshDate, setLoansRefreshDate] = useState<Date>(new Date());

  const loansData = useFetch(getShareholderLoans(companyId, partnerId), { dependencies: [loansRefreshDate] });
  const { showLoader, showError, showNoContent, showContent } = useShowContent(loansData);
  const { payload: loans } = loansData;

  const handleAddLoanOpen = (isOpen: boolean) => () => setAddLoanOpen(isOpen);
  const handleLoansRefresh = () => setLoansRefreshDate(new Date());

  return (
    <>
      <Card>
        <Flex margin={'0 0 10px 0'} justify={'space-between'}>
          <Heading margin={'0'}>Loans</Heading>
          <AddNewButton onClick={handleAddLoanOpen(true)}>Add loan</AddNewButton>
        </Flex>
        {showLoader && <Loader />}
        {showNoContent && <Empty>No data</Empty>}
        {showError && <Error>Fetch error</Error>}
        {showContent && loans && <Table<Loan> columns={columns} data={loans} />}
      </Card>
      <AddShareholderLoan isOpen={isAddLoanOpen} handleClose={handleAddLoanOpen(false)} loansRefresh={handleLoansRefresh} />
    </>
  );
};

export default Loans;
