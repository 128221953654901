import styled, { css } from 'styled-components';

import { ArrowIcon, SideArrowIcon, borders, colors, font, shadows } from 'style';

const PageButton = styled.button<{ selected?: boolean }>`
  background-color: #fff;
  box-shadow: ${shadows.default};
  border: ${borders.default};
  border-radius: 8px;
  padding: 8px 12px;
  display: grid;
  place-items: center;
  font-weight: ${font.weight.medium};

  ${({ selected }) =>
    selected &&
    css`
      background-color: ${colors.blue.base};
      color: #fff;
    `}

  &:disabled {
    opacity: 0.5;
  }
`;

const ArrowLeft = styled(ArrowIcon)`
  transform: rotate(180deg);
`;

const ArrowRight = ArrowIcon;

const SideArrowLeft = styled(SideArrowIcon)`
  margin: 4px;
  width: 11px;
  height: 11px;
`;

const SideArrowRight = styled(SideArrowLeft)`
  transform: rotate(180deg);
`;

export { PageButton, ArrowLeft, ArrowRight, SideArrowLeft, SideArrowRight };
