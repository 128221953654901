import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { handlePreparePath } from 'utils/routes';
import { invoicesRoutes } from './invoices.routes';

const Invoices: React.FC = () => {
  const { path: routePath } = useRouteMatch();

  return (
    <Switch>
      {invoicesRoutes
        .filter((route) => !route.disabled)
        .map(({ path, ...props }) => (
          <Route key={path} path={handlePreparePath(routePath, path)} {...props} />
        ))}
    </Switch>
  );
};

export default Invoices;
