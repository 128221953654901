import React from 'react';
import { useParams } from 'react-router-dom';

import Transaction from 'form/transaction/transaction';
import { postTransaction } from 'api';
import { useSubmit, useToast } from 'components/hooks';
import { mapTransactionData } from 'utils';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  loansRefresh: () => void;
}

const AddLoan: React.FC<Props> = ({ isOpen, handleClose, loansRefresh }) => {
  const { success, error } = useToast();
  const { companyId, employeeId } = useParams<{ companyId: string; employeeId: string }>();

  const { onSubmit, onSubmitSuccess, onSubmitError, mapData } = useSubmit(postTransaction(companyId));
  onSubmitError(error('Transaction error'));
  onSubmitSuccess(() => {
    handleClose();
    loansRefresh();
    success('Transaction has been added');
  });
  mapData((data) => {
    return mapTransactionData(data);
  });

  return <Transaction isOpen={isOpen} handleClose={handleClose} handleSubmit={onSubmit} type={'new'} lender={'employee'} loanLenderId={+employeeId} />;
};

export default AddLoan;
