export const getCurrentMonthRange = () => {
  const currentDate = new Date();
  const firstMonthDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1, 12, 0, 0);
  const lastMonthDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0, 12, 0, 0);

  const startDate = new Date(firstMonthDay.setUTCHours(12));
  const endDate = new Date(lastMonthDay.setUTCHours(12));

  return [startDate, endDate];
};

type ISODateRange = [string, string];

export const dateRangeToISO = (dateRange: [Date, Date]): ISODateRange => {
  const [startRangeDate, endRangeDate] = dateRange;

  const startDate = startRangeDate.toISOString();
  const endDate = endRangeDate.toISOString();

  return [startDate, endDate];
};
