import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import AccountManagement from '../AccountManagement/AccountManagement';
import { useSubmit, useFetch, useToast, useShowContent } from 'components/hooks';
import { putCar, getCar, CarDto } from 'api';
import { Car } from 'models';
import { UpdateSingleItem } from 'types';

interface Props {
  item: Car;
  isOpen: boolean;
  handleClose: () => void;
  updateCar: (args: UpdateSingleItem<Car[]>) => void;
}

const EditCar: React.FC<Props> = ({ item, isOpen, handleClose, updateCar }) => {
  const { success, error } = useToast();
  const { companyId } = useParams<{ companyId: string }>();

  const carData = useFetch(getCar(companyId, item.id));
  const { showLoader, showError, showNoContent } = useShowContent(carData);
  const { payload: car } = carData;

  const initialValues: CarDto | undefined = useMemo(() => {
    if (car) {
      return { name: car.name };
    }
  }, [car]);

  const { onSubmit, onSubmitSuccess, onSubmitError } = useSubmit(putCar(companyId, item.id));
  onSubmitError(error('Cannot update car'));
  onSubmitSuccess((car) => {
    updateCar({ updatedItem: car });
    success(`${car.name} has been updated`);
    handleClose();
  });

  const disableEdit = showError || showNoContent;

  return (
    <AccountManagement
      isOpen={isOpen}
      handleClose={handleClose}
      handleSubmit={onSubmit}
      isLoading={showLoader}
      initialValues={initialValues}
      buttonText={'Save'}
      showError={disableEdit}
      title={'Edit car'}
    />
  );
};

export default EditCar;
