import fetchMiddleware from 'api/api.middleware';
import { IdParam } from 'types';
import { Location } from 'models';
import { LocationDto, DeleteLocationResponseDto } from './api.location.dto';

export type LocationType = 'electricity' | 'swimming-pool' | 'telephone-internet';
export type LocationsNames = 'Electricity' | 'Swimming pool' | 'Telephone/Internet';

export const getLocations = (companyId: IdParam, type: LocationType) => () =>
  fetchMiddleware<Location[]>({ method: 'get', url: `/companies/${companyId}/locations`, query: { locationType: type } });

export const getLocation = (companyId: IdParam, locationId: IdParam) => () =>
  fetchMiddleware<Location>({ method: 'get', url: `/companies/${companyId}/locations/${locationId}` });

export const postLocation = (companyId: IdParam, type: LocationType) => (data: LocationDto) =>
  fetchMiddleware<Location>({ method: 'post', url: `/companies/${companyId}/locations`, data, query: { locationType: type } });

export const putLocation = (companyId: IdParam, locationId: IdParam) => (data: LocationDto) =>
  fetchMiddleware<Location>({ method: 'put', url: `/companies/${companyId}/locations/${locationId}`, data });

export const deleteLocation = (companyId: IdParam, locationId: IdParam) =>
  fetchMiddleware<DeleteLocationResponseDto>({
    method: 'delete',
    url: `/companies/${companyId}/locations/${locationId}`,
    data: {}
  });
