import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import States from './States/States';
import { Table, DeleteModal } from 'components';
import { AddEmployee, EditEmployee } from 'pages/Company/views/Employee/modals';
import { getEmployees, deleteEmployee, putEmployeeStatus, DeleteEmployeeResponseDto } from 'api';
import { useFetch, useShowContent, useCall, useEdit, useDelete, useToast, usePermissions } from 'components/hooks';
import { prepareColumns, DropdownValue } from './employees.columns';
import { Employee } from 'models';
import { PAGE_ITEMS } from 'utils/table';

import { Card } from 'style';

interface Props {
  isAddEmployeeOpen: boolean;
  closeAddEmployee: () => void;
}

const EmployeesTable: React.FC<Props> = ({ isAddEmployeeOpen, closeAddEmployee }) => {
  const history = useHistory();
  const { success, error } = useToast();
  const { companyPermissions } = usePermissions();
  const { companyId } = useParams<{ companyId: string }>();
  const { itemToDelete, setItemToDelete, isDeletePopupOpen, closeDeletePopup } = useDelete<Employee>();
  const { itemToEdit, setItemToEdit, isEditPopupOpen, closeEditPopup } = useEdit<Employee>();

  const employeesData = useFetch(getEmployees(companyId));
  const { showContent, showLoader, showNoContent, showError } = useShowContent(employeesData);
  const { payload: employees, deleteSingleItem, updateSingleItem, addSingleItem } = employeesData;

  const handleDeleteSuccess = ({ id }: DeleteEmployeeResponseDto) => {
    success('Employee has been deleted');
    deleteSingleItem({ itemId: id });
    closeDeletePopup();
  };

  const handleStatusSuccess = (payload: Employee) => {
    success('Employee status has been changed');
    updateSingleItem({ updatedItem: payload });
  };

  const { submit, onCallSuccess, onCallError } = useCall(deleteEmployee);
  onCallError(error('Cannot delete employee'));
  onCallSuccess(handleDeleteSuccess);

  const { submit: submitStatus, onCallSuccess: onStatusSuccess, onCallError: onStatusError } = useCall(putEmployeeStatus);
  onStatusError(error('Cannot update employee status'));
  onStatusSuccess(handleStatusSuccess);

  const handleDeleteEmployee = async () => {
    if (itemToDelete) {
      await submit(companyId, itemToDelete.id);
    }
  };

  const handleRowClick = (employee: Employee) => () => {
    if (canReadEmployee) {
      history.push(`/company/${companyId}/employee/${employee.id}`);
    }
  };

  const handleDropdownSelect = async ({ type, item }: DropdownValue) => {
    type === 'edit' && setItemToEdit(item);
    type === 'delete' && setItemToDelete(item);
    type === 'status' && (await submitStatus(companyId, item.id));
  };

  const columns = prepareColumns({ handleDropdownSelect, ability: companyPermissions });

  const canReadEmployee = companyPermissions.can('read', 'Employee');
  const showAddEmployee = companyPermissions.can('create', 'Employee');
  const showEditEmployee = companyPermissions.can('update', 'Employee');
  const showDeleteEmployee = companyPermissions.can('delete', 'Employee');

  return (
    <>
      <Card>
        <States showLoader={showLoader} showNoContent={showNoContent} showError={showError} />
        {showContent && employees && (
          <Table<Employee>
            columns={columns}
            data={employees}
            handleRowClick={handleRowClick}
            showFooter={true}
            showGlobalFilter={true}
            showColumnsFilter={true}
            pageItems={PAGE_ITEMS}
            haveDropdown={true}
          />
        )}
      </Card>

      {showAddEmployee && <AddEmployee isOpen={isAddEmployeeOpen} handleClose={closeAddEmployee} addEmployee={addSingleItem} />}
      {itemToEdit && showEditEmployee && (
        <EditEmployee isOpen={isEditPopupOpen} handleClose={closeEditPopup} item={itemToEdit} updateEmployee={updateSingleItem} />
      )}
      {showDeleteEmployee && (
        <DeleteModal isOpen={isDeletePopupOpen} handleClose={closeDeletePopup} handleDelete={handleDeleteEmployee} itemName={itemToDelete?.name} />
      )}
    </>
  );
};

export default EmployeesTable;
