import styled from 'styled-components';

import { borders, colors } from 'style';

const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
  appearance: none;
  background-color: transparent;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  height: 20px;
  width: 20px;
  position: relative;
  transition: all 0.25s ease;
  vertical-align: middle;
  border: ${borders.input.base};

  &:focus {
    outline: none;
    -moz-outline-radius: 3px;
  }
  &:checked {
    background-color: ${colors.blue.base};
    border: 2px solid ${colors.blue.base};
  }
  &:checked:after {
    content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="none" stroke="rgb(255,255,255)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>');
    position: absolute;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.label`
  margin-left: 10px;
  transform-origin: left;
  font-size: 1.4rem;
  cursor: pointer;
`;

export { StyledCheckbox, Wrapper, Label };
