import React from 'react';

import { FormField, Heading } from 'components';
import { NotificationType } from 'models';

import { Flex } from 'style';
import { FlexWrapper } from 'pages/Company/views/Notifications/notifications.styles';

const TYPE: NotificationType = 'entityDeleted';

const EntityDeleted: React.FC = () => {
  return (
    <FlexWrapper align={'stretch'} direction={'column'}>
      <Heading margin={'0'}>Entity deleted</Heading>
      <Flex>
        <FormField type={'toggle'} name={`notifications[${TYPE}].isActive`} />
      </Flex>
    </FlexWrapper>
  );
};

export default EntityDeleted;
