import React, { useState } from 'react';

import Summary from './components/Summary/Summary';
import GenerateReport from './modals/GenerateReport/GenerateReport';
import { Button, PageHeader } from 'components';
import { useDateRange, usePermissions } from 'components/hooks';

import { Grid } from 'style';

const Tax: React.FC = () => {
  const { companyPermissions } = usePermissions();
  const { dateRange, handleDateRange } = useDateRange();

  const [refreshDate, setRefreshDate] = useState<Date>(new Date());
  const [isGenerateReportOpen, setGenerateReportOpen] = useState<boolean>(false);

  const refreshPastReports = () => setRefreshDate(new Date());
  const handleGenerateReportOpen = (isOpen: boolean) => () => setGenerateReportOpen(isOpen);

  const canGenerateReport = companyPermissions.can('generate', 'TaxReports');
  const canReadReports = companyPermissions.can('read', 'TaxReports');

  const CustomButton = canGenerateReport && <Button onClick={handleGenerateReportOpen(true)}>Generate report</Button>;

  const margin = '0 0 24px 0';

  return (
    <>
      <PageHeader heading={'TAX'} handleDateChange={handleDateRange} customButton={CustomButton} />
      <Grid margin={margin}>
        <Summary dateRange={dateRange} />
      </Grid>
      {/*{canReadReports && (*/}
      {/*  <Grid margin={margin}>*/}
      {/*    <TaxTable refreshDate={refreshDate} />*/}
      {/*  </Grid>*/}
      {/*)}*/}
      <GenerateReport isOpen={isGenerateReportOpen} handleClose={handleGenerateReportOpen(false)} successCallback={refreshPastReports} dateRange={dateRange} />
    </>
  );
};

export default Tax;
