import React from 'react';

import UsersManagement from '../UsersManagement/UsersManagement';
import { useSubmit, useToast } from 'components/hooks';
import { postUser } from 'api';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
}

const AddUser: React.FC<Props> = ({ isOpen, handleClose }) => {
  const { success, error } = useToast();

  const { onSubmit, onSubmitSuccess, onSubmitError } = useSubmit(postUser);
  onSubmitError(error('User cannot be added'));
  onSubmitSuccess(() => {
    success(`Email has been sent`);
    handleClose();
  });

  return <UsersManagement isOpen={isOpen} handleSubmit={onSubmit} handleClose={handleClose} title={'New user'} />;
};

export default AddUser;
