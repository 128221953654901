import fetchMiddleware from 'api/api.middleware';
import { IdParam } from 'types';
import { Invoice } from 'models';

import { InvoiceDto, PutInvoiceDto } from './api.invoices.dto';

export const getInvoices = (companyId: IdParam) => () => fetchMiddleware<Invoice[]>({ method: 'get', url: `/companies/${companyId}/invoices` });

export const getSingleInvoice = (companyId: IdParam, invoiceId: IdParam) => () =>
  fetchMiddleware<InvoiceDto>({ method: 'get', url: `/companies/${companyId}/invoices/${invoiceId}` });

export const downloadInvoice = (companyId: IdParam, invoiceId: IdParam) => () =>
  fetchMiddleware<string | Blob>({ method: 'get', url: `/companies/${companyId}/invoices/${invoiceId}/download`, responseType: 'blob' });

export const postInvoice = (companyId: IdParam) => (data: InvoiceDto) =>
  fetchMiddleware<Invoice>({ method: 'post', url: `/companies/${companyId}/invoices`, data });

export const putInvoiceStatus = (companyId: IdParam) => (invoiceId: IdParam, data: PutInvoiceDto) =>
  fetchMiddleware<Invoice>({ method: 'put', url: `/companies/${companyId}/invoices/${invoiceId}/status`, data });

export const putInvoice = (companyId: IdParam, invoiceId: IdParam) => (data: InvoiceDto) =>
  fetchMiddleware<Invoice>({ method: 'put', url: `/companies/${companyId}/invoices/${invoiceId}`, data });

export const deleteInvoice = (companyId: IdParam, invoiceId: IdParam) =>
  fetchMiddleware<Invoice>({ method: 'delete', url: `/companies/${companyId}/invoices/${invoiceId}`, data: {} });
