import React, { useEffect } from 'react';
import { useField } from 'formik';

import { FileUpload } from 'components';
import { FileUploadProps } from 'components/form/FileUpload/FileUpload';

type FileLimitedProps = Omit<FileUploadProps, 'onChange' | 'handleDelete'>;

export interface FileProps extends FileLimitedProps {
  name: string;
  onFieldChange?: () => void;
  onFieldError?: () => void;
  label?: string;
  className?: string;
}

const FileForm: React.FC<FileProps> = ({ name, onFieldChange, onFieldError, label, ...props }) => {
  const [field, meta, helpers] = useField({ name });

  const { error, touched } = meta;
  const { setValue } = helpers;

  const showError = error && touched;

  const handleSetValue = (value: File[] | string[]) => {
    value.length === 1 ? setValue(value[0]) : setValue(value);
    onFieldChange && onFieldChange();
  };

  const onFileChange = (value: any) => {
    handleSetValue(value);
  };

  const handleDelete = (fileToDelete: File | string) => {
    if (Array.isArray(field.value)) {
      const files = field.value.filter((file) => file !== fileToDelete);
      setValue(files);
    } else {
      setValue(null);
    }
  };

  useEffect(() => {
    if (showError) {
      onFieldError && onFieldError();
    }
  }, [showError, onFieldError]);

  return <FileUpload name={name} onChange={onFileChange} handleDelete={handleDelete} value={field.value} label={label} {...props} errorMessage={error} />;
};

export default FileForm;
