import React from 'react';
import { FormField, Heading } from '../../../../../components';
import { Flex } from '../../../../../style';

const VfmsIntegration: React.FC = () => {
  return (
    <div>
      <Heading margin={'10px 0'}>VFMS</Heading>
      <Flex width={'100%'} align={'flex-start'} margin={'10px 0'}>
        {<FormField name={'vfmsIntergrationId'} type={'text'} placeholder={'Integration ID'} label={'Integration ID'} />}
      </Flex>
      <Flex width={'100%'} align={'flex-start'}>
        {<FormField name={'vfmsTokenId'} type={'text'} placeholder={'Token ID'} label={'Token ID'} />}
      </Flex>
      <Flex width={'100%'} align={'flex-start'} margin={'10px 0'}>
        {<FormField name={'vfmsRestaurantIntergrationId'} type={'text'} placeholder={'Restaurant Integration ID'} label={'Restaurant Integration ID'} />}
      </Flex>
      <Flex width={'100%'} align={'flex-start'}>
        {<FormField name={'vfmsRestaurantTokenId'} type={'text'} placeholder={'Restaurant Token ID'} label={'Restaurant Token ID'} />}
      </Flex>
    </div>
  );
};

export default VfmsIntegration;
