import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getCompanyUsers, putReservationShareholder } from 'api';
import { useCall, useFetch, usePermissions, useShowContent, useToast } from 'components/hooks';
import { Select } from 'components';
import { Option } from 'types';
import { Reservation } from 'models';

interface Props {
  reservation: Reservation;
  editable: boolean;
}

const Shareholders: React.FC<Props> = ({ reservation, editable }) => {
  const { success, error } = useToast();
  const { companyPermissions } = usePermissions();
  const { companyId } = useParams<{ companyId: string }>();

  const [currentShareholder, setCurrentShareholder] = useState<number>(reservation.shareholderId);

  const shareholdersData = useFetch(getCompanyUsers(companyId, 'shareholder'));
  const { showContent } = useShowContent(shareholdersData);
  const { payload: shareholders } = shareholdersData;

  const { submit, onCallSuccess, onCallError, isSubmitting } = useCall(putReservationShareholder(companyId, reservation.id));
  onCallError(error('Cannot update shareholder'));
  onCallSuccess(({ shareholderId }) => {
    success('Shareholder has been updated');
    setCurrentShareholder(shareholderId);
  });

  const handleShareholderChange = async (shareholderId: number | null) => await submit({ shareholderId });

  const shareholdersOptions: Option[] = useMemo(() => {
    if (shareholders) {
      const remove = { name: 'Empty', value: null };
      const options = shareholders.map(({ name, surname, id }) => ({ name: `${name} ${surname}`, value: id }));

      return [remove, ...options];
    } else {
      return [];
    }
  }, [shareholders]);

  const canUpdateReservation = companyPermissions.can('update', 'ReservationDetails');
  const disableOperatorSelect = !showContent || isSubmitting || !canUpdateReservation || !editable;

  return (
    <Select<number | null>
      items={shareholdersOptions}
      onChange={handleShareholderChange}
      value={currentShareholder}
      size={'small'}
      disabled={disableOperatorSelect}
      placeholder={'Select'}
      alignPosition={'right'}
      hideRadioButtons={true}
      largeOptions={true}
    />
  );
};

export default Shareholders;
