import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Heading, Table, Loader, Empty, Error } from 'components';
import { useFetch, useShowContent, useIsAuthenticated, usePermissions } from 'components/hooks';
import { getCompanyUsers } from 'api';
import { User } from 'models';
import { columns } from './partners-columns';

import { Card } from 'style';

const Partners: React.FC = () => {
  const history = useHistory();
  const { companyId } = useParams<{ companyId: string }>();
  const { user } = useIsAuthenticated();
  const { companyPermissions } = usePermissions();

  const shareholdersData = useFetch(getCompanyUsers(companyId, 'shareholder'));
  const { showLoader, showContent, showNoContent, showError } = useShowContent(shareholdersData);
  const { payload: shareholders } = shareholdersData;

  const canReadOthersPartners = companyPermissions.can('read', 'SinglePartner');
  const canReadSelfPartner = companyPermissions.can('read', 'SelfPartner');

  const handleRowClick = (partner: User) => () => {
    const canReadSelf = user?.id === partner.id && canReadSelfPartner;

    if (canReadOthersPartners || canReadSelf) {
      history.push(`/company/${companyId}/partner/${partner.id}`);
    }
  };

  return (
    <Card height={'auto'}>
      <Heading>Partners</Heading>
      {showLoader && <Loader />}
      {showNoContent && <Empty>No data</Empty>}
      {showError && <Error>Failed to fetch</Error>}
      {showContent && shareholders && <Table<User> columns={columns} data={shareholders} handleRowClick={handleRowClick} />}
    </Card>
  );
};

export default Partners;
