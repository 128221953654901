import React from 'react';

import { Button } from 'components';

import { StyledPlusIcon } from './add-new-button.styles';

interface Props {
  onClick: () => void;
  children?: string;
  disabled?: boolean;
}

const AddNewButton: React.FC<Props> = ({ onClick, children, disabled }) => {
  return (
    <Button onClick={onClick} disabled={disabled}>
      <StyledPlusIcon />
      {children || 'Add new'}
    </Button>
  );
};

export default AddNewButton;
