import React from 'react';
import { Formik } from 'formik';
import { useParams } from 'react-router-dom';

import { Modal, FormField, Button } from 'components';
import { postSalaryPayslip, PayslipDto } from 'api';
import { useSubmit, useToast } from 'components/hooks';
import { Salary } from 'models';

import { PositionBox, Flex } from 'style';
import { StyledForm } from './add-payslip.styles';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  salary: Salary;
  successCallback: () => void;
}

const AddPayslip: React.FC<Props> = ({ isOpen, handleClose, salary, successCallback }) => {
  const { success, error } = useToast();
  const { companyId, employeeId } = useParams<{ companyId: string; employeeId: string }>();
  const { onSubmit, onSubmitSuccess, onSubmitError } = useSubmit(postSalaryPayslip(companyId, employeeId, salary.id));
  onSubmitSuccess(() => {
    success('File has been added');
    successCallback();
    handleClose();
  });
  onSubmitError(error('Something went wrong'));

  const closeWithReset = (resetForm: () => void) => () => {
    handleClose();
    resetForm();
  };
  const { overtime, publicHolidays, advanceRepayment, repayments, travelAllowance, bonus, baseTzs = 0, loansTzs = 0, baseUsd } = salary;
  const initialValues: PayslipDto = {
    date: new Date(salary.date),
    totalTzs: overtime + publicHolidays + advanceRepayment + repayments + travelAllowance + bonus + baseTzs - loansTzs,
    totalUsd: baseUsd,
    file: null
  };

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} title={'Pay salary'} dropdown={true}>
      <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
        {({ resetForm, values }) => (
          <StyledForm>
            <FormField name={'date'} type={'date'} placeholder={'Date'} label={'Date'} fullWidth disabled withAsterisk />
            <FormField name={'totalTzs'} type={'money'} placeholder={'Amount'} label={'Total (TZS)'} prefix={''} disabled withAsterisk />
            <FormField name={'totalUsd'} type={'money'} placeholder={'Amount'} label={'Total (USD)'} prefix={''} disabled withAsterisk />
            <FormField name={'file'} type={'file'} label={'File'} withAsterisk />
            <PositionBox position={'top-right'}>
              <Flex gap={12}>
                <Button onClick={closeWithReset(resetForm)} buttonType={'secondary'}>
                  Close
                </Button>
                <Button type={'submit'} disabled={!values['file']}>
                  Save
                </Button>
              </Flex>
            </PositionBox>
          </StyledForm>
        )}
      </Formik>
    </Modal>
  );
};

export default AddPayslip;
