import React, { useEffect, useMemo } from 'react';
import NumberFormat, { NumberFormatProps, NumberFormatValues } from 'react-number-format';
import { useField } from 'formik';

import { InputError } from 'components/index';
import { BANK_ACCOUNT } from 'utils/format';

import { Label } from 'style';
import { Wrapper } from 'components/form/FormField/formField.styles';

type FormatProps = Omit<NumberFormatProps, 'type'>;
type FieldType = 'phone' | 'bank' | 'money' | 'percentage' | 'number-format';

export interface NumberProps extends FormatProps {
  type: FieldType;
  name: string;
  width?: string;
  label?: string;
  separator?: string;
  hideErrorMessage?: boolean;
  displayType?: 'text' | 'input';
  onFieldChange?: () => void;
  onFieldError?: () => void;
  required?: boolean;
  withAsterisk?: boolean;
}

const NumberForm: React.FC<NumberProps> = ({
  type,
  name,
  label,
  separator,
  displayType = 'input',
  width,
  hideErrorMessage,
  onFieldError,
  onFieldChange,
  hidden,
  withAsterisk,
  ...props
}) => {
  const [field, meta, helpers] = useField({ name });

  const { error, touched } = meta;
  const { setValue } = helpers;

  const showError = error && touched;

  useEffect(() => {
    if (showError) {
      onFieldError && onFieldError();
    }
  }, [showError]);

  const handleSetValue = (value: any) => {
    setValue(value);
    onFieldChange && onFieldChange();
  };

  const onInputChange = (value: NumberFormatValues) => {
    if (type === 'money' || type === 'percentage' || type === 'number-format') {
      handleSetValue(value.floatValue);
    } else {
      handleSetValue(value.formattedValue.trim());
    }
  };

  const errorMessage = showError ? error : '';

  const classNames = ['number-input', showError ? 'number-input-error' : ''];
  const classes = classNames.join(' ');

  const value = field.value !== undefined ? field.value : undefined;
  const inputAttributes = { ...field, value, onChange: () => {}, onValueChange: onInputChange };

  const format = useMemo(() => {
    if (type === 'bank') {
      return BANK_ACCOUNT;
    } else {
      return undefined;
    }
  }, []);

  const defaultSeparator = type === 'money' ? ' ' : separator || '';
  const prefix = type === 'money' ? '$' : props.prefix;
  const suffix = type === 'percentage' ? '%' : undefined;
  const allowLeadingZeros = type === 'phone' || type === 'bank';
  const decimalSeparator = type === 'money' || type === 'percentage' || type === 'number-format' ? ',' : undefined;

  return (
    <Wrapper width={width}>
      {label && !hidden && (
        <Label htmlFor={field.name}>
          {label}
          {withAsterisk && <span style={{ color: 'red' }}>*</span>}
        </Label>
      )}
      <NumberFormat
        format={format}
        thousandSeparator={defaultSeparator}
        suffix={suffix}
        prefix={prefix}
        displayType={displayType}
        allowLeadingZeros={allowLeadingZeros}
        className={classes}
        decimalScale={2}
        decimalSeparator={decimalSeparator}
        hidden={hidden}
        {...inputAttributes}
        {...props}
      />
      {errorMessage && !hideErrorMessage && <InputError message={errorMessage} />}
    </Wrapper>
  );
};

export default NumberForm;
