import React, { PropsWithChildren } from 'react';
import { TableBodyPropGetter, TableBodyProps, Row } from 'react-table';

import { ObjectType } from 'types';

import { TableRow, TableCell } from '../table.styles';

interface Props<T extends ObjectType> {
  getTableBodyProps: (propGetter?: TableBodyPropGetter<T> | undefined) => TableBodyProps;
  prepareRow: (row: Row<T>) => void;
  page: Row<T>[];
  handleRowClick?: (original: T) => () => void;
  showFooter?: boolean;
}

function Body<T extends ObjectType>({ getTableBodyProps, prepareRow, page, handleRowClick, showFooter }: PropsWithChildren<Props<T>>) {
  return (
    <tbody {...getTableBodyProps()}>
      {page.map((row, index) => {
        prepareRow(row);
        const handleClick = handleRowClick && handleRowClick(row.original);

        return (
          <TableRow {...row.getRowProps()} onClick={handleClick} showFooter={showFooter} key={index}>
            {row.cells.map((cell, index) => {
              const isDropdown = cell.column.id === 'dropdown';
              return (
                <TableCell
                  isDropdown={isDropdown}
                  {...cell.getCellProps({
                    style: {
                      minWidth: cell.column.minWidth,
                      width: cell.column.width,
                      maxWidth: cell.column.maxWidth
                    }
                  })}
                  key={index}
                >
                  {cell.render('Cell')}
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}
    </tbody>
  );
}

export default Body;
