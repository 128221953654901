import React from 'react';

import { Wrapper } from './badge.styles';
import { Flex, Paragraph, BadgeClosedIcon } from 'style';

export type BadgeType = 'success' | 'info' | 'warning' | 'error' | 'empty' | 'closed';

interface Props {
  text: string;
  type?: BadgeType;
  children?: React.ReactNode;
}

const Badge: React.FC<Props> = ({ children, text, type = 'info' }) => {
  return (
    <Wrapper type={type}>
      <Flex gap={10} padding={'4px 0'}>
        {type === 'closed' && <BadgeClosedIcon />}
        {children}
        <Paragraph type={'p3'}>{text}</Paragraph>
      </Flex>
    </Wrapper>
  );
};

export default Badge;
