import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { usePermissions } from 'components/hooks';
import { companyItems } from 'components/navigation/Menu/menu.items';
import { COMPANY_STORAGE } from 'constants/auth.constants';

import { Flex, Paragraph } from 'style';
import { NavLinkFlex, StyledParagraph, StyledSection } from '../../menu.styles';

interface Props {
  showRestaurant: boolean;
}

const CompanyItems: React.FC<Props> = ({ showRestaurant }) => {
  const { pathname } = useLocation();
  const { companyId } = useParams<{ companyId?: string }>();
  const { companyPermissions } = usePermissions();

  const storageCompanyId = localStorage.getItem(COMPANY_STORAGE);

  const company = companyId || storageCompanyId;

  return (
    <>
      {company && (
        <>
          <Flex margin={'16px 0 0 0'}>
            <StyledParagraph type={'p3'}>Main menu</StyledParagraph>
          </Flex>
          <StyledSection>
            {companyItems(company, companyPermissions, showRestaurant)
              .filter((item) => item.condition)
              .map((item) => (
                <NavLinkFlex key={item.name} isActive={item.path === pathname}>
                  <NavLink to={item.path} activeClassName={'test'} exact={item.exact}>
                    {item.icon}
                    <Paragraph>{item.name}</Paragraph>
                  </NavLink>
                </NavLinkFlex>
              ))}
          </StyledSection>
        </>
      )}
    </>
  );
};

export default CompanyItems;
