import React from 'react';

import PageInfo from './components/PageInfo/PageInfo';
import DateSelector from './components/DateSelector/DateSelector';
import { Input } from 'components';

import { HideWrapper, VisibleWrapper } from 'style';
import { StyledHeader, StyledFlex } from './page-header.styles';

interface Props {
  heading: string;
  description?: string;
  inputValue?: string;
  handleInputChange?: (value: string) => void;
  handleDateChange?: (dates: [Date, Date]) => void;
  dateRange?: [Date, Date];
  customButton?: React.ReactNode;
  customSelect?: React.ReactNode;
  padding?: string;
  customFilters?: React.ReactNode | React.ReactNode[];
}

const PageHeader: React.FC<Props> = ({
  customButton,
  dateRange,
  customSelect,
  padding,
  inputValue,
  handleInputChange,
  handleDateChange,
  customFilters,
  ...props
}) => {
  const showGap = Boolean(customButton || handleDateChange || customSelect);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (handleInputChange) {
      handleInputChange(event.target.value);
    }
  };

  return (
    <StyledHeader direction={'column'} padding={padding}>
      <PageInfo {...props} />

      <StyledFlex justify={'flex-start'} align={'flex-start'} showGap={showGap}>
        {handleInputChange && <Input inputType={'search'} value={inputValue} onChange={handleChange} placeholder={'Search'} />}
        {customSelect && <VisibleWrapper visibleFrom={'tablet'}>{customSelect}</VisibleWrapper>}

        {/*desktop date selector has to be inside flex wrapper*/}
        <DateSelector resolution={'desktop'} dateRange={dateRange} handleDateChange={handleDateChange} />
        {customFilters}
        {customButton}
      </StyledFlex>

      {/*mobile date selector has to be below content*/}
      <DateSelector resolution={'mobile'} dateRange={dateRange} handleDateChange={handleDateChange} />
      <HideWrapper fullWidth={true} hideFrom={'tablet'}>
        {customSelect}
      </HideWrapper>
    </StyledHeader>
  );
};

export default PageHeader;
