import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import AccountManagement from '../AccountManagement/AccountManagement';
import { useFetch, useShowContent, useSubmit, useToast } from 'components/hooks';
import { getLocation, putLocation, LocationDto } from 'api';
import { Location } from 'models';
import { UpdateSingleItem } from 'types';

interface Props {
  item: Location;
  isOpen: boolean;
  handleClose: () => void;
  updateLocation: (args: UpdateSingleItem<Location[]>) => void;
}

const EditLocation: React.FC<Props> = ({ item, isOpen, handleClose, updateLocation }) => {
  const { success, error } = useToast();
  const { companyId } = useParams<{ companyId: string }>();

  const locationData = useFetch(getLocation(companyId, item.id));
  const { showLoader, showError, showNoContent } = useShowContent(locationData);
  const { payload: location } = locationData;

  const initialValues: LocationDto | undefined = useMemo(() => {
    if (location) {
      return { name: location.name };
    }
  }, [location]);

  const { onSubmit, onSubmitSuccess, onSubmitError } = useSubmit(putLocation(companyId, item.id));
  onSubmitError(error(`Cannot update location`));
  onSubmitSuccess((location) => {
    updateLocation({ updatedItem: location });
    success(`${location.name} has been updated`);
    handleClose();
  });

  const disableEdit = showError || showNoContent;

  return (
    <AccountManagement
      isOpen={isOpen}
      handleClose={handleClose}
      handleSubmit={onSubmit}
      isLoading={showLoader}
      showError={disableEdit}
      title={'Edit location'}
      initialValues={initialValues}
      buttonText={'Save'}
    />
  );
};

export default EditLocation;
