import styled from 'styled-components';

import { PlusIcon } from 'style';

const StyledPlusIcon = styled(PlusIcon)`
  width: 13px !important;
  height: 13px !important;
`;

export { StyledPlusIcon };
