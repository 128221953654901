import React, { useEffect } from 'react';
import { useField } from 'formik';

import { Select } from 'components';
import { SelectElementProps } from 'components/form/Select/Select';

type SelectLimitedProps = Omit<SelectElementProps, 'onChange'>;

export interface SelectProps extends SelectLimitedProps {
  name: string;
  onFieldChange?: () => void;
  onFieldError?: () => void;
  label?: string;
  hideError?: boolean;
}

const SelectForm: React.FC<SelectProps> = ({ name, onFieldChange, onFieldError, label, hideError, ...props }) => {
  const [field, meta, helpers] = useField({ name });

  const { error, touched } = meta;
  const { setValue } = helpers;

  const showError = !hideError && error && touched;

  const handleSetValue = (value: any) => {
    setValue(value);
    onFieldChange && onFieldChange();
  };

  const onSelectChange = (value: any) => {
    handleSetValue(value);
  };

  useEffect(() => {
    if (showError) {
      onFieldError && onFieldError();
    }
  }, [showError, onFieldError]);

  return <Select onChange={onSelectChange} onBlur={field.onBlur} value={field.value} {...props} label={label} errorMessage={error} hideError={hideError} />;
};

export default SelectForm;
