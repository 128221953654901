import styled, { css } from 'styled-components';

import { colors, font } from './theme';

type ParagraphType = 'p1' | 'p2' | 'p3';

interface Props {
  type?: ParagraphType;
  color?: keyof typeof colors.gray;
  weight?: keyof typeof font.weight;
  marginBottom?: string;
  fontSize?: number;
  textAlign?: string;
}

const Paragraph = styled.p<Props>`
  margin-bottom: ${({ marginBottom }) => marginBottom || 0};
  text-align: ${({ textAlign }) => textAlign || 'left'};

  ${({ type = 'p2', color, weight = 'book', fontSize }) => {
    switch (type) {
      case 'p1':
        return css`
          font-size: ${fontSize ? `${fontSize}px` : font.size.paragraph[type]};
          color: ${color ? colors.gray[color] : colors.gray.scale1};
          font-weight: ${weight && font.weight[weight]};
        `;
      case 'p2':
        return css`
          font-size: ${fontSize ? `${fontSize}px` : font.size.paragraph[type]};
          color: ${color ? colors.gray[color] : colors.gray.scale2};
          font-weight: ${weight && font.weight[weight]};
        `;
      case 'p3':
        return css`
          font-size: ${fontSize ? `${fontSize}px` : font.size.paragraph[type]};
          color: ${color ? colors.gray[color] : colors.gray.scale3};
          font-weight: ${weight && font.weight[weight]};
        `;
    }
  }}
`;

const ErrorParagraph = styled.p`
  display: inline-flex;
  column-gap: 5px;
  font-size: 1.3rem;
  margin-top: 8px;
  color: ${colors.red};
`;

const StatusParagraph = styled(Paragraph)<{ active: boolean }>`
  color: ${({ active }) => (active ? colors.green : colors.red)};
  font-weight: ${font.weight.medium};
`;

const MoneyParagraph = styled(Paragraph)<{ negative: boolean }>`
  color: ${({ negative }) => (negative ? colors.red : colors.blue.base)};
  font-weight: ${font.weight.medium};
  font-size: 16px;
`;

export { Paragraph, ErrorParagraph, StatusParagraph, MoneyParagraph };
