import React, { useContext, useEffect, useState } from 'react';
import { FormikHelpers } from 'formik';
import { useParams } from 'react-router-dom';

import CashFlowForm from './cash-flow-form/cash-flow-form';
import PettyCashForm from './petty-cash-form/petty-cash-form';
import { CashFlowDto, LoanLender, LoanType, RepayLoanObject } from './cash-flow-form/types';
import { CashFlowContext } from './context/cash-flow.context';
import { AddSupplier } from 'pages/Company/views/Supplier/modals';
import { useFetch, usePermissions } from 'components/hooks';
import { getSuppliers } from 'api';
import { Supplier } from 'models';

import { SimpleWrapper } from 'style';
import { AddSingleItem } from 'types';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (values: CashFlowDto, helpers: FormikHelpers<CashFlowDto>) => void;
  initialValues?: CashFlowDto;
  isEdit?: boolean;
  transactionId?: number;
  progress?: number;

  type?: LoanType;
  lender?: LoanLender | RepayLoanObject;
  loanLenderId?: number;
}

const TransactionContent: React.FC<Props> = ({ ...props }) => {
  const { companyPermissions } = usePermissions();
  const { companyId } = useParams<{ companyId: string }>();
  const { currentForm, setReinitialize, disseminatePettyCashValues } = useContext(CashFlowContext);
  const [isAddSupplierModalOpen, setAddSupplierModalOpen] = useState(false);

  const setAddSupplierModalOpenProvider = (isOpen: boolean) => () => setAddSupplierModalOpen(isOpen);

  useEffect(() => {
    setReinitialize(Boolean(props.isEdit));
  }, [props.isEdit]);

  const suppliersData = useFetch(getSuppliers(companyId));
  const suppliers = suppliersData.payload || [];
  const { addSingleItem } = suppliersData;

  const onAddSupplier = async (res: AddSingleItem<Supplier>) => {
    const supplierId = res.payload.id;
    await addSingleItem(res);
    disseminatePettyCashValues((prevState) => (prevState ? { ...prevState, supplierId } : { supplierId }));
  };

  const showAddSupplier = companyPermissions.can('create', 'Supplier');

  return (
    <>
      <SimpleWrapper>
        {currentForm === 'cash-flow' && <CashFlowForm onOpenAddSupplierModal={setAddSupplierModalOpenProvider(true)} suppliers={suppliers} {...props} />}
        {currentForm === 'petty-cash' && <PettyCashForm onOpenAddSupplierModal={setAddSupplierModalOpenProvider(true)} suppliers={suppliers} />}
      </SimpleWrapper>
      {showAddSupplier && <AddSupplier isOpen={isAddSupplierModalOpen} handleClose={setAddSupplierModalOpenProvider(false)} addSupplier={onAddSupplier} />}
    </>
  );
};

export default TransactionContent;
