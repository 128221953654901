import styled, { css } from 'styled-components';

import { Justify, Align, Direction, FlexMediaQueries } from 'types';

import { theme } from 'style/theme';
import { HTMLProps } from 'react';

interface FlexProps extends HTMLProps<HTMLElement> {
  direction?: Direction;
  gap?: number;
  justify?: Justify;
  align?: Align;
  grow?: number;
  margin?: string;
  padding?: string;
  borderColor?: string; //bottom only
  width?: string;
  noBorder?: boolean;
  queries?: FlexMediaQueries[];
}

const Flex = styled.div<FlexProps>`
  display: flex;
  width: ${({ width }) => width || 'auto'};
  flex-direction: ${({ direction }) => direction || 'row'};
  gap: ${({ gap }) => (gap !== undefined ? gap : 12)}px;
  justify-content: ${({ justify }) => justify || 'flex-start'};
  align-items: ${({ align }) => align || 'center'};
  margin: ${({ margin }) => margin};
  border-bottom: ${({ borderColor, noBorder }) => (noBorder ? 'none' : borderColor && `1px solid ${borderColor}`)};
  padding: ${({ padding }) => padding};
  flex-wrap: ${({ wrap }) => wrap};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'inherit')};

  ${({ grow }) =>
    grow &&
    css`
      flex-grow: ${grow};
    `}

  ${({ queries }) =>
    queries?.map(
      ({ mq, align, justify, direction, gap }) =>
        css`
          ${theme.mq[mq]} {
            flex-direction: ${direction || 'inherit'};
            align-items: ${align || 'inherit'};
            justify-content: ${justify || 'inherit'};
            gap: ${gap !== undefined ? gap : 12}px;
          }
        `
    )};

  &:last-child {
    border-bottom: none;
  }
`;

export { Flex };
