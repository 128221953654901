import React from 'react';
import { Column, Row } from 'react-table';
import { AnyMongoAbility } from '@casl/ability';

import { Formatter, SelectActiveFilter } from 'components';
import { tableDropdown } from 'utils/table';
import { Option } from 'types';
import { Supplier } from 'models';

import { StatusParagraph } from 'style';

export interface DropdownValue {
  type: 'delete' | 'edit';
  item: Supplier;
}

interface DropdownArguments {
  handleDropdownSelect: (value: DropdownValue) => void;
  ability: AnyMongoAbility;
}

const prepareDropdownItems = (item: Supplier, ability: AnyMongoAbility): Option<DropdownValue>[] => {
  const editItem = ability.can('update', 'Supplier') ? [{ name: 'Edit', value: { type: 'edit', item } }] : [];
  const deleteItem = ability.can('delete', 'Supplier') ? [{ name: 'Remove', value: { type: 'delete', item }, type: 'delete' }] : [];

  return [...editItem, ...deleteItem] as Option<DropdownValue>[];
};

export const prepareColumns = ({ handleDropdownSelect, ability }: DropdownArguments): Column<Supplier>[] => {
  return [
    { Header: 'Name', accessor: 'name', disableFilters: true, width: 200 },
    { Header: 'Address', accessor: 'address', disableFilters: true },
    {
      Header: 'Phone',
      accessor: 'phone',
      disableFilters: true,
      Cell: ({ value }: { value: string }) => (
        <Formatter value={value} type={'phone'}>
          {value}
        </Formatter>
      )
    },
    { Header: 'ZRB no.', accessor: 'zrbId', disableFilters: true },
    { Header: 'TIN no.', accessor: 'tinId', disableFilters: true },
    {
      Header: 'Account no.',
      width: 250,
      accessor: 'bankAccount',
      disableFilters: true,
      Cell: ({ value }: { value: string }) => (
        <Formatter value={value} type={'bank'}>
          {value}
        </Formatter>
      )
    },
    {
      accessor: 'isActive',
      disableSortBy: true,
      filter: 'equals',
      disableGlobalFilter: true,
      Filter: SelectActiveFilter,
      Cell: ({ value }: { value: boolean }) => <StatusParagraph active={value}>{value ? 'Active' : 'Inactive'}</StatusParagraph>
    },
    {
      Header: '',
      id: 'dropdown',
      width: 100,
      disableFilters: true,
      Cell: ({ row }: { row: Row<Supplier> }) => {
        const options = prepareDropdownItems(row.original, ability);

        return tableDropdown<DropdownValue>({ options, handleDropdownSelect });
      }
    }
  ];
};
