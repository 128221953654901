import React from 'react';
import { useFormikContext } from 'formik';
import { object, SchemaOf, number } from 'yup';

import DieselGenerators, { DieselGeneratorValues, dieselGeneratorSchema } from '../builders/diesel-generators';
import Electricity, { ElectricityValues, electricitySchema } from '../builders/electricity';
import FlightsHotel, { FlightsHotelValue, flightsHotelSchema } from '../builders/flights-hotel';
import ManagementWelfare, { ManagementWelfareValues, managementWelfareSchema } from '../builders/management-welfare';
import Petrol, { PetrolValues, petrolSchema } from '../builders/petrol';
import SalariesManagement, { SalariesManagementValues, salaryManagementSchema } from '../builders/salaries-management';
import SalariesStaff, { SalariesStaffValues, salariesStaffSchema } from '../builders/salaries-staff';
import StaffWelfare, { StaffWelfareValues, staffWelfareSchema } from '../builders/staff-welfare';
import SwimmingPool, { SwimmingPoolValues, swimmingPoolSchema } from '../builders/swimming-pool';
import Internet, { InternetValues, internetSchema } from '../builders/internet';
import LoanBuilder, { LoanValues, loanSchema } from '../builders/loan/loan-builder';
import Refund, { RefundValues, refundSchema } from '../builders/refund';
import IncomeGuests, { IncomeGuestsValues, incomeGuestsSchema } from '../builders/income-guests';
import PartnerBooking, { PartnerBookingValues, partnerBookingSchema } from '../builders/partner-booking';
import Exchange from '../builders/exchange';
import { CashFlowDto, AccountValues, LoanType, LoanLender, RepayLoanObject, ValidationAccountType } from '../types';

export type AccountData =
  | DieselGeneratorValues
  | ElectricityValues
  | FlightsHotelValue
  | ManagementWelfareValues
  | PetrolValues
  | SalariesManagementValues
  | SalariesStaffValues
  | StaffWelfareValues
  | SwimmingPoolValues
  | InternetValues
  | LoanValues
  | RefundValues
  | IncomeGuestsValues
  | PartnerBookingValues;

type AccountTypeRender = ValidationAccountType | 'exchange';

export type AccountDataValues = {
  type?: LoanType;
  lender?: LoanLender | RepayLoanObject;
  loanData: LoanValues;
  ratesNumber?: number;
  endDate?: Date;
  provision?: number;
  companyLoanId?: number;
  userId?: number;
  carId?: number;
  salaryId?: number;
  employeeLoanId: number;
  shareholderLoanId?: number;
  supplierId?: number;
  reservationId?: number;
  generatorId?: number;
  locationId?: number;
  employeeId?: number;
  companyId?: number;
};

type BuilderObject = Record<AccountTypeRender, JSX.Element>;
type ValidationObject = Record<ValidationAccountType, SchemaOf<AccountData>>;

const validationKeys: AccountValues[] = [
  'diesel-generators',
  'electricity',
  // 'flights-hotel',
  // 'management-welfare',
  'petrol',
  'salaries-management',
  'salaries-staff',
  // 'staff-welfare',
  'swimming-pool',
  // 'telephone-internet',
  'loan',
  'refund',
  'income-guests',
  'partner-booking'
];

export const renderKeys: AccountValues[] = [...validationKeys, 'exchange'];

export const Validation: ValidationObject = {
  'diesel-generators': dieselGeneratorSchema,
  electricity: electricitySchema,
  'flights-hotel': flightsHotelSchema,
  'management-welfare': managementWelfareSchema,
  petrol: petrolSchema,
  'salaries-management': salaryManagementSchema,
  'salaries-staff': salariesStaffSchema,
  'staff-welfare': staffWelfareSchema,
  'swimming-pool': swimmingPoolSchema,
  'telephone-internet': internetSchema,
  loan: loanSchema,
  refund: refundSchema,
  'income-guests': incomeGuestsSchema,
  'partner-booking': partnerBookingSchema
};

export const renderValidationSchema = (account: AccountValues | undefined) => {
  if (account && validationKeys.includes(account)) {
    return Validation[account as ValidationAccountType];
  } else {
    return object({
      salaryId: number()
    }).nullable();
  }
};

const AccountBuilder: React.FC = () => {
  const {
    values: { account }
  } = useFormikContext<CashFlowDto>();

  const FormBuilder: BuilderObject = {
    'diesel-generators': <DieselGenerators />,
    electricity: <Electricity />,
    'flights-hotel': <FlightsHotel />,
    'management-welfare': <ManagementWelfare />,
    petrol: <Petrol />,
    'salaries-management': <SalariesManagement />,
    'salaries-staff': <SalariesStaff />,
    'staff-welfare': <StaffWelfare />,
    'swimming-pool': <SwimmingPool />,
    'telephone-internet': <Internet />,
    loan: <LoanBuilder />,
    exchange: <Exchange />,
    'income-guests': <IncomeGuests />,
    refund: <Refund />,
    'partner-booking': <PartnerBooking />
  };

  if (account && renderKeys.includes(account)) {
    return FormBuilder[account as AccountTypeRender];
  }

  return <></>;
};

export default AccountBuilder;
