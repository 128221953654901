import { BankValue, CurrencyValue, Option } from 'types';

export const currencySelectItems: Option<CurrencyValue>[] = [
  { name: 'TZS', value: 'tzs' },
  { name: 'USD', value: 'usd' },
  { name: 'EUR', value: 'eur' },
  { name: 'PLN', value: 'pln' }
];

export const restrictedCurrencyItems: Option<CurrencyValue>[] = [
  { name: 'TZS', value: 'tzs' },
  { name: 'USD', value: 'usd' },
  { name: 'EUR', value: 'eur' }
];

export const bankSelectItems: Option<BankValue>[] = [
  { name: 'Zanzibar', value: 'znz' },
  { name: 'Poland', value: 'pl' },
  { name: 'Cash', value: 'cash' }
];

export const onlyZanzibarBankItems: Option<BankValue>[] = [{ name: 'Zanzibar', value: 'znz' }];

export const prepareMultiline = (text: string) => text.replaceAll('\n', '<br />');
