import React from 'react';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';

import ConfirmForm from './components/ConfirmForm/ConfirmForm';
import { useSubmit, useToast } from 'components/hooks';
import { View } from 'components';
import { confirmMail, ConfirmDto, LoginResponseDto } from 'api';
import { confirmSchema } from './confirm.validation';
import { REFRESH_TOKEN_STORAGE, TOKEN_STORAGE } from 'constants/auth.constants';

import { MainHeading, Wrapper } from 'pages/Login/login.styles';
import { Card, PositionBox } from 'style';

const initialValues: ConfirmDto = {
  token: '',
  password: '',
  confirmPassword: ''
};

const ConfirmMail: React.FC = () => {
  const history = useHistory();
  const { success, error } = useToast();

  const handleSuccess = ({ refreshToken, token }: LoginResponseDto) => {
    localStorage.setItem(TOKEN_STORAGE, token);
    localStorage.setItem(REFRESH_TOKEN_STORAGE, refreshToken);
    history.push('/companies');
    success('Password has been changed');
  };

  const { onSubmit, onSubmitSuccess, onSubmitError } = useSubmit(confirmMail);
  onSubmitSuccess(handleSuccess);
  onSubmitError(error('Token is not valid'));

  return (
    <View showMenu={false} showHeader={false} noVerticalPadding={true}>
      <PositionBox position={'center'} height={'100vh'}>
        <Wrapper>
          <MainHeading>Enter your password</MainHeading>
          <Card padding={'24px 62px'}>
            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={confirmSchema}>
              <ConfirmForm />
            </Formik>
          </Card>
        </Wrapper>
      </PositionBox>
    </View>
  );
};

export default ConfirmMail;
