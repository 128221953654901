import { useContext, useEffect, useState } from 'react';

import { User } from 'models';
import { AuthenticateContext } from 'context/authenticate.context';
import { REFRESH_TOKEN_STORAGE, TOKEN_STORAGE } from 'constants/auth.constants';

type AuthenticatedReturn = {
  isAuthenticated: boolean;
  user: User | null;
};

const useIsAuthenticated = (): AuthenticatedReturn => {
  const authenticateData = useContext(AuthenticateContext);
  const [isAuthenticated, setAuthenticated] = useState<boolean>(false);

  if (!authenticateData) {
    throw new Error('Cannot use custom hook without authenticate context');
  }

  const { user } = authenticateData;

  const token = localStorage.getItem(TOKEN_STORAGE);
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_STORAGE);

  useEffect(() => {
    const authenticatedCondition = Boolean(token && refreshToken && user);
    setAuthenticated(authenticatedCondition);
  }, [user, token, refreshToken]);

  return { isAuthenticated, user };
};

export default useIsAuthenticated;
