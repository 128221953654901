import React, { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { number, object, SchemaOf } from 'yup';

import NewLoanEmployeeForm from './new-loan-employee-form';
import { FormField } from 'components/index';
import { useFetch, useShowContent } from 'components/hooks';
import { getEmployees } from 'api';
import { Option } from 'types';
import { message } from 'utils';
import { CashFlowContext } from 'form/transaction/context/cash-flow.context';

import { Flex } from 'style';

export const employeeLoanSchema: SchemaOf<EmployeeLoanValues> = object({
  employeeId: number().required(message.required).nullable(),
  ratesNumber: number().positive(message.positive).required(message.required)
});

export type EmployeeLoanValues = {
  employeeId: number;
  ratesNumber: number;
};

const NewLoanEmployee: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const { defaultLoanData } = useContext(CashFlowContext);

  const employeesData = useFetch(getEmployees(companyId));
  const { showLoader, showError } = useShowContent(employeesData);
  const { payload: employees } = employeesData;

  const employeesOptions: Option[] = useMemo(() => {
    if (employees) return employees.map(({ id, name }) => ({ name, value: id }));
    else return [];
  }, [employees]);

  const disableSelect = showLoader || showError || !!defaultLoanData?.loanLenderId;

  return (
    <Flex direction={'column'} margin={'0 0 12px 0'} justify={'flex-start'} align={'flex-start'} gap={16}>
      {!disableSelect && (
        <FormField
          name={'accountData.loanData.employeeId'}
          type={'select'}
          items={employeesOptions}
          label={'Select employee'}
          placeholder={'Select employee'}
          width={'100%'}
          disabled={disableSelect}
        />
      )}
      <NewLoanEmployeeForm />
    </Flex>
  );
};

export default NewLoanEmployee;
