import styled from 'styled-components';

import { colors, shadows } from 'style';

const Wrapper = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 9px;
  background: ${colors.background.secondary};
  box-shadow: ${shadows.default};
  border-radius: 40px;
  font-size: 20px;
  line-height: 28px;
  color: ${colors.gray.scale2};
`;

export { Wrapper };
