import fetchMiddleware from 'api/api.middleware';
import { IdParam, UserRole } from 'types';
import { Company, User } from 'models';
import { CompanyDto, DeleteResponseDto, CompanyCloudbedsDto, FinancialDetailsDto, UserSharesDto, CompanyUserRole } from './api.companies.dto';
import { mapToFormData } from 'utils';

export const getCompany = (companyId: IdParam) => () => fetchMiddleware<Company>({ method: 'get', url: `/companies/${companyId}` });

export const getFinancialDetails = (companyId: IdParam) => () =>
  fetchMiddleware<FinancialDetailsDto>({ method: 'get', url: `/companies/${companyId}/financial-details` });

export const getCompanies = () => fetchMiddleware<Company[]>({ method: 'get', url: `/companies` });

export const getCompanyLogo = (companyId: IdParam) => () =>
  fetchMiddleware<Company[]>({ method: 'get', url: `/companies/${companyId}/logo`, responseType: 'blob' });

export const getCompanyRole = (companyId: IdParam) => () => fetchMiddleware<CompanyUserRole>({ method: 'get', url: `/companies/${companyId}/users/me` });

export const getCompanyUsers = (companyId: IdParam, role: UserRole) => () =>
  fetchMiddleware<User[]>({ method: 'get', url: `/companies/${companyId}/users`, query: { role } });

export const getCompanyUser = (companyId: IdParam, userId: IdParam) => () =>
  fetchMiddleware<User>({ method: 'get', url: `/companies/${companyId}/users/${userId}` });

export const getUserShares = (companyId: IdParam, shareholderId: IdParam) => () =>
  fetchMiddleware<UserSharesDto[]>({ method: 'get', url: `/companies/${companyId}/users/${shareholderId}/shares` });

export const postCompany = (data: CompanyDto) => fetchMiddleware<Company>({ method: 'post', url: `/companies`, data });

export const postCompanyLogo = (companyId: IdParam, logo: File) => {
  const data = mapToFormData({ file: logo });

  return fetchMiddleware<Company>({ method: 'post', url: `/companies/${companyId}/logo`, data, headers: { 'Content-Type': 'multipart/form-data' } });
};

export const putCompany = (companyId: IdParam) => (data: CompanyDto) => fetchMiddleware<Company>({ method: 'put', url: `/companies/${companyId}`, data });

export const putCompanyCloudbeds = (companyId: IdParam) => (data: CompanyCloudbedsDto) =>
  fetchMiddleware({ method: 'put', url: `/companies/${companyId}/cloudbeds`, data });

export const deleteCompany = (companyId: IdParam) => fetchMiddleware<DeleteResponseDto>({ method: 'delete', url: `/companies/${companyId}`, data: {} });
