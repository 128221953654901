import { RouteModel } from 'types';
import { AnyMongoAbility } from '@casl/ability';

import Employees from './views/Employees/Employees';
import SingleEmployee from './views/SingleEmployee/SingleEmployee';

export const employeeRoutes = (ability: AnyMongoAbility): RouteModel[] => {
  const showEmployee = ability.can('read', 'Employee');
  return [
    {
      name: 'Employees',
      path: '/',
      exact: true,
      component: Employees,
      protected: true
    },
    {
      name: 'Employee',
      path: '/:employeeId',
      exact: true,
      component: SingleEmployee,
      protected: true,
      disabled: !showEmployee
    }
  ];
};
