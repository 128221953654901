import React, { useState } from 'react';

import SupplierData from './SupplierData/SupplierData';
import SupplierInvoices from './SupplierInvoices/SupplierInvoices';
import { Button, PageHeader } from 'components';
import { usePermissions } from 'components/hooks';

import { StyledGrid } from './single-supplier.styles';

const SingleSupplier: React.FC = () => {
  const { companyPermissions } = usePermissions();
  const [supplierName, setSupplierName] = useState('');
  const [isEditModalOpen, setEditModalOpen] = useState(false);

  const handleSetSupplierName = (name: string) => setSupplierName(name);
  const setEditModalOpenProvider = (isOpen: boolean) => () => setEditModalOpen(isOpen);

  const canReadSupplier = companyPermissions.can('read', 'Supplier');
  const canEditSupplier = companyPermissions.can('update', 'Supplier');
  const canReadSupplierInvoices = companyPermissions.can('read', 'SupplierInvoices');

  const customButton = canEditSupplier ? (
    <Button buttonType={'secondary'} onClick={setEditModalOpenProvider(true)}>
      Edit supplier
    </Button>
  ) : null;

  return (
    <>
      <PageHeader heading={`Supplier: ${supplierName}`} customButton={customButton} />
      {canReadSupplier && (
        <StyledGrid>
          <SupplierData setSupplierName={handleSetSupplierName} handleCloseModal={setEditModalOpenProvider(false)} isEditModalOpen={isEditModalOpen} />
          {canReadSupplierInvoices && <SupplierInvoices />}
        </StyledGrid>
      )}
    </>
  );
};

export default SingleSupplier;
