import React from 'react';
import { useFormikContext } from 'formik';
import { mixed, object, SchemaOf, string } from 'yup';

import LoanForm from './loan-form/loan-form';
import NewLoanCompany, { companyLoanSchema, CompanyLoanValues } from './new-loan-company/new-loan-company';
import NewLoanEmployee, { employeeLoanSchema, EmployeeLoanValues } from './new-loan-employee/new-loan-employee';
import NewLoanShareholder, { shareholderLoanSchema, ShareholderLoanValues } from './new-loan-shareholder/new-loan-shareholder';
import PayLoan, { payLoanSchema, PayLoanValues } from './pay-loan/pay-loan';
import { CashFlowDto, LoanType, LoanLender, RepayLoanObject } from '../../types';
import { message } from 'utils';

export type LoanDataValues = CompanyLoanValues | EmployeeLoanValues | ShareholderLoanValues | PayLoanValues;

export const loanSchema: SchemaOf<LoanValues> = object({
  type: mixed().oneOf<LoanType>(['new', 'pay']).required(message.required).default('new'),
  lender: mixed().when('type', {
    is: (type: LoanType) => type === 'new',
    then: mixed().oneOf(['company', 'employee', 'shareholder']),
    otherwise: mixed().oneOf(['company', 'employee']).nullable()
  }),
  loanData: object<any>().when(['type', 'lender'], (type: LoanType, lender: any) => {
    if (type === 'new' && lender === 'company') return companyLoanSchema;
    if (type === 'new' && lender === 'employee') return employeeLoanSchema;
    if (type === 'new' && lender === 'shareholder') return shareholderLoanSchema;
    if (type === 'pay') return payLoanSchema;
    return object();
  }),
  currency: string().default('tzs')
});

export type LoanValues = {
  type: LoanType;
  lender: LoanLender | RepayLoanObject;
  loanData: LoanDataValues;
};

const LoanBuilder: React.FC = () => {
  const {
    values: { accountData }
  } = useFormikContext<CashFlowDto>();

  const showNewLoan = accountData?.type === 'new';
  const showPayLoan = accountData?.type === 'pay';

  const showCompanyNewLoan = showNewLoan && accountData.lender === 'company';
  const showEmployeeNewLoan = showNewLoan && accountData.lender === 'employee';
  const showShareholderNewLoan = showNewLoan && accountData.lender === 'shareholder';

  return (
    <div>
      <LoanForm />
      {showCompanyNewLoan && <NewLoanCompany />}
      {showEmployeeNewLoan && <NewLoanEmployee />}
      {showShareholderNewLoan && <NewLoanShareholder />}
      {showPayLoan && <PayLoan />}
    </div>
  );
};

export default LoanBuilder;
