import React from 'react';

import { Heading, Button } from 'components';

import { CloseIcon } from 'style';
import { HeaderFlex } from './header.styles';

interface Props {
  handleClose?: () => void;
  title?: string;
  showCloseButton: boolean;
}

const Header: React.FC<Props> = ({ handleClose, title, showCloseButton }) => {
  const justify = title ? 'space-between' : 'flex-end';

  return (
    <HeaderFlex justify={justify} align={'center'}>
      {title && <Heading type={'h4'}>{title}</Heading>}
      {showCloseButton && (
        <Button buttonType={'wrapper'} onClick={handleClose}>
          <CloseIcon />
        </Button>
      )}
    </HeaderFlex>
  );
};

export default Header;
