import styled from 'styled-components';

import { Grid, theme } from 'style';

const StyledGrid = styled(Grid)`
  margin-bottom: 16px;

  ${theme.mq.standard} {
    grid-template-columns: 1fr 2fr;
    margin-bottom: 24px;
  }
`;

const MarginGrid = styled(Grid)`
  margin-bottom: 16px;

  ${theme.mq.standard} {
    margin-bottom: 24px;
  }
`;

const SalariesWrapper = styled(Grid)`
  margin-bottom: 60px;
`;

export { StyledGrid, MarginGrid, SalariesWrapper };
