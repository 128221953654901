import React from 'react';
import { Formik, Form } from 'formik';

import { FormField, Heading, Button } from 'components';

import { SimpleWrapper, Flex } from 'style';

interface UserProfileDto {
  name: string;
  email: string;
}

const ProfileForm: React.FC = () => {
  const initialValues: UserProfileDto = {
    name: '',
    email: ''
  };

  return (
    <SimpleWrapper>
      <Formik initialValues={initialValues} onSubmit={() => {}}>
        <Form>
          <Flex direction={'column'} gap={8} justify={'flex-start'} align={'flex-start'} margin={'0 0 16px 0'}>
            <Heading type={'h4'}>Profile</Heading>
            <FormField name={'name'} type={'text'} placeholder={'Name'} />
            <FormField name={'email'} type={'email'} placeholder={'Email'} />
            <Flex justify={'flex-end'} width={'100%'}>
              <Button type={'submit'}>Save</Button>
            </Flex>
          </Flex>
        </Form>
      </Formik>
    </SimpleWrapper>
  );
};

export default ProfileForm;
