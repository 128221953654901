import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { logout } from 'api';
import { useCall } from 'components/hooks';
import { Avatar, Button, Select } from 'components';
import { useWindowDimensions } from 'components/hooks';
import { AuthenticateContext } from 'context/authenticate.context';
import { REFRESH_TOKEN_STORAGE, TOKEN_STORAGE } from 'constants/auth.constants';

import { StyledHeader } from './header.styles';
import { Flex, HamburgerIcon, DownIcon, breakpoints } from 'style';

interface Props {
  handleMenuOpen: () => void;
  showMenu?: boolean;
}

const dropdownOptions = [{ name: 'Logout', value: 'logout' }];

const Header = React.forwardRef<HTMLElement, Props>(({ handleMenuOpen, showMenu }, ref) => {
  const history = useHistory();
  const { width } = useWindowDimensions();
  const { setUser } = useContext(AuthenticateContext);

  const handleLogoutSuccess = () => {
    localStorage.removeItem(TOKEN_STORAGE);
    localStorage.removeItem(REFRESH_TOKEN_STORAGE);

    history.push('/login');
    setUser(null);
  };

  const { submit, onCallSuccess, onCallError } = useCall(logout);
  onCallSuccess(handleLogoutSuccess);

  const refreshToken = localStorage.getItem(REFRESH_TOKEN_STORAGE);

  const handleLogout = () => refreshToken && submit({ refreshToken });

  const allowMenuToggle = showMenu && width < breakpoints.hdReady;
  const justify = allowMenuToggle ? 'space-between' : 'flex-end';

  const handleSelectChange = (value: string) => {
    if (value === 'logout') {
      handleLogout();
    }
  };

  return (
    <StyledHeader ref={ref}>
      <Flex justify={justify}>
        {allowMenuToggle && (
          <Button buttonType={'wrapper'} onClick={handleMenuOpen}>
            <HamburgerIcon />
          </Button>
        )}

        <Flex gap={12}>
          <Avatar />
          <Select items={dropdownOptions} onChange={handleSelectChange} size={'small'} customButton={<DownIcon />} alignPosition={'right'} />
        </Flex>
      </Flex>
    </StyledHeader>
  );
});

Header.displayName = 'Header';

export default Header;
