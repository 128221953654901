import React from 'react';
import { useParams } from 'react-router-dom';

import EmployeeManagement from '../EmployeeManagement/EmployeeManagement';
import { useSubmit, useToast } from 'components/hooks';
import { postEmployee } from 'api';
import { Employee } from 'models';
import { AddSingleItem } from 'types';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  addEmployee: (args: AddSingleItem<Employee>) => void;
}

const AddEmployee: React.FC<Props> = ({ isOpen, handleClose, addEmployee }) => {
  const { success, error } = useToast();
  const { companyId } = useParams<{ companyId: string }>();

  const { onSubmit, onSubmitSuccess, onSubmitError, mapData } = useSubmit(postEmployee(companyId));
  onSubmitError(error('Employee cannot be added'));
  onSubmitSuccess((employee) => {
    addEmployee({ payload: employee });
    success(`${employee.name} has been added`);
    handleClose();
  });
  mapData((data) => ({
    ...data,
    contractStart: new Date(data.contractStart).toISOString(),
    contractEnd: new Date(data.contractEnd).toISOString()
  }));

  return <EmployeeManagement isOpen={isOpen} handleClose={handleClose} handleSubmit={onSubmit} buttonText={'Add'} title={'New employee'} />;
};

export default AddEmployee;
