import React, { InputHTMLAttributes } from 'react';
import { FieldInputProps } from 'formik';

import { InputAlertIcon, ShowPasswordIcon, ErrorParagraph, Label } from 'style';
import { InputWrapper, StyledInput, Search, Calendar, TrailingComponentWrapper } from './input.styles';

export type InputSizes = 'small' | 'medium' | 'large' | 'popup';
export type InputType = 'password' | 'search' | 'date' | 'date-full-width';
export type SearchProps = { fullWidth?: boolean; hideBorder?: boolean };

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  field?: FieldInputProps<any>;
  inputType?: InputType;
  inputSize?: InputSizes;
  trailingComponent?: JSX.Element;
  togglePasswordInputType?: () => void;
  searchProps?: SearchProps;
  width?: string;
  readonly labelText?: string;
  readonly isError?: boolean;
  readonly errorMessage?: string;
  readonly placeholder?: string;
}

export const Input: React.FC<InputProps> = ({
  field,
  labelText,
  inputType,
  errorMessage,
  togglePasswordInputType,
  inputSize,
  trailingComponent,
  maxLength = 50,
  searchProps,
  width,
  ...props
}) => {
  const showSearchIcon = inputType === 'search';
  const showDateIcon = inputType === 'date' || inputType === 'date-full-width';
  const showPasswordIcon = inputType === 'password';

  return (
    <InputWrapper inputType={inputType} inputSize={inputSize} searchFullWidth={searchProps?.fullWidth}>
      {showSearchIcon && <Search />}
      {showDateIcon && inputType && <Calendar type={inputType} />}
      {showPasswordIcon && <ShowPasswordIcon onClick={togglePasswordInputType} />}
      {labelText && <Label htmlFor={field?.name}>{labelText}</Label>}
      {trailingComponent && <TrailingComponentWrapper>{trailingComponent}</TrailingComponentWrapper>}
      <StyledInput
        width={width}
        maxLength={maxLength}
        searchHideBorder={searchProps?.hideBorder}
        {...props}
        {...field}
        inputType={inputType}
        hasError={!!errorMessage}
      />
      {errorMessage && (
        <ErrorParagraph>
          <InputAlertIcon />
          {errorMessage}
        </ErrorParagraph>
      )}
    </InputWrapper>
  );
};

export default Input;
