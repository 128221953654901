import styled, { css } from 'styled-components';

import { GridMediaQueries } from 'types';

import { theme } from './theme';

interface GridProps {
  columns?: string;

  rows?: string;
  autoRows?: string;

  gap?: number;
  columnGap?: number;
  rowGap?: number;

  margin?: string;
  padding?: string;

  align?: 'start' | 'center' | 'end' | 'stretch';
  justify?: 'start' | 'end' | 'center' | 'stretch' | 'space-around' | 'space-between' | 'space-evenly';

  queries?: GridMediaQueries[];
}

const Grid = styled.div<GridProps>`
  width: 100%;
  display: grid;

  grid-template-columns: ${({ columns }) => columns || '100%'};
  grid-template-rows: ${({ rows }) => rows || 'auto'};

  align-items: ${({ align }) => align || 'start'};
  justify-content: ${({ justify }) => justify || 'stretch'};

  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};

  gap: ${({ gap }) => gap || 16}px;

  ${({ columnGap }) =>
    columnGap &&
    css`
      grid-column-gap: ${columnGap}px;
    `}
  ${({ rowGap }) =>
    rowGap &&
    css`
      grid-row-gap: ${rowGap}px;
    `}

  ${({ queries }) =>
    queries?.map(
      ({ mq, columns, rows, gap }) =>
        css`
          ${theme.mq[mq]} {
            gap: ${`${gap}px` || 'inherit'};
            grid-template-columns: ${columns || 'inherit'};
            grid-template-rows: ${rows || 'inherit'};
          }
        `
    )};

  ${({ autoRows }) =>
    autoRows &&
    css`
      grid-auto-rows: ${autoRows};
      grid-template-rows: unset;

      ${theme.mq.standard} {
        grid-template-rows: unset;
        grid-auto-rows: ${autoRows};
      }
    `}

  ${theme.mq.standard} {
    gap: 24px;
  }
`;

/** Reusable CSS Grid cell component */

interface GridCellProps {
  rowSpan?: number;
  rowSpanMd?: number;

  colSpan?: number;
  colSpanMd?: number;

  photoCover?: boolean;
}

const GridCell = styled.div<GridCellProps>`
  grid-column: span ${({ colSpan }) => colSpan || 1};
  grid-row: span ${({ rowSpan }) => rowSpan || 1};

  ${theme.mq.standard} {
    grid-column: span ${({ colSpanMd }) => colSpanMd || 1};
    grid-row: span ${({ rowSpanMd }) => rowSpanMd || 1};
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }

  ${({ photoCover }) =>
    photoCover &&
    css`
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    `}
`;

const DesktopTripleGrid = styled(Grid)`
  grid-template-rows: auto;
  ${theme.mq.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${theme.mq.standard} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export { Grid, GridCell, DesktopTripleGrid };
