import React from 'react';

import { FormField } from 'components';
import { currencySelectItems, bankSelectItems } from 'utils';

import { Flex, Grid } from 'style';

const NewLoanCompanyForm: React.FC = () => {
  return (
    <Flex direction={'column'} width={'100%'} margin={'12px 0 0 0'}>
      <FormField name={'date'} type={'date'} fullWidth={true} width={'100%'} placeholder={'Start date'} label={'Start date'} />
      <FormField name={'accountData.loanData.endDate'} type={'date'} fullWidth={true} width={'100%'} placeholder={'End date'} label={'End date'} />
      <FormField name={'accountData.loanData.provision'} type={'number-format'} prefix={''} suffix={'%'} placeholder={'Provision'} label={'Provision'} />
      <Grid columns={'3fr 1fr'} gap={1} rowGap={1} columnGap={1}>
        <FormField name={'amount'} type={'money'} prefix={''} placeholder={'Amount'} label={'Amount'} />
        <FormField name={'currency'} type={'select'} items={currencySelectItems} label={'Currency'} />
      </Grid>
      <FormField name={'accountData.loanData.bankFrom'} type={'select'} items={bankSelectItems} placeholder={'Bank from'} label={'Bank from'} width={'100%'} />
      <FormField name={'bank'} type={'select'} items={bankSelectItems} placeholder={'Bank to'} label={'Bank to'} width={'100%'} />
      <FormField name={'comment'} type={'textarea'} placeholder={'Comment'} label={'Comment'} />
    </Flex>
  );
};

export default NewLoanCompanyForm;
