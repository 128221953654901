import { object, string, SchemaOf } from 'yup';

import { SupplierDto } from 'api';
import { message } from 'utils';

export const supplierSchema: SchemaOf<SupplierDto> = object().shape({
  name: string().required(message.required),
  address: string(),
  phone: string(),
  tinId: string(),
  vatId: string(),
  bankAccount: string(),
  description: string()
});
