import React, { useMemo } from 'react';
import { number, object, SchemaOf } from 'yup';
import { useParams } from 'react-router-dom';

import { FormField } from 'components/index';
import { useFetch, useShowContent } from 'components/hooks';
import { getLocations } from 'api';
import { message } from 'utils';

import { Flex } from 'style';

export const swimmingPoolSchema: SchemaOf<SwimmingPoolValues> = object({
  locationId: number().required(message.required)
});

export interface SwimmingPoolValues {
  locationId: number;
}

const SwimmingPool: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();

  const locationsData = useFetch(getLocations(companyId, 'swimming-pool'));
  const { showLoader, showNoContent } = useShowContent(locationsData);
  const { payload: locations } = locationsData;

  const options = useMemo(() => {
    if (locations) {
      return locations.map(({ name, id }) => ({ name, value: id }));
    }
  }, [locations]);

  const placeholder = showNoContent ? 'No locations to select' : 'Select a location';
  const disabled = showLoader || showNoContent;

  return (
    <Flex direction={'column'} margin={'8px 0'} gap={8}>
      <FormField name={'accountData.locationId'} type={'select'} items={options || []} width={'100%'} placeholder={placeholder} disabled={disabled} />
    </Flex>
  );
};

export default SwimmingPool;
