import { useEffect, useState } from 'react';

const RESET_STATE_TIME = 500;

interface DeleteReturnData<T> {
  isDeletePopupOpen: boolean;
  itemToDelete: T | null;
  closeDeletePopup: () => void;
  setItemToDelete: (item: T) => void;
}

function useDelete<T>(): DeleteReturnData<T> {
  const [isDeletePopupOpen, setDeletePopupOpen] = useState<boolean>(false);
  const [deleteItem, setDeleteItem] = useState<T | null>(null);

  const setItemToDelete = (item: T) => setDeleteItem(item);

  const closeDeletePopup = () => {
    setDeletePopupOpen(false);

    setTimeout(() => {
      setDeleteItem(null);
    }, RESET_STATE_TIME);
  };

  useEffect(() => {
    if (deleteItem !== null) {
      setDeletePopupOpen(true);
    }
  }, [deleteItem]);

  return {
    itemToDelete: deleteItem,
    isDeletePopupOpen,
    closeDeletePopup,
    setItemToDelete
  };
}

export default useDelete;
