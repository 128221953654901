import styled from 'styled-components';

import { Grid, theme } from 'style';

const Wrapper = styled(Grid)`
  ${theme.mq.standard} {
    gap: 4px;
  }
`;

export { Wrapper };
