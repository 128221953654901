import React, { PropsWithChildren } from 'react';

import { Option } from 'types';
import { SelectSize } from '../../Select';

import { SelectedItemIcon, StyledSelectItem, StyledSelectOptions } from '../../select.styles';

interface Props<T> {
  getMenuProps: any;
  getItemProps: any;
  isOpen: boolean;
  items: Option<T>[];
  highlightedIndex: number;
  handleOptionClick: (event: React.MouseEvent<HTMLUListElement>) => void;
  isItemSelected: (item: Option) => boolean;
  alignPosition: 'left' | 'center' | 'right';
  hideRadioButtons?: boolean;
  hideSelectedIcon?: boolean;
  size?: SelectSize;
  largeOptions?: boolean;
  tableSelect?: boolean;
}

function Options<T = any>({
  getMenuProps,
  getItemProps,
  isOpen,
  items,
  isItemSelected,
  handleOptionClick,
  highlightedIndex,
  alignPosition,
  largeOptions,
  tableSelect,
  ...props
}: PropsWithChildren<Props<T>>) {
  return (
    <StyledSelectOptions
      {...getMenuProps({})}
      largeOptions={largeOptions}
      isOpen={isOpen}
      alignPosition={alignPosition}
      tableSelect={tableSelect}
      onClick={handleOptionClick}
    >
      {isOpen &&
        items.map((item, index) => {
          const key = typeof item.value !== 'object' ? item.value : item.name + index;

          return (
            <StyledSelectItem
              key={key}
              {...getItemProps({ item, index })}
              isSelected={isItemSelected(item) && !props.hideSelectedIcon}
              highlighted={highlightedIndex === index}
              disabled={item.blocked}
              type={item.type}
              {...props}
            >
              {isItemSelected(item) && !props.hideSelectedIcon && <SelectedItemIcon />}
              {item.name}
            </StyledSelectItem>
          );
        })}
    </StyledSelectOptions>
  );
}

export default Options;
