import React, { useEffect } from 'react';
import { useField } from 'formik';

import { Input } from 'components';
import { InputType } from 'components/form/Input/Input';

type InputWithoutWidth = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'width'>;

export interface InputProps extends InputWithoutWidth {
  name: string;
  type: React.HTMLProps<HTMLInputElement>['type'];
  label?: string;
  onFieldChange?: () => void;
  onFieldError?: () => void;
  inputType?: InputType;
  trailingComponent?: JSX.Element;
}

const InputForm: React.FC<InputProps> = ({ name, type, label, onFieldChange, onFieldError, inputType, ...rest }) => {
  const [field, meta, helpers] = useField({ name });

  const { error, touched } = meta;
  const { setValue } = helpers;

  const showError = error && touched;

  const handleSetValue = (value: any) => {
    type === 'number' ? setValue(+value) : setValue(value);
    onFieldChange && onFieldChange();
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => handleSetValue(event.target.value);

  useEffect(() => {
    if (showError) {
      onFieldError && onFieldError();
    }
  }, [showError]);

  return (
    <Input
      {...rest}
      type={type}
      name={name}
      field={{ ...field, onChange: onInputChange }}
      labelText={label}
      errorMessage={showError ? error : ''}
      inputType={inputType}
    />
  );
};

export default InputForm;
