import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import States from './States/States';
import { AddNewButton, Heading, Table, DeleteModal } from 'components';
import { useFetch, useShowContent, useDelete, useCall, useToast, usePermissions } from 'components/hooks';
import { getLoans, deleteLoan, DeleteLoanResponseDto } from 'api';
import { AddLoan } from 'pages/Company/views/Employee/modals';
import { prepareColumns } from './loans.columns';
import { Loan } from 'models';

import { Card, Flex } from 'style';

interface Props {
  refreshDateFromSalary: Date;
}

const Loans: React.FC<Props> = ({ refreshDateFromSalary }) => {
  const { success, error } = useToast();
  const { companyPermissions } = usePermissions();
  const { companyId, employeeId } = useParams<{ companyId: string; employeeId: string }>();
  const { itemToDelete, isDeletePopupOpen, closeDeletePopup } = useDelete<Loan>();

  const [isAddLoanOpen, setAddLoanOpen] = useState<boolean>(false);
  const [loansRefreshDate, setLoansRefreshDate] = useState<Date>(new Date());

  const loansData = useFetch(getLoans(companyId, employeeId), { dependencies: [loansRefreshDate, refreshDateFromSalary] });
  const { showLoader, showContent, showNoContent, showError } = useShowContent(loansData);
  const { payload: loans, deleteSingleItem } = loansData;

  const handleDeleteSuccess = ({ id }: DeleteLoanResponseDto) => {
    deleteSingleItem({ itemId: id });
    success('Loan has been deleted');
    closeDeletePopup();
  };

  const { submit, onCallSuccess, onCallError } = useCall(deleteLoan);
  onCallSuccess(handleDeleteSuccess);
  onCallError(error('Loan cannot be deleted'));

  const handleLoansRefresh = () => setLoansRefreshDate(new Date());
  const handleAddLoanOpen = (isOpen: boolean) => () => setAddLoanOpen(isOpen);

  const handleLoanDelete = async () => {
    if (itemToDelete) {
      await submit(companyId, employeeId, itemToDelete.id);
    }
  };

  const canAddLoan = companyPermissions.can('create', 'EmployeeLoan');
  const columns = prepareColumns();

  return (
    <>
      <Card>
        <Flex justify={'space-between'} margin={'0 0 16px 0'}>
          <Heading margin={'0'}>Loans</Heading>
          {canAddLoan && (
            <AddNewButton onClick={handleAddLoanOpen(true)} disabled={showLoader}>
              Add loan
            </AddNewButton>
          )}
        </Flex>
        <States showLoader={showLoader} showError={showError} showNoContent={showNoContent} />
        {showContent && loans && <Table<Loan> columns={columns} data={loans} />}
      </Card>
      <AddLoan isOpen={isAddLoanOpen} handleClose={handleAddLoanOpen(false)} loansRefresh={handleLoansRefresh} />
      <DeleteModal isOpen={isDeletePopupOpen} handleDelete={handleLoanDelete} handleClose={closeDeletePopup} />
    </>
  );
};

export default Loans;
