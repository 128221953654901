import React, { useMemo } from 'react';
import { SchemaOf, object, number } from 'yup';
import { useParams } from 'react-router-dom';

import { FormField } from 'components';
import { useFetch, useShowContent } from 'components/hooks';
import { getGenerators } from 'api';
import { message } from 'utils';

import { Flex } from 'style';

export const dieselGeneratorSchema: SchemaOf<DieselGeneratorValues> = object({
  generatorId: number().required(message.required)
});

export type DieselGeneratorValues = {
  generatorId: number;
};

const DieselGenerators: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();

  const generatorsData = useFetch(getGenerators(companyId));
  const { showLoader, showNoContent } = useShowContent(generatorsData);
  const { payload: generators } = generatorsData;

  const options = useMemo(() => {
    if (generators) {
      return generators.map(({ name, id }) => ({ name, value: id }));
    }
  }, [generators]);

  const placeholder = showNoContent ? 'No generators to select' : 'Select a generator';
  const disabled = showLoader || showNoContent;

  return (
    <Flex direction={'column'} margin={'8px 0'} gap={8}>
      <FormField name={'accountData.generatorId'} type={'select'} items={options || []} width={'100%'} placeholder={placeholder} disabled={disabled} />
    </Flex>
  );
};

export default DieselGenerators;
