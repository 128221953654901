import React from 'react';
import Dropzone, { DropzoneProps } from 'react-dropzone';

import { Button } from 'components';

import { Wrapper, DropzoneArea, DownloadButton, FileName } from './file-upload.styles';
import { UploadIcon, Flex, ErrorParagraph, InputAlertIcon, Paragraph, Label, CloseIcon } from 'style';

export type DropzoneType = 'default' | 'simple';

export interface FileUploadProps extends DropzoneProps {
  name: string;
  onChange: (file: File[] | string[]) => void;
  handleDelete: (file: File | string) => void;
  value?: File[] | File | string | string[] | null;
  formats?: string[];
  label?: string;
  errorMessage?: string;
  dropzoneType?: DropzoneType;
  handleFileDownload?: () => void;
  fullWidth?: boolean;
  withAsterisk?: boolean;
  className?: string;
}

const FileUpload: React.FC<FileUploadProps> = ({
  formats = ['.pdf'],
  onChange,
  handleDelete,
  value,
  name,
  label,
  errorMessage,
  dropzoneType = 'default',
  handleFileDownload,
  fullWidth = true,
  withAsterisk,
  ...props
}) => {
  const onFileDelete = (fileToDelete: File | string) => (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    handleDelete(fileToDelete);
  };

  const convertToArray = (value: File | string | null | undefined) => (value ? [value] : []);
  const valueFiles = Array.isArray(value) ? value : convertToArray(value);

  const handleFileClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    handleFileDownload && handleFileDownload();
  };

  return (
    <Dropzone accept={formats} onDropAccepted={onChange} maxFiles={1} {...props}>
      {({ getRootProps, getInputProps }) => {
        return (
          <Wrapper fullWidth={fullWidth}>
            {label && (
              <Label>
                {label}
                {withAsterisk && <span style={{ color: 'red' }}>*</span>}
              </Label>
            )}
            <DropzoneArea {...getRootProps()} dropzoneType={dropzoneType} fullWidth={fullWidth}>
              <input {...getInputProps()} name={name} />

              {valueFiles.map((file) => (
                <div key={typeof file === 'string' ? file : file!.name + file!.size}>
                  <Flex justify={'space-between'} width={'100%'}>
                    <Button buttonType={'wrapper'}>
                      <FileName type={'p3'} color={'scale2'}>
                        {typeof file === 'string' ? file : file.name}
                      </FileName>
                    </Button>
                    <Button buttonType={'wrapper'} onClick={onFileDelete(file)}>
                      <CloseIcon />
                    </Button>
                  </Flex>
                  {handleFileDownload && (
                    <DownloadButton type={'button'} onClick={handleFileClick}>
                      Download
                    </DownloadButton>
                  )}
                </div>
              ))}

              {!valueFiles.length && (
                <Button buttonType={'wrapper'}>
                  <Flex gap={8} margin={'0'}>
                    <UploadIcon />
                    <Paragraph type={'p3'}>Choose file</Paragraph>
                  </Flex>
                </Button>
              )}
            </DropzoneArea>
            {errorMessage && (
              <ErrorParagraph>
                <InputAlertIcon />
                {errorMessage}
              </ErrorParagraph>
            )}
          </Wrapper>
        );
      }}
    </Dropzone>
  );
};

export default FileUpload;
