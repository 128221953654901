import React from 'react';
import { Column } from 'react-table';

import { displayAmount } from 'utils';
import { Formatter } from 'components';
import { EmployeeTax } from 'models';

export const prepareColumns = (): Column<EmployeeTax>[] => {
  return [
    { Header: 'Date', accessor: 'date', Cell: ({ value }: { value: string }) => new Date(value).toLocaleDateString() },
    { Header: 'ZSFF', accessor: 'zsff', Cell: ({ value }: { value: number }) => <Formatter {...displayAmount(value, 'tzs')} /> },
    { Header: 'PAYE', accessor: 'paye', Cell: ({ value }: { value: number }) => <Formatter {...displayAmount(value, 'tzs')} /> },
    { Header: 'SDL', accessor: 'sdl', Cell: ({ value }: { value: number }) => <Formatter {...displayAmount(value, 'tzs')} /> },
    {
      Header: 'Sum',
      accessor: 'sum',
      Cell: ({ value }: { value: number }) => <Formatter weight={'bold'} {...displayAmount(value, 'tzs')} />
    }
  ];
};
