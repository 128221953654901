import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import States from './States/States';
import { AddNewButton, Heading, Table, DeleteModal } from 'components';
import { AddSalary, AddPayslip } from 'pages/Company/views/Employee/modals';
import { getSalaries, deleteSalary, generatePayslip, DeleteSalaryResponseDto } from 'api';
import { useFetch, useShowContent, useCall, usePermissions, useDelete, useToast } from 'components/hooks';
import { prepareColumns, DropdownValue } from './salaries.columns';
import { downloadFile } from 'utils';
import { Salary } from 'models';

import { Card, Flex } from 'style';

interface Props {
  refreshLoans: () => void;
}

const Salaries: React.FC<Props> = ({ refreshLoans }) => {
  const { success, error } = useToast();
  const { companyPermissions } = usePermissions();
  const { companyId, employeeId } = useParams<{ companyId: string; employeeId: string }>();
  const { itemToDelete, setItemToDelete, isDeletePopupOpen, closeDeletePopup } = useDelete<Salary>();

  const [isAddSalaryOpen, setAddSalaryOpen] = useState<boolean>(false);
  const [salaryToPay, setPayslipSalary] = useState<Salary | null>(null);

  const salariesData = useFetch(getSalaries(companyId, employeeId));
  const { showLoader, showContent, showNoContent, showError } = useShowContent(salariesData);
  const { payload: salaries, refresh, deleteSingleItem } = salariesData;

  const handleDeleteSuccess = ({ id }: DeleteSalaryResponseDto) => {
    deleteSingleItem({ itemId: id });
    success('Salary has been deleted');
    closeDeletePopup();
  };

  const { submit, onCallSuccess, onCallError } = useCall(deleteSalary);
  onCallError(error('Salary cannot be deleted'));
  onCallSuccess(handleDeleteSuccess);

  const { submit: payslipSubmit, onCallSuccess: onGenerateSuccess, onCallError: onGenerateError } = useCall(generatePayslip);
  onGenerateError(error('Failed to generate payslip'));
  onGenerateSuccess((payslip, headers) => {
    success('Payslip has been downloaded');
    const fileName = headers['x-file-name'];
    downloadFile(payslip, fileName);
  });

  const handleAddPayslipClose = () => setPayslipSalary(null);
  const handleAddSalaryOpen = (isOpen: boolean) => () => setAddSalaryOpen(isOpen);
  const handleDropdownSelect = async ({ type, item }: DropdownValue) => {
    if (type === 'delete') {
      setItemToDelete(item);
    }
    if (type === 'payslip') {
      await payslipSubmit(companyId, item.employeeId, item.id);
    }
    if (type === 'pay-salary') {
      setPayslipSalary(item);
    }
  };

  const addSalarySuccessCallback = async () => {
    await refresh();
    refreshLoans();
  };

  const handleSalaryDelete = async () => {
    if (itemToDelete) {
      await submit(companyId, employeeId, itemToDelete.id);
    }
  };

  const columns = prepareColumns({ handleDropdownSelect, ability: companyPermissions });

  const showAddSalary = companyPermissions.can('create', 'EmployeeSalary');
  const showDeleteSalary = companyPermissions.can('delete', 'EmployeeSalary');
  const showAddPayslip = companyPermissions.can('add', 'Payslip');

  return (
    <>
      <Card>
        <Flex justify={'space-between'} margin={'0 0 16px 0'}>
          <Heading margin={'0'}>Salaries</Heading>
          {showAddSalary && <AddNewButton onClick={handleAddSalaryOpen(true)}>Add salary</AddNewButton>}
        </Flex>
        <States showLoader={showLoader} showNoContent={showNoContent} showError={showError} />
        {showContent && salaries && <Table<Salary> columns={columns} data={salaries} />}
      </Card>

      {showAddSalary && <AddSalary isOpen={isAddSalaryOpen} handleClose={handleAddSalaryOpen(false)} successCallback={addSalarySuccessCallback} />}
      {showDeleteSalary && <DeleteModal isOpen={isDeletePopupOpen} handleClose={closeDeletePopup} handleDelete={handleSalaryDelete} />}
      {showAddPayslip && salaryToPay && <AddPayslip isOpen={true} handleClose={handleAddPayslipClose} salary={salaryToPay} successCallback={refresh} />}
    </>
  );
};

export default Salaries;
