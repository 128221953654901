import React, { useMemo } from 'react';
import { Column, Row } from 'react-table';
import { AnyMongoAbility } from '@casl/ability';

import { Formatter } from 'components';
import { tableDropdown } from 'utils/table';
import { displayAmount } from 'utils';
import { Option } from 'types';
import { Reservation } from 'models';

import { Paragraph } from 'style';

export interface DropdownValue {
  type: 'open' | 'invoice' | 'invoice-pl' | 'invoice-znz' | 'invoice-cash';
  id: number;
}

interface ColumnArguments {
  handleDropdownSelect: (value: DropdownValue) => void;
  ability: AnyMongoAbility;
}

const prepareDropdownItems = (itemId: number, isClosed: boolean, ability: AnyMongoAbility): Option<DropdownValue>[] => {
  const canReadReservation = ability.can('read', 'ReservationDetails');
  const canGeneratePlInvoice = ability.can('generate', 'InvoicePl') && isClosed;
  const canGenerateZnzInvoice = ability.can('generate', 'InvoiceZnz') && isClosed;
  const canGenerateCashInvoice = ability.can('generate', 'InvoiceCash') && isClosed;

  const invoicePLNItem = canGeneratePlInvoice ? [{ name: 'Generate invoice - PL', value: { type: 'invoice-pl', id: itemId } }] : [];
  const invoiceZNZItem = canGenerateZnzInvoice ? [{ name: 'Generate invoice - ZNZ', value: { type: 'invoice-znz', id: itemId } }] : [];
  const invoiceCashItem = canGenerateCashInvoice ? [{ name: 'Generate invoice - Cash', value: { type: 'invoice-cash', id: itemId } }] : [];
  const openItem = canReadReservation ? [{ name: 'Open', value: { type: 'open', id: itemId } }] : [];

  return [...openItem, ...invoicePLNItem, ...invoiceCashItem, ...invoiceZNZItem] as Option<DropdownValue>[];
};

const TOTAL_VALUE = 78473;

const handleTotalAmount = (rows: Row<Reservation>[]): number => {
  return rows.reduce((summary, row) => row.values.amount + summary, 0);
};

const handleTotalPercentage = (rows: Row<Reservation>[]) => {
  const getProvision = (amount: number, provision: number) => (TOTAL_VALUE * provision) / 100;
  return rows.reduce((summary, row) => getProvision(row.original.amount, row.original.provision) + summary, 0);
};

export const prepareColumns = ({ handleDropdownSelect, ability }: ColumnArguments): Column<Reservation>[] => {
  return [
    {
      Header: 'Check in',
      accessor: 'startDate',
      width: 100,
      Cell: ({ value }: { value: string }) => new Date(value).toLocaleDateString(),
      Footer: () => (
        <Paragraph weight={'medium'} color={'scale1'}>
          Summary
        </Paragraph>
      )
    },
    { Header: 'Check out', accessor: 'endDate', Cell: ({ value }: { value: string }) => new Date(value).toLocaleDateString() },
    { Header: 'Name', accessor: 'guestName' },
    {
      Header: 'Amount',
      accessor: 'amount',
      Cell: ({ value }: { value: number }) => <Formatter {...displayAmount(value, 'usd')} />,
      Footer: ({ rows }) => {
        const total = useMemo(() => {
          return handleTotalAmount(rows);
        }, [rows]);

        return <Formatter weight={'medium'} color={'scale1'} {...displayAmount(total?.toFixed(2), 'usd')} />;
      }
    },
    {
      Header: 'TO Provision',
      accessor: 'provision',
      Cell: ({ row }: any) => {
        const amount = row.original.amount;
        const provision = row.original.provision;
        const calculatedValue = provision !== null && (amount * provision) / 100;

        const result = calculatedValue ? `${provision}% ($${calculatedValue.toFixed(2)})` : '-';

        return <Paragraph>{result}</Paragraph>;
      },
      Footer: ({ rows }) => {
        const total = useMemo(() => {
          return handleTotalPercentage(rows);
        }, [rows]);

        return <Formatter value={total?.toFixed(2)} weight={'medium'} color={'scale1'} />;
      }
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ value }: { value: string }) => <Paragraph style={{ color: value === 'canceled' ? 'red' : 'grey' }}>{value}</Paragraph>
    },
    {
      Header: '',
      id: 'dropdown',
      width: 100,
      Cell: ({ row }: { row: Row<Reservation> }) => {
        const options = prepareDropdownItems(row.original.id, row.original.isClosed, ability);

        return tableDropdown<DropdownValue>({ options, handleDropdownSelect });
      }
    }
  ];
};
