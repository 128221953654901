import React, { useEffect } from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { useField } from 'formik';

import { Label } from 'style';
import { Wrapper } from '../formField.styles';

type FormatProps = Omit<NumberFormatProps, 'type'>;

export interface ZipCodeProps extends FormatProps {
  name: string;
  label?: string;
  onFieldChange?: () => void;
  onFieldError?: () => void;
}

const zipCodeFormat = '## ####';

const ZipCodeForm: React.FC<ZipCodeProps> = ({ name, label, onFieldError, ...props }) => {
  const [field, meta, helpers] = useField({ name });

  const { error, touched } = meta;
  const { setValue } = helpers;

  const showError = error && touched;

  useEffect(() => {
    if (showError) {
      onFieldError && onFieldError();
    }
  }, [showError]);

  return (
    <Wrapper>
      {label && <Label htmlFor={field.name}>{label}</Label>}
      <NumberFormat
        onValueChange={({ formattedValue }) => setValue(formattedValue.trim())}
        name={name}
        value={field.value}
        format={zipCodeFormat}
        className={`phone-input`}
        {...props}
      />
    </Wrapper>
  );
};

export default ZipCodeForm;
