import styled, { css } from 'styled-components';

import { Placement, MediaPosition } from 'types';

import { shadows, theme, colors } from 'style/theme';

interface CardProps {
  padding?: string;
  width?: string;
  height?: 'auto';
  dropdown?: boolean;
  margin?: string;
}

const Card = styled.section<CardProps>`
  width: 100%;
  height: ${({ height }) => height || '100%'};
  box-shadow: ${shadows.card};
  background-color: #fff;
  border-radius: 8px;
  position: relative;
  overflow: ${({ dropdown }) => (dropdown ? 'visible' : 'scroll')};
  padding: ${({ padding }) => padding || '16px'};
  margin: ${({ margin }) => margin || 0};

  ${theme.mq.tablet} {
    width: ${({ width }) => width || '100%'};
  }

  ${theme.mq.standard} {
    padding: 24px 24px 12px 24px;
  }
`;

const placement = (placement: Placement) => {
  switch (placement) {
    case 'top-left':
      return css`
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
      `;
    case 'top-center':
      return css`
        display: flex;
        align-items: flex-start;
        justify-content: center;
      `;
    case 'top-right':
      return css`
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
      `;
    case 'center-left':
      return css`
        display: flex;
        align-items: center;
        justify-content: flex-start;
      `;
    case 'center':
      return css`
        display: grid !important;
        place-items: center;
      `;
    case 'center-right':
      return css`
        display: flex;
        align-items: center;
        justify-content: flex-end;
      `;
    case 'bottom-left':
      return css`
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
      `;
    case 'bottom-center':
      return css`
        display: flex;
        align-items: flex-end;
        justify-content: center;
      `;
    case 'bottom-right':
      return css`
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
      `;
  }
};

interface PositionBoxProps {
  position?: Placement;
  queries?: MediaPosition[];
  width?: string;
  height?: string;
  margin?: string;
}

const PositionBox = styled.div<PositionBoxProps>`
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || 'auto'};
  margin: ${({ margin }) => margin};

  ${({ position = 'top-left' }) => placement(position)}

  ${({ queries }) =>
    queries?.map(
      ({ mq, position }) =>
        css`
          ${theme.mq[mq]} {
            ${placement(position)}
          }
        `
    )};
`;

interface VisibleWrapperProps {
  visibleFrom: keyof typeof theme.mq;
}

const VisibleWrapper = styled.div<VisibleWrapperProps>`
  display: none;

  ${({ visibleFrom }) => css`
    ${theme.mq[visibleFrom]} {
      display: block;
    }
  `}
`;

interface HideWrapperProps {
  hideFrom: keyof typeof theme.mq;
  fullWidth?: boolean;
}

const HideWrapper = styled.div<HideWrapperProps>`
  display: block;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};

  ${({ hideFrom }) => css`
    ${theme.mq[hideFrom]} {
      display: none;
    }
  `};
`;

const SimpleWrapper = styled.section`
  width: 100%;
`;

const ToggleVisibleWrapper = styled.div<{ isVisible: boolean }>`
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: visibility 0.3s ease, opacity 0.3s ease;
`;

const BottomWrapper = styled.div`
  margin: 2rem 0 1rem 0;

  ${theme.mq.standard} {
    margin: 1rem 0;
  }
`;

const Label = styled.label`
  margin-bottom: 8px;
  cursor: default;
  font-size: 14px;
  color: ${colors.gray.scale2};
  font-weight: 400;
  display: inline-block;
`;

export { Card, PositionBox, VisibleWrapper, HideWrapper, SimpleWrapper, ToggleVisibleWrapper, BottomWrapper, Label, placement };
