import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { handleStatus } from 'api/api.middleware';

import { REFRESH_TOKEN_STORAGE, TOKEN_STORAGE } from 'constants/auth.constants';
import { appEnvironment } from 'config';

interface AxiosConfig extends AxiosRequestConfig {
  isRetry?: boolean;
}

const { apiUrl } = appEnvironment;
const refreshPath = '/auth/refresh-token';
const refreshTokenPath = apiUrl + refreshPath;

export const handleRefreshToken = (error: AxiosError) => {
  const originalRequest: AxiosConfig = error.config;
  const statusCode = handleStatus(error);

  if (statusCode !== 401) return Promise.reject(error);

  const isRefreshRequest = originalRequest.url?.includes(refreshPath);
  const isAuthError = statusCode === 401;

  if (isAuthError && isRefreshRequest) {
    redirectAndReset();

    return Promise.reject(error);
  }

  if (isAuthError && !originalRequest?.isRetry) {
    originalRequest.isRetry = true;

    return axios
      .post(refreshTokenPath, {
        refreshToken: localStorage.getItem(REFRESH_TOKEN_STORAGE)
      })
      .then((response) => {
        const token = response?.data?.token;
        const refreshToken = response?.data?.refreshToken;

        localStorage.setItem(TOKEN_STORAGE, token);
        localStorage.setItem(REFRESH_TOKEN_STORAGE, refreshToken);

        originalRequest.headers['Authorization'] = 'Bearer ' + token;
        return axios(originalRequest);
      })
      .catch((error) => {
        redirectAndReset();

        return Promise.reject(error);
      });
  }

  return Promise.reject(error);
};

const redirectAndReset = () => {
  localStorage.removeItem(TOKEN_STORAGE);
  localStorage.removeItem(REFRESH_TOKEN_STORAGE);

  window.history.pushState({}, '', '/login');
  window.location.assign('/login');
  window.location.reload();
};
