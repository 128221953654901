import {
  Main,
  Tax,
  CashFlow,
  BankCheck,
  Partner,
  Employee,
  Reservations,
  Restaurant,
  Supplier,
  Settings,
  Notifications,
  Logs,
  Invoices
} from 'pages/Company/views';

import { RouteModel } from 'types';
import { AnyMongoAbility } from '@casl/ability';

export const companyRoutes = (ability: AnyMongoAbility): RouteModel[] => [
  {
    name: 'Company',
    description: 'Overview',
    path: '/',
    exact: true,
    component: Main,
    protected: true
  },
  {
    name: 'Partner',
    description: 'Overview',
    path: '/partner/:partnerId',
    exact: true,
    component: Partner,
    protected: true
  },
  {
    name: 'Bank Check',
    path: '/bank-check',
    exact: true,
    component: BankCheck,
    protected: true
  },
  {
    name: 'Cash flow',
    path: '/cash-flow',
    exact: true,
    component: CashFlow,
    protected: true,
    disabled: !ability.can('read', 'CashFlow')
  },
  {
    name: 'Cash flow',
    path: '/reservations',
    exact: false,
    component: Reservations,
    protected: true,
    disabled: !ability.can('read', 'Reservations')
  },
  {
    name: 'Restaurant',
    path: '/restaurant',
    exact: true,
    component: Restaurant,
    protected: true,
    disabled: !ability.can('read', 'Restaurant')
  },
  {
    name: 'Tax',
    path: '/tax',
    exact: true,
    component: Tax,
    protected: true,
    disabled: !ability.can('read', 'Tax')
  },
  {
    name: 'Invoices',
    path: '/invoices',
    exact: true,
    component: Invoices,
    protected: true,
    disabled: !ability.can('read', 'CashFlow')
  },
  {
    name: 'Employee',
    path: '/employee',
    exact: false,
    component: Employee,
    protected: true
  },
  {
    name: 'Supplier',
    path: '/supplier',
    exact: false,
    component: Supplier,
    protected: true
  },
  {
    name: 'Settings',
    path: '/settings',
    exact: false,
    component: Settings,
    protected: true,
    disabled: !ability.can('read', 'Settings')
  },
  {
    name: 'Notifications',
    path: '/notifications',
    exact: false,
    component: Notifications,
    protected: true,
    disabled: !ability.can('use', 'Notifications')
  },
  {
    name: 'Logs',
    path: '/logs',
    exact: false,
    component: Logs,
    protected: true,
    disabled: !ability.can('read', 'Logs')
  }
];
