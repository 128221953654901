import React, { PropsWithChildren } from 'react';
import { HeaderGroup } from 'react-table';

import { ObjectType } from 'types';

import { DownIcon, Flex } from 'style';
import { StyledSpan, TableColumnHeading, UpIcon } from './header.styles';

interface Props<T extends ObjectType> {
  headerGroups: HeaderGroup<T>[];
  showHeader: boolean;
  alignCellsLeft?: boolean;
  showColumnsFilter?: boolean;
}

function Header<T extends ObjectType>({
  headerGroups,
  showHeader,
  alignCellsLeft,
  showColumnsFilter
}: PropsWithChildren<Props<T>>) {
  return (
    <thead>
      {showHeader &&
        headerGroups.map((headerGroup, index) => (
          <tr {...headerGroup.getHeaderGroupProps()} key={index}>
            {headerGroup.headers.map((column, index) => {
              return (
                <TableColumnHeading
                  {...column.getHeaderProps(column.getSortByToggleProps)}
                  key={index}
                  alignCellsLeft={alignCellsLeft}
                >
                  <Flex>
                    {column.render('Header')}
                    <StyledSpan>{column.isSorted ? column.isSortedDesc ? <DownIcon /> : <UpIcon /> : ''}</StyledSpan>
                  </Flex>
                  <div>{column.canFilter && showColumnsFilter ? column.render('Filter') : null}</div>
                </TableColumnHeading>
              );
            })}
          </tr>
        ))}
    </thead>
  );
}

export default Header;
