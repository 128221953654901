import React from 'react';
import { FieldInputProps } from 'formik';

import { StyledCheckbox, Wrapper, Label } from './checkbox.styles';

type Props = Omit<React.HTMLAttributes<HTMLInputElement>, 'type'>;

export interface CheckboxProps extends Props {
  field?: FieldInputProps<any>;
  label?: string;
  checked?: boolean;
  error?: string;
}

export const Checkbox: React.FC<CheckboxProps> = ({ field, label, checked, id, error, ...rest }) => {
  return (
    <Wrapper>
      <StyledCheckbox type={'checkbox'} checked={checked} {...field} id={id} {...rest} />
      {label && (
        <Label className={'no-select'} htmlFor={id}>
          {label}
        </Label>
      )}
    </Wrapper>
  );
};

export default Checkbox;
