import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import AccountManagement from '../AccountManagement/AccountManagement';
import { useSubmit, useToast } from 'components/hooks';
import { postLocation, LocationType } from 'api';
import { Location } from 'models';
import { AddSingleItem } from 'types';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  addLocation: (args: AddSingleItem<Location>) => void;
  locationType: LocationType;
}

const AddLocation: React.FC<Props> = ({ isOpen, handleClose, addLocation, locationType }) => {
  const { success, error } = useToast();
  const { companyId } = useParams<{ companyId: string }>();

  const locationName = useMemo(() => {
    if (locationType === 'electricity') return 'Electricity';
    if (locationType === 'swimming-pool') return 'Swimming pool';
    if (locationType === 'telephone-internet') return 'Telephone/Internet';
  }, [locationType]);

  const { onSubmit, onSubmitSuccess, onSubmitError } = useSubmit(postLocation(companyId, locationType));
  onSubmitError(error(`${locationName} location cannot be added`));
  onSubmitSuccess((payload) => {
    addLocation({ payload });
    success(`${payload.name} has been added`);
    handleClose();
  });

  return <AccountManagement isOpen={isOpen} handleClose={handleClose} handleSubmit={onSubmit} title={`New ${locationName} location`} />;
};

export default AddLocation;
