import React from 'react';
import { Column, Row } from 'react-table';
import { AnyMongoAbility } from '@casl/ability';

import { Formatter } from 'components';
import { tableDropdown } from 'utils/table';
import { TourOperator } from 'models';
import { Option, SecuredTableDropdownArguments } from 'types';

export interface DropdownValue {
  type: 'delete' | 'edit';
  item: TourOperator;
}

const prepareDropdownItems = (item: TourOperator, ability: AnyMongoAbility): Option<DropdownValue>[] => {
  const editItem = ability.can('update', 'TourOperators') ? [{ name: 'Edit', value: { type: 'edit', item } }] : [];
  const deleteItem = ability.can('delete', 'TourOperators') ? [{ name: 'Remove', value: { type: 'delete', item }, type: 'delete' }] : [];

  return [...editItem, ...deleteItem] as Option<DropdownValue>[];
};

export const prepareColumns = ({ handleDropdownSelect, ability }: SecuredTableDropdownArguments<DropdownValue>): Column<TourOperator>[] => [
  { accessor: 'name', Header: 'Name' },
  {
    accessor: 'provision',
    Header: 'Provision',
    Cell: ({ value }: { value: string }) => <Formatter value={value} prefix={' '} suffix={'%'} />
  },
  {
    id: 'dropdown',
    width: 50,
    Cell: ({ row }: { row: Row<TourOperator> }) => {
      const options = prepareDropdownItems(row.original, ability);

      return tableDropdown<DropdownValue>({ options, handleDropdownSelect });
    }
  }
];
