import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import OperatorManagement from '../OperatorManagement/OperatorManagement';
import { useSubmit, useFetch, useToast, useShowContent } from 'components/hooks';
import { putOperator, getSingleOperator, TourOperatorDto } from 'api';
import { TourOperator } from 'models';
import { UpdateSingleItem } from 'types';

interface Props {
  item: TourOperator;
  isOpen: boolean;
  handleClose: () => void;
  updateOperator: (args: UpdateSingleItem<TourOperator[]>) => void;
}

const EditOperator: React.FC<Props> = ({ item, isOpen, handleClose, updateOperator }) => {
  const { success, error } = useToast();
  const { companyId } = useParams<{ companyId: string }>();

  const operatorData = useFetch(getSingleOperator(companyId, item.id));
  const { showLoader, showError, showNoContent } = useShowContent(operatorData);
  const { payload: operator } = operatorData;

  const initialValues: TourOperatorDto | undefined = useMemo(() => {
    if (operator) {
      return { name: operator.name, provision: operator.provision };
    }
  }, [operator]);

  const { onSubmit, onSubmitSuccess, onSubmitError } = useSubmit(putOperator(companyId, item.id));
  onSubmitError(error('Cannot update tour operator'));
  onSubmitSuccess((operator) => {
    updateOperator({ updatedItem: operator });
    success(`${operator.name} has been updated`);
    handleClose();
  });

  const disableEdit = showError || showNoContent;

  return (
    <OperatorManagement
      isOpen={isOpen}
      handleClose={handleClose}
      handleSubmit={onSubmit}
      isLoading={showLoader}
      showError={disableEdit}
      initialValues={initialValues}
      buttonText={'Save'}
      title={'Edit Tour Operator'}
    />
  );
};

export default EditOperator;
