import React, { useEffect, useMemo, useState } from 'react';

import CompanyManagement from '../CompanyManagement/CompanyManagement';
import { useCall, useFetch, useShowContent, useSubmit, useToast } from 'components/hooks';
import { putCompany, getCompany, CompanyDto, postCompanyLogo, getCompanyLogo } from 'api';
import { Company } from 'models';
import { UpdateSingleItem } from 'types';

interface Props {
  item: Company;
  isOpen: boolean;
  handleClose: () => void;
  updateCompany: (args: UpdateSingleItem<Company[]>) => void;
}

const EditCompany: React.FC<Props> = ({ item, isOpen, handleClose, updateCompany }) => {
  const { success, error } = useToast();

  const [logo, setLogo] = useState<File | string | null>(null);

  const companyData = useFetch(getCompany(item.id));
  const { showError, showNoContent, showLoader } = useShowContent(companyData);
  const { payload: company } = companyData;

  const { submit: getLogo, onCallSuccess } = useCall(getCompanyLogo(item.id));
  onCallSuccess((data, headers) => {
    const fileName: string = headers['x-file-name'];
    setLogo(fileName);
  });

  const { submit: postLogo, onCallSuccess: onUpdateLogoSuccess, onCallError: onUpdateLogoError } = useCall(postCompanyLogo);
  onUpdateLogoSuccess(() => success('Logo has been updated'));
  onUpdateLogoError(error('Could not update the logo'));

  const handlePostLogo = async (companyId: number) => {
    if (logo && logo instanceof File) {
      await postLogo(companyId, logo);
    }
  };

  useEffect(() => {
    (async () => {
      await getLogo();
    })();
  }, []);

  const initialValues: CompanyDto | undefined = useMemo(() => {
    if (company) {
      return {
        name: company.name,
        address: company.address,
        vatId: company.vatId,
        tinId: company.tinId,
        restaurant: !!company.posterToken,
        posterToken: company.posterToken,
        startBalance: company.startBalance,
        zrbId: company.zrbId,
        shareholders: company.shareholders.map(({ id, shares }) => ({ userId: id, shares })),
        logo: logo,
        vfmsIntergrationId: company.vfmsIntergrationId,
        vfmsTokenId: company.vfmsTokenId,
        vfmsRestaurantIntergrationId: company.vfmsRestaurantIntergrationId,
        vfmsRestaurantTokenId: company.vfmsRestaurantTokenId
      };
    }
  }, [company, logo]);

  const { onSubmit, onSubmitSuccess, onSubmitError, mapData } = useSubmit(putCompany(item.id));
  mapData((company) => {
    const values = { ...company };
    delete values.logo;

    return values;
  });
  onSubmitError(error('Cannot update company'));
  onSubmitSuccess(async (company) => {
    updateCompany({ updatedItem: company });
    success(`${company.name} has been updated`);
    handleClose();
    await handlePostLogo(company.id);
  });

  const disableEdit = showError || showNoContent;

  return (
    <CompanyManagement
      isOpen={isOpen}
      handleSubmit={onSubmit}
      initialValues={initialValues}
      title={'Edit company'}
      handleClose={handleClose}
      showError={disableEdit}
      isLoading={showLoader}
      isEdit={true}
      setLogo={setLogo}
    />
  );
};

export default EditCompany;
