import { Login, Companies, Company, Settings, Users, ConfirmMail, PasswordReset, ResetPasswordRequest } from 'pages';
import { RouteModel } from 'types';

export const publicRoutes: RouteModel[] = [
  {
    path: '/login',
    exact: true,
    component: Login,
    protected: false
  },
  {
    path: '/confirm-email',
    exact: true,
    component: ConfirmMail,
    protected: false
  },
  {
    path: '/reset-password',
    exact: true,
    component: PasswordReset,
    protected: false
  },
  {
    path: '/forgot-password',
    exact: true,
    component: ResetPasswordRequest,
    protected: false
  }
];

export const routes: RouteModel[] = [
  {
    path: '/settings',
    exact: true,
    component: Settings,
    protected: true
  },
  {
    path: '/users',
    exact: true,
    component: Users,
    protected: true
  },
  {
    path: '/companies',
    exact: true,
    component: Companies,
    name: 'Companies',
    description: 'List of all active companies',
    protected: true
  },
  {
    path: '/company/:companyId',
    exact: false,
    component: Company,
    name: 'Company',
    protected: true
  }
];
