import React, { PropsWithChildren, useEffect, useRef } from 'react';
import { useFlexLayout, useTable, useSortBy, usePagination, useFilters, useGlobalFilter, Column, IdType } from 'react-table';

import GlobalFilter from './filters/GlobalFilter/GlobalFilter';
import Header from './Header/Header';
import Body from './Body/Body';
import Footer from './Footer/Footer';
import { Pagination } from 'components';

import { PositionBox } from 'style';
import { TableWrapper, TableContent } from './table.styles';

type ObjectType = Record<string, unknown>;

interface Props<T extends ObjectType> {
  columns: Column<T>[];
  data: T[];
  showHeader?: boolean;
  showFooter?: boolean;
  showGlobalFilter?: boolean;
  showColumnsFilter?: boolean;
  showPagination?: boolean;
  alignCellsLeft?: boolean;
  handleRowClick?: (original: T) => () => void;
  pageItems?: number;
  haveDropdown?: boolean;
  hiddenColumns?: IdType<T>[];
}

function Table<T extends ObjectType>({
  columns,
  data,
  alignCellsLeft,
  showHeader = true,
  showFooter,
  showGlobalFilter,
  showColumnsFilter,
  showPagination = true,
  handleRowClick,
  pageItems = 8,
  haveDropdown,
  hiddenColumns
}: PropsWithChildren<Props<T>>) {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const instance = useTable<T>(
    { columns, data, autoResetFilters: false, autoResetPage: false, initialState: { hiddenColumns: hiddenColumns || [] } },
    useFlexLayout,
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    page,
    prepareRow,
    pageOptions,
    previousPage,
    nextPage,
    canNextPage,
    canPreviousPage,
    gotoPage,
    state: { pageIndex, globalFilter },
    setPageSize,
    setGlobalFilter
  } = instance;

  const allPages = pageOptions.length;
  const allowPagination = allPages !== 1 && showPagination;

  useEffect(() => {
    setPageSize(showPagination ? pageItems : data.length);
  }, [data, showPagination, pageItems]);

  return (
    <>
      <TableWrapper haveDropdown={haveDropdown} ref={wrapperRef} className={'table'}>
        {showGlobalFilter && <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />}
        <TableContent {...getTableProps()}>
          <Header<T> headerGroups={headerGroups} showHeader={showHeader} alignCellsLeft={alignCellsLeft} showColumnsFilter={showColumnsFilter} />
          <Body<T> getTableBodyProps={getTableBodyProps} page={page} handleRowClick={handleRowClick} prepareRow={prepareRow} showFooter={showFooter} />
          <Footer<T> footerGroups={footerGroups} showFooter={showFooter} />
        </TableContent>
      </TableWrapper>
      {allowPagination && (
        <PositionBox position={'center-right'} margin={'12px 0'}>
          <Pagination
            currentPageIndex={pageIndex}
            handlePageChange={gotoPage}
            canNextPage={canNextPage}
            canPrevPage={canPreviousPage}
            handleNextPage={nextPage}
            handlePrevPage={previousPage}
            allPages={allPages}
          />
        </PositionBox>
      )}
    </>
  );
}

export default Table;
