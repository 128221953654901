import React, { PropsWithChildren } from 'react';
import { HeaderGroup } from 'react-table';

import { ObjectType } from 'types';

import { TableRow, TableCell } from '../table.styles';

interface Props<T extends ObjectType> {
  footerGroups: HeaderGroup<T>[];
  showFooter?: boolean;
}

function Footer<T extends ObjectType>({ footerGroups, showFooter }: PropsWithChildren<Props<T>>) {
  return (
    <>
      {/*prevent unnecessary footer render*/}
      {showFooter && (
        <tfoot style={{ width: '100%' }}>
          {footerGroups.map((group, index) => (
            <TableRow {...group.getFooterGroupProps()} showFooter={showFooter} isFooter={true} key={index}>
              {group.headers.map((column, index) => (
                <TableCell {...column.getFooterProps()} key={index}>
                  {column.render('Footer')}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </tfoot>
      )}
    </>
  );
}

export default Footer;
