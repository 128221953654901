import React from 'react';
import { useParams } from 'react-router-dom';

import InvoiceManagement from './InvoiceManagement/InvoiceManagement';
import { useSubmit, useToast } from 'components/hooks';
import { postInvoice } from 'api';
import { Invoice } from 'models';
import { AddSingleItem } from 'types';
import { prepareMultiline } from 'utils';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  onAddInvoice: (args: AddSingleItem<Invoice>) => void;
}

const AddInvoice: React.FC<Props> = ({ isOpen, handleClose, onAddInvoice }) => {
  const { success, error } = useToast();
  const { companyId } = useParams<{ companyId: string }>();

  const { onSubmit, onSubmitSuccess, onSubmitError, mapData } = useSubmit(postInvoice(companyId));
  mapData((data) => ({
    ...data,
    notes: prepareMultiline(data.notes || '')
  }));
  onSubmitError(error('Invoice cannot be added'));
  onSubmitSuccess((invoice) => {
    onAddInvoice({ payload: invoice });
    success(`Invoice has been added`);
    handleClose();
  });

  return <InvoiceManagement isOpen={isOpen} handleClose={handleClose} handleSubmit={onSubmit} buttonText={'Create'} title={'Create invoice'} />;
};

export default AddInvoice;
