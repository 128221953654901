import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Details from './components/Details/Details';
import Payment from './components/Payment/Payment';
import { Alert, Badge } from 'components';
import { useFetch, useShowContent, usePermissions } from 'components/hooks';
import { getReservation } from 'api';

import { StyledGrid, StyledHeading, HeaderFlex } from './single-reservation.styles';

const SingleReservation: React.FC = () => {
  const { companyPermissions } = usePermissions();
  const { companyId, reservationId } = useParams<{ companyId: string; reservationId: string }>();

  const [isAlertVisible, setAlertVisible] = useState<boolean>();
  const [editable, setEditable] = useState<boolean>(false);
  const [refreshReservationDate, setRefreshReservationDate] = useState<Date>(new Date());

  const reservationData = useFetch(getReservation(companyId, reservationId), { dependencies: [refreshReservationDate] });
  const { showNoContent, showLoader, showError } = useShowContent(reservationData);
  const { payload: reservation } = reservationData;

  const refreshReservation = () => setRefreshReservationDate(new Date());
  const allowReservationEdit = () => setEditable(true);

  useEffect(() => {
    setAlertVisible(reservation?.isClosed);
    setEditable(!reservation?.isClosed);
  }, [reservation]);

  useEffect(() => {
    if (editable) {
      setAlertVisible(false);
    }
  }, [editable]);

  const showAlert = reservation?.isClosed && isAlertVisible;
  const showFetchError = showError || showNoContent;
  const showReservation = companyPermissions.can('read', 'Reservations');

  return (
    <>
      <HeaderFlex>
        <StyledHeading type={'h2'}>Reservation</StyledHeading>
        {reservation?.isClosed && <Badge type={'closed'} text={'Closed'} />}
      </HeaderFlex>
      {showAlert && (
        <Alert text={'This reservation is closed, do you still want to edit?'} buttonText={'Edit reservation'} handleButtonClick={allowReservationEdit} />
      )}
      <StyledGrid columns={'1fr'}>
        {reservation && showReservation && (
          <>
            <Details showLoader={showLoader} showFetchError={showFetchError} reservation={reservation} editable={editable} />
            <Payment
              showLoader={showLoader}
              showFetchError={showFetchError}
              reservation={reservation}
              refreshReservation={refreshReservation}
              editable={editable}
            />
          </>
        )}
      </StyledGrid>
    </>
  );
};

export default SingleReservation;
