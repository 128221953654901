import React, { useRef, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import gsap from 'gsap';

import Header from './components/Header/Header';
import { modalOpenAnimation } from 'animations';

import { ModalBackdrop, ModalBody, Content } from './modal.styles';

export interface Props {
  isOpen: boolean;
  handleClose?: () => void;
  hideHeader?: boolean;
  hideClose?: boolean;
  title?: string;
  width?: string;
  fullMobile?: boolean;
  dropdown?: boolean;
  showPadding?: boolean;
  elevation?: 1 | 2 | 3;
}

const Modal: React.FC<Props> = ({ isOpen, handleClose, hideClose, title, width, hideHeader, showPadding, dropdown, fullMobile, elevation, children }) => {
  const backgroundRef = useRef<HTMLDivElement | null>(null);
  const boxRef = useRef<HTMLDivElement | null>(null);

  const [tl] = useState<GSAPTimeline>(gsap.timeline({ defaults: { ease: 'Power3.inOut' } }));

  useEffect(() => {
    modalOpenAnimation(tl, backgroundRef, boxRef);
  }, []);

  useEffect(() => {
    isOpen ? tl.play() : tl.reverse();
  }, [isOpen]);

  const showHeader = !hideHeader;
  const portalElement = document.getElementById(elevation ? 'portal-elevated-' + elevation : 'portal');

  return (
    portalElement &&
    ReactDOM.createPortal(
      <ModalBackdrop ref={backgroundRef}>
        <ModalBody ref={boxRef} width={width} fullMobile={fullMobile} dropdown={dropdown}>
          {showHeader && <Header handleClose={handleClose} title={title} showCloseButton={!hideClose && Boolean(handleClose)} />}
          <Content showPadding={showPadding}>{children}</Content>
        </ModalBody>
      </ModalBackdrop>,
      portalElement
    )
  );
};

export default Modal;
