import React, { useEffect } from 'react';
import { useField } from 'formik';

import { MultipleCombobox } from 'components';
import { Props } from 'components/form/MultipleCombobox/MultipleCombobox';

type MultipleComboboxLimitedProps = Omit<Props, 'onChange'>;

export interface MultipleComboboxProps extends MultipleComboboxLimitedProps {
  name: string;
  onFieldChange?: () => void;
  onFieldError?: () => void;
  label?: string;
}

const MultipleComboboxForm: React.FC<MultipleComboboxProps> = ({ name, onFieldChange, onFieldError, label, ...props }) => {
  const [field, meta, helpers] = useField({ name });

  const { error, touched } = meta;
  const { setValue } = helpers;

  const showError = error && touched;

  const handleSetValue = (value: any[]) => {
    setValue(value);
    onFieldChange && onFieldChange();
  };

  const onSelectChange = (value: any[]) => {
    handleSetValue(value);
  };

  useEffect(() => {
    if (showError) {
      onFieldError && onFieldError();
    }
  }, [showError, onFieldError]);

  return <MultipleCombobox onChange={onSelectChange} value={field.value} label={label} {...props} />;
};

export default MultipleComboboxForm;
