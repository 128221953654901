import { object, boolean, number, mixed, SchemaOf } from 'yup';

import { CurrencyValue } from 'types';
import { ReservationDto } from 'api';
import { currenciesOneOf } from 'utils';
import { message } from 'utils';

export const reservationSchema: SchemaOf<ReservationDto> = object().shape({
  cashAmount: number().required(message.required).min(0, 'Minimum 0'),
  cashCurrency: mixed().oneOf<CurrencyValue>(currenciesOneOf).required(message.currency),
  reportCash: boolean().required(message.required),

  bankPlAmount: number().required(message.required).min(0, 'Minimum 0'),
  bankPlCurrency: mixed().oneOf<CurrencyValue>(currenciesOneOf).required(message.currency),
  reportBankPl: boolean().required(message.required),

  bankZnzAmount: number().required(message.required).min(0, 'Minimum 0'),
  bankZnzCurrency: mixed().oneOf<CurrencyValue>(currenciesOneOf).required(message.currency),
  reportBankZnz: boolean().required(message.required),

  isClosed: boolean()
});
