import fetchMiddleware from 'api/api.middleware';
import { IdParam } from 'types';
import { TourOperator } from 'models';
import { TourOperatorDto, DeleteOperatorResponseDto } from './api.tour-operator.dto';

export const getOperators = (companyId: IdParam) => () => fetchMiddleware<TourOperator[]>({ method: 'get', url: `/companies/${companyId}/tour-operators` });

export const getSingleOperator = (companyId: IdParam, operatorId: IdParam) => () =>
  fetchMiddleware<TourOperator>({ method: 'get', url: `/companies/${companyId}/tour-operators/${operatorId}` });

export const postOperator = (companyId: IdParam) => (data: TourOperatorDto) =>
  fetchMiddleware<TourOperator>({ method: 'post', url: `/companies/${companyId}/tour-operators`, data });

export const putOperator = (companyId: IdParam, operatorId: IdParam) => (data: TourOperatorDto) =>
  fetchMiddleware<TourOperator>({ method: 'put', url: `/companies/${companyId}/tour-operators/${operatorId}`, data });

export const deleteOperator = (companyId: IdParam, operatorId: IdParam) =>
  fetchMiddleware<DeleteOperatorResponseDto>({
    method: 'delete',
    url: `/companies/${companyId}/tour-operators/${operatorId}`,
    data: {}
  });
