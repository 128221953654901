import React from 'react';

import { Empty, Error, Loader } from 'components';

interface Props {
  showLoader: boolean;
  showError: boolean;
  showNoContent: boolean;
}

const States: React.FC<Props> = ({ showLoader, showNoContent, showError }) => {
  const height = '300px';

  return (
    <>
      {showLoader && <Loader height={height} />}
      {showError && <Error height={height}>Cannot load companies</Error>}
      {showNoContent && <Empty height={height}>No data</Empty>}
    </>
  );
};

export default States;
