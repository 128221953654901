import styled from 'styled-components';

import { borders, shadows } from 'style';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: ${borders.default};
  border-radius: 8px;
  box-shadow: ${shadows.default};
  padding: 8px 12px;
  gap: 12px;
  margin: 8px 0;
  cursor: pointer;
`;

export { Wrapper };
