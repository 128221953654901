import styled from 'styled-components';

import { Grid, theme } from 'style';

const Wrapper = styled(Grid)`
  grid-template-rows: auto;
  ${theme.mq.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export { Wrapper };
