import React, { useEffect } from 'react';
import { useField } from 'formik';

import { Combobox } from 'components';
import { Props } from 'components/form/Combobox/Combobox';

type ComboboxPropsOmitted = Omit<Props, 'onChange'>;

export interface ComboboxProps extends ComboboxPropsOmitted {
  name: string;
  onFieldChange?: () => void;
  onFieldError?: () => void;
  label?: string;
  width?: string;
}

const ComboboxForm: React.FC<ComboboxProps> = ({ name, onFieldChange, onFieldError, label, ...props }) => {
  const [field, meta, helpers] = useField({ name });

  const { error, touched } = meta;
  const { setValue } = helpers;

  const showError = error && touched;

  const handleSetValue = (value: any) => {
    setValue(value);
    onFieldChange && onFieldChange();
  };

  const onSelectChange = (value: any) => {
    handleSetValue(value);
  };

  useEffect(() => {
    if (showError) {
      onFieldError && onFieldError();
    }
  }, [showError, onFieldError]);

  return <Combobox {...field} onChange={onSelectChange} {...props} labelText={label} errorMessage={error} />;
};

export default ComboboxForm;
