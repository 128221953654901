import styled from 'styled-components';

import { PositionBox, theme } from 'style';

const StyledPositionBox = styled(PositionBox)`
  ${theme.mq.standard} {
    justify-content: flex-end;
  }
`;

export { StyledPositionBox };
