import React from 'react';
import { useParams } from 'react-router-dom';

import AccountManagement from '../AccountManagement/AccountManagement';
import { useSubmit, useToast } from 'components/hooks';
import { postCar } from 'api';
import { Car } from 'models';
import { AddSingleItem } from 'types';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  addCar: (args: AddSingleItem<Car>) => void;
}

const AddCar: React.FC<Props> = ({ isOpen, handleClose, addCar }) => {
  const { success, error } = useToast();
  const { companyId } = useParams<{ companyId: string }>();

  const { onSubmit, onSubmitSuccess, onSubmitError } = useSubmit(postCar(companyId));
  onSubmitError(error('Car cannot be added'));
  onSubmitSuccess((car) => {
    addCar({ payload: car });
    success(`${car.name} has been added`);
    handleClose();
  });

  return <AccountManagement isOpen={isOpen} handleClose={handleClose} handleSubmit={onSubmit} title={'New car'} />;
};

export default AddCar;
