import React, { useEffect } from 'react';
import { useField } from 'formik';

import { ToggleCheckbox } from 'components/index';
import { Props } from 'components/form/ToggleCheckbox/ToggleCheckbox';
import { Flex, Label } from 'style';

type PropsWithoutChecked = Omit<Props, 'checked'>;

export interface ToggleProps extends PropsWithoutChecked {
  name: string;
  onFieldChange?: (value: any) => void;
  onFieldError?: () => void;
  label?: string;
}

const ToggleForm: React.FC<ToggleProps> = ({ name, onFieldChange, onFieldError, label }) => {
  const [field, meta, helpers] = useField({ name });

  const { error, touched } = meta;
  const { setValue } = helpers;

  const showError = error && touched;

  const handleSetValue = (value: boolean) => {
    setValue(value);
    onFieldChange && onFieldChange(value);
  };

  const handleToggleChange = () => handleSetValue(!field.value);

  useEffect(() => {
    if (showError) {
      onFieldError && onFieldError();
    }
  }, [showError]);

  return (
    <Flex direction={'column'} align={'flex-start'}>
      {label && <Label htmlFor={field.name}>{label}</Label>}
      <ToggleCheckbox name={field.name} checked={field.value} onChange={handleToggleChange} value={field.value} />
    </Flex>
  );
};

export default ToggleForm;
