import React from 'react';
import { useFormikContext } from 'formik';

import { FormField } from 'components/index';
import { RadioOption } from 'types';
import { CashFlowDto, AccountValues } from '../types';
import { currencySelectItems, restrictedCurrencyItems } from 'utils';
import { usePermissions } from 'components/hooks';

import { Grid } from 'style';

const Amount: React.FC = () => {
  const {
    values: { account, exchange }
  } = useFormikContext<CashFlowDto>();
  const { companyPermissions } = usePermissions();

  const options: RadioOption[] = [
    { name: 'Yes', value: true },
    { name: 'No', value: false }
  ];

  const disableExchangedTypes: (AccountValues | undefined)[] = ['exchange', 'salaries-staff', 'salaries-management', 'loan'];
  const showExchanged = account && !disableExchangedTypes.includes(account);

  const hideAmountAccounts: (AccountValues | undefined)[] = ['exchange', 'loan'];
  const showAmount = account && !hideAmountAccounts.includes(account) && !exchange;

  const currencyOptions = companyPermissions.can('create', 'ExchangedTransaction') ? currencySelectItems : restrictedCurrencyItems;

  return (
    <>
      {showAmount && (
        <Grid columns={'3fr 1fr'} margin={'8px 0'}>
          <FormField name={'amount'} type={'money'} prefix={''} placeholder={'Amount'} label={'Amount'} />
          <FormField name={'currency'} type={'select'} items={currencyOptions} label={'Currency'} />
        </Grid>
      )}
      {showExchanged && <FormField name={'vat'} type={'radio'} options={options} mainLabel={'VRN'} />}
    </>
  );
};

export default Amount;
