import styled from 'styled-components';

import { Grid, theme } from 'style';

const StyledGrid = styled(Grid)<{ fullWidth?: boolean }>`
  ${theme.mq.standard} {
    grid-template-columns: ${({ fullWidth }) => (fullWidth ? '1fr' : '1fr 2fr')};
  }
`;

export { StyledGrid };
