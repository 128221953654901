import styled from 'styled-components';

import { colors, Grid, theme } from 'style';

const StyledGrid = styled(Grid)`
  grid-template-rows: auto;
  ${theme.mq.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${theme.mq.quadHd} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  margin: 20px 0 5px 0;
  background-color: ${colors.gray.scale4};
`;

export { StyledGrid, Separator };
