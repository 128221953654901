import React, { useState } from 'react';
import { useCombobox, useMultipleSelection } from 'downshift';

import Selected from './components/Selected/Selected';
import ComboInput from './components/ComboInput/ComboInput';
import { InputProps } from 'components/form/Input/Input';
import { Option } from 'types';

import {
  StyledLabel,
  StyledRow,
  StyledSelectItem,
  StyledSelectOptions,
  StyledSelectWrapper
} from 'components/form/Select/select.styles';
import { Flex } from 'style';

type InputPropsOmitted = Omit<InputProps, 'onChange' | 'size'>;

export interface Props extends InputPropsOmitted {
  items: Option[];
  onChange: (value: any[]) => void;
  value?: any[];
  label?: string;
}

const MultipleCombobox: React.FC<Props> = ({ items, onChange, value, label, ...inputProps }) => {
  const [inputValue, setInputValue] = useState('');

  const currentValues = items.filter((item) => value && value.includes(item.value));
  const defaultValues = items.filter((item) => item.isDefault);

  const initialValue = value ? currentValues : defaultValues;

  const prepareSelectedItems = (selected: Option[]) => selected.map((item) => item.value);

  const { getSelectedItemProps, getDropdownProps, addSelectedItem, removeSelectedItem, selectedItems } =
    useMultipleSelection<Option>({
      initialSelectedItems: initialValue,
      onSelectedItemsChange: ({ selectedItems }) => selectedItems && onChange(prepareSelectedItems(selectedItems))
    });

  const getFilteredItems = (items: Option[]) => {
    const rest = items.filter((item) => !selectedItems.some((selectedItem) => selectedItem.value === item.value));
    return rest.filter((item) => item.name.toLowerCase().includes(inputValue.toLowerCase()));
  };

  const { isOpen, getMenuProps, getInputProps, getComboboxProps, highlightedIndex, getItemProps, selectItem } = useCombobox({
    inputValue,
    items: getFilteredItems(items),
    onStateChange: ({ inputValue, type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(inputValue || '');

          break;
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (selectedItem) {
            setInputValue('');
            addSelectedItem(selectedItem);

            // @ts-ignore
            selectItem(null);

            if (onChange) {
              onChange(prepareSelectedItems(selectedItems));
            }
          }

          break;
        default:
          break;
      }
    }
  });

  const showResults = getFilteredItems(items).length > 0;

  return (
    <StyledSelectWrapper>
      {label && (
        <StyledRow>
          <StyledLabel>{label}</StyledLabel>
        </StyledRow>
      )}
      <div {...getComboboxProps()}>
        <ComboInput {...getInputProps(getDropdownProps({ preventKeyAction: isOpen }))} {...inputProps}>
          <Flex gap={4}>
            {selectedItems.map((item) => {
              const handleDelete = () => removeSelectedItem(item);
              return <Selected key={item.value} name={item.name} handleDelete={handleDelete} />;
            })}
          </Flex>
        </ComboInput>
      </div>
      <StyledSelectOptions isOpen={isOpen} {...getMenuProps()} alignPosition={'left'} offsetTop={20}>
        {isOpen && (
          <>
            {showResults &&
              getFilteredItems(items).map((item, index) => (
                <StyledSelectItem
                  {...getItemProps({ item, index })}
                  key={`${index}`}
                  disabled={item.blocked}
                  highlighted={highlightedIndex === index}
                >
                  {item.name}
                </StyledSelectItem>
              ))}
            {!showResults && <p>Empty</p>}
          </>
        )}
      </StyledSelectOptions>
    </StyledSelectWrapper>
  );
};

export default MultipleCombobox;
