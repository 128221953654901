import React from 'react';
import { useParams } from 'react-router-dom';

import { Heading, Table, Loader, Error } from 'components';
import { useFetch, useShowContent } from 'components/hooks';
import { getUserShares, UserSharesDto } from 'api';
import { columns } from './companies.columns';

import { Card } from 'style';

const Companies: React.FC = () => {
  const { companyId, partnerId } = useParams<{ companyId: string; partnerId: string }>();

  const sharesData = useFetch(getUserShares(companyId, partnerId));
  const { showContent, showNoContent, showError, showLoader } = useShowContent(sharesData);
  const { payload: shares } = sharesData;

  return (
    <Card height={'auto'}>
      <Heading>Partners</Heading>
      {showLoader && <Loader />}
      {(showNoContent || showError) && <Error>Fetch error</Error>}
      {showContent && shares && <Table<UserSharesDto> columns={columns} data={shares} pageItems={4} />}
    </Card>
  );
};

export default Companies;
