import React from 'react';
import { useParams } from 'react-router-dom';

import Transaction from 'form/transaction/transaction';
import { useSubmit, useToast } from 'components/hooks';
import { postTransaction } from 'api';
import { mapTransactionData } from 'utils';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  loansRefresh: () => void;
}

const AddShareholderLoan: React.FC<Props> = ({ isOpen, handleClose, loansRefresh }) => {
  const { success, error } = useToast();
  const { companyId, partnerId } = useParams<{ companyId: string; partnerId: string }>();

  const { onSubmit, onSubmitSuccess, onSubmitError, mapData } = useSubmit(postTransaction(companyId));
  onSubmitError(error('Transaction error'));
  onSubmitSuccess(() => {
    handleClose();
    loansRefresh();
    success('Transaction has been added');
  });
  mapData((data) => {
    return mapTransactionData(data);
  });

  return <Transaction isOpen={isOpen} handleClose={handleClose} handleSubmit={onSubmit} type={'new'} lender={'shareholder'} loanLenderId={+partnerId} />;
};

export default AddShareholderLoan;
