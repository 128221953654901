import { FieldProps } from 'components';

export const mainFields: FieldProps[] = [
  { name: 'name', placeholder: 'Company name', type: 'text', label: 'Company name' },
  { name: 'address', placeholder: 'Address', type: 'text', label: 'Address' },
  { name: 'vatId', placeholder: 'VRN no.', type: 'text', label: 'VRN no.' },
  { name: 'tinId', placeholder: 'TIN no.', type: 'text', label: 'TIN no.' },
  // { name: 'vrn', placeholder: 'VRN', type: 'text', label: 'VRN' },
  { name: 'zrbId', placeholder: 'ZRB no.', type: 'text', label: 'ZRB no.' }
];
