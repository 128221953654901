import React from 'react';
import { useParams } from 'react-router-dom';

import States from '../States/States';
import { BalanceValues } from 'components';
import { useFetch, usePermissions, useShowContent } from 'components/hooks';
import { getBalance } from 'api';

import { Card, DesktopTripleGrid } from 'style';

interface Props {
  dateRange: [Date, Date];
  cashRefreshDate: Date;
}

const Statistics: React.FC<Props> = ({ dateRange, cashRefreshDate }) => {
  const { companyPermissions } = usePermissions();
  const { companyId } = useParams<{ companyId: string }>();

  const balanceData = useFetch(getBalance(companyId, 'cash', dateRange), { dependencies: [dateRange, cashRefreshDate] });
  const { showContent, ...loadingStates } = useShowContent(balanceData);
  const { payload: balance } = balanceData;

  const showCashStatistics = companyPermissions.can('read', 'CashStatistics');

  if (!showCashStatistics) return null;

  return (
    <DesktopTripleGrid columns={'1fr'} margin={'16px 0 0 0'}>
      <Card>
        <States {...loadingStates} />
        {showContent && balance && (
          <BalanceValues heading={'BALANCE ON START - CASH'} tzs={balance.tzs.startBalance} usd={balance.usd.startBalance} eur={balance.eur.startBalance} />
        )}
      </Card>
      <Card>
        <States {...loadingStates} />
        {showContent && balance && (
          <BalanceValues heading={'BALANCE ON END - CASH'} tzs={balance.tzs.endBalance} usd={balance.usd.endBalance} eur={balance.eur.endBalance} />
        )}
      </Card>
      <Card>
        <States {...loadingStates} />
        {showContent && balance && (
          <BalanceValues heading={'SPENDING - CASH'} tzs={balance.tzs.spendings} usd={balance.usd.spendings} eur={balance.eur.spendings} />
        )}
      </Card>
    </DesktopTripleGrid>
  );
};

export default Statistics;
