interface Breakpoints {
  mobile: number;
  mobileL: number;
  tabletS: number;
  tablet: number;
  standard: number;
  hdReady: number;
  quadHd: number;
  quadHdL: number;
  fullHd: number;
}

export const breakpoints: Breakpoints = {
  mobile: 340,
  mobileL: 400,
  tabletS: 512,
  tablet: 710,
  standard: 1024,
  hdReady: 1280,
  quadHd: 1440,
  quadHdL: 1660,
  fullHd: 1900
};

export const theme = {
  mq: {
    mobile: `@media all and (min-width: ${breakpoints.mobile}px)`,
    mobileL: `@media all and (min-width: ${breakpoints.mobileL}px)`,
    tabletS: `@media all and (min-width: ${breakpoints.tabletS}px)`,
    tablet: `@media all and (min-width: ${breakpoints.tablet}px)`,
    standard: `@media all and (min-width: ${breakpoints.standard}px)`,
    hdReady: `@media all and (min-width: ${breakpoints.hdReady}px)`,
    quadHd: `@media all and (min-width: ${breakpoints.quadHd}px)`,
    quadHdL: `@media all and (min-width: ${breakpoints.quadHdL}px)`,
    fullHd: `@media all and (min-width: ${breakpoints.fullHd}px)`
  }
};

export const font = {
  family: {
    Inter: 'Inter, sans-serif'
  },
  weight: {
    bold: 700,
    semi: 600,
    medium: 500,
    book: 400
  },
  size: {
    paragraph: {
      p1: '20px',
      p2: '16px',
      p3: '13px'
    },
    heading: {
      display: '64px',
      h1: '48px',
      h2: '36px',
      h3: '24px',
      h4: '20px',
      h5: '16px',
      h6: '13px'
    }
  }
};

export const colors = {
  green: '#00BA34',
  red: '#E92C2C',
  orange: '#F98600',
  gray: {
    scale1: '#1C1C1C',
    scale2: '#585757',
    scale3: '#969696',
    scale4: '#E8E8E8',
    scale5: '#FFF'
  },
  blue: {
    base: '#0085FF',
    opacity: 'rgba(0, 133, 255, 0.1)',
    light: ' linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), #0085FF',
    medium: ' linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #0085FF',
    darker: 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #168FFF'
  },
  background: {
    primary: '#FFFFFF',
    secondary: '#F7F7F7',
    blue: 'rgba(0, 133, 255, 0.1)',
    green: 'rgba(0, 186, 52, 0.1)',
    red: 'rgba(255, 59, 59, 0.1)'
  },
  modal: {
    background: 'rgba(0,0,0, 0.3)'
  }
};

export const shadows = {
  default: '0px 1px 1px rgba(0, 0, 0, 0.06)',
  card: '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 2px 1px rgba(0, 0, 0, 0.06), 0px 1px 1px rgba(0, 0, 0, 0.08)',
  button: '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 2px 1px rgba(0, 0, 0, 0.06), 0px 1px 1px rgba(0, 0, 0, 0.08)',
  modal: '0px 3px 5px rgba(0, 0, 0, 0.1), 0px 1px 18px rgba(0, 0, 0, 0.06), 0px 6px 10px rgba(0, 0, 0, 0.08)'
};

export const borders = {
  default: '1px solid #E8E8E8',
  button: {
    secondary: '1px solid #E8E8E8'
  },
  input: {
    base: '1px solid #E8E8E8',
    active: '1px solid #0085FF',
    error: '1px solid #E92C2C',
    success: '1px solid #00BA34'
  }
};
