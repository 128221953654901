import styled, { css } from 'styled-components';

import { InputSizes } from 'components/form/Input/Input';
import { borders, colors } from 'style';

const InputWrapper = styled.div<{ inputSize?: InputSizes }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  background: #fff;
  border: ${borders.input.base};
  border-radius: 8px;

  /* ------------------------------- Input sizes ------------------------------ */
  input {
    ${({ inputSize }) => {
      switch (inputSize) {
        case 'small':
          return css`
            padding: 7px 16px;
          `;
        case 'large':
          return css`
            padding: 15px 16px;
          `;
      }
    }}
  }
`;

const StyledInput = styled.input`
  width: 100%;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${colors.gray.scale2};
  height: 4rem;
  padding: 0.8rem 1.2rem;
  border: none;
  border-radius: 8px;

  &:focus {
    outline: none;
    border: none;
  }

  &:disabled {
    opacity: 0.6;
  }

  &::placeholder {
    color: ${colors.gray.scale3};
  }

  &:-webkit-autofill,
  &:-webkit-autofill::first-line {
    font-size: 1.6rem;
    font-family: 'Inter', sans-serif;
  }
`;

export { InputWrapper, StyledInput };
