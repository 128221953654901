import styled from 'styled-components';

import { colors } from 'style';

const ErrorParagraph = styled.p`
  display: inline-flex;
  column-gap: 5px;
  font-size: 1.3rem;
  margin-top: 8px;
  align-items: center;
  color: ${colors.red};
`;

export { ErrorParagraph };
