import React from 'react';
import { Formik, Form } from 'formik';
import { useParams } from 'react-router-dom';

import { Button, CardItem, Formatter } from 'components';
import { useSubmit, useToast } from 'components/hooks';
import { postRestaurantReport, PostRestaurantReportDto } from 'api';
import { reportValidationSchema } from './report.validation';

import { Paragraph, PositionBox } from 'style';

interface Props {
  income: number;
  dateRange: [Date, Date];
  handleReportsRefresh: () => void;
}

const ReportForm: React.FC<Props> = ({ income, dateRange, handleReportsRefresh }) => {
  const [startDate, endDate] = dateRange;

  const { success, error } = useToast();
  const { companyId } = useParams<{ companyId: string }>();

  const initialValues: PostRestaurantReportDto = {
    percentage: 0,
    startDate: startDate,
    endDate: endDate
  };

  const { onSubmit, onSubmitSuccess, onSubmitError } = useSubmit(postRestaurantReport(companyId));
  onSubmitSuccess(() => {
    handleReportsRefresh();
    success('Report has been generated');
  });
  onSubmitError(error('Failed to generate the report'));

  const calculateSummary = (value: number, percentage: number) => (value * (percentage / 100)).toFixed(2);

  return (
    <Formik<PostRestaurantReportDto> initialValues={initialValues} onSubmit={onSubmit} enableReinitialize={true} validationSchema={reportValidationSchema}>
      {({ values }) => {
        return (
          <Form>
            <CardItem name={'Income'}>
              <Paragraph type={'p2'} color={'scale3'}>
                <Formatter value={income} />
              </Paragraph>
            </CardItem>
            <PositionBox position={'top-right'} margin={'24px 0 0 0'}>
              <Button type={'submit'}>Generate Report</Button>
            </PositionBox>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ReportForm;
