import styled, { css } from 'styled-components';

import { Props } from './Heading';

import { colors, font } from 'style';

type HeadingProps = Omit<Props, 'children' | 'type'>;

const headingStyles = css<HeadingProps>`
  color: ${({ color }) => (color ? colors.gray[color] : colors.gray.scale1)};
  margin: ${({ margin }) => margin};
  font-weight: ${({ weight }) => (weight ? font.weight[weight] : font.weight.medium)};
  ${({ ellipsisWidth }) =>
    typeof ellipsisWidth !== 'undefined' &&
    css`
      max-width: ${ellipsisWidth === 'number' ? ellipsisWidth + 'px' : ellipsisWidth};
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    `}
`;

const DisplayHeading = styled.h1`
  ${headingStyles};
  font-size: ${font.size.heading.display};
  line-height: 80px;
`;

const Heading1 = styled.h1`
  ${headingStyles};
  font-size: ${font.size.heading.h1};
  line-height: 60px;
`;

const Heading2 = styled.h2`
  ${headingStyles};
  font-size: ${font.size.heading.h2};
  line-height: 48px;
`;

const Heading3 = styled.h3`
  ${headingStyles};
  font-size: ${font.size.heading.h3};
  line-height: 32px;
`;

const Heading4 = styled.h4`
  ${headingStyles};
  font-size: ${font.size.heading.h4};
  line-height: 28px;
`;

const Heading5 = styled.h5`
  ${headingStyles};
  font-size: ${font.size.heading.h5};
  line-height: 24px;
  margin: ${({ margin }) => margin || '0 0 15px 0'};
`;

const Heading6 = styled.h6`
  ${headingStyles};
  font-size: ${font.size.heading.h6};
  line-height: 20px;
`;

export { DisplayHeading, Heading1, Heading2, Heading3, Heading4, Heading5, Heading6 };
