import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import queryString from 'query-string';

import { handleRefreshToken } from 'api/api.refresh-token';
import { ObjectType } from 'types';
import { API_URL, TOKEN_STORAGE } from 'constants/auth.constants';

type Method = 'post' | 'patch' | 'put' | 'delete' | 'get';

export interface ParamsProps extends AxiosRequestConfig {
  headers?: HeadersInit;
  url: string;
  method: Method;
  data?: any;
  canceling?: boolean;
  baseUrl?: string;
  params?: string;
  query?: ObjectType;
}

export interface Status {
  isCanceled: boolean;
  status: number;
  headers?: any;
}

export interface ErrorResponse {
  message: string;
  detail?: string;
  title?: string;
  response?: any;
  request?: any;
}

type AbortControllersType = {
  [key: string]: any;
};

const { CancelToken } = axios;
const abortControllers: AbortControllersType = {};
const cancelMessage = 'Request has been canceled';

function fetchMiddleware<T>(params: ParamsProps): Promise<[T | null, ErrorResponse | null, Status]> {
  return new Promise((resolve) => {
    const query = handlePrepareQuery(params.query);
    const base = params.baseUrl || API_URL;
    const url = base + params.url + query;
    const isFormData = params.data instanceof FormData;

    const headers: HeadersInit = handleHeaders(params, isFormData);
    const cancelToken = handleCancelRequest(params);
    const data = handlePrepareData(params.data, isFormData);

    return axios({
      ...params,
      url,
      data,
      headers
    })
      .then(({ data, status, headers }: AxiosResponse) => {
        const responseData = status === 204 ? null : data;
        return resolve([responseData, null, { status, headers, isCanceled: false }]);
      })
      .catch((err: ErrorResponse) => {
        const status = handleStatus(err);
        const error = handleErrors(err.response?.data || err);
        const isCanceled = err.message === cancelMessage;

        return resolve([null, error, { status, isCanceled }]);
      });
  });
}

export default fetchMiddleware;

const handlePrepareQuery = (paramsQuery?: ObjectType) => {
  const stringifyOptions = { skipNull: true, skipEmptyString: true };

  return paramsQuery ? `?${queryString.stringify(paramsQuery, stringifyOptions)}` : '';
};

const handlePrepareData = (payload: any, isFormData: boolean) => {
  let data = null;

  if (isFormData) {
    data = payload;
  } else if (payload) {
    data = JSON.stringify(payload);
  }
  return data;
};

const handleHeaders = (params: ParamsProps, isFormData: boolean) => {
  const headers: HeadersInit = {};

  if (!isFormData) {
    headers['Content-Type'] = 'application/json';
  }

  const token = localStorage.getItem(TOKEN_STORAGE);
  headers['Authorization'] = `Bearer ${token}`;

  Object.assign(headers, params.headers);

  return headers;
};

const handleCancelRequest = ({ baseUrl, url }: ParamsProps) => {
  const abortName = baseUrl ? baseUrl + url.split('?')[0] : API_URL + url.split('?')[0];

  if (!abortControllers[abortName]) {
    abortControllers[abortName] = CancelToken.source();
  } else {
    abortControllers[abortName].cancel('Request has been canceled');
    delete abortControllers[abortName];
  }

  const abortToken = abortControllers[abortName];

  return abortToken?.token;
};

const handleErrors = (error: ErrorResponse): Error | ErrorResponse => {
  return error.message === cancelMessage ? new Error('Request canceled') : error;
};

export const handleStatus = ({ response, request }: ErrorResponse): number => {
  if (response) {
    return response?.status;
  } else if (request) {
    return request?.status;
  }

  return 0;
};

axios.interceptors.response.use((response) => response, handleRefreshToken);
