import React from 'react';

import { Select } from 'components';
import { Option, currencies, CurrencyValue } from 'types';

import { colors, MoreVerticalIcon } from 'style';

export const PAGE_ITEMS = 50;

/* T -> dropdown item value */
interface ColumnDropdownArguments<T> {
  handleDropdownSelect: (value: T) => void;
  options: Option<T>[];
}

export function tableDropdown<T>({ options, handleDropdownSelect }: ColumnDropdownArguments<T>): JSX.Element | null {
  return options.length ? <Select<T> items={options} onChange={handleDropdownSelect} alignPosition={'right'} customButton={<MoreVerticalIcon />} /> : null;
}

export const renderAmount = (positive: boolean, currency: CurrencyValue) => {
  const mappedCurrency = currencies[currency];

  const prefix = positive ? ' ' : '(';
  const suffix = positive ? ` ${mappedCurrency}` : ` ${mappedCurrency})`;

  const color = positive ? colors.green : colors.red;

  return { prefix, suffix, color };
};

export const renderMoneyPrefix = (currency: CurrencyValue) => {
  const mappedCurrency = currencies[currency];

  const prefix = ' ';
  const suffix = ` ${mappedCurrency}`;

  return { prefix, suffix };
};
