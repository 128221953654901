import React from 'react';
import { useFormikContext } from 'formik';
import { useParams } from 'react-router-dom';

import { Formatter, FormField, Loader, Empty, Error } from 'components';
import { useFetch, useShowContent } from 'components/hooks';
import { getBaseAndLoans, SalaryDto } from 'api';
import { currencies } from 'types';

import { Flex, Paragraph } from 'style';
import { bankSelectItems } from 'utils';

interface Props {
  isOpen: boolean;
  canManageUsdSalaries?: boolean;
}

const MainFields: React.FC<Props> = ({ isOpen, canManageUsdSalaries }) => {
  const { setFieldValue } = useFormikContext<SalaryDto>();
  const { companyId, employeeId } = useParams<{ companyId: string; employeeId: string }>();

  const baseAndLoansData = useFetch(getBaseAndLoans(companyId, employeeId), {
    dependencies: [isOpen],
    conditions: isOpen,
    onSuccess: (payload) => {
      setFieldValue('baseTzs', payload.baseTzs);
      setFieldValue('baseUsd', payload.baseUsd);
      setFieldValue('loansTzs', payload.ratesTzsSum);
      setFieldValue('loansUsd', payload.ratesUsdSum);
    }
  });
  const { showContent, showNoContent, showLoader, showError } = useShowContent(baseAndLoansData);
  const { payload: baseAndLoans } = baseAndLoansData;

  return (
    <>
      <Flex justify={'space-between'}>
        <Paragraph>Choose date</Paragraph>
        <FormField name={'date'} type={'date'} />
      </Flex>
      <Flex justify={'space-between'} margin={'12px 0'}>
        <Paragraph>Paid from (TZS)</Paragraph>
        <FormField name={'bankTzs'} type={'select'} items={bankSelectItems} placeholder={'Paid from'} alignPosition={'right'} />
      </Flex>
      {canManageUsdSalaries && (
        <Flex justify={'space-between'} margin={'12px 0'}>
          <Paragraph>Paid from (USD)</Paragraph>
          <FormField name={'bankUsd'} type={'select'} items={bankSelectItems} placeholder={'Paid from'} alignPosition={'right'} />
        </Flex>
      )}
      {showLoader && <Loader height={'50px'} />}
      {showNoContent && <Empty height={'50px'}>No data</Empty>}
      {showError && <Error height={'50px'}>Failed to fetch</Error>}
      {showContent && baseAndLoans && (
        <>
          <Flex justify={'space-between'} margin={'16px 0 8px 0'}>
            <Paragraph>Base Pay</Paragraph>
            <Flex>
              <Formatter value={baseAndLoans.baseTzs} type={'money'} prefix={' '} suffix={` ${currencies['tzs']}`} />
              {canManageUsdSalaries && <Formatter value={baseAndLoans.baseUsd} type={'money'} prefix={' '} suffix={` ${currencies['usd']}`} />}
            </Flex>
          </Flex>
          <Flex justify={'space-between'} margin={'0 0 16px 0'}>
            <Paragraph>Loans</Paragraph>
            <Flex>
              <Formatter value={baseAndLoans.ratesTzsSum} type={'money'} prefix={' '} suffix={` ${currencies['tzs']}`} />
              {canManageUsdSalaries && <Formatter value={baseAndLoans.ratesUsdSum} type={'money'} prefix={' '} suffix={` ${currencies['usd']}`} />}
            </Flex>
          </Flex>
        </>
      )}
    </>
  );
};

export default MainFields;
