import { object, string, date, number, mixed, SchemaOf } from 'yup';

import { SalaryDto } from 'api';
import { BankValue } from 'types';
import { bankOneOf } from 'utils';
import { message } from 'utils';

export const salarySchema: SchemaOf<SalaryDto> = object().shape({
  date: date().required(message.required),
  baseTzs: number(),
  baseUsd: number(),
  loansTzs: number(),
  loansUsd: number(),
  overtime: number().required(message.required).min(0, 'Minimum 0'),
  publicHolidays: number().required(message.required).min(0, 'Minimum 0'),
  advanceRepayment: number().required(message.required).min(0, 'Minimum 0'),
  repayments: number().required(message.required).min(0, 'Minimum 0'),
  travelAllowance: number().required(message.required).min(0, 'Minimum 0'),
  bonus: number().required(message.required).min(0, 'Minimum 0'),
  comment: string(),
  bankTzs: mixed().oneOf<BankValue>(bankOneOf).required(message.required),
  bankUsd: mixed().oneOf<BankValue>(bankOneOf).required(message.required)
});
