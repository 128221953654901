import fetchMiddleware from 'api/api.middleware';
import { dateRangeToISO } from 'utils';
import { IdParam } from 'types';
import { Tax, TaxReportModel } from 'models';
import { GenerateTaxReportDto } from './api.taxes.dto';

export const getTaxes = (companyId: IdParam, range: [Date, Date]) => () => {
  const [startDate, endDate] = dateRangeToISO(range);

  return fetchMiddleware<Tax>({ method: 'get', url: `/companies/${companyId}/tax`, query: { startDate, endDate } });
};

export const getTaxReports = (companyId: IdParam) => () => fetchMiddleware<TaxReportModel[]>({ method: 'get', url: `/companies/${companyId}/tax/reports` });

export const getTaxReportFile = (companyId: IdParam) => (reportId: IdParam) =>
  fetchMiddleware<string | Blob>({ method: 'get', url: `/companies/${companyId}/tax/reports/${reportId}`, responseType: 'arraybuffer' });

export const generateTaxReport = (companyId: IdParam) => (data: GenerateTaxReportDto) =>
  fetchMiddleware<string | Blob>({ method: 'post', url: `/companies/${companyId}/tax/reports`, data, responseType: 'arraybuffer' });
