import React from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './date-range.styles.css';

type DateProps = Omit<ReactDatePickerProps, 'onChange'>;

export interface DatePickerProps extends DateProps {
  handleClose?: () => void;
  onChange: (dates: [Date, Date]) => void;
  onFieldChange?: () => void;
  onFieldError?: () => void;
  initialRange?: [Date, Date];
}

const DateRange: React.FC<DatePickerProps> = ({ onFieldChange, onFieldError, onChange, handleClose, startDate, endDate, initialRange, ...rest }) => {
  const handleSetValue = (dates: [Date, Date]) => {
    onChange(dates);
    onFieldChange && onFieldChange();
  };

  const onDateSelect = (dates: [Date, Date], event: React.SyntheticEvent<any, Event> | undefined) => {
    handleSetValue(dates);
    event?.preventDefault();
  };

  const start = initialRange ? initialRange[0] : startDate;
  const end = initialRange ? initialRange[1] : endDate;
  const minDate = new Date(2021, 0, 1);

  return (
    <DatePicker
      selectsRange
      inline
      onChange={onDateSelect}
      selected={start}
      startDate={start}
      endDate={end}
      minDate={minDate}
      onInputError={onFieldError}
      onClickOutside={handleClose}
      dateFormat={'dd/MM/yyyy'}
      peekNextMonth={true}
      dropdownMode='select'
      // showMonthYearPicker={true}
      showFourColumnMonthYearPicker={true}
      {...rest}
    />
  );
};

export default DateRange;
