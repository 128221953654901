import React from 'react';
import { useParams } from 'react-router-dom';

import AccountManagement from '../AccountManagement/AccountManagement';
import { useSubmit, useToast } from 'components/hooks';
import { postGenerator } from 'api';
import { Generator } from 'models';
import { AddSingleItem } from 'types';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  addGenerator: (args: AddSingleItem<Generator>) => void;
}

const AddGenerator: React.FC<Props> = ({ isOpen, handleClose, addGenerator }) => {
  const { success, error } = useToast();
  const { companyId } = useParams<{ companyId: string }>();

  const { onSubmit, onSubmitSuccess, onSubmitError } = useSubmit(postGenerator(companyId));
  onSubmitError(error('GeneratorModel cannot be added'));
  onSubmitSuccess((generator) => {
    addGenerator({ payload: generator });
    success(`${generator.name} has been added`);
    handleClose();
  });

  return <AccountManagement isOpen={isOpen} handleClose={handleClose} handleSubmit={onSubmit} title={'New generator'} />;
};

export default AddGenerator;
