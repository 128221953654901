import React from 'react';
import { useFormikContext } from 'formik';

import { FormField } from 'components';
import { CompanyDto } from 'api';

const radioOptions = [
  { name: 'Yes', value: true },
  { name: 'No', value: false }
];

interface Props {
  currentValue?: boolean;
}

const Restaurant: React.FC<Props> = ({ currentValue: boolean }) => {
  const { values, setFieldValue } = useFormikContext<CompanyDto>();

  return (
    <div>
      <FormField name={'restaurant'} type={'radio'} mainLabel={'Restaurant'} options={radioOptions} value={values.restaurant} />
      {values.restaurant && <FormField name={'posterToken'} type={'text'} placeholder={'Token'} label={'Token'} />}
    </div>
  );
};

export default Restaurant;
