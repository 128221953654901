import React, { useMemo } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';

import { Button, FormField, Modal, Loader } from 'components';
import { usersFields } from './users.fields';
import { useFetch, useShowContent } from 'components/hooks';
import { getCompanies, UserDto } from 'api';
import { Option } from 'types';
import { userSchema } from './user.validation';

import { Flex, SimpleWrapper } from 'style';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (values: UserDto, helpers: FormikHelpers<UserDto>) => void;
  initialValues?: UserDto;
  buttonText?: string;
  title: 'New user' | 'Edit user';
  disabled?: boolean;
}

const emptyValues: UserDto = {
  name: '',
  surname: '',
  email: '',
  role: 'control',
  companies: []
};

const UsersManagement: React.FC<Props> = ({ isOpen, handleSubmit, handleClose, initialValues, buttonText, title, disabled }) => {
  const values: UserDto = initialValues || emptyValues;

  const companiesData = useFetch(getCompanies);
  const { showLoader, showError } = useShowContent(companiesData);
  const { payload: companies } = companiesData;

  const companyOptions: Option[] | undefined = useMemo(() => {
    if (companies) {
      return companies.map(({ name, id }) => ({ name, value: id }));
    }
  }, [companies]);

  const disabledSelect = showLoader || showError;

  const closeWithReset = (resetForm: () => void) => () => {
    handleClose();
    resetForm();
  };

  return (
    <Formik initialValues={values} onSubmit={handleSubmit} enableReinitialize={true} validationSchema={userSchema}>
      {({ resetForm, isSubmitting }) => (
        <Modal isOpen={isOpen} handleClose={closeWithReset(resetForm)} title={title} dropdown={true}>
          <SimpleWrapper>
            <Form>
              {isSubmitting && <Loader height={'400px'} />}
              {!isSubmitting && (
                <Flex direction={'column'} gap={8}>
                  {usersFields(companyOptions || [], disabledSelect).map((field) => (
                    <FormField key={field.name} {...field} />
                  ))}
                </Flex>
              )}

              <Flex justify={'flex-end'} margin={'24px 0'}>
                <Button buttonType={'secondary'} onClick={handleClose}>
                  Cancel
                </Button>
                <Button type={'submit'} disabled={disabled || isSubmitting}>
                  {buttonText || 'Add'}
                </Button>
              </Flex>
            </Form>
          </SimpleWrapper>
        </Modal>
      )}
    </Formik>
  );
};

export default UsersManagement;
