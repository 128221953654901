export const employeePermissions = (can: any, cannot: any) => {
  can('read', 'all');

  /* Dashboard */
  cannot('read', 'BankCheck');
  cannot('read', 'BankCheckZnz');
  cannot('read', 'BankCheckPl');
  cannot('read', 'BankCheckCash');
  cannot('read', 'SinglePartner');
  cannot('read', 'SelfPartner');

  /* Cash Flow */
  can('read', 'CashFlow');
  can(['create', 'read'], 'Transaction');
  can('generate', 'PettyCash');
  can('read', 'TransactionCash');
  can('read', 'TransactionZnz');
  can('select', 'AllTransactionBanks');
  can('create', 'ExchangedTransaction');

  cannot('read', 'CashStatistics');
  cannot('read', 'TransactionPL');
  cannot(['update', 'delete'], 'Transaction');
  cannot('generate', 'TransactionReport');
  cannot('manage', 'CompanyLoan');

  /* Reservations */
  cannot('manage', 'Reservations');
  cannot('read', 'ReservationDetails');
  cannot('generate', 'InvoiceCash');
  cannot('generate', 'InvoiceZnz');
  cannot('generate', 'InvoicePl');
  cannot('update', 'ReservationDetails');

  /* Salaries */
  can(['create', 'read'], 'Salaries');
  can('read', 'Employee');
  can(['create', 'update'], 'EmployeeSalary');
  can(['generate', 'add'], 'Payslip');
  can('create', 'Employee');
  can('manage', 'EmployeePersonal');
  can('manage', 'EmployeeSalaryUsd');
  can('read', ['EmployeeLoan', 'EmployeeTaxes']);

  cannot(['update', 'delete'], 'Employee');
  cannot('delete', 'EmployeeSalary');
  cannot('delete', 'Salaries');
  cannot(['delete', 'create', 'update'], ['EmployeeLoan', 'EmployeeTaxes']);
  cannot('update', 'EmployeeStatus');

  /* Restaurant */
  can('read', 'RestaurantRealRevenue');

  cannot('manage', 'Restaurant');
  cannot('manage', 'RestaurantReports');
  cannot('manage', 'RestaurantDetails');

  /* Tax */
  cannot('read', 'Tax');
  cannot('read', 'TaxReports');
  cannot('generate', 'TaxReports');
  //todo: verify
  cannot('select', 'AllTaxBanks');

  /* Suppliers */
  can('manage', 'Supplier');

  cannot('read', ['SupplierDetails', 'SupplierInvoices']);

  /* Settings */
  can('manage', 'Settings'); //show in menu when read === true
  can('manage', 'TourOperators');
  can('manage', 'AccountValues');

  /* Logs */
  cannot(['manage', 'read'], 'Log');

  /* Users */
  can('read', 'User');

  cannot(['create', 'update', 'delete'], 'User');

  /* Notifications */
  cannot('manage', 'Notifications');
};
