import { useAbility } from '@casl/react';
import { AnyMongoAbility } from '@casl/ability';

import { GlobalAbilityContext } from 'permissions/global/global-permissions.context';
import { CompanyAbilityContext } from 'permissions/company/company-permissions.context';
import { CompanySubjects } from 'permissions';

interface PermissionsArguments {
  type?: 'company' | 'global';
  permissionSubject?: CompanySubjects;
}

interface PermissionsReturnData {
  globalPermissions: AnyMongoAbility;
  companyPermissions: AnyMongoAbility;
}

const usePermissions = (): PermissionsReturnData => {
  const globalPermissions = useAbility(GlobalAbilityContext);
  const companyPermissions = useAbility(CompanyAbilityContext);

  return {
    globalPermissions,
    companyPermissions
  };
};

export default usePermissions;
