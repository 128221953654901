import styled, { css } from 'styled-components';

import { Props as LoaderProps } from './Loader';

interface LoaderWrapperProps extends LoaderProps {
  isVisible?: boolean;
}

export const LoaderWrapper = styled.div<LoaderWrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ height }) => height || '100%'};
  width: 100%;

  svg {
    margin-left: 0;
  }
  ${({ accent }) =>
    accent &&
    css`
      color: transparent;
    `}
`;
