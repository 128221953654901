import React from 'react';
import { Form, Formik, FormikHelpers } from 'formik';

import { Button, Error, FormField, Loader, Modal } from 'components';
import { InvoiceDto } from 'api';
import { invoiceSchema } from './invoices.validation';
import { currencySelectItems } from 'utils';
import { InvoiceProducts } from '../../components';

import { Flex, SimpleWrapper, Paragraph } from 'style';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (values: InvoiceDto, helpers: FormikHelpers<InvoiceDto>) => void;
  initialValues?: InvoiceDto;
  buttonText?: string;
  isLoading?: boolean;
  showError?: boolean;
  title: string;
}

const emptyValues: InvoiceDto = {
  buyerAddress: '',
  buyerName: '',
  buyerTin: '',
  buyerVrn: '',
  buyerZrb: '',
  currency: 'usd',
  items: [
    {
      name: '',
      price: 0,
      quantity: 1,
      vat: 15
    }
  ],
  notes: ''
  // sellerVrn: '',
  // sellerZrb: ''
};

const InvoiceManagement: React.FC<Props> = ({ isOpen, handleClose, handleSubmit, initialValues, buttonText, showError, isLoading, title }) => {
  const values: InvoiceDto = initialValues || emptyValues;

  const closeWithReset = (resetForm: () => void) => () => {
    handleClose();
    resetForm();
  };

  const showContent = !isLoading && !showError;

  return (
    <Formik initialValues={values} onSubmit={handleSubmit} enableReinitialize validationSchema={invoiceSchema}>
      {({ resetForm }) => (
        <Modal isOpen={isOpen} handleClose={closeWithReset(resetForm)} title={title} width={'700px'}>
          <SimpleWrapper>
            <Form>
              {isLoading && <Loader />}
              {showError && <Error>Cannot fetch invoice</Error>}
              {showContent && (
                <>
                  <Flex direction={'column'} align={'stretch'} gap={8}>
                    {/*<Paragraph weight={'medium'} color={'scale1'} marginBottom={'1.6rem'}>*/}
                    {/*  Seller*/}
                    {/*</Paragraph>*/}

                    {/*<Flex margin={'0 0 3.2rem'} direction={'column'} queries={[{ mq: 'standard', direction: 'row' }]}>*/}
                    {/*  <FormField name={'sellerVrn'} label={'VRN'} placeholder={'VRN'} />*/}
                    {/*  <FormField name={'sellerZrb'} label={'ZRB'} placeholder={'ZRB'} />*/}
                    {/*</Flex>*/}

                    <Paragraph weight={'medium'} color={'scale1'} marginBottom={'1.6rem'}>
                      Buyer
                    </Paragraph>

                    <FormField name={'buyerName'} label={'Buyer name'} placeholder={'Buyer name'} />
                    <FormField name={'buyerAddress'} label={'Buyer address'} placeholder={'Buyer address'} />
                    <Flex margin={'0 0 3.2rem'} direction={'column'} queries={[{ mq: 'standard', direction: 'row' }]}>
                      <FormField name={'buyerTin'} label={'TIN'} placeholder={'TIN'} />
                      <FormField name={'buyerVrn'} label={'VRN'} placeholder={'VRN'} />
                      <FormField name={'buyerZrb'} label={'ZRB'} placeholder={'ZRB'} />
                    </Flex>

                    <Paragraph weight={'medium'} color={'scale1'} marginBottom={'1.6rem'}>
                      Invoice details
                    </Paragraph>

                    <Flex direction={'column'} align={'stretch'} margin={'0 0 3.2rem'} queries={[{ mq: 'standard', gap: 32, direction: 'row' }]}>
                      <FormField name={'currency'} type={'select'} label={'Currency'} items={currencySelectItems} />
                      <FormField name={'notes'} label={'Notes'} placeholder={'Notes'} type={'textarea'} />
                    </Flex>

                    <Paragraph weight={'medium'} color={'scale1'} marginBottom={'1.6rem'}>
                      Products
                    </Paragraph>

                    <InvoiceProducts />
                  </Flex>
                  <Flex justify={'flex-end'} margin={'24px 0'}>
                    <Button buttonType={'secondary'} onClick={closeWithReset(resetForm)}>
                      Cancel
                    </Button>
                    <Button type={'submit'}>{buttonText || 'Save'}</Button>
                  </Flex>
                </>
              )}
            </Form>
          </SimpleWrapper>
        </Modal>
      )}
    </Formik>
  );
};

export default InvoiceManagement;
