import React from 'react';
import { useParams } from 'react-router-dom';

import States from '../States/States';
import { BalanceValues, Heading } from 'components';
import { useFetch, useShowContent } from 'components/hooks';
import { getBalance } from 'api';
import { headings } from '../../utils/bank-check.utils';

import { Card } from 'style';
import { Separator } from '../../BankCheck.styles';

interface Props {
  dateRange: [Date, Date];
}

const CashBank: React.FC<Props> = ({ dateRange }) => {
  const { companyId } = useParams<{ companyId: string }>();

  const balanceData = useFetch(getBalance(companyId, 'cash', dateRange), { dependencies: [dateRange] });
  const { showContent, ...loadingStates } = useShowContent(balanceData);
  const { payload: balance } = balanceData;

  return (
    <Card>
      <Heading type={'h5'} margin={'0 0 3px 0'} weight={'bold'}>
        CASH BANK
      </Heading>
      <States {...loadingStates} />
      {showContent && balance && (
        <>
          <BalanceValues
            background={true}
            heading={headings.start}
            tzs={balance.tzs.startBalance}
            usd={balance.usd.startBalance}
            eur={balance.eur.startBalance}
          />
          <BalanceValues heading={headings.spendings} tzs={balance.tzs.spendings} usd={balance.usd.spendings} eur={balance.eur.spendings} />
          <BalanceValues heading={headings.accountOut} tzs={balance.tzs.expenseSum} usd={balance.usd.expenseSum} eur={balance.eur.expenseSum} />
          <Separator />
          <BalanceValues heading={headings.accountIn} tzs={balance.tzs.incomeSum} usd={balance.usd.incomeSum} eur={balance.eur.incomeSum} />
          <BalanceValues background={true} heading={headings.end} tzs={balance.tzs.endBalance} usd={balance.usd.endBalance} eur={balance.eur.endBalance} />
        </>
      )}
    </Card>
  );
};

export default CashBank;
