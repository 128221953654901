import React from 'react';

import { InputAlertIcon } from 'style';
import { ErrorParagraph } from './input-error.styles';

interface Props {
  message: string;
}

const InputError: React.FC<Props> = ({ message }) => {
  return (
    <ErrorParagraph>
      <InputAlertIcon />
      {message}
    </ErrorParagraph>
  );
};

export default InputError;
