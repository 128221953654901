import React from 'react';

import { Input } from 'components/index';

import { Wrapper } from './global-filter.styles';

interface Props {
  filter: any;
  setFilter: any;
}

const GlobalFilter: React.FC<Props> = ({ filter, setFilter }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };

  return (
    <Wrapper>
      <Input
        type={'text'}
        inputType={'search'}
        value={filter || ''}
        onChange={handleChange}
        placeholder={'Search'}
        searchProps={{ fullWidth: true, hideBorder: true }}
      />
    </Wrapper>
  );
};

export default GlobalFilter;
