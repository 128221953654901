import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { number, object, SchemaOf } from 'yup';

import { FormField } from 'components';
import { useFetch, useShowContent } from 'components/hooks';
import { getLocations } from 'api';
import { Option } from 'types';
import { message } from 'utils';

import { Flex } from 'style';

export const electricitySchema: SchemaOf<ElectricityValues> = object({
  locationId: number().required(message.required)
});

export type ElectricityValues = {
  locationId: number;
};

const Electricity: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();

  const electricityData = useFetch(getLocations(companyId, 'electricity'));
  const { showLoader, showNoContent } = useShowContent(electricityData);
  const { payload: electricity } = electricityData;

  const options: Option[] | undefined = useMemo(() => {
    if (electricity) {
      return electricity.map(({ name, id }) => ({ name, value: id }));
    }
  }, [electricity]);

  const placeholder = showNoContent ? 'No electricity to select' : 'Select an electricity';
  const disabled = showLoader || showNoContent;

  return (
    <Flex direction={'column'} margin={'8px 0'} gap={8}>
      <FormField name={'accountData.locationId'} type={'select'} items={options || []} width={'100%'} placeholder={placeholder} disabled={disabled} />
    </Flex>
  );
};

export default Electricity;
