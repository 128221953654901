import fetchMiddleware from 'api/api.middleware';

import { User } from 'models';
import { LoginDto, LoginResponseDto, SendResetPasswordDto, LogoutDto, ConfirmDto, ResetPasswordDto } from './api.auth.dto';

export const login = (data: LoginDto) => fetchMiddleware<LoginResponseDto>({ method: 'post', url: '/auth/login', data });

export const confirmMail = (data: ConfirmDto) => fetchMiddleware<LoginResponseDto>({ method: 'post', url: '/users/confirm-email', data });

export const sendResetPassword = (data: SendResetPasswordDto) => fetchMiddleware({ method: 'post', url: '/auth/reset-password-req', data });

export const resetPassword = (data: ResetPasswordDto) => fetchMiddleware<LoginResponseDto>({ method: 'post', url: '/auth/reset-password', data });

export const logout = (data: LogoutDto) => fetchMiddleware({ method: 'post', url: '/auth/revoke-token', data });

export const getUserData = () => fetchMiddleware<User>({ method: 'get', url: '/users/me' });
