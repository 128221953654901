import React, { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { date, number, mixed, object, SchemaOf } from 'yup';

import NewLoanCompanyForm from './new-loan-company-form';
import { FormField } from 'components/index';
import { useFetch, useShowContent } from 'components/hooks';
import { getCompanies } from 'api';
import { Company } from 'models';
import { BankValue, Option } from 'types';
import { bankOneOf, message } from 'utils';
import { CashFlowContext } from 'form/transaction/context/cash-flow.context';

import { Flex } from 'style';

export const companyLoanSchema: SchemaOf<CompanyLoanValues> = object({
  companyId: number().required(message.required).nullable(),
  endDate: date().required(message.required),
  provision: number().required(message.required),
  bankFrom: mixed().oneOf<BankValue>(bankOneOf).required(message.required)
});

export type CompanyLoanValues = {
  companyId: number;
  endDate: Date;
  provision: number;
  bankFrom: BankValue;
};

const NewLoanCompany: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const { defaultLoanData } = useContext(CashFlowContext);

  const companiesData = useFetch(getCompanies);
  const { showLoader, showError } = useShowContent(companiesData);
  const { payload: companies } = companiesData;

  const companiesOptions: Option<number>[] = useMemo(() => {
    const mapToOption = ({ id, name }: Company) => ({ name, value: id });
    const filterCurrentCompany = ({ value }: Option<number>) => value !== +companyId;

    if (companies) return companies.map(mapToOption).filter(filterCurrentCompany);
    else return [];
  }, [companies]);

  const disableSelect = showLoader || showError || !!defaultLoanData?.loanLenderId;

  return (
    <Flex direction={'column'} justify={'flex-start'} align={'flex-start'}>
      <FormField
        name={'accountData.loanData.companyId'}
        type={'select'}
        items={companiesOptions}
        placeholder={'Select company'}
        label={'Select company'}
        width={'100%'}
        disabled={disableSelect}
      />
      <NewLoanCompanyForm />
    </Flex>
  );
};

export default NewLoanCompany;
