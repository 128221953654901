import React, { useEffect, useRef, useState } from 'react';

import { DateRange } from 'components';
import { parseDate, getCurrentMonthRange } from 'utils';

import { CalendarIcon } from 'style';
import { DatePickerWrapper, Wrapper, FilterButton } from './date-filter.styles';

interface Props {
  dateRange?: [Date, Date];
  onDateChange?: (dates: [Date, Date]) => void;
  align?: 'left' | 'right';
}

const [firstMonthDay, lastMonthDay] = getCurrentMonthRange();

const DateFilter: React.FC<Props> = ({ dateRange, onDateChange, align = 'right' }) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [protrusionWidth, setProtrusionWidth] = useState<number>(0);
  const [startDate, setStartDate] = useState<Date>(firstMonthDay);
  const [endDate, setEndDate] = useState<Date>(lastMonthDay);

  const dropdownMenuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (dateRange) {
      setStartDate(dateRange[0]);
      setEndDate(dateRange[1]);
    }
  }, [dateRange]);

  useEffect(() => {
    /** Measure how much dropdown does protrude outside screen edge */
    if (dropdownMenuRef?.current) {
      const dropdownMenuXPos = dropdownMenuRef.current.getBoundingClientRect().x;
      const wrapperWidth = dropdownMenuRef.current.offsetWidth;
      const windowWidth = window.innerWidth;

      setProtrusionWidth(dropdownMenuXPos + wrapperWidth - windowWidth + 32);
    }
    /** / Measure how much dropdown does protrude outside screen edge */
  }, [isOpen]);

  const toggleOpen = () => setOpen(!isOpen);
  const handleCalendarOpen = (isOpen: boolean) => () => setOpen(isOpen);

  const onChange = (dates: [Date, Date]) => {
    const [startDate, endDate] = dates;
    setStartDate(startDate);
    setEndDate(endDate);

    if (dates[0] && dates[1]) {
      onDateChange && onDateChange(dates);
      handleCalendarOpen(false)();
    }
  };

  return (
    <Wrapper>
      <FilterButton onClick={toggleOpen} type={'button'}>
        <CalendarIcon />
        {parseDate(startDate)} - {endDate && parseDate(endDate)}
      </FilterButton>
      <DatePickerWrapper protrusionWidth={protrusionWidth} isOpen={isOpen} ref={dropdownMenuRef} align={align}>
        <DateRange {...{ startDate, endDate, onChange, handleClose: handleCalendarOpen(false) }} />
      </DatePickerWrapper>
    </Wrapper>
  );
};

export default DateFilter;
