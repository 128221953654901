import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Select } from 'components';
import { getOperators, putReservationOperator } from 'api';
import { useCall, useFetch, usePermissions, useShowContent, useToast } from 'components/hooks';
import { Option } from 'types';
import { Reservation } from 'models';

interface Props {
  reservation: Reservation;
  editable: boolean;
}

const Operators: React.FC<Props> = ({ reservation, editable }) => {
  const { success, error } = useToast();
  const { companyPermissions } = usePermissions();
  const { companyId } = useParams<{ companyId: string }>();

  const [currentOperator, setCurrentOperator] = useState<number>(reservation.tourOperatorId);

  const operatorsData = useFetch(getOperators(companyId));
  const { showContent } = useShowContent(operatorsData);
  const { payload: operators } = operatorsData;

  const { submit, onCallSuccess, onCallError, isSubmitting } = useCall(putReservationOperator(companyId, reservation.id));
  onCallError(error('Cannot update tour operator'));
  onCallSuccess(({ tourOperatorId }) => {
    success('Tour operator has been updated');
    setCurrentOperator(tourOperatorId);
  });

  const handleTourOperatorChange = async (tourOperatorId: number) => await submit({ tourOperatorId });

  const operatorsOptions: Option[] = useMemo(() => {
    if (operators) {
      return operators.map((operator) => ({ name: operator.name, value: operator.id }));
    } else {
      return [];
    }
  }, [operators]);

  const canUpdateTourOperator = companyPermissions.can('update', 'ReservationDetails');
  const disableOperatorSelect = !showContent || isSubmitting || !canUpdateTourOperator || !editable;

  return (
    <Select<number>
      items={operatorsOptions}
      onChange={handleTourOperatorChange}
      value={currentOperator}
      size={'small'}
      disabled={disableOperatorSelect}
      placeholder={'Select'}
      alignPosition={'right'}
      hideRadioButtons={true}
      largeOptions={true}
    />
  );
};

export default Operators;
