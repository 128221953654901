import fetchMiddleware from 'api/api.middleware';

import { User } from 'models';
import { IdParam } from 'types';
import { DeleteUserResponse, UserDto } from './api.users.dto';

export const getUsers = () => fetchMiddleware<User[]>({ method: 'get', url: `/users` });

export const getSingleUser = (userId: IdParam) => () => fetchMiddleware<User>({ method: 'get', url: `/users/${userId}` });

export const postUser = (data: UserDto) => fetchMiddleware<User>({ method: 'post', url: `/users`, data });

export const putUser = (userId: IdParam) => (data: UserDto) => fetchMiddleware<User>({ method: 'put', url: `/users/${userId}`, data });

export const deleteUser = (userId: IdParam) => fetchMiddleware<DeleteUserResponse>({ method: 'delete', url: `/users/${userId}`, data: {} });
