import React from 'react';

import { Flex, Paragraph } from 'style';
import { Wrapper, StyledHeading } from './page-info.styles';

interface Props {
  heading: string;
  description?: string;
}

const PageInfo: React.FC<Props> = ({ heading, description }) => {
  return (
    <Wrapper>
      <Flex>
        <StyledHeading type={'h2'} margin={'0'}>
          {heading}
        </StyledHeading>
      </Flex>
      {description && (
        <Paragraph type={'p1'} color={'scale3'}>
          {description}
        </Paragraph>
      )}
    </Wrapper>
  );
};

export default PageInfo;
