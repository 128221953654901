import React, { useState } from 'react';

import UsersTable from './components/UsersTable/UsersTable';
import { View, PageHeader, AddNewButton } from 'components';
import { usePermissions } from 'components/hooks';

import { Grid } from 'style';

const Users: React.FC = () => {
  const { globalPermissions } = usePermissions();

  const [isAddUserOpen, setAddUserOpen] = useState<boolean>(false);

  const handleAddUserOpen = (isOpen: boolean) => () => setAddUserOpen(isOpen);

  const canAddUser = globalPermissions.can('create', 'Users');
  const CustomButton = canAddUser && <AddNewButton onClick={handleAddUserOpen(true)}>Add User</AddNewButton>;

  return (
    <>
      <View>
        <PageHeader heading={'Users'} customButton={CustomButton} />
        <Grid>
          <UsersTable isAddUserOpen={isAddUserOpen} handleAddUserClose={handleAddUserOpen(false)} />
        </Grid>
      </View>
    </>
  );
};

export default Users;
