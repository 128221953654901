import React from 'react';

import { RadioOption } from 'types';

import { Paragraph } from 'style';
import { StyledRadio, RadioWrapper, Wrapper, StyledOptionsWrapper, StyledLabel } from './radio.styles';

export type Justify = 'flex-start' | 'flex-end' | 'space-between';

export interface Props {
  options: RadioOption[];
  name: string;
  onChange: (option: RadioOption) => () => void;
  value?: any;
  isVertical?: boolean;
  verticalOptions?: boolean;
  justify?: Justify;
  radioMargin?: string;
  gap?: number;
  mainLabel?: string;
  disabled?: boolean;
}

const Radio: React.FC<Props> = ({ onChange, name, value, options, justify, isVertical, radioMargin, disabled, verticalOptions, gap, mainLabel }) => {
  const showLabel = Boolean(mainLabel);
  return (
    <Wrapper isVertical={isVertical} gap={gap || 20} showLabel={showLabel} justify={justify}>
      {mainLabel && (
        <Paragraph type={'p2'} fontSize={14}>
          {mainLabel}
        </Paragraph>
      )}
      <StyledOptionsWrapper verticalOptions={verticalOptions}>
        {options.map((option, index) => {
          const isChecked = option.value === value;
          return (
            <RadioWrapper key={index} radioMargin={radioMargin} verticalOptions={verticalOptions}>
              <StyledRadio type={'radio'} onChange={onChange(option)} name={name} id={`${name}-${index}`} checked={isChecked} disabled={disabled} />
              <StyledLabel className={'no-select'} htmlFor={`${name}-${index}`}>
                {option.name}
              </StyledLabel>
            </RadioWrapper>
          );
        })}
      </StyledOptionsWrapper>
    </Wrapper>
  );
};

export default Radio;
