import React, { useMemo } from 'react';

import { paginationLogic } from './pagination.logic';

import { Flex } from 'style';
import { PageButton, ArrowLeft, ArrowRight, SideArrowLeft, SideArrowRight } from './pagination.styles';

interface Props {
  currentPageIndex: number;
  handlePageChange: (page: number) => void;
  handlePrevPage: () => void;
  canPrevPage: boolean;
  handleNextPage: () => void;
  canNextPage: boolean;
  allPages: number;
}

/**
  @props
    currentPage - page number e.g 1|2|3... (not page index)
*/

const Pagination: React.FC<Props> = ({ currentPageIndex, handlePageChange, handleNextPage, canNextPage, handlePrevPage, canPrevPage, allPages }) => {
  /* pages -> [1, ..., allPages.length] */
  const pages = [...Array(allPages).keys()].map((i) => i + 1);

  const pageNumber = useMemo(() => currentPageIndex + 1, [currentPageIndex]);

  /* displayPages -> [1] | [1,2] | [1,2,3] | [currentPage - 1, currentPage, currentPage + 1] | last 3 pages */
  const displayPages = useMemo(() => paginationLogic(pages, pageNumber), [pageNumber, pages]);

  const handleChange = (pageIndex: number) => () => handlePageChange(pageIndex);
  const handleFirstPageChange = () => handlePageChange(0);
  const handleLastPageChange = () => handlePageChange(allPages - 1);

  const disableGoToFirst = pageNumber === 1;
  const disableGoToLast = pageNumber === allPages;

  /* hide when 1st page button is no longer shown */
  const hideGoToFirst = pageNumber <= 2 || disableGoToFirst;
  /* hide when all pages are visible [1,2,3] or last page button is still visible */
  /* will be hidden when current page is second to last */
  const hideGoToLast = allPages <= 3 || pageNumber >= allPages - 1;

  return (
    <Flex gap={8}>
      {!hideGoToFirst && (
        <PageButton disabled={disableGoToFirst} onClick={handleFirstPageChange}>
          <SideArrowLeft />
        </PageButton>
      )}

      <PageButton disabled={!canPrevPage} onClick={handlePrevPage}>
        <ArrowLeft />
      </PageButton>

      {displayPages.map((page) => (
        <PageButton key={page} onClick={handleChange(page - 1)} selected={page === pageNumber}>
          {page}
        </PageButton>
      ))}
      <PageButton disabled={!canNextPage} onClick={handleNextPage}>
        <ArrowRight />
      </PageButton>

      {!hideGoToLast && (
        <PageButton disabled={disableGoToLast} onClick={handleLastPageChange}>
          <SideArrowRight />
        </PageButton>
      )}
    </Flex>
  );
};

export default Pagination;
