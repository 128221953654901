import styled from 'styled-components';
import { Form } from 'formik';

const StyledForm = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export { StyledForm };
