import React from 'react';
import { AnyMongoAbility } from '@casl/ability';
import { Column, Row } from 'react-table';

import { Button, Formatter } from 'components';
import { tableDropdown } from 'utils/table';
import { Option } from 'types';
import { RestaurantReport } from 'models';

import { Paragraph } from 'style';

export interface DropdownValue {
  type: 'delete';
  item: RestaurantReport;
}

interface DropdownArguments {
  ability: AnyMongoAbility;
  handleDropdownSelect: (value: DropdownValue) => void;
  handleReportDownload: (id: number) => void;
}

const prepareDropdownItems = (item: RestaurantReport, ability: AnyMongoAbility): Option<DropdownValue>[] => {
  const deleteItem = ability.can('delete', 'RestaurantReport') ? [{ name: 'Remove', value: { type: 'delete', item }, type: 'delete' }] : [];
  return [...deleteItem] as Option<DropdownValue>[];
};

export const prepareColumns = ({ handleDropdownSelect, ability, handleReportDownload }: DropdownArguments): Column<RestaurantReport>[] => {
  const canSeeRealRevenue = ability.can('read', 'RestaurantRealRevenue');

  return [
    {
      Header: 'Revenue',
      accessor: 'reportedRevenue',
      Cell: ({ value }: { value: number }) => <Formatter value={value} />
    },
    {
      Header: 'TAX Paid',
      accessor: 'taxPaid',
      Cell: ({ value }: { value: number }) => <Formatter value={value} />
    },
    {
      Header: 'Start date',
      accessor: 'startDate',
      Cell: ({ value }: { value: string }) => <Paragraph>{new Date(value).toLocaleDateString()}</Paragraph>
    },
    {
      Header: 'End date',
      accessor: 'endDate',
      Cell: ({ value }: { value: string }) => <Paragraph>{new Date(value).toLocaleDateString()}</Paragraph>
    },
    {
      Header: '',
      id: 'pdfDownload',
      Cell: ({ row }: { row: Row<RestaurantReport> }) => (
        <Button buttonType={'borderless'} onClick={() => handleReportDownload(row.original.id)} style={{ zIndex: 0 }}>
          Download csv
        </Button>
      )
    },
    {
      id: 'dropdown',
      width: 50,
      Cell: ({ row }: { row: Row<RestaurantReport> }) => {
        const options = prepareDropdownItems(row.original, ability);

        return tableDropdown<DropdownValue>({ options, handleDropdownSelect });
      }
    }
  ];
};
