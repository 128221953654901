import styled from 'styled-components';

import { colors } from 'style';

const Bar = styled.div<{ progress: number }>`
  width: 100%;
  position: relative;
  height: 2px;
  background-color: #ccc;
  transition: width 0.4s ease;
  margin: 2px 0;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: ${({ progress }) => `${progress}%`};
    height: 2px;
    background-color: ${colors.blue.base};
  }

  ${({ theme }) => theme.mq.standard} {
    width: 200px;
  }
`;

export { Bar };
