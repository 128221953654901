import React from 'react';
import { useFormikContext } from 'formik';

import { Button, FormField, Formatter } from 'components';
import { InvoiceDto, InvoiceItem } from 'api';
import { currencies } from 'types';

import { Flex, CloseIcon } from 'style';
import { WrapperGrid, StyledGrid, TotalValue } from './products.styles';

const InvoiceProducts = () => {
  const { values, setFieldValue } = useFormikContext<InvoiceDto>();

  const addProduct = () => {
    const emptyProduct: InvoiceItem = { name: '', quantity: 1, price: 0, vat: 15 };
    setFieldValue('items', [...values.items, emptyProduct]);
  };

  const removeProduct = (index: number) => () => {
    const products = values.items;

    products.splice(index, 1);

    setFieldValue('items', products);
  };

  const showRemoveButton = values.items.length > 1;

  return (
    <div>
      {values.items.map((product, index) => {
        const value = +product.quantity * product.price;
        const percentage = product.vat / 100;
        const totalValue = value + value * percentage;

        return (
          <Flex key={index}>
            <WrapperGrid columns={'1fr'} gap={8} margin={'12px 0'}>
              <FormField name={`items[${index}].name`} type={'text'} label={'Product or service'} placeholder={'Product or Service'} maxLength={100} />
              <StyledGrid columns={'2fr 2fr 2fr 3fr'} align={'center'}>
                <FormField name={`items[${index}].quantity`} type={'number'} label={'Quantity'} placeholder={'Quantity'} />
                <FormField name={`items[${index}].price`} type={'money'} label={'Net price'} placeholder={'Net price'} prefix={''} />
                <FormField name={`items[${index}].vat`} type={'percentage'} label={'VAT'} placeholder={'%'} prefix={''} suffix={'%'} />
                <TotalValue>
                  <Formatter type={'money'} prefix={' '} suffix={` ${currencies[values.currency]} `} value={totalValue.toFixed(2)} />
                </TotalValue>
              </StyledGrid>
            </WrapperGrid>
            {showRemoveButton && (
              <Button buttonType={'wrapper'} onClick={removeProduct(index)}>
                <CloseIcon />
              </Button>
            )}
          </Flex>
        );
      })}
      <Button onClick={addProduct}>Add more</Button>
    </div>
  );
};

export default InvoiceProducts;
