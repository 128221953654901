import { ErrorResponse, Status } from 'api/api.middleware';
import { isEmptyObject } from 'utils/functions';

interface ShowContentProps {
  loading: boolean;
  payload: any;
  error: ErrorResponse | null;
  status: Status;
  isRefreshCall: boolean;
}

interface ShowContentOptions {
  showLoaderOnRefresh?: boolean;
  key?: keyof ShowContentProps['payload'];
}

export interface ShowContentReturnInterface {
  showLoader: boolean;
  showContent: boolean;
  showNoContent: boolean;
  showError: boolean;
}

const useShowContent = (
  { loading, payload, error, status, isRefreshCall }: ShowContentProps,
  options?: ShowContentOptions
): ShowContentReturnInterface => {
  const checkPayload = (): boolean => {
    const responsePayload = payload && options?.key ? payload[options.key] : payload;
    if (responsePayload) {
      if (Array.isArray(responsePayload)) {
        return Boolean(responsePayload.length);
      }
      return !isEmptyObject(responsePayload);
    }

    return false;
  };

  const hasPayload = checkPayload();

  const showLoader = isRefreshCall ? (options?.showLoaderOnRefresh ? loading : false) : loading;
  const showContent = hasPayload && !showLoader && !error;
  const showNoContent = !hasPayload && !showLoader && !error;
  const showError = !!error && !showLoader && !status?.isCanceled;

  return {
    showLoader,
    showContent,
    showNoContent,
    showError
  };
};

export default useShowContent;
