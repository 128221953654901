import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { number, object, SchemaOf } from 'yup';

import { FormField } from 'components';
import { useFetch, useShowContent } from 'components/hooks';
import { getCompanyUsers, getReservations } from 'api';

import { message } from 'utils';

import { Flex } from 'style';

export const partnerBookingSchema: SchemaOf<PartnerBookingValues> = object({
  reservationId: number().required(message.required),
  shareholderId: number().required(message.required)
});

export interface PartnerBookingValues {
  reservationId: number;
  shareholderId: number;
}

const PartnerBooking: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();

  const reservationsData = useFetch(getReservations({ companyId, isClosed: false }));
  const { showLoader: showReservationLoader, showNoContent: showEmptyReservations } = useShowContent(reservationsData);
  const { payload: reservations } = reservationsData;

  const shareholdersData = useFetch(getCompanyUsers(companyId, 'shareholder'));
  const { showLoader: showShareholderLoader, showNoContent: showEmptyShareholders } = useShowContent(shareholdersData);
  const { payload: shareholders } = shareholdersData;

  const reservationOptions = useMemo(() => {
    if (reservations) {
      return reservations.map(({ id, guestName, startDate }) => {
        const name = `${guestName} ${new Date(startDate).toLocaleDateString()}`;
        return { name, value: id };
      });
    }
  }, [reservations]);

  const shareholdersOptions = useMemo(() => {
    if (shareholders) {
      return shareholders.map(({ id, name, surname }) => {
        const shareholderName = `${name} ${surname}`;
        return { name: shareholderName, value: id };
      });
    }
  }, [shareholders]);

  const reservationPlaceholder = showEmptyReservations ? 'No reservations to select' : 'Select a reservation';
  const reservationDisabled = showReservationLoader || showEmptyReservations;

  const shareholderPlaceholder = showEmptyShareholders ? 'No shareholders to select' : 'Select a shareholder';
  const shareholderDisabled = showShareholderLoader || showEmptyShareholders;

  return (
    <Flex direction={'column'} margin={'8px 0'} gap={8}>
      <FormField
        name={'accountData.reservationId'}
        type={'select'}
        items={reservationOptions || []}
        width={'100%'}
        placeholder={reservationPlaceholder}
        label={'Reservation'}
        disabled={reservationDisabled}
      />
      <FormField
        name={'accountData.shareholderId'}
        type={'select'}
        items={shareholdersOptions || []}
        width={'100%'}
        placeholder={shareholderPlaceholder}
        label={'Shareholder'}
        disabled={shareholderDisabled}
      />
    </Flex>
  );
};

export default PartnerBooking;
