import React from 'react';

import { headingRenderer } from './heading-renderer';

import { colors, font } from 'style';

type HeadingType = 'display' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export interface Props {
  children: React.ReactNode;
  margin?: string;
  type?: HeadingType;
  color?: keyof typeof colors.gray;
  weight?: keyof typeof font.weight;
  ellipsisWidth?: number | string;
}

/**
  @Props
    type: {
      display: 64px;
      h1: 48px;
      h2: 36px;
      h3: 24px;
      h4: 20px;
      h5: 16px;
      h6: 13px;
    },
    colors: {
      'scale1',
      'scale2',
      'scale3',
      'scale4',
    }

 @Edit
    can edit styles from styled-views
    const StyledHeading = styled(Heading)`...`;

 @Default h5
 */

const Heading: React.FC<Props> = (props) => headingRenderer(props);

export default Heading;
