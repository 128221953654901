import { RadioOption } from 'types';

import { GenerateReportDto } from 'api';

const costTypesOptions: RadioOption[] = [
  { name: 'With VRN', value: true },
  { name: 'Without VRN', value: false }
];

const costLocationOptions: RadioOption[] = [
  { name: 'Zanzibar', value: 'znz' },
  { name: 'Europe', value: 'pl' },
  { name: 'Cash', value: 'cash' }
];

const znzCostLocation: RadioOption[] = [{ name: 'Zanzibar', value: 'znz' }];

const getBankOptions = (canSelectAllBanks: boolean) => (canSelectAllBanks ? costLocationOptions : znzCostLocation);

const getInitialValues = ([startDate, endDate]: [Date | null, Date | null]): GenerateReportDto => ({
  startDate: startDate || new Date(),
  endDate: endDate || new Date(),
  vat: true,
  banks: []
});

export { costTypesOptions, getInitialValues, getBankOptions };
