import { FieldProps } from 'components';
import { currencies } from 'types';

export const additionalPayFields: FieldProps[] = [
  { name: 'overtime', type: 'money', placeholder: 'Overtime', label: 'Overtime', prefix: '', suffix: ` ${currencies['tzs']}` },
  { name: 'publicHolidays', type: 'money', placeholder: 'Public Holidays', label: 'Public Holidays', prefix: '', suffix: ` ${currencies['tzs']}` },
  { name: 'advanceRepayment', type: 'money', placeholder: 'Advance repayment', label: 'Advance repayment', prefix: '', suffix: ` ${currencies['tzs']}` },
  { name: 'repayments', type: 'money', placeholder: 'Repayments', label: 'Repayments', prefix: '', suffix: ` ${currencies['tzs']}` },
  { name: 'travelAllowance', type: 'money', placeholder: 'Travel Allowance', label: 'Travel Allowance', prefix: '', suffix: ` ${currencies['tzs']}` },
  { name: 'bonus', type: 'money', placeholder: 'Bonus', label: 'Bonus', prefix: '', suffix: ` ${currencies['tzs']}` }
];
