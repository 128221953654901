import React from 'react';
import { Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';

import { Button, FormField, Loader, View } from 'components';
import { useSubmit, useToast } from 'components/hooks';
import { sendResetPassword, SendResetPasswordDto } from 'api';
import { resetPasswordRequestSchema } from './reset-password-request.validation';

import { ForgotPassword, MainHeading, Wrapper } from 'pages/Login/login.styles';
import { Card, Flex, PositionBox } from 'style';

const initialValues: SendResetPasswordDto = {
  email: ''
};

const ResetPasswordRequest: React.FC = () => {
  const history = useHistory();
  const { success, error } = useToast();

  const redirectToLogin = () => {
    history.push('/login');
  };

  const { onSubmit, onSubmitSuccess, onSubmitError } = useSubmit(sendResetPassword);
  onSubmitError(error('Cannot send email'));
  onSubmitSuccess(() => {
    success('Message has been sent');
    history.push('/login');
  });

  return (
    <View showMenu={false} showHeader={false} noVerticalPadding={true}>
      <PositionBox position={'center'} height={'100vh'}>
        <Wrapper>
          <MainHeading>Enter your email</MainHeading>
          <Card padding={'24px 62px'}>
            <Formik<SendResetPasswordDto> initialValues={initialValues} onSubmit={onSubmit} validationSchema={resetPasswordRequestSchema}>
              {({ isSubmitting }) => (
                <Form>
                  {isSubmitting && <Loader />}
                  {!isSubmitting && (
                    <Flex direction={'column'} gap={12}>
                      <FormField name={'email'} type={'email'} placeholder={'Email'} label={'Email'} />
                      <Button fullWidth={true} type={'submit'}>
                        Save
                      </Button>
                      <Button buttonType={'wrapper'} onClick={redirectToLogin}>
                        <ForgotPassword type={'h6'}>Go back to login</ForgotPassword>
                      </Button>
                    </Flex>
                  )}
                </Form>
              )}
            </Formik>
          </Card>
        </Wrapper>
      </PositionBox>
    </View>
  );
};

export default ResetPasswordRequest;
