import React from 'react';
import { Formik, Form } from 'formik';
import { useParams } from 'react-router-dom';

import { Modal, DateFilter, FormField, Button, InputError } from 'components';
import { useSubmit, useToast, usePermissions } from 'components/hooks';
import { generateReport } from 'api';
import { downloadFile } from 'utils';
import { reportSchema } from './report.validation';
import { costTypesOptions, getInitialValues, getBankOptions } from './report.options';

import { SimpleWrapper, Flex, PositionBox } from 'style';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  dateRange: [Date | null, Date | null];
}

const GenerateReport: React.FC<Props> = ({ isOpen, handleClose, dateRange }) => {
  const { error } = useToast();
  const { companyPermissions } = usePermissions();
  const { companyId } = useParams<{ companyId: string }>();

  const { onSubmit, onSubmitSuccess, onSubmitError } = useSubmit(generateReport(companyId));
  onSubmitError(error('Failed to generate a report'));
  onSubmitSuccess((report, values, headers) => {
    const fileName = headers['x-file-name'];
    downloadFile(report, fileName);
    handleClose();
  });

  const closeWithReset = (resetForm: () => void) => () => {
    handleClose();
    resetForm();
  };

  const canSelectAllBanks = companyPermissions.can('select', 'AllTransactionBanks');

  return (
    <Formik initialValues={getInitialValues(dateRange)} onSubmit={onSubmit} validationSchema={reportSchema} enableReinitialize>
      {({ values, setFieldValue, resetForm, errors }) => {
        const handleDateChange = (range: [Date, Date]) => {
          const [startDate, endDate] = range;
          setFieldValue('startDate', startDate);
          setFieldValue('endDate', endDate);
        };
        return (
          <Modal isOpen={isOpen} handleClose={closeWithReset(resetForm)} dropdown={true} title={'Generate Report'}>
            <Form>
              <SimpleWrapper>
                <Flex direction={'column'} align={'stretch'}>
                  <Flex justify={'space-between'}>
                    <p>Time period</p>
                    <Flex>
                      <DateFilter dateRange={[values.startDate, values.endDate]} onDateChange={handleDateChange} />
                    </Flex>
                  </Flex>

                  <Flex justify={'space-between'}>
                    <p>Cost Types</p>
                    <FormField name={'vat'} type={'radio'} options={costTypesOptions} />
                  </Flex>

                  <Flex justify={'space-between'}>
                    <p>Cost Location</p>
                    <Flex wrap={'wrap'}>
                      {getBankOptions(canSelectAllBanks).map(({ name, value }) => (
                        <FormField
                          type={'checkbox'}
                          name={'banks'}
                          key={name}
                          id={name + value}
                          value={value}
                          label={name}
                          checked={values?.banks?.includes(value)}
                          multiple
                        />
                      ))}
                    </Flex>
                  </Flex>
                  {errors.banks && <InputError message={errors.banks} />}
                </Flex>

                <PositionBox position={'top-right'} margin={'24px 0 6px 0'}>
                  <Flex gap={8}>
                    <Button buttonType={'secondary'} onClick={closeWithReset(resetForm)}>
                      Cancel
                    </Button>
                    <Button type={'submit'}>Generate</Button>
                  </Flex>
                </PositionBox>
              </SimpleWrapper>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default GenerateReport;
