import React from 'react';

import { InputProps } from 'components/form/Input/Input';

import { InputWrapper, StyledInput } from './combo-input.styles';

interface Props extends InputProps {
  children: React.ReactNode;
}

const ComboInput: React.FC<Props> = ({ inputSize, children, ...props }) => {
  return (
    <InputWrapper inputSize={inputSize}>
      {children}
      <StyledInput {...props} />
    </InputWrapper>
  );
};

export default ComboInput;
