import { FieldProps } from 'components';
import { Option, RadioOption } from 'types';

const permissionOptions: RadioOption[] = [
  { name: 'Shareholder', value: 'shareholder' },
  { name: 'Employee', value: 'employee' },
  { name: 'Control', value: 'control' }
];

export const usersFields = (companyOptions: Option[], disabled: boolean): FieldProps[] => [
  { name: 'name', placeholder: 'Name', type: 'text', label: 'Name' },
  { name: 'surname', placeholder: 'Surname', type: 'text', label: 'Surname' },
  { name: 'email', placeholder: 'E-mail', type: 'email', label: 'E-mail' },
  { name: 'role', placeholder: 'Permission', type: 'select', label: 'Permission', items: permissionOptions, width: '100%' },
  { name: 'companies', placeholder: 'Companies', type: 'multiple-select', items: companyOptions, label: 'Companies', width: '100%', disabled }
];
