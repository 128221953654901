import styled from 'styled-components';

import { Heading } from 'components/index';
import { theme } from 'style';

const Wrapper = styled.div`
  width: 100%;

  ${theme.mq.standard} {
    max-width: 300px;
  }
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 0;
`;

export { Wrapper, StyledHeading };
