import styled from 'styled-components';

import { Grid, theme } from 'style';

const WrapperGrid = styled(Grid)`
  ${theme.mq.standard} {
    gap: 12px;
  }
`;

const StyledGrid = styled(Grid)`
  ${theme.mq.standard} {
    grid-template-columns: 3fr repeat(2, 2fr);
  }
`;

const TotalValue = styled.div`
  display: flex;
  align-self: flex-end;
  margin-bottom: 14px;
`;

export { WrapperGrid, StyledGrid, TotalValue };
