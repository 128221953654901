import { isObject, isEmptyObject, removeEmpty } from 'utils';

const prepareData = (data: any) => {
  const isFile = data instanceof File;

  if (isObject(data) && !isEmptyObject(data) && !isFile) {
    return JSON.stringify(data);
  } else {
    return data;
  }
};

export const mapToFormData = (obj: Record<string, any>): FormData => {
  const formData = new FormData();
  const data = removeEmpty(obj);

  Object.keys(data).forEach((key) => formData.append(key, prepareData(obj[key])));
  return formData;
};
