import React from 'react';

import TourOperators from './components/TourOperators/TourOperators';
import Accounts from './components/Accounts/Accounts';
import { PageHeader } from 'components';
import { usePermissions } from 'components/hooks';

const Settings: React.FC = () => {
  const { companyPermissions } = usePermissions();

  const showTourOperators = companyPermissions.can('read', 'TourOperators');
  const showAccounts = companyPermissions.can('read', 'AccountValues');

  return (
    <>
      <PageHeader heading={'Settings'} />
      {showTourOperators && <TourOperators />}
      {showAccounts && <Accounts />}
    </>
  );
};

export default Settings;
