import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Table, Loader, Error, Empty } from 'components';
import { useFetch, useShowContent, usePermissions, useCall, useToast } from 'components/hooks';
import { getReservations, getReservationInvoice } from 'api';
import { downloadFile } from 'utils';
import { Reservation, ReservationType } from 'models';
import { DropdownValue, prepareColumns } from './reservations.columns';
import { PAGE_ITEMS } from 'utils/table';
import { filterReservations } from '../reservations.utils';

import { Grid, Card } from 'style';

interface Props {
  dateRange: [Date, Date];
  statusFilter: ReservationType[];
  reservationsRefresh: Date;
}

const ReservationTable: React.FC<Props> = ({ dateRange, statusFilter, reservationsRefresh }) => {
  const history = useHistory();
  const { error, info } = useToast();
  const { companyPermissions } = usePermissions();
  const { companyId } = useParams<{ companyId: string }>();

  const reservationsData = useFetch(getReservations({ companyId, dateRange }), { dependencies: [dateRange, reservationsRefresh] });
  const { showContent, showLoader, showNoContent, showError } = useShowContent(reservationsData);
  const { payload: reservations } = reservationsData;

  const { submit, onCallSuccess, onCallError, onCallStart } = useCall(getReservationInvoice);
  onCallStart(() => info('Invoice is generating...'));
  onCallError(error('Failed to generate invoice'));
  onCallSuccess((payload, headers) => {
    const fileName = headers['x-file-name'];
    downloadFile(payload, fileName);
  });

  const canReadReservationDetails = companyPermissions.can('read', 'ReservationDetails');

  const handleRowClick = (row: Reservation) => () => {
    if (canReadReservationDetails) {
      history.push(`/company/${companyId}/reservations/${row.id}`);
    }
  };

  const handleDropdownSelect = async ({ type, id }: DropdownValue) => {
    if (type === 'open') {
      history.push(`/company/${companyId}/reservations/${id}`);
    }
    if (type === 'invoice-pl') {
      await submit(companyId, id, 'pl');
    }
    if (type === 'invoice-znz') {
      await submit(companyId, id, 'znz');
    }
    if (type === 'invoice-cash') {
      await submit(companyId, id, 'cash');
    }
  };

  const columns = prepareColumns({ handleDropdownSelect, ability: companyPermissions });

  const filteredReservations = reservations?.filter(filterReservations(statusFilter)) || [];

  return (
    <Grid columns={'1fr'}>
      <Card>
        {showLoader && <Loader />}
        {showNoContent && <Empty>No data</Empty>}
        {showError && <Error>Fetch error</Error>}
        {showContent && filteredReservations && (
          <Table<Reservation>
            columns={columns}
            data={filteredReservations}
            handleRowClick={handleRowClick}
            showFooter={true}
            showGlobalFilter={true}
            pageItems={PAGE_ITEMS}
          />
        )}
      </Card>
    </Grid>
  );
};

export default ReservationTable;
