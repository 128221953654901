import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { useParams } from 'react-router-dom';

import CashForm from './CashForm/CashForm';
import BankPlForm from './BankPlForm/BankPlForm';
import BankZnzForm from './BankZnzForm/BankZnzForm';
import { Button, Heading } from 'components';
import { putReservation } from 'api';
import { usePermissions, useSubmit, useToast } from 'components/hooks';
import { prepareValues } from './payment.values';
import { RadioOption } from 'types';
import { Reservation } from 'models';
import { reservationSchema } from './payment.validation';

import { ButtonFlex } from './payment.styles';
import { Card, PositionBox, SpinnerIcon } from 'style';

interface Props {
  showLoader: boolean;
  showFetchError: boolean;
  reservation: Reservation;
  refreshReservation: () => void;
  editable: boolean;
}

const Payment: React.FC<Props> = ({ reservation, refreshReservation, editable }) => {
  const { success, error } = useToast();
  const { companyPermissions } = usePermissions();
  const { companyId, reservationId } = useParams<{ companyId: string; reservationId: string }>();

  const [closeAfterSubmit, setCloseAfterSubmit] = useState<boolean>(false);

  const handleCloseAfterSubmit = (close: boolean) => () => setCloseAfterSubmit(close);

  const { onSubmit, onSubmitSuccess, onSubmitError, mapData } = useSubmit(putReservation(companyId, reservationId));
  onSubmitError(() => {
    error('Cannot update reservation')();
    setCloseAfterSubmit(false);
  });
  onSubmitSuccess(() => {
    refreshReservation();
    setCloseAfterSubmit(false);
    success('Reservation has been updated');
  });
  mapData((data) => {
    const paymentData = { ...data };

    if (closeAfterSubmit) {
      paymentData['isClosed'] = true;
    } else {
      delete paymentData.isClosed;
    }

    return paymentData;
  });

  const initialValues = prepareValues(reservation);

  const radioOptions: RadioOption[] = [
    { name: 'Yes', value: true },
    { name: 'No', value: false }
  ];

  const canEditReservation = companyPermissions.can('update', 'ReservationDetails') && editable;

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={reservationSchema} enableReinitialize={true}>
      {({ isSubmitting }) => (
        <Form>
          <Card dropdown={true}>
            <Heading>Payment details</Heading>
            <Heading>Report to ZNZ</Heading>
            <CashForm radioOptions={radioOptions} editable={editable} />
            <BankZnzForm radioOptions={radioOptions} editable={editable} />
            <Heading margin={'20px 0 0 0'}>Other</Heading>
            <BankPlForm radioOptions={radioOptions} editable={editable} />
          </Card>
          <PositionBox position={'top-right'} margin={'16px 0 0 0'}>
            <ButtonFlex>
              <Button type={'submit'} buttonType={'secondary'} onClick={handleCloseAfterSubmit(false)} disabled={!canEditReservation || isSubmitting}>
                Save
              </Button>
              <Button type={'submit'} disabled={!canEditReservation || isSubmitting} onClick={handleCloseAfterSubmit(true)}>
                {isSubmitting && <SpinnerIcon />}
                {!isSubmitting && 'Save and send'}
              </Button>
            </ButtonFlex>
          </PositionBox>
        </Form>
      )}
    </Formik>
  );
};

export default Payment;
