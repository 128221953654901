import styled, { css } from 'styled-components';

import { BadgeType } from './Badge';

import { colors, font } from 'style';

type BadgeProps = {
  type: BadgeType;
};

const Wrapper = styled.div<BadgeProps>`
  width: auto;
  display: inline-block;
  padding: 2px 6px;
  border-radius: 6px;

  p {
    color: #fff;
    text-align: center;
    font-weight: ${font.weight.medium};
  }

  ${({ type }) => {
    switch (type) {
      case 'success':
        return css`
          background-color: ${colors.background.green};
          p {
            color: ${colors.green};
          }
        `;
      case 'info':
        return css`
          background-color: ${colors.blue.base};
        `;
      case 'warning':
        return css`
          background-color: ${colors.orange};
        `;
      case 'error':
        return css`
          background-color: ${colors.red};
        `;
      case 'empty':
        return css`
          background: #fff;

          p {
            color: ${colors.gray.scale1};
          }
        `;
      case 'closed':
        return css`
          background-color: ${colors.background.red};

          p {
            color: ${colors.red};
          }
        `;
    }
  }}
`;

export { Wrapper };
