import styled from 'styled-components';

import { Grid, theme } from 'style';

const StyledGrid = styled(Grid)`
  ${theme.mq.standard} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Column = styled.div`
  width: 100%;
  position: relative;

  ${theme.mq.standard} {
    height: 100%;
  }
`;

export { StyledGrid, Column };
