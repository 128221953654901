import React, { useState } from 'react';

import InvoicesTable from './InvoicesTable/InvoicesTable';
import { AddNewButton, PageHeader } from 'components';
import { usePermissions } from 'components/hooks';

import { Grid } from 'style';

const Invoices: React.FC = () => {
  const { companyPermissions } = usePermissions();

  const [isAddInvoiceOpen, setAddInvoiceOpen] = useState<boolean>(false);

  const handleAddInvoiceOpen = (isOpen: boolean) => () => setAddInvoiceOpen(isOpen);

  const showAddInvoice = companyPermissions.can('create', 'Invoice');
  const CustomButton = showAddInvoice && <AddNewButton onClick={handleAddInvoiceOpen(true)}>Create Invoice</AddNewButton>;

  return (
    <>
      <PageHeader heading={'Invoices'} customButton={CustomButton} />
      <Grid columns={'1fr'}>
        <InvoicesTable isAddInvoiceOpen={isAddInvoiceOpen} closeAddInvoice={handleAddInvoiceOpen(false)} />
      </Grid>
    </>
  );
};

export default Invoices;
