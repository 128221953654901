import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import AccountManagement from '../AccountManagement/AccountManagement';
import { useSubmit, useFetch, useToast, useShowContent } from 'components/hooks';
import { putGenerator, getGenerator, GeneratorsDto } from 'api';
import { Generator } from 'models';
import { UpdateSingleItem } from 'types';

interface Props {
  item: Generator;
  isOpen: boolean;
  handleClose: () => void;
  updateGenerator: (args: UpdateSingleItem<Generator[]>) => void;
}

const EditGenerator: React.FC<Props> = ({ item, isOpen, handleClose, updateGenerator }) => {
  const { success, error } = useToast();
  const { companyId } = useParams<{ companyId: string }>();

  const generatorData = useFetch(getGenerator(companyId, item.id));
  const { showLoader, showError, showNoContent } = useShowContent(generatorData);
  const { payload: generator } = generatorData;

  const initialValues: GeneratorsDto | undefined = useMemo(() => {
    if (generator) {
      return { name: generator.name };
    }
  }, [generator]);

  const { onSubmit, onSubmitSuccess, onSubmitError } = useSubmit(putGenerator(companyId, item.id));
  onSubmitError(error('Cannot update generator'));
  onSubmitSuccess((generator) => {
    updateGenerator({ updatedItem: generator });
    success(`${generator.name} has been updated`);
    handleClose();
  });

  const disableEdit = showError || showNoContent;

  return (
    <AccountManagement
      isOpen={isOpen}
      handleClose={handleClose}
      handleSubmit={onSubmit}
      isLoading={showLoader}
      showError={disableEdit}
      initialValues={initialValues}
      buttonText={'Save'}
      title={'Edit generator'}
    />
  );
};

export default EditGenerator;
