import styled, { css } from 'styled-components';

import { borders, colors } from 'style';

const TableWrapper = styled.div<{ haveDropdown?: boolean }>`
  width: 100%;
  height: auto;
  overflow: ${({ haveDropdown }) => (haveDropdown ? 'visible' : 'scroll')};
`;

const TableContent = styled.table`
  width: 100%;
`;

type RowProps = {
  onClick?: () => void;
  showFooter?: boolean;
  isFooter?: boolean;
};

const TableRow = styled.tr<RowProps>`
  min-height: ${({ isFooter }) => (isFooter ? '4.8rem' : '6.4rem')};
  display: flex;
  align-items: center;
  transition: background-color 0.2s ease;
  border-bottom: ${({ showFooter, isFooter }) => (isFooter && showFooter ? 'none' : borders.default)};

  &:last-of-type {
    border-bottom: ${({ showFooter }) => !showFooter && 'none'};
  }

  &:hover {
    background-color: ${({ onClick }) => onClick && colors.background.secondary};
    cursor: ${({ onClick }) => onClick && 'pointer'};
  }
`;

export interface TableCellProps {
  alertBackground?: boolean;
  onClick?: () => void;
  noColumnBorders?: boolean;
  isDropdown?: boolean;
}

const TableCell = styled.td<TableCellProps>`
  min-height: 6.4rem;
  display: flex;
  align-items: center;
  justify-content: ${({ isDropdown }) => (isDropdown ? 'flex-end' : 'flex-start')};
  background-color: #fff;
  text-align: left;
  overflow: ${({ isDropdown }) => !isDropdown && 'scroll'};
  padding: 0.8rem 1rem;
  line-height: 24px;

  &::-webkit-scrollbar {
    height: 2px;
  }

  &:first-of-type {
    text-align: left;
    justify-content: flex-start;
    padding: 0 8px;
  }

  ${({ noColumnBorders }) =>
    noColumnBorders &&
    css`
      border-right: 0;
      border-left: 0;
    `}

  ${({ onClick }) =>
    onClick &&
    css`
      &:hover {
        cursor: pointer;
        background-color: ${colors.background.blue};
      }
    `}

  ${({ alertBackground }) =>
    alertBackground &&
    css`
      background-color: ${colors.background.red};
    `}
`;

export { TableWrapper, TableContent, TableRow, TableCell };
