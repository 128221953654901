import styled from 'styled-components';

import { colors, Paragraph } from 'style';

const StyledParagraph = styled(Paragraph)`
  color: ${colors.gray.scale3};
  margin-top: 12px;
  font-size: 14px;
`;

export { StyledParagraph };
