import styled, { css } from 'styled-components';

import { SelectSize } from './Select';

import { colors, theme, borders, shadows, CheckIcon } from 'style';

export const StyledSelectWrapper = styled.div<{ width?: string }>`
  width: ${({ width }) => width || 'auto'};
  position: relative;
`;

export const SelectInsideWrapper = styled.div`
  position: relative;
`;

interface StyledSelectProps {
  isOpen?: boolean;
  size?: SelectSize;
  disabled?: boolean;
  isItemSelected?: boolean;
  hasError?: boolean;
}

export const StyledSelect = styled.div<StyledSelectProps>`
  ${({ isOpen, size, disabled, isItemSelected, hasError }) => css`
    display: flex;
    justify-content: space-between;
    max-height: 80px;
    align-items: center;
    width: 100%;
    padding: 11px 16px;
    background: white;
    border: ${borders.default};
    box-shadow: ${shadows.default};
    border-radius: 8px;
    text-align: left;
    position: relative;
    cursor: pointer;

    svg {
      margin-left: 10px;
    }

    p {
      font-size: ${size === 'small' ? '14px' : '16px'};

      ${theme.mq.quadHdL} {
        font-size: 16px;
      }
    }

    svg,
    svg path {
      fill: ${colors.gray.scale3};
    }

    ${isOpen &&
    css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-color: transparent;

      .chevron {
        transform: rotate(180deg);
      }
    `};

    ${disabled &&
    css`
      opacity: 0.6;
      pointer-events: none;
    `};

    ${size === 'small' &&
    css`
      height: 28px;
    `};

    ${size === 'medium' &&
    css`
      height: 40px;
    `};

    ${size === 'large' &&
    css`
      height: 48px;
    `};

    ${isItemSelected &&
    css`
      p {
        color: ${colors.gray.scale2};
      }
    `}

    ${hasError &&
    css`
      border-color: ${colors.red};
    `}
  `}
`;

interface StyledSelectOptionsProps {
  isOpen?: boolean;
  alignPosition: 'left' | 'center' | 'right';
  largeOptions?: boolean;
  tableSelect?: boolean;
}

export const StyledSelectOptions = styled.ul<StyledSelectOptionsProps>`
  ${({ isOpen, alignPosition, largeOptions, tableSelect }) => css`
    bottom: 0;
    left: 0;
    list-style: none;
    position: absolute;
    outline: none;
    border: ${borders.default};
    box-shadow: ${shadows.default};
    padding: 8px 0;
    background-color: #fff;
    width: 100%;
    z-index: 1220;
    border-radius: 8px;
    cursor: pointer;
    min-width: ${largeOptions ? '300px' : '190px'};
    transform: ${tableSelect ? 'translateY(100%) translateX(-100px) !important' : 'translateY(100%)'};

    ${!isOpen &&
    css`
      display: none;
    `}

    max-height: 200px;
    overflow-y: auto;

    ${alignPosition === 'center' &&
    css`
      left: 50%;
      right: auto;
    `}

    ${alignPosition === 'right' &&
    css`
      right: 0;
      left: auto;
    `}
  `}
`;

interface StyledSelectItemProps {
  highlighted: boolean;
  isSelected?: boolean;
  hideRadioButtons?: boolean;
  disabled?: boolean;
  hideSelectedIcon?: boolean;
  type?: 'delete' | 'default';
  size?: SelectSize;
}

export const StyledSelectItem = styled.li<StyledSelectItemProps>`
  ${({ isSelected, hideRadioButtons, disabled, highlighted, hideSelectedIcon, type, size }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${hideSelectedIcon ? '0.8rem 1.6rem 0.8rem 2.2rem' : '0.8rem 1.6rem 0.8rem 4.8rem'};
    line-height: 2.4rem;
    pointer-events: ${disabled && 'none'};
    opacity: ${disabled ? 0.6 : 1};
    cursor: ${disabled && 'default'};
    transition: background 0.2s ease;

    ${size === 'small' &&
    css`
      font-size: 13px;
    `}

    svg {
      height: 1.4em;
      display: none;
    }

    &:hover {
      background-color: ${colors.background.secondary};
    }

    ${isSelected &&
    css`
      background-color: ${colors.background.blue};
      svg {
        display: inline-block;
      }
    `}

    ${type === 'delete' &&
    css`
      color: ${colors.red};

      &:hover {
        background-color: ${colors.background.red};
      }
    `}

    ${highlighted &&
    css`
      background-color: ${colors.background.blue};
    `}

    ${hideRadioButtons &&
    css`
      padding: 1rem 0.8rem;
    `};
  `}
`;

export const StyledRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${theme.mq.standard} {
    justify-content: flex-start;
    gap: 2rem;
  }
`;

export const StyledLabel = styled.label`
  margin-bottom: 8px;
  font-size: 14px;
`;

export const CustomIconWrapper = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  display: inline-block;
`;

export const SelectedItemIcon = styled(CheckIcon)`
  position: absolute;
  left: 1.6rem;

  width: 1.6rem;
  height: 1.2rem;
  path {
    fill: ${colors.blue.base};
  }
`;

export const CheckboxWrapper = styled.div`
  position: absolute;
  left: 1.2rem;
`;
