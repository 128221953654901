import { AnyMongoAbility } from '@casl/ability';

import { theme } from 'style';

export type Currency = 'PLN' | 'TZS' | 'USD' | 'EUR';
export type CurrencyValue = 'pln' | 'tzs' | 'usd' | 'eur';
export type BankValue = 'znz' | 'pl' | 'cash';

export const currencies: Record<CurrencyValue, Currency> = {
  pln: 'PLN',
  tzs: 'TZS',
  usd: 'USD',
  eur: 'EUR'
};

export type UpdateSingleItem<T, K = any> = {
  updatedItem: K;
  itemKey?: string | keyof K;
  arrayKey?: keyof T;
};

/*
  T = response data (from useFetch hook)
 */

export type DeleteSingleItem<T> = {
  itemId: number;
  itemKey?: string | keyof T;
  arrayKey?: keyof T;
};

export type AddSingleItem<T> = {
  payload: T;
  arrayKey?: string;
};

export type IdParam = number | string;
export type ObjectType = Record<string, unknown>;

export interface Option<T = any> {
  name: string;
  value?: T;
  blocked?: boolean;
  isDefault?: boolean;
  icon?: JSX.Element;
  type?: 'delete' | 'default';
  onClick?: (value: T) => void;
}

export interface RadioOption {
  name: string;
  value: any;
}

/* Dropdown value */
export interface TableDropdownArguments<T> {
  handleDropdownSelect: (value: T) => void;
}

export interface SecuredTableDropdownArguments<T> {
  handleDropdownSelect: (value: T) => void;
  ability: AnyMongoAbility;
}

export type Placement = 'top-left' | 'top-right' | 'top-center' | 'center-left' | 'center' | 'center-right' | 'bottom-left' | 'bottom-center' | 'bottom-right';

export type MediaPosition = { mq: keyof typeof theme.mq; position: Placement };

export type Justify = 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-around';
export type Align = 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline';
export type Direction = 'column' | 'row';

export type FlexMediaQueries = { mq: keyof typeof theme.mq; align?: Align; justify?: Justify; direction?: Direction; gap?: number };

export type GridMediaQueries = { mq: keyof typeof theme.mq; columns?: string; rows?: string; gap?: number };
