import React from 'react';

import InputForm, { InputProps } from './components/inputForm';
import TextareaForm, { TextareaProps } from './components/textareaForm';
import DateForm, { DatePickerProps } from './components/dateForm';
import ZipCodeForm, { ZipCodeProps } from './components/zipCodeForm';
import CheckboxForm, { CheckboxProps } from './components/checkboxForm';
import SelectForm, { SelectProps } from './components/selectForm';
import MultipleSelectForm, { MultipleSelectProps } from './components/multipleSelectForm';
import ComboboxForm, { ComboboxProps } from './components/comboboxForm';
import MultipleComboboxForm, { MultipleComboboxProps } from './components/multipleComboboxForm';
import RadioForm, { RadioProps } from './components/radioForm';
import NumberForm, { NumberProps } from './components/numberForm';
import FileForm, { FileProps } from './components/fileForm';
import ToggleForm, { ToggleProps } from './components/toggleForm';
import { InputType } from 'components/form/Input/Input';

type InputFormProps = { type?: 'text' | 'number' | 'password' | 'email' } & InputProps;
type TextareaFormProps = { type?: 'textarea' } & TextareaProps;
type DatePickerFormProps = { type?: 'date' } & DatePickerProps;
type ZipCodeFormProps = { type?: 'zipCode' } & ZipCodeProps;
type CheckboxFormProps = { type?: 'checkbox' } & CheckboxProps;
type SelectFormProps = { type?: 'select' } & SelectProps;
type MultipleSelectFormProps = { type?: 'multiple-select' } & MultipleSelectProps;
type ComboboxFormProps = { type?: 'combobox' } & ComboboxProps;
type MultipleComboboxFormProps = { type?: 'multiple-combobox' } & MultipleComboboxProps;
type RadioFormProps = { type?: 'radio' } & RadioProps;
type FileFormProps = { type?: 'file' } & FileProps;
type ToggleFormProps = { type?: 'toggle' } & ToggleProps;
type NumberFormProps = { type?: 'phone' | 'bank' | 'money' | 'percentage' | 'number-format' } & NumberProps;

export type FormFieldTypes = FormFieldProps['type'];

type FormFieldProps =
  | InputFormProps
  | TextareaFormProps
  | DatePickerFormProps
  | ZipCodeFormProps
  | CheckboxFormProps
  | SelectFormProps
  | ComboboxFormProps
  | MultipleSelectFormProps
  | MultipleComboboxFormProps
  | RadioFormProps
  | NumberFormProps
  | FileFormProps
  | ToggleFormProps;

type Props = {
  name: string;
  label?: string;
  onFieldChange?: (value?: any) => void;
  onFieldError?: () => void;
  inputType?: InputType;
  className?: string;
  withAsterisk?: boolean;
};

export type FieldProps = Props & FormFieldProps;

const FormField: React.FC<FieldProps> = ({ type = 'text', label, ...rest }) => {
  const props = { className: 'slider-cancel', ...rest };
  if (type === 'date') {
    return <DateForm {...(rest as any)} label={label} />;
  }

  if (type === 'zipCode') {
    return <ZipCodeForm {...(rest as ZipCodeFormProps)} />;
  }

  if (type === 'checkbox') {
    return <CheckboxForm {...(props as CheckboxFormProps)} label={label} />;
  }

  if (type === 'textarea') {
    return <TextareaForm {...(props as TextareaFormProps)} label={label} />;
  }

  if (type === 'select') {
    return <SelectForm {...(rest as SelectFormProps)} label={label} />;
  }

  if (type === 'multiple-select') {
    return <MultipleSelectForm {...(rest as MultipleSelectProps)} label={label} />;
  }

  if (type === 'combobox') {
    return <ComboboxForm {...(rest as ComboboxFormProps)} label={label} />;
  }

  if (type === 'multiple-combobox') {
    return <MultipleComboboxForm {...(rest as MultipleComboboxFormProps)} label={label} />;
  }

  if (type === 'file') {
    return <FileForm {...(rest as FileFormProps)} label={label} />;
  }

  if (type === 'radio') {
    return <RadioForm {...(rest as RadioProps)} />;
  }

  if (type === 'toggle') {
    return <ToggleForm {...(rest as ToggleProps)} label={label} />;
  }

  if (type === 'money' || type === 'bank' || type === 'phone' || type === 'percentage' || type === 'number-format') {
    return <NumberForm {...(rest as NumberFormProps)} label={label} type={type} />;
  }

  return <InputForm type={type} {...(props as any)} label={label} />;
};

export default FormField;
