import React, { useMemo } from 'react';

import UsersManagement from '../UsersManagement/UsersManagement';
import { useSubmit, useFetch, useShowContent, useToast } from 'components/hooks';
import { putUser, getSingleUser, UserDto } from 'api';
import { User } from 'models';
import { UpdateSingleItem } from 'types';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  item: User;
  updateEmployee: (args: UpdateSingleItem<User[]>) => void;
}

const EditUser: React.FC<Props> = ({ isOpen, handleClose, item, updateEmployee }) => {
  const { success, error } = useToast();

  const userData = useFetch(getSingleUser(item.id));
  const { showLoader, showError, showNoContent } = useShowContent(userData);
  const { payload: user } = userData;

  const initialValues: UserDto | undefined = useMemo(() => {
    if (user) {
      return {
        name: user.name,
        surname: user.surname,
        email: user.email,
        role: user?.role || 'control',
        companies: user?.companies || []
      };
    }
  }, [user]);

  const { onSubmit, onSubmitSuccess, onSubmitError } = useSubmit(putUser(item.id));
  onSubmitError(error('Cannot update user'));
  onSubmitSuccess((user) => {
    updateEmployee({ updatedItem: user });
    success(`${user.name} ${user.surname} has been updated`);
    handleClose();
  });

  const disableEdit = showError || showNoContent;

  return (
    <UsersManagement
      isOpen={isOpen}
      initialValues={initialValues}
      handleSubmit={onSubmit}
      handleClose={handleClose}
      title={'Edit user'}
      buttonText={'Save'}
      disabled={disableEdit}
    />
  );
};

export default EditUser;
