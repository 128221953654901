import React, { useState } from 'react';

import CompanyManagement from '../CompanyManagement/CompanyManagement';
import CloudbedsIntegrate from '../CloudbedsIntegrate/CloudbedsIntegrate';
import { useSubmit, useToast, useCall } from 'components/hooks';
import { postCompany, postCompanyLogo } from 'api';
import { Company } from 'models';
import { AddSingleItem } from 'types';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  addSingleCompany: (args: AddSingleItem<Company>) => void;
}

const AddCompany: React.FC<Props> = ({ isOpen, handleClose, addSingleCompany }) => {
  const { success, error } = useToast();
  const [view, setView] = useState<'form' | 'success'>('form');
  const [companyId, setCompanyId] = useState<number | null>(null);
  const [logo, setLogo] = useState<File | string | null>(null);

  const closePopup = () => {
    handleClose();
    setView('form');
  };

  const { submit: postLogo, onCallSuccess, onCallError } = useCall(postCompanyLogo);
  onCallSuccess(() => success('Logo has been added'));
  onCallError(error('Could not add the logo'));

  const handlePostLogo = async (companyId: number) => {
    if (logo && logo instanceof File) {
      await postLogo(companyId, logo);
    }
  };

  const { onSubmit, onSubmitSuccess, onSubmitError, mapData } = useSubmit(postCompany);
  mapData((company) => {
    const values = { ...company };
    delete values.logo;

    return values;
  });
  onSubmitSuccess(async (payload) => {
    addSingleCompany({ payload });
    setCompanyId(payload.id);
    success('Company has been added');
    setView('success');
    await handlePostLogo(payload.id);
  });
  onSubmitError(error('Company cannot be added'));

  return (
    <>
      {view === 'form' && <CompanyManagement isOpen={isOpen} handleSubmit={onSubmit} handleClose={handleClose} title={'New company'} setLogo={setLogo} />}
      {view === 'success' && companyId && <CloudbedsIntegrate isOpen={isOpen} handleClose={closePopup} name={'tewdf3'} type={'test'} companyId={companyId} />}
    </>
  );
};

export default AddCompany;
