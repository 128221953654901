import styled from 'styled-components';

import { Grid, theme } from 'style';

const StyledGrid = styled(Grid)`
  ${theme.mq.standard} {
    grid-template-columns: 1fr 2fr;
  }
`;

const SelectWrapper = styled.div`
  width: 100%;

  ${theme.mq.tablet} {
    width: 240px;
  }
`;

export { StyledGrid, SelectWrapper };
