import React from 'react';
import { useParams } from 'react-router-dom';

import ReportForm from './ReportForm/ReportForm';
import { useFetch, useShowContent } from 'components/hooks';
import { getRestaurantIncome } from 'api';
import { Heading, Loader, Empty, Error } from 'components';

import { Card } from 'style';

interface Props {
  dateRange: [Date, Date];
  handleReportsRefresh: () => void;
}

const RestaurantReport: React.FC<Props> = ({ dateRange, handleReportsRefresh }) => {
  const { companyId } = useParams<{ companyId: string }>();

  const restaurantIncomeData = useFetch(getRestaurantIncome(companyId, dateRange), { dependencies: [dateRange] });
  const { showContent, showNoContent, showError, showLoader } = useShowContent(restaurantIncomeData);
  const { payload: income } = restaurantIncomeData;

  return (
    <Card height={'auto'}>
      <Heading>Generate restaurant report</Heading>
      {showLoader && <Loader />}
      {showError && <Error>Failed to fetch</Error>}
      {showNoContent && <Empty>No data</Empty>}
      {showContent && income && <ReportForm income={income} dateRange={dateRange} handleReportsRefresh={handleReportsRefresh} />}
    </Card>
  );
};

export default RestaurantReport;
