import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { usePermissions } from 'components/hooks';
import { generalItems } from '../../menu.items';

import { Flex, Paragraph } from 'style';
import { NavLinkFlex, StyledParagraph, StyledSection } from '../../menu.styles';

const GeneralItems: React.FC = () => {
  const { pathname } = useLocation();
  const { companyPermissions } = usePermissions();

  return (
    <>
      <Flex margin={'16px 0 0 0'}>
        <StyledParagraph type={'p3'}>General</StyledParagraph>
      </Flex>
      <StyledSection>
        {generalItems(companyPermissions)
          .filter((route) => route.condition)
          .map((item) => (
            <NavLinkFlex key={item.name} isActive={item.path === pathname}>
              <NavLink to={item.path} activeClassName={'test'} exact={item.exact}>
                {item.icon}
                <Paragraph>{item.name}</Paragraph>
              </NavLink>
            </NavLinkFlex>
          ))}
      </StyledSection>
    </>
  );
};

export default GeneralItems;
