import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import States from './States/States';
import AddButton from '../AddButton/AddButton';
import { deleteLocation, getLocations, DeleteLocationResponseDto, LocationsNames, LocationType } from 'api';
import { DeleteModal, Heading, Table } from 'components';
import { AddLocation, EditLocation } from 'pages/Company/views/Settings/modals';
import { useCall, useDelete, useEdit, useFetch, useShowContent, useToast, usePermissions } from 'components/hooks';
import { DropdownValue, prepareColumns } from '../locations.columns';
import { Location as LocationModel } from 'models';

import { Card } from 'style';

interface Props {
  type: LocationType;
  name: LocationsNames;
}

const Location: React.FC<Props> = ({ type, name }) => {
  const { success, error } = useToast();
  const { companyPermissions } = usePermissions();
  const { companyId } = useParams<{ companyId: string }>();

  const { itemToEdit, setItemToEdit, isEditPopupOpen, closeEditPopup } = useEdit<LocationModel>();
  const { itemToDelete, setItemToDelete, isDeletePopupOpen, closeDeletePopup } = useDelete<LocationModel>();

  const [isAddLocationOpen, setAddLocationOpen] = useState<boolean>(false);

  const locationsData = useFetch(getLocations(companyId, type));
  const { showLoader, showContent, showNoContent, showError } = useShowContent(locationsData);
  const { payload: locations, addSingleItem, updateSingleItem, deleteSingleItem } = locationsData;

  const handleDeleteSuccess = ({ id }: DeleteLocationResponseDto) => {
    const message = itemToDelete ? `Location ${itemToDelete.name} has been deleted` : 'Location has been deleted';
    success(message);
    deleteSingleItem({ itemId: id });
    closeDeletePopup();
  };

  const { submit, onCallSuccess, onCallError } = useCall(deleteLocation);
  onCallError(error('Cannot delete location'));
  onCallSuccess(handleDeleteSuccess);

  const handleDeleteLocation = async () => {
    if (itemToDelete) {
      await submit(companyId, itemToDelete.id);
    }
  };

  const handleAddLocationOpen = (isOpen: boolean) => () => setAddLocationOpen(isOpen);

  const handleDropdownSelect = ({ item, type }: DropdownValue) => {
    type === 'edit' && setItemToEdit(item);
    type === 'delete' && setItemToDelete(item);
  };

  const columns = prepareColumns({ handleDropdownSelect, ability: companyPermissions });
  const canAddAccountValue = companyPermissions.can('create', 'AccountValues');
  const canEditAccountValue = companyPermissions.can('update', 'AccountValues');
  const canDeleteAccountValue = companyPermissions.can('delete', 'AccountValues');

  return (
    <>
      <Card height={'auto'}>
        <Heading margin={'8px 0 16px 0'}>{name}</Heading>
        <States showLoader={showLoader} showNoContent={showNoContent} showError={showError} />
        {showContent && locations && <Table<LocationModel> columns={columns} pageItems={5} data={locations} showHeader={false} />}
        {canAddAccountValue && <AddButton handleOpen={handleAddLocationOpen(true)} />}
      </Card>

      {canAddAccountValue && (
        <AddLocation isOpen={isAddLocationOpen} handleClose={handleAddLocationOpen(false)} addLocation={addSingleItem} locationType={type} />
      )}
      {canDeleteAccountValue && <DeleteModal isOpen={isDeletePopupOpen} handleClose={closeDeletePopup} handleDelete={handleDeleteLocation} />}
      {itemToEdit && canEditAccountValue && (
        <EditLocation item={itemToEdit} isOpen={isEditPopupOpen} handleClose={closeEditPopup} updateLocation={updateSingleItem} />
      )}
    </>
  );
};

export default Location;
