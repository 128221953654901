import React from 'react';

import { getInitials } from 'utils';

import { Icon, InitialsAvatar, IconWrapper, ImageWrapper, ImageAvatar } from './Avatar.styles';

export type AvatarSize = 'xs' | 's' | 'm' | 'l' | 'xl';

interface Props {
  size?: AvatarSize;
  notifications?: boolean;
}

interface ImageAvatarProps extends Props {
  image: string;
  userName?: never;
}

interface InitialsAvatarProps extends Props {
  image?: never;
  userName?: string;
}

type AvatarProps = ImageAvatarProps | InitialsAvatarProps;

const Avatar: React.FC<AvatarProps> = ({ size = 'm', userName, image, notifications }) => {
  const initials = userName ? getInitials(userName) : null;

  return (
    <>
      {image && (
        <ImageWrapper size={size} notifications={notifications}>
          <ImageAvatar size={size} notifications={notifications} alt={'avatar'} src={image} />
        </ImageWrapper>
      )}
      {initials && (
        <InitialsAvatar size={size} notifications={notifications}>
          {initials}
        </InitialsAvatar>
      )}
      {!image && !userName && (
        <IconWrapper size={size}>
          <Icon size={size} notifications={notifications} />{' '}
        </IconWrapper>
      )}
    </>
  );
};

export default Avatar;
