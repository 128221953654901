import React from 'react';
import { Link } from 'react-router-dom';

import { FlexWrapper, StyledBackArrow, StyledFlex } from './header.styles';

interface Props {
  children: React.ReactNode;
}

const Header: React.FC<Props> = ({ children }) => {
  return (
    <div>
      <FlexWrapper>
        <Link to={'/companies'}>
          <StyledBackArrow />
          <p>Back to companies</p>
        </Link>
      </FlexWrapper>
      <StyledFlex margin={'10px 0 0 0'}>{children}</StyledFlex>
    </div>
  );
};

export default Header;
