import React, { useState } from 'react';

type CompanyContextType = {
  companyName: string | null;
  setCompanyName: (name: string | null) => void;
};

export const CompanyContext = React.createContext<CompanyContextType>({
  companyName: null,
  setCompanyName: (name: string | null) => {}
});

interface Props {
  children: React.ReactNode;
}

const CompanyContextProvider: React.FC<Props> = ({ children }) => {
  const [companyName, setCompanyName] = useState<string | null>(null);

  return (
    <CompanyContext.Provider
      value={{
        companyName,
        setCompanyName
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

export default CompanyContextProvider;
