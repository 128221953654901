import React from 'react';
import styled from 'styled-components';
import { AnyMongoAbility } from '@casl/ability';

import { IdParam } from 'types';

import {
  AssignmentIcon,
  DashboardIcon,
  TaxIcon,
  HomeIcon,
  RestaurantIcon,
  ReservationIcon,
  SuppliersIcon,
  CashFlowIcon,
  UsersIcon,
  DirectoryIcon,
  SettingsIcon,
  NotificationIcon,
  TextSnippetIcon
} from 'style';

const StyledNotificationIcon = styled(NotificationIcon)`
  margin-left: 3px;
`;

interface MenuItem {
  name: string;
  path: string;
  icon: React.ReactNode;
  condition: boolean;
  exact?: boolean;
}

export const companyItems = (companyId: IdParam, ability: AnyMongoAbility, showRestaurant: boolean): MenuItem[] => [
  { name: 'Home', path: `/company/${companyId}`, icon: <HomeIcon />, exact: true, condition: true },
  { name: 'Cash flow', path: `/company/${companyId}/cash-flow`, icon: <CashFlowIcon />, condition: ability.can('read', 'CashFlow') },
  { name: 'Reservations', path: `/company/${companyId}/reservations`, icon: <ReservationIcon />, condition: ability.can('read', 'Reservations') },
  { name: 'Salaries', path: `/company/${companyId}/employee`, icon: <AssignmentIcon />, condition: ability.can('read', 'Salaries') },
  { name: 'Restaurant', path: `/company/${companyId}/restaurant`, icon: <RestaurantIcon />, condition: ability.can('read', 'Restaurant') && showRestaurant },
  { name: 'Tax', path: `/company/${companyId}/tax`, icon: <TaxIcon />, condition: ability.can('read', 'Tax') },
  { name: 'Invoices', path: `/company/${companyId}/invoices`, icon: <TextSnippetIcon />, condition: ability.can('read', 'CashFlow') },
  { name: 'Suppliers', path: `/company/${companyId}/supplier`, icon: <SuppliersIcon />, condition: ability.can('read', 'Supplier') },
  { name: 'Settings', path: `/company/${companyId}/settings`, icon: <SettingsIcon />, condition: ability.can('read', 'Settings') },
  {
    name: 'Notifications',
    path: `/company/${companyId}/notifications`,
    icon: <StyledNotificationIcon />,
    condition: ability.can('use', 'Notifications'),
    exact: true
  },
  { name: 'Logs', path: `/company/${companyId}/logs`, icon: <DirectoryIcon />, condition: ability.can('read', 'Log'), exact: true }
];

export const generalItems = (ability: AnyMongoAbility): MenuItem[] => {
  return [
    { name: 'Companies', path: `/companies`, icon: <DashboardIcon />, exact: true, condition: true },
    { name: 'Users', path: `/users`, icon: <UsersIcon />, exact: true, condition: ability.can('read', 'User') }
  ];
};
