import { object, string, number, array, SchemaOf, boolean, mixed } from 'yup';

import { message } from 'utils';
import { CompanyDto, Shareholder } from 'api';

export const shareholdersSchema: SchemaOf<Shareholder> = object().shape({
  userId: number().required(message.required),
  shares: number().min(0, message.min(1)).max(100, message.max(100)).required(message.required)
});

/* exclude cloudbedsProperty and posterToken -> these fields are not included in a form */
type CompanyForm = Omit<CompanyDto, 'cloudbedsPropertyId' | 'posterToken'>;

export const companySchema: SchemaOf<CompanyForm> = object().shape({
  name: string().required(message.required),
  address: string().required(message.required),
  vatId: string().required(message.required),
  zrbId: string().required(message.required),
  tinId: string().required(message.required),
  restaurant: boolean().required(message.required),
  posterToken: string(),
  logo: mixed().required(message.required),
  vfmsIntergrationId: string().optional(),
  vfmsTokenId: string().optional(),
  vfmsRestaurantIntergrationId: string(),
  vfmsRestaurantTokenId: string(),
  startBalance: object().shape({
    bankPlAmountPln: number().required(message.required).typeError(message.required),
    bankPlAmountEur: number().required(message.required).typeError(message.required),
    bankPlAmountUsd: number().required(message.required).typeError(message.required),

    bankZnzAmountTzs: number().required(message.required).typeError(message.required),
    bankZnzAmountEur: number().required(message.required).typeError(message.required),
    bankZnzAmountUsd: number().required(message.required).typeError(message.required),

    cashAmountTzs: number().required(message.required).typeError(message.required),
    cashAmountEur: number().required(message.required).typeError(message.required),
    cashAmountUsd: number().required(message.required).typeError(message.required)
  }),
  shareholders: array()
    .of(shareholdersSchema)
    .test('sum', message.sharesSummary, (rows = []) => {
      const total = rows.reduce((total, row) => total + (row.shares || 0), 0);

      return total === 100 || total === 0;
    })
});
