import React from 'react';
import { useParams } from 'react-router-dom';

import OperatorManagement from '../OperatorManagement/OperatorManagement';
import { useSubmit, useToast } from 'components/hooks';
import { postOperator } from 'api';
import { AddSingleItem } from 'types';
import { TourOperator } from 'models';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  addOperator: (args: AddSingleItem<TourOperator>) => void;
}

const AddOperator: React.FC<Props> = ({ isOpen, handleClose, addOperator }) => {
  const { success, error } = useToast();
  const { companyId } = useParams<{ companyId: string }>();

  const { onSubmit, onSubmitSuccess, onSubmitError } = useSubmit(postOperator(companyId));
  onSubmitError(error('Cannot add tour operator'));
  onSubmitSuccess((operator) => {
    addOperator({ payload: operator });
    success(`${operator.name} has been added as a Tour Operator`);
    handleClose();
  });

  return <OperatorManagement isOpen={isOpen} handleClose={handleClose} handleSubmit={onSubmit} title={'New Tour Operator'} />;
};

export default AddOperator;
