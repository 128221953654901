import { Reservation, ReservationType } from 'models';
import { Option } from 'types';

export const filterReservations = (statusFilter: ReservationType[]) => (reservation: Reservation) => {
  if (statusFilter.length === 0) {
    return true;
  } else {
    return statusFilter.includes(reservation.status);
  }
};

export const statusOptions: Option<ReservationType>[] = [
  { name: 'Confirmed', value: 'confirmed' },
  { name: 'Not confirmed', value: 'not_confirmed' },
  { name: 'Canceled', value: 'canceled' },
  { name: 'Checked in', value: 'checked_in' },
  { name: 'Checked out', value: 'checked_out' },
  { name: 'No show', value: 'no_show' }
];
