import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';

import ShowDetails from './components/ShowDetails/ShowDetails';
import InsertData from './components/InsertData/InsertData';
import { Modal } from 'components';
import { putCompanyCloudbeds } from 'api';
import { useSubmit, useToast } from 'components/hooks';
import { prepareCloudbedsUrl, prepareRedirectUri } from './cloudbeds.utils';
import { CompanyCloudbedsDto } from 'api';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  companyId: number;
  name: string;
  type: string;
}

const Wrapper = styled.div`
  width: 100%;
  white-space: initial;
`;

const initialValues: CompanyCloudbedsDto = {
  cloudbedsClientId: '',
  cloudbedsClientSecret: ''
};

type View = 'show-details' | 'insert-data';

const CloudbedsIntegrate: React.FC<Props> = ({ isOpen, handleClose, companyId, ...details }) => {
  const { error } = useToast();
  const [integrationView, setIntegrationView] = useState<View>('show-details');

  const redirectUri = prepareRedirectUri(companyId);

  const { onSubmit, onSubmitSuccess, onSubmitError, mapData } = useSubmit(putCompanyCloudbeds(companyId));
  onSubmitSuccess((_, values) => {
    const url = prepareCloudbedsUrl(values.cloudbedsClientId, companyId);
    window.open(url);
  });

  onSubmitError(error('Cannot save the data'));
  mapData((data) => ({
    ...data,
    redirectUri
  }));

  const changeView = (view: View) => () => setIntegrationView(view);

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      <Modal isOpen={isOpen} handleClose={handleClose} title={'Cloudbeds integration'} hideClose={true} width={'600px'}>
        <Wrapper>
          {integrationView === 'show-details' && (
            <ShowDetails {...details} redirectUri={prepareRedirectUri(companyId)} handleNextPage={changeView('insert-data')} />
          )}
          {integrationView === 'insert-data' && <InsertData handlePageBack={changeView('show-details')} />}
        </Wrapper>
      </Modal>
    </Formik>
  );
};

export default CloudbedsIntegrate;
