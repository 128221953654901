import React from 'react';
import { useFormikContext } from 'formik';

import { Button, FormField, Heading, Formatter } from 'components';
import { PettyCashDto } from 'api';
import { emptyProduct } from '../petty-cash-form';
import { currencies } from 'types';

import { Flex, CloseIcon } from 'style';
import { WrapperGrid, StyledGrid, TotalValue } from './products.styles';

const Products: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<PettyCashDto>();

  const addProduct = () => setFieldValue('items', [...values.items, emptyProduct]);
  const removeProduct = (index: number) => () => {
    const products = values.items;

    products.splice(index, 1);

    setFieldValue('items', products);
  };

  const showRemoveButton = values.items.length > 1;

  return (
    <div>
      <Heading type={'h5'} margin={'24px 0 0 0'}>
        Products or Services
      </Heading>
      {values.items.map((product, index) => {
        const totalValue = +product.quantity * product.amount;
        return (
          <Flex key={index}>
            <WrapperGrid columns={'1fr'} gap={1} margin={'12px 0'}>
              <FormField name={`items[${index}].name`} type={'text'} label={'Product or Service'} placeholder={'Product or Service'} maxLength={100} />
              <StyledGrid columns={'4fr 2fr'} align={'center'}>
                <FormField name={`items[${index}].quantity`} type={'number'} label={'Quantity'} placeholder={'Quantity'} />
                <FormField name={`items[${index}].amount`} type={'money'} label={'Unit price'} placeholder={'Unit price'} prefix={''} />
                <TotalValue>
                  <Formatter type={'money'} prefix={' '} suffix={`${currencies[values.currency]} `} value={totalValue.toFixed(2)} />
                </TotalValue>
              </StyledGrid>
            </WrapperGrid>
            {showRemoveButton && (
              <Button buttonType={'wrapper'} onClick={removeProduct(index)}>
                <CloseIcon />
              </Button>
            )}
          </Flex>
        );
      })}
      <Button onClick={addProduct}>Add more</Button>
    </div>
  );
};

export default Products;
