import React from 'react';

import Operators from './Operators/Operators';
import Shareholders from './Shareholders/Shareholders';
import { Heading, CardItem, Loader, Error } from 'components';
import { Reservation } from 'models';

import { Card } from 'style';

interface Props {
  showLoader: boolean;
  showFetchError: boolean;
  reservation: Reservation;
  editable: boolean;
}

const Details: React.FC<Props> = ({ showLoader, showFetchError, reservation, editable }) => {
  const showContent = !showLoader && !showFetchError;

  return (
    <Card dropdown={true} height={'auto'}>
      <Heading>Reservation details</Heading>
      {showLoader && <Loader />}
      {showFetchError && <Error>Fetch error</Error>}
      {showContent && (
        <>
          <CardItem name={'Name'} value={reservation.guestName} />
          <CardItem name={'Check-in'} value={new Date(reservation.startDate).toLocaleDateString()} />
          <CardItem name={'Check-out'} value={new Date(reservation.endDate).toLocaleDateString()} />
          <CardItem name={'Total amount'} moneyValue={reservation.amount} currency={'usd'} />
          <CardItem name={'Tour operator'}>
            <Operators reservation={reservation} editable={editable} />
          </CardItem>
          <CardItem name={'Shareholder'}>
            <Shareholders reservation={reservation} editable={editable} />
          </CardItem>
        </>
      )}
    </Card>
  );
};

export default Details;
