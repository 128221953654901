import React, { useMemo } from 'react';

import { Table, DeleteModal, Loader, Empty, Error } from 'components';
import { useFetch, useShowContent, useDelete, useEdit, usePermissions, useCall, useToast } from 'components/hooks';
import { getUsers, deleteUser } from 'api';
import { AddUser, EditUser } from 'pages/Users/modals';
import { prepareColumns, DropdownValue } from './users.columns';
import { User } from 'models';

import { Card } from 'style';

interface Props {
  isAddUserOpen: boolean;
  handleAddUserClose: () => void;
}

const UsersTable: React.FC<Props> = ({ isAddUserOpen, handleAddUserClose }) => {
  const { success, error } = useToast();
  const { globalPermissions } = usePermissions();
  const { itemToEdit, setItemToEdit, isEditPopupOpen, closeEditPopup } = useEdit<User>();
  const { itemToDelete, setItemToDelete, isDeletePopupOpen, closeDeletePopup } = useDelete<User>();

  const usersData = useFetch(getUsers);
  const { showLoader, showContent, showNoContent, showError } = useShowContent(usersData);
  const { payload: users, deleteSingleItem, updateSingleItem } = usersData;

  const visibleUsers = useMemo(() => users?.filter((user) => !user.isDeleted), [users]);

  const { submit, onCallSuccess, onCallError } = useCall(deleteUser);
  onCallError(error('Failed to delete user'));
  onCallSuccess((user) => {
    deleteSingleItem({ itemId: user.id });
    success('User has been deleted');
    closeDeletePopup();
  });

  const handleUserDelete = async () => {
    if (itemToDelete) {
      await submit(itemToDelete.id);
    }
  };

  const handleDropdownSelect = ({ item, type }: DropdownValue) => {
    type === 'delete' && setItemToDelete(item);
    type === 'edit' && setItemToEdit(item);
  };

  const columns = prepareColumns({ handleDropdownSelect, ability: globalPermissions });

  return (
    <>
      <Card>
        {showLoader && <Loader />}
        {showNoContent && <Empty>No data</Empty>}
        {showError && <Error>Failed to fetch</Error>}
        {showContent && visibleUsers && <Table<User> columns={columns} data={visibleUsers} showGlobalFilter={true} pageItems={50} />}
      </Card>
      <DeleteModal isOpen={isDeletePopupOpen} handleClose={closeDeletePopup} handleDelete={handleUserDelete} itemName={itemToDelete?.name} />
      <AddUser isOpen={isAddUserOpen} handleClose={handleAddUserClose} />
      {itemToEdit && <EditUser isOpen={isEditPopupOpen} item={itemToEdit} handleClose={closeEditPopup} updateEmployee={updateSingleItem} />}
    </>
  );
};

export default UsersTable;
