import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';
import AuthenticateContextProvider from 'context/authenticate.context';
import CompanyContextProvider from 'context/company.context';
import reportWebVitals from './reportWebVitals';

import './index.css';

ReactDOM.render(
  <Router>
    <AuthenticateContextProvider>
      <CompanyContextProvider>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </CompanyContextProvider>
    </AuthenticateContextProvider>
  </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
