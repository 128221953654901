import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Header from './components/Header/Header';
import CompanyItems from './components/CompanyItems/CompanyItems';
import GeneralItems from './components/GeneralItems/GeneralItems';
import { Button, Heading, Loader } from 'components';
import { useFetch, useShowContent } from 'components/hooks';
import { getCompany } from 'api';
import { COMPANY_STORAGE } from 'constants/auth.constants';
import { CompanyContext } from 'context/company.context';

import { CloseIcon } from 'style';
import { Wrapper, StyledPositionBox } from './menu.styles';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
}

const Menu: React.FC<Props> = ({ isOpen, handleClose }) => {
  const { setCompanyName } = useContext(CompanyContext);
  const { companyId } = useParams<{ companyId: string }>();

  const storageCompanyId = localStorage.getItem(COMPANY_STORAGE) || '0';

  const companyData = useFetch(getCompany(companyId || storageCompanyId), {
    conditions: Boolean(companyId || storageCompanyId),
    dependencies: [companyId],
    onSuccess: (company) => setCompanyName(company.name)
  });
  const { showLoader, showContent } = useShowContent(companyData);
  const { payload: company } = companyData;

  const companyName = company?.name;

  useEffect(() => {
    if (companyId) {
      companyId !== storageCompanyId && localStorage.setItem(COMPANY_STORAGE, companyId);
    }
  }, [companyId]);

  return (
    <Wrapper isOpen={isOpen}>
      <StyledPositionBox position={'top-right'}>
        <Button buttonType={'wrapper'} onClick={handleClose}>
          <CloseIcon />
        </Button>
      </StyledPositionBox>
      <Header>
        <>
          {showLoader && <Loader height={'auto'} />}
          {showContent && companyName && (
            <Heading type={'h3'} ellipsisWidth={'80%'}>
              {companyName}
            </Heading>
          )}
        </>
      </Header>
      <CompanyItems showRestaurant={Boolean(company?.posterToken)} />
      <GeneralItems />
    </Wrapper>
  );
};

export default Menu;
