import React, { PropsWithChildren, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useSelect } from 'downshift';
import { usePopperTooltip } from 'react-popper-tooltip';

import Options from './components/Options/Options';
import { InputError } from 'components';
import { itemToString } from 'utils';
import { options } from './select-hook-options';
import { Option } from 'types';

import { Paragraph, SelectorIcon, Label } from 'style';
import { StyledSelectWrapper, StyledSelect, StyledRow, CustomIconWrapper, SelectInsideWrapper } from './select.styles';

export type SelectSize = 'small' | 'medium' | 'large';

type SelectProps = Omit<React.HTMLAttributes<HTMLSelectElement>, 'onChange'>;
type Align = 'left' | 'center' | 'right';

export interface SelectElementProps<T = any> extends SelectProps {
  placeholder?: string;
  items: Option<T>[];
  onChange: (value: T) => void;
  value?: T;
  label?: string;
  size?: SelectSize;
  customButton?: React.ReactNode;
  hideRadioButtons?: boolean;
  disabled?: boolean;
  closeOnChange?: boolean;
  alignPosition?: Align;
  errorMessage?: string;
  width?: string;
  largeOptions?: boolean;
  tableSelect?: boolean;
  hideError?: boolean;
}

// T = select value type
function Select<T = any>({
  placeholder,
  items,
  onChange,
  label,
  value,
  disabled,
  hideRadioButtons,
  customButton,
  size,
  alignPosition = 'left',
  errorMessage,
  width,
  largeOptions,
  hideError,
  tableSelect,
  ...rest
}: PropsWithChildren<SelectElementProps<T>>) {
  const currentValue = items.find((item) => item.value === value);

  const itemSelected = value ? currentValue : null;

  const { isOpen, selectedItem, getToggleButtonProps, getLabelProps, getMenuProps, getItemProps, highlightedIndex, selectItem, openMenu, closeMenu } =
    useSelect({
      ...options({ items, onChange, selectedItem: itemSelected }),
      onStateChange: ({ type, isOpen, selectedItem }) => {
        switch (type) {
          case useSelect.stateChangeTypes.MenuKeyDownEnter:
          case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
          case useSelect.stateChangeTypes.ItemClick:
            if (selectedItem) {
              !value && selectItem(selectedItem);
            }
            break;
          default:
            break;
        }
      }
    });

  const { getTooltipProps, setTooltipRef, setTriggerRef } = usePopperTooltip({
    trigger: 'click',
    visible: isOpen,
    onVisibleChange: (isOpen) => (isOpen ? openMenu() : closeMenu())
  });

  const handleButtonClick = (event: MouseEvent) => {
    event.stopPropagation();
    openMenu();
  };

  const handleOptionClick = (event: React.MouseEvent<HTMLUListElement>) => event.stopPropagation();

  const itemDisplay = () => {
    if (!selectedItem) {
      return placeholder;
    }

    return itemToString(selectedItem);
  };

  useEffect(() => {
    const handleKeyDown = ({ key }: KeyboardEvent) => {
      if (key === 'Escape') closeMenu();
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => document.removeEventListener('keydown', handleKeyDown);
  }, []);

  const isItemSelected = (item: Option) => selectedItem === item;

  const hideSelectedIcon = !!customButton || hideRadioButtons;

  const portalElement = document.getElementById('dropdown');

  const Menu = (
    <Options
      largeOptions={largeOptions}
      getMenuProps={getMenuProps}
      getItemProps={getItemProps}
      isOpen={isOpen}
      items={items}
      highlightedIndex={highlightedIndex}
      handleOptionClick={handleOptionClick}
      isItemSelected={isItemSelected}
      alignPosition={alignPosition}
      hideRadioButtons={hideRadioButtons}
      hideSelectedIcon={hideSelectedIcon}
      size={size}
      tableSelect={!!customButton && tableSelect}
    />
  );

  return (
    <StyledSelectWrapper width={width}>
      {label && (
        <StyledRow>
          <Label {...getLabelProps()}>{label}</Label>
        </StyledRow>
      )}
      {customButton && (
        <CustomIconWrapper {...getToggleButtonProps({}, { suppressRefError: true })} onClick={handleButtonClick} ref={setTriggerRef}>
          {customButton}
        </CustomIconWrapper>
      )}
      <SelectInsideWrapper>
        {!customButton && (
          <StyledSelect
            {...getToggleButtonProps()}
            isOpen={isOpen}
            className='no-select'
            size={size}
            disabled={disabled}
            isItemSelected={!!selectedItem}
            hasError={!!errorMessage}
            {...rest}
          >
            {!selectedItem && <Paragraph color={'scale3'}>{placeholder}</Paragraph>}
            {selectedItem && <Paragraph color={'scale3'}>{itemDisplay()}</Paragraph>}
            <SelectorIcon className={'chevron'} />
          </StyledSelect>
        )}

        {!!customButton &&
          portalElement &&
          ReactDOM.createPortal(
            <div ref={setTooltipRef} {...getTooltipProps()}>
              {Menu}
            </div>,
            portalElement
          )}

        {!customButton && Menu}

        {errorMessage && !hideError && <InputError message={errorMessage} />}
      </SelectInsideWrapper>
    </StyledSelectWrapper>
  );
}

export default Select;
