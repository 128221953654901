import React, { useState } from 'react';

import EmployeesTable from './EmployeesTable/EmployeesTable';
import { AddNewButton, PageHeader } from 'components';
import { usePermissions } from 'components/hooks';

import { Grid } from 'style';

const Employees: React.FC = () => {
  const { companyPermissions } = usePermissions();

  const [isAddEmployeeOpen, setAddEmployeeOpen] = useState<boolean>(false);

  const handleAddEmployeeOpen = (isOpen: boolean) => () => setAddEmployeeOpen(isOpen);

  const showAddEmployee = companyPermissions.can('create', 'Employee');
  const CustomButton = showAddEmployee && <AddNewButton onClick={handleAddEmployeeOpen(true)}>Add Employee</AddNewButton>;

  return (
    <>
      <PageHeader heading={'Salaries'} customButton={CustomButton} />
      <Grid columns={'1fr'}>
        <EmployeesTable isAddEmployeeOpen={isAddEmployeeOpen} closeAddEmployee={handleAddEmployeeOpen(false)} />
      </Grid>
    </>
  );
};

export default Employees;
