import styled from 'styled-components';

import { Heading } from 'components';

import { colors, font, theme } from 'style';

const Wrapper = styled.main`
  width: 100%;

  ${theme.mq.tabletS} {
    width: 420px;
  }
`;

const MainHeading = styled.h5`
  font-weight: ${font.weight.bold};
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.125em;
  text-transform: uppercase;
  color: ${colors.blue.base};
  text-align: center;
  margin-bottom: 16px;
`;

const ForgotPassword = styled(Heading)`
  color: ${colors.blue.base};
`;

export { Wrapper, MainHeading, ForgotPassword };
