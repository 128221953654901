import React from 'react';
import { useHistory } from 'react-router-dom';

import States from './States/States';
import { Table, DeleteModal } from 'components';
import { AddCompany, EditCompany } from 'pages/Companies/modals';
import { useFetch, useDelete, useEdit, useCall, useShowContent, useToast, usePermissions } from 'components/hooks';
import { getCompanies, deleteCompany } from 'api';
import { prepareColumns, DropdownValue } from './companies-table.columns';
import { Company } from 'models';

import { Card } from 'style';

interface Props {
  isAddCompanyOpen: boolean;
  handleAddCompanyClose: () => void;
}

const CompaniesTable: React.FC<Props> = ({ isAddCompanyOpen, handleAddCompanyClose }) => {
  const history = useHistory();

  const { error, success } = useToast();
  const { globalPermissions } = usePermissions();
  const { itemToDelete, setItemToDelete, isDeletePopupOpen, closeDeletePopup } = useDelete<Company>();
  const { itemToEdit, setItemToEdit, isEditPopupOpen, closeEditPopup } = useEdit<Company>();

  const companiesData = useFetch(getCompanies);
  const { showContent, showLoader, showNoContent, showError } = useShowContent(companiesData);
  const { payload: companies, deleteSingleItem, addSingleItem, updateSingleItem } = companiesData;

  const handleDeleteSuccess = ({ id }: { id: number }) => {
    const message = itemToDelete ? `Company ${itemToDelete.name} has been deleted` : 'Company has been deleted';
    success(message);
    deleteSingleItem({ itemId: id });
    closeDeletePopup();
  };

  const { submit, onCallSuccess, onCallError } = useCall(deleteCompany);
  onCallSuccess(handleDeleteSuccess);
  onCallError(error('Cannot delete company'));

  const handleCompanyDelete = async () => {
    if (itemToDelete) {
      await submit(itemToDelete.id);
    }
  };

  const redirectToCompany = (value: Company) => () => history.push(`/company/${value.id}`);

  const handleDropdownSelect = ({ item, type }: DropdownValue) => {
    if (type === 'delete') setItemToDelete(item);
    if (type === 'edit') setItemToEdit(item);
  };

  const columns = prepareColumns({ handleDropdownSelect, ability: globalPermissions });
  const canCreateCompany = globalPermissions.can('create', 'Company');
  const canEditCompany = globalPermissions.can('update', 'Company');
  const canDeleteCompany = globalPermissions.can('delete', 'Company');

  return (
    <>
      <Card dropdown={true}>
        <States showLoader={showLoader} showError={showError} showNoContent={showNoContent} />
        {showContent && companies && (
          <Table<Company>
            columns={columns}
            data={companies}
            showHeader={false}
            handleRowClick={redirectToCompany}
            haveDropdown={true}
            showGlobalFilter={true}
            showPagination={false}
          />
        )}
      </Card>
      {canDeleteCompany && (
        <DeleteModal isOpen={isDeletePopupOpen} handleDelete={handleCompanyDelete} handleClose={closeDeletePopup} itemName={itemToDelete?.name} />
      )}
      {canCreateCompany && <AddCompany isOpen={isAddCompanyOpen} handleClose={handleAddCompanyClose} addSingleCompany={addSingleItem} />}
      {itemToEdit && canEditCompany && <EditCompany item={itemToEdit} isOpen={isEditPopupOpen} handleClose={closeEditPopup} updateCompany={updateSingleItem} />}
    </>
  );
};

export default CompaniesTable;
