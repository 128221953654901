import styled from 'styled-components';

import { TableCell, TableContent, TableRow } from 'components/ui/Table/table.styles';

const Table = styled(TableContent)`
  table-layout: auto;
`;

const Row = styled(TableRow)`
  width: 100%;
  text-align: right;

  &:first-child {
    text-align: left;
  }
`;

const LeftCell = styled(TableCell)`
  margin-left: 1rem;
  text-align: left;
  width: 40%;
  min-width: 150px;
`;

const Cell = styled(TableCell)<{ columnsNumber?: number }>`
  width: calc(60% / ${({ columnsNumber }) => columnsNumber || 4});
  min-width: ${({ columnsNumber }) => 50 * (columnsNumber || 4)}px;
  justify-content: flex-end;
`;

export { Table, Row, LeftCell, Cell };
