import React, { useState } from 'react';

import SuppliersTable from './SuppliersTable/SuppliersTable';
import { PageHeader, AddNewButton } from 'components';
import { usePermissions } from 'components/hooks';

import { Grid } from 'style';

const Suppliers: React.FC = () => {
  const { companyPermissions } = usePermissions();
  const [isAddSupplierOpen, setAddSupplierOpen] = useState<boolean>(false);

  const handleAddSupplierOpen = (isOpen: boolean) => () => setAddSupplierOpen(isOpen);

  const showAddSupplier = companyPermissions.can('create', 'Supplier');

  const CustomButton = showAddSupplier && <AddNewButton onClick={handleAddSupplierOpen(true)}>Add Supplier</AddNewButton>;

  return (
    <>
      <PageHeader heading={'Suppliers'} customButton={CustomButton} />
      <Grid columns={'1fr'}>
        <SuppliersTable isAddSupplierOpen={isAddSupplierOpen} handleAddSupplierClose={handleAddSupplierOpen(false)} />
      </Grid>
    </>
  );
};

export default Suppliers;
