import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { number, object, mixed, SchemaOf } from 'yup';

import { FormField, Select } from 'components';
import { useFetch, useShowContent } from 'components/hooks';
import { getBorrowings } from 'api';
import { Loan } from 'models';
import { Option, BankValue, CurrencyValue } from 'types';
import { CashFlowDto } from 'form/transaction/cash-flow-form/types';
import { bankSelectItems, currencySelectItems, bankOneOf } from 'utils';
import { message } from 'utils';

import { Flex, Grid } from 'style';

export const payLoanSchema: SchemaOf<PayLoanValues> = object().shape(
  {
    bankFrom: mixed()
      .oneOf<BankValue>(bankOneOf)
      .when('companyLoanId', (loanId: number | undefined) => {
        if (loanId) {
          return mixed().oneOf<BankValue>(bankOneOf).required(message.required);
        } else {
          return mixed().oneOf<BankValue>(bankOneOf).default(undefined);
        }
      }),
    companyLoanId: number().when('shareholderLoanId', (loanId: number | undefined) => {
      if (loanId) {
        return number().default(undefined);
      } else {
        return number().required();
      }
    }),
    shareholderLoanId: number().when('companyLoanId', (loanId: number | undefined) => {
      if (loanId) {
        return number().default(undefined);
      } else {
        return number().required();
      }
    })
  },
  [
    ['companyLoanId', 'shareholderLoanId'],
    ['bankFrom', 'companyLoanId']
  ]
);

export type PayLoanValues = {
  companyLoanId?: number | null;
  shareholderLoanId?: number | null;
};

type LoanValue = { id: number; type: 'company' | 'shareholder'; currency: CurrencyValue };

const PayLoan: React.FC = () => {
  const {
    setFieldValue,
    values: {
      accountData: { loanData }
    }
  } = useFormikContext<CashFlowDto>();
  const { companyId } = useParams<{ companyId: string }>();

  const loansData = useFetch(getBorrowings(companyId));
  const { showLoader, showError } = useShowContent(loansData);
  const { payload: loans } = loansData;

  const loansOptions: Option<LoanValue>[] = useMemo(() => {
    if (loans) {
      const mapLoans =
        (type: 'company' | 'shareholder') =>
        ({ amount, currency, date, id, paidAmount, name }: Loan): Option<LoanValue> => ({
          name: `${name}: ${amount - paidAmount} ${currency}, ${new Date(date).toLocaleDateString()}`,
          value: { id, type, currency }
        });
      const companyLoans: Option<LoanValue>[] = loans.companyLoans.filter(({ isPaid }) => !isPaid).map(mapLoans('company'));
      const shareholderLoans: Option<LoanValue>[] = loans.shareholderLoans.filter(({ isPaid }) => !isPaid).map(mapLoans('shareholder'));

      return [...companyLoans, ...shareholderLoans];
    } else {
      return [];
    }
  }, [loans]);

  const loanValue = (): LoanValue => {
    if (loanData?.companyLoanId) {
      return { id: loanData.companyLoanId, type: 'company', currency: loanData.currency };
    } else {
      return { id: loanData?.shareholderId, type: 'shareholder', currency: loanData.currency };
    }
  };

  const handleSelectChange = ({ id, type, currency }: LoanValue) => {
    if (type === 'company') {
      setFieldValue('accountData.loanData.companyLoanId', id);
      setFieldValue('accountData.loanData.shareholderLoanId', undefined);
    } else {
      setFieldValue('accountData.loanData.shareholderLoanId', id);
      setFieldValue('accountData.loanData.companyLoanId', undefined);
    }
    setFieldValue('currency', currency);
  };

  // @ts-ignore
  const showBankFrom = loanData && 'companyLoanId' in loanData;
  const disableSelect = showLoader || showError;

  return (
    <Flex direction={'column'}>
      <Select<LoanValue>
        value={loanValue()}
        items={loansOptions}
        onChange={handleSelectChange}
        placeholder={'Loan'}
        label={'Loan'}
        width={'100%'}
        disabled={disableSelect}
      />
      <Grid columns={'3fr 1fr'} gap={1} rowGap={1} columnGap={1}>
        <FormField name={'amount'} type={'money'} prefix={''} placeholder={'Amount'} label={'Amount'} />
        <FormField name={'currency'} type={'select'} items={currencySelectItems} disabled={true} label={'Currency'} />
      </Grid>
      {showBankFrom && (
        <FormField
          name={'accountData.loanData.bankFrom'}
          type={'select'}
          items={bankSelectItems}
          placeholder={'Bank from'}
          label={'Bank from'}
          width={'100%'}
        />
      )}
      <FormField name={'bank'} type={'select'} items={bankSelectItems} placeholder={'Paid from'} label={'Paid from'} width={'100%'} />
      <FormField name={'comment'} type={'textarea'} placeholder={'Comment'} label={'Comment'} />
    </Flex>
  );
};

export default PayLoan;
