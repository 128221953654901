import React, { useState } from 'react';

import CompaniesTable from './components/CompaniesTable/CompaniesTable';
import { PageHeader, View, AddNewButton } from 'components';

import { usePermissions } from 'components/hooks';

import { StyledGrid } from './companies.styles';

const Companies: React.FC = () => {
  const { globalPermissions } = usePermissions();

  const [isAddNewOpen, setAddNewOpen] = useState<boolean>(false);

  const handleAddCompanyOpen = (isOpen: boolean) => () => setAddNewOpen(isOpen);

  const canCreateCompany = globalPermissions.can('create', 'Company');
  const CustomButton = canCreateCompany && <AddNewButton onClick={handleAddCompanyOpen(true)}>Add new</AddNewButton>;

  return (
    <View width={'80%'} showMenu={false}>
      <PageHeader heading={'Companies'} customButton={CustomButton} padding={'0 10px'} />
      <StyledGrid columns={'1fr'} justify={'center'}>
        <CompaniesTable isAddCompanyOpen={isAddNewOpen} handleAddCompanyClose={handleAddCompanyOpen(false)} />
      </StyledGrid>
    </View>
  );
};

export default Companies;
