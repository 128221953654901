import fetchMiddleware from 'api/api.middleware';
import { mapToFormData } from 'utils';
import { Salary } from 'models';
import { IdParam } from 'types';
import { SalaryDto, DeleteSalaryResponseDto, BaseAndLoansDto, PayslipDto } from './api.salary.dto';

export const getSalaries = (companyId: IdParam, employeeId: IdParam | null) => () =>
  fetchMiddleware<Salary[]>({ method: 'get', url: `/companies/${companyId}/employees/${employeeId}/salaries` });

export const getBaseAndLoans = (companyId: IdParam, employeeId: IdParam) => () =>
  fetchMiddleware<BaseAndLoansDto>({ method: 'get', url: `/companies/${companyId}/employees/${employeeId}/salaries/base-and-loans` });

export const postSalary = (companyId: IdParam, employeeId: IdParam) => (data: SalaryDto) =>
  fetchMiddleware<string | Blob>({ method: 'post', url: `/companies/${companyId}/employees/${employeeId}/salaries`, data, responseType: 'blob' });

export const postSalaryPayslip = (companyId: IdParam, employeeId: IdParam, salaryId: IdParam) => (data: PayslipDto) => {
  const formData = mapToFormData(data);

  return fetchMiddleware({
    method: 'post',
    url: `/companies/${companyId}/employees/${employeeId}/salaries/${salaryId}/payroll`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};

export const generatePayslip = (companyId: IdParam, employeeId: IdParam, salaryId: IdParam) =>
  fetchMiddleware<string | Blob>({ method: 'get', url: `/companies/${companyId}/employees/${employeeId}/salaries/${salaryId}/payroll`, responseType: 'blob' });

export const deleteSalary = (companyId: IdParam, employeeId: IdParam, salaryId: IdParam) =>
  fetchMiddleware<DeleteSalaryResponseDto>({
    method: 'delete',
    url: `/companies/${companyId}/employees/${employeeId}/salaries/${salaryId}`,
    data: {}
  });
