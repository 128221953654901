import React from 'react';
import { useFormikContext } from 'formik';

import { FormField, Heading, Formatter } from 'components';
import { SalaryDto } from 'api';
import { currencies } from 'types';
import { additionalPayFields } from './additional-pay.fields';

import { Flex } from 'style';

const AdditionalPay: React.FC = () => {
  const {
    values: { overtime, publicHolidays, advanceRepayment, repayments, travelAllowance, bonus, baseTzs = 0, loansTzs = 0, loansUsd = 0, baseUsd = 0 }
  } = useFormikContext<SalaryDto>();

  const totalTzs = overtime + publicHolidays + advanceRepayment + repayments + travelAllowance + bonus + baseTzs - loansTzs;
  const totalUsd = baseUsd - loansUsd;

  return (
    <>
      <Heading>Additional Pay</Heading>
      <Flex direction={'column'} gap={8}>
        {additionalPayFields.map((field) => (
          <FormField key={field.name} {...field} />
        ))}
      </Flex>
      <Flex justify={'space-between'} direction={'row'} align={'flex-start'} margin={'16px 0'}>
        <Heading margin={'0'}>Total Pay</Heading>
        <Flex>
          <Formatter value={totalTzs} weight={'medium'} color={'scale1'} prefix={' '} suffix={` ${currencies['tzs']}`} />
          <Formatter value={totalUsd} weight={'medium'} color={'scale1'} prefix={' '} suffix={` ${currencies['usd']}`} />
        </Flex>
      </Flex>
    </>
  );
};

export default AdditionalPay;
