import React, { useState } from 'react';

import { CashFlowDto, LoanLender, LoanType, RepayLoanObject } from '../cash-flow-form/types';
import { PettyCashDto } from '../../../api';

export type Forms = 'cash-flow' | 'petty-cash';

type DefaultLoanData = {
  type?: LoanType;
  lender?: LoanLender | RepayLoanObject;
  loanLenderId?: number;
  loanId?: number;
};

type CashFlowContextType = {
  currentForm: Forms;
  savedFormValues: CashFlowDto | null;
  defaultLoanData: DefaultLoanData | null;
  isReinitialize: boolean;
  pettyCashValues: Partial<PettyCashDto> | null;

  setReinitialize: (reinitialize: boolean) => void;
  handleFormChange: (form: Forms) => () => void;
  handleSetValues: React.Dispatch<React.SetStateAction<CashFlowDto | null>>;
  setDefaultLoanData: (loan: DefaultLoanData | null) => void;
  disseminatePettyCashValues: React.Dispatch<React.SetStateAction<Partial<PettyCashDto> | null>>;
};

export const CashFlowContext = React.createContext<CashFlowContextType>({
  currentForm: 'cash-flow',
  savedFormValues: null,
  defaultLoanData: null,
  isReinitialize: false,
  pettyCashValues: null,

  setReinitialize: (reinitialize) => {},
  handleFormChange: (form) => () => {},
  handleSetValues: () => {},
  setDefaultLoanData: (loanData: DefaultLoanData | null) => {},
  disseminatePettyCashValues: () => {}
});

interface Props {
  children: React.ReactNode;
}

const CashFlowContextProvider: React.FC<Props> = ({ children }) => {
  const [savedFormValues, setValues] = useState<CashFlowDto | null>(null);
  const [currentForm, setCurrentForm] = useState<Forms>('cash-flow');
  const [defaultLoanData, setDefaultLoanData] = useState<DefaultLoanData | null>(null);
  const [isReinitialize, setReinitialize] = useState<boolean>(false);
  const [pettyCashValues, setPettyCashValues] = useState<Partial<PettyCashDto> | null>(null);

  const handleFormChange = (form: Forms) => () => setCurrentForm(form);

  return (
    <CashFlowContext.Provider
      value={{
        currentForm,
        savedFormValues,
        defaultLoanData,
        handleFormChange,
        handleSetValues: setValues,
        disseminatePettyCashValues: setPettyCashValues,
        pettyCashValues,
        setDefaultLoanData,
        isReinitialize,
        setReinitialize
      }}
    >
      {children}
    </CashFlowContext.Provider>
  );
};

export default CashFlowContextProvider;
