import { number, object, boolean, SchemaOf, mixed } from 'yup';

import { NotificationDto, NotificationsState } from 'api';
import { CurrencyValue } from 'types';
import { currenciesOneOf, message } from 'utils';

const amountNotificationSchema: SchemaOf<NotificationDto> = object().shape({
  isActive: boolean().required(message.required),
  value: number().when('isActive', {
    is: true,
    then: number().required(message.required),
    otherwise: number().nullable(true)
  }),
  currency: mixed().when('isActive', {
    is: true,
    then: mixed().oneOf<CurrencyValue>(currenciesOneOf).required(message.currency),
    otherwise: mixed()
      .oneOf<CurrencyValue>([null, ...currenciesOneOf])
      .nullable(true)
  })
});

const booleanNotificationSchema: SchemaOf<NotificationDto> = object().shape({
  isActive: boolean().required(message.required),
  value: number().nullable(true),
  currency: mixed()
    .oneOf<CurrencyValue>([null, ...currenciesOneOf])
    .nullable(true)
});

export const validationSchema: SchemaOf<NotificationsState> = object().shape({
  notifications: object().shape({
    expenseMoreThan: amountNotificationSchema,
    incomeMoreThan: amountNotificationSchema,
    salaryMoreThan: amountNotificationSchema,
    entityAdded: booleanNotificationSchema,
    entityDeleted: booleanNotificationSchema,
    loanPaid: booleanNotificationSchema
  })
});
