import { object, number, string, array, mixed, SchemaOf } from 'yup';

import { currenciesOneOf } from 'utils';
import { InvoiceDto } from 'api';
import { CurrencyValue } from 'types';

export const invoiceSchema: SchemaOf<InvoiceDto> = object().shape({
  currency: mixed().oneOf<CurrencyValue>(currenciesOneOf),
  buyerName: string().required('Buyer name is required'),
  buyerAddress: string().required('Buyer address is required'),
  buyerTin: string().required('Buyer TIN is required'),
  buyerVrn: string(),
  buyerZrb: string(),
  notes: string(),
  items: array().of(
    object({
      name: string().required('Name is required'),
      price: number().min(0.01, 'Price must be equal or more than 0.01').required('Price is required'),
      quantity: number().min(1, 'Quantity must be equal or more than 1').required('Quantity is required'),
      vat: number().min(0, 'VAT must be positive').max(99, 'Maxiumum is 99%').required('VAT is required')
    })
  )
});
