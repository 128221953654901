import styled from 'styled-components';

import { Flex, Grid, theme } from 'style';

const FormWrapper = styled.section`
  margin-top: 26px;

  &:first-child {
    margin-top: 0;
  }

  ${theme.mq.standard} {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

const StyledGrid = styled(Grid)`
  width: 100%;
  grid-template-columns: 2fr 2fr;
  align-items: center;
  justify-content: flex-end;

  ${theme.mq.tablet} {
    grid-template-columns: 2fr 1fr auto;
  }
`;

const ButtonFlex = styled(Flex)`
  width: 100%;
  flex-direction: column;

  button {
    width: 100%;
    justify-content: center;
  }

  ${theme.mq.mobileL} {
    flex-direction: row;
    justify-content: flex-end;

    button {
      width: auto;
      justify-content: flex-start;
    }
  }
`;

const ParagraphWrapper = styled.div`
  width: 60px;
`;

export { FormWrapper, ButtonFlex, StyledGrid, ParagraphWrapper };
