import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import States from './States/States';
import { Table, DeleteModal } from 'components';
import { useFetch, useShowContent, useCall, useDelete, useEdit, useToast, usePermissions } from 'components/hooks';
import { AddSupplier, EditSupplier } from 'pages/Company/views/Supplier/modals';
import { getSuppliers, deleteSupplier, DeleteSupplierResponseDto } from 'api';
import { prepareColumns, DropdownValue } from './suppliers.columns';
import { Supplier } from 'models';
import { PAGE_ITEMS } from 'utils/table';

import { Card } from 'style';

interface Props {
  isAddSupplierOpen: boolean;
  handleAddSupplierClose: () => void;
}

const SuppliersTable: React.FC<Props> = ({ isAddSupplierOpen, handleAddSupplierClose }) => {
  const history = useHistory();

  const { success, error } = useToast();
  const { companyPermissions } = usePermissions();
  const { companyId } = useParams<{ companyId: string }>();
  const { itemToDelete, setItemToDelete, isDeletePopupOpen, closeDeletePopup } = useDelete<Supplier>();
  const { itemToEdit, setItemToEdit, isEditPopupOpen, closeEditPopup } = useEdit<Supplier>();

  const suppliersData = useFetch(getSuppliers(companyId));
  const { showLoader, showContent, showNoContent, showError } = useShowContent(suppliersData);
  const { payload: suppliers, addSingleItem, updateSingleItem, deleteSingleItem } = suppliersData;

  const handleDeleteSuccess = ({ id }: DeleteSupplierResponseDto) => {
    deleteSingleItem({ itemId: id });
    success('Supplier has been deleted');
    closeDeletePopup();
  };

  const { submit, onCallSuccess, onCallError } = useCall(deleteSupplier);
  onCallError(error('Supplier cannot be deleted'));
  onCallSuccess(handleDeleteSuccess);

  const handleSupplierDelete = async () => {
    if (itemToDelete) {
      await submit(companyId, itemToDelete.id);
    }
  };

  const handleRowClick = (value: Supplier) => () => {
    if (!companyPermissions.can('read', 'SupplierDetails')) return;

    history.push(`/company/${companyId}/supplier/${value.id}`);
  };
  const handleDropdownSelect = ({ item, type }: DropdownValue) => {
    type === 'delete' && setItemToDelete(item);
    type === 'edit' && setItemToEdit(item);
  };

  const columns = prepareColumns({ handleDropdownSelect, ability: companyPermissions });
  const showAddSupplier = companyPermissions.can('create', 'Supplier');
  const showEditSupplier = companyPermissions.can('update', 'Supplier');
  const showDeleteSupplier = companyPermissions.can('delete', 'Supplier');

  return (
    <>
      <Card>
        <States showLoader={showLoader} showNoContent={showNoContent} showError={showError} />
        {showContent && suppliers && (
          <Table<Supplier>
            columns={columns}
            data={suppliers}
            handleRowClick={handleRowClick}
            showColumnsFilter={true}
            showGlobalFilter={true}
            pageItems={PAGE_ITEMS}
          />
        )}
      </Card>

      {showAddSupplier && <AddSupplier isOpen={isAddSupplierOpen} handleClose={handleAddSupplierClose} addSupplier={addSingleItem} />}
      {showDeleteSupplier && (
        <DeleteModal isOpen={isDeletePopupOpen} handleClose={closeDeletePopup} handleDelete={handleSupplierDelete} itemName={itemToDelete?.name} />
      )}
      {itemToEdit && showEditSupplier && (
        <EditSupplier item={itemToEdit} isOpen={isEditPopupOpen} handleClose={closeEditPopup} updateSupplier={updateSingleItem} />
      )}
    </>
  );
};

export default SuppliersTable;
