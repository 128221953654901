import React from 'react';
import { FormikHelpers } from 'formik';

import TransactionContent from './transaction-content';
import CashFlowContextProvider from './context/cash-flow.context';
import { CashFlowDto, LoanLender, LoanType, RepayLoanObject } from 'form/transaction/cash-flow-form/types';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (values: CashFlowDto, helpers: FormikHelpers<CashFlowDto>) => void;
  initialValues?: CashFlowDto;
  isEdit?: boolean;
  transactionId?: number;
  progress?: number;

  type?: LoanType;
  lender?: LoanLender | RepayLoanObject;
  loanLenderId?: number;
}

const Transaction: React.FC<Props> = ({ ...props }) => {
  return <CashFlowContextProvider>{props.isOpen && <TransactionContent {...props} />}</CashFlowContextProvider>;
};

export default Transaction;
