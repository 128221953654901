import fetchMiddleware from 'api/api.middleware';
import { IdParam } from 'types';
import { GetLogsDto } from './api.logs.dto';

export const getLogs = (companyId: IdParam, query: string, pageIndex: number, pageSize: number) => () =>
  fetchMiddleware<GetLogsDto>({ method: 'get', url: `/companies/${companyId}/logs`, query: { query, page: pageIndex, pageSize } });

export const deleteLog = (companyId: IdParam, logId: IdParam) =>
  fetchMiddleware<{ id: number }>({
    method: 'delete',
    url: `/companies/${companyId}/logs/${logId}`,
    data: {}
  });
