import fetchMiddleware from 'api/api.middleware';
import { IdParam } from 'types';
import { Car } from 'models';
import { CarDto, DeleteCarResponseDto } from './api.cars.dto';

export const getCars = (companyId: IdParam) => () => fetchMiddleware<Car[]>({ method: 'get', url: `/companies/${companyId}/cars` });

export const getCar = (companyId: IdParam, carId: IdParam) => () => fetchMiddleware<Car>({ method: 'get', url: `/companies/${companyId}/cars/${carId}` });

export const postCar = (companyId: IdParam) => (data: CarDto) => fetchMiddleware<Car>({ method: 'post', url: `/companies/${companyId}/cars`, data });

export const putCar = (companyId: IdParam, carId: IdParam) => (data: CarDto) =>
  fetchMiddleware<Car>({ method: 'put', url: `/companies/${companyId}/cars/${carId}`, data });

export const deleteCar = (companyId: IdParam, carId: IdParam) =>
  fetchMiddleware<DeleteCarResponseDto>({ method: 'delete', url: `/companies/${companyId}/cars/${carId}`, data: {} });
