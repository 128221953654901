import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Table, Pagination, Loader, Error, Empty } from 'components';
import { useFetch, useShowContent } from 'components/hooks';
import { getLogs } from 'api/logs/api.logs';
import { Log } from 'models';
import { columns } from './logs.columns';

import { Card } from 'style';

export const PAGE_SIZE = 50;

interface Props {
  searchQuery: string;
}

const LogsTable: React.FC<Props> = ({ searchQuery }) => {
  const { companyId } = useParams<{ companyId: string }>();
  const [pageIndex, setPageIndex] = useState<number>(0);

  const logsData = useFetch(getLogs(companyId, searchQuery, pageIndex, PAGE_SIZE), { dependencies: [searchQuery, pageIndex] });
  const { showContent, showLoader, showNoContent, showError } = useShowContent(logsData, { key: 'results' });
  const { payload: logs } = logsData;

  const handlePageChange = (pageIndex: number) => setPageIndex(pageIndex);
  const handleNextPage = () => setPageIndex((prevPage) => prevPage + 1);
  const handlePrevPage = () => setPageIndex((prevPage) => prevPage - 1);

  const allPages = useMemo(() => {
    if (logs) {
      return Math.ceil(logs.total / PAGE_SIZE);
    } else {
      return 0;
    }
  }, [logs]);

  const canPrevPage = pageIndex - 1 >= 0;
  const canNextPage = pageIndex + 1 <= allPages - 1;

  return (
    <Card>
      {showLoader && <Loader />}
      {showNoContent && <Empty>No data</Empty>}
      {showError && <Error>Failed to fetch</Error>}
      {showContent && logs && (
        <>
          <Table<Log> columns={columns} data={logs.results} alignCellsLeft={true} showPagination={false} />
          <Pagination
            currentPageIndex={pageIndex}
            handlePageChange={handlePageChange}
            handleNextPage={handleNextPage}
            handlePrevPage={handlePrevPage}
            canPrevPage={canPrevPage}
            canNextPage={canNextPage}
            allPages={allPages}
          />
        </>
      )}
    </Card>
  );
};

export default LogsTable;
