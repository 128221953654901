import React from 'react';
import { Column } from 'react-table';

import { Formatter } from 'components';
import { displayAmount } from 'utils';
import { User } from 'models';

import { Paragraph } from 'style';

export const columns: Column<User>[] = [
  {
    accessor: 'name',
    Header: 'Name',
    Cell: ({ value: name, row }: { value: string; row: any }) => {
      const surname = row.original.surname;
      const shareholderName = `${name} ${surname}`;
      return <Paragraph weight={'medium'}>{shareholderName}</Paragraph>;
    }
  },
  {
    Header: 'Shares',
    accessor: 'shares',
    Cell: ({ value }: { value: number }) => <Formatter value={value} prefix={' '} suffix={'%'} />
  },
  {
    Header: 'Balance',
    accessor: 'balance',
    Cell: ({ value }: { value: string }) => <Formatter {...displayAmount(value, 'usd')} />
  }
];
