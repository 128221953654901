import React from 'react';

import { Flex, Paragraph } from 'style';
import { Bar } from './progress-bar.styles';

interface Props {
  progress: number;
}

const ProgressBar: React.FC<Props> = ({ progress }) => {
  const integerProgress = Math.trunc(progress);
  return (
    <Flex width={'100%'} justify={'space-between'}>
      <Bar progress={progress} />
      <Paragraph>{integerProgress}%</Paragraph>
    </Flex>
  );
};

export default ProgressBar;
