import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';

type QueryParam = string | number | (string | number)[];

type QueryParams = Record<string, QueryParam>;

export interface QueryReturn {
  query: QueryParams;
  search: string;
  setQuery: (key: string, value: QueryParam) => void;
  setQueries: (value: QueryParams) => void;
  removeQuery: (key: string) => void;
  resetQueries: () => void;
  removeQueries: (keys: string[]) => void;
}

const options = {
  skipNull: true,
  skipEmptyString: true
};

//T = expected query object
function useQuery(): QueryReturn {
  const history = useHistory();
  const location = useLocation();

  const { search } = location;
  const query = queryString.parse(search) as QueryParams;

  const setQuery = (key: string, value: QueryParam) => {
    const prevQuery = { ...query };
    prevQuery[key] = value;

    const queries = queryString.stringify(prevQuery, options);

    history.replace({ search: queries });
  };

  const setQueries = (value: QueryParams) => {
    const queries = queryString.stringify(value, options);

    history.replace({ search: queries });
  };

  const resetQueries = () => {
    const queries = queryString.stringify({}, options);

    history.replace({ search: queries });
  };

  const removeQuery = (key: string) => {
    const prevQuery = { ...query };
    delete prevQuery[key];

    const queries = queryString.stringify(prevQuery, options);
    history.replace({ search: queries });
  };

  const removeQueries = (keys: string[]) => {
    const prevQuery = { ...query };
    keys.map((key) => delete prevQuery[key]);

    const queries = queryString.stringify(prevQuery, options);
    history.replace({ search: queries });
  };

  return {
    query,
    search,
    setQuery,
    setQueries,
    removeQuery,
    resetQueries,
    removeQueries
  };
}

export default useQuery;
