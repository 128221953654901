import React from 'react';

import { Heading } from 'components';

import { AttachmentIcon } from 'style';
import { Wrapper } from './personal-pdf.styles';

interface Props {
  userName: string;
  onClick: () => void;
}

const PersonalPdf: React.FC<Props> = ({ userName, onClick }) => {
  return (
    <Wrapper onClick={onClick}>
      <AttachmentIcon />
      <Heading margin={'0'}>{userName} personal data PDF</Heading>
    </Wrapper>
  );
};

export default PersonalPdf;
