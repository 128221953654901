import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import States from './States/States';
import AddButton from '../AddButton/AddButton';
import { DeleteModal, Heading, Table } from 'components';
import { useCall, useDelete, useEdit, useFetch, usePermissions, useShowContent, useToast } from 'components/hooks';
import { AddGenerator, EditGenerator } from 'pages/Company/views/Settings/modals';
import { getGenerators, deleteGenerator, DeleteGeneratorResponseDto } from 'api';
import { prepareColumns, DropdownValue } from './generators.columns';
import { Generator } from 'models';

import { Card } from 'style';

const DieselGenerators: React.FC = () => {
  const { success, error } = useToast();
  const { companyPermissions } = usePermissions();
  const { companyId } = useParams<{ companyId: string }>();
  const [isAddGeneratorOpen, setAddGeneratorOpen] = useState<boolean>(false);

  const { itemToEdit, setItemToEdit, isEditPopupOpen, closeEditPopup } = useEdit<Generator>();
  const { itemToDelete, setItemToDelete, isDeletePopupOpen, closeDeletePopup } = useDelete<Generator>();

  const generatorsData = useFetch(getGenerators(companyId));
  const { showLoader, showContent, showNoContent, showError } = useShowContent(generatorsData);
  const { payload: generators, addSingleItem, updateSingleItem, deleteSingleItem } = generatorsData;

  const handleDeleteSuccess = ({ id }: DeleteGeneratorResponseDto) => {
    const message = itemToDelete ? `GeneratorModel ${itemToDelete.name} has been deleted` : 'GeneratorModel has been deleted';
    success(message);
    deleteSingleItem({ itemId: id });
    closeDeletePopup();
  };

  const { submit, onCallSuccess, onCallError } = useCall(deleteGenerator);
  onCallError(error('Cannot delete generator'));
  onCallSuccess(handleDeleteSuccess);

  const handleDeleteGenerator = async () => {
    if (itemToDelete) {
      await submit(companyId, itemToDelete.id);
    }
  };

  const handleAddGeneratorOpen = (isOpen: boolean) => () => setAddGeneratorOpen(isOpen);

  const handleDropdownSelect = ({ item, type }: DropdownValue) => {
    type === 'edit' && setItemToEdit(item);
    type === 'delete' && setItemToDelete(item);
  };

  const columns = prepareColumns({ handleDropdownSelect, ability: companyPermissions });
  const canAddAccountValue = companyPermissions.can('create', 'AccountValues');
  const canEditAccountValue = companyPermissions.can('update', 'AccountValues');
  const canDeleteAccountValue = companyPermissions.can('delete', 'AccountValues');

  return (
    <>
      <Card height={'auto'}>
        <Heading margin={'8px 0 16px 0'}>Diesel generators</Heading>
        <States showLoader={showLoader} showNoContent={showNoContent} showError={showError} />
        {showContent && generators && <Table<Generator> pageItems={5} columns={columns} data={generators} showHeader={false} />}
        {canAddAccountValue && <AddButton handleOpen={handleAddGeneratorOpen(true)} />}
      </Card>
      {canAddAccountValue && <AddGenerator isOpen={isAddGeneratorOpen} handleClose={handleAddGeneratorOpen(false)} addGenerator={addSingleItem} />}
      {canDeleteAccountValue && <DeleteModal isOpen={isDeletePopupOpen} handleClose={closeDeletePopup} handleDelete={handleDeleteGenerator} />}
      {itemToEdit && canEditAccountValue && (
        <EditGenerator item={itemToEdit} isOpen={isEditPopupOpen} handleClose={closeEditPopup} updateGenerator={updateSingleItem} />
      )}
    </>
  );
};

export default DieselGenerators;
