import React from 'react';
import { Formik, Form } from 'formik';
import { useParams } from 'react-router-dom';

import { Modal, DateFilter, FormField, Button } from 'components';
import { useSubmit, useToast, usePermissions } from 'components/hooks';
import { generateTaxReport, GenerateTaxReportDto } from 'api';
import { Option } from 'types';
import { reportSchema } from './report.validation';
import { bankSelectItems, downloadFile } from 'utils';

import { SimpleWrapper, Flex, PositionBox } from 'style';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  successCallback: () => void;
  dateRange: [Date, Date];
}

const allOptions: Option[] = [{ name: 'All', value: 'all' }, ...bankSelectItems];
const bankZnzOption: Option[] = [{ name: 'Zanzibar', value: 'znz' }];

const GenerateReport: React.FC<Props> = ({ isOpen, handleClose, successCallback, dateRange }) => {
  const { error, success } = useToast();
  const { companyPermissions } = usePermissions();
  const { companyId } = useParams<{ companyId: string }>();

  const { onSubmit, onSubmitSuccess, onSubmitError } = useSubmit(generateTaxReport(companyId));
  onSubmitError(error('Failed to generate a tax report'));
  onSubmitSuccess((payload, values, headers) => {
    const fileName = headers['x-file-name'];
    downloadFile(payload, fileName);
    handleClose();
    successCallback();
    success('Report has been generated');
  });

  const closeWithReset = (resetForm: () => void) => () => {
    handleClose();
    resetForm();
  };

  const canSelectAllBanks = companyPermissions.can('select', 'AllTaxBanks');
  const bankOptions = canSelectAllBanks ? allOptions : bankZnzOption;
  const [startDate, endDate] = dateRange;

  const initialValues: GenerateTaxReportDto = {
    startDate,
    endDate,
    bank: 'znz'
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={reportSchema} enableReinitialize>
      {({ values, setFieldValue, resetForm }) => {
        const handleDateChange = (range: [Date, Date]) => {
          const [startDate, endDate] = range;
          setFieldValue('startDate', startDate);
          setFieldValue('endDate', endDate);
        };

        return (
          <Modal isOpen={isOpen} dropdown={true} handleClose={closeWithReset(resetForm)} title={'Generate Report'}>
            <Form>
              <SimpleWrapper>
                <Flex direction={'column'}>
                  <Flex justify={'space-between'} width={'100%'}>
                    <p>Time period</p>
                    <Flex>
                      <DateFilter dateRange={[values.startDate, values.endDate]} onDateChange={handleDateChange} />
                    </Flex>
                  </Flex>

                  <Flex justify={'space-between'} width={'100%'}>
                    <p>Location</p>
                    <FormField name={'bank'} type={'select'} items={bankOptions} />
                  </Flex>
                </Flex>

                <PositionBox position={'top-right'} margin={'24px 0 6px 0'}>
                  <Flex gap={8}>
                    <Button buttonType={'secondary'} onClick={closeWithReset(resetForm)}>
                      Cancel
                    </Button>
                    <Button type={'submit'}>Generate</Button>
                  </Flex>
                </PositionBox>
              </SimpleWrapper>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default GenerateReport;
