import React from 'react';
import { useParams } from 'react-router-dom';

import { Heading, CardItem, Loader, Error } from 'components';
import { getCompany } from 'api';
import { useFetch, useShowContent } from 'components/hooks';

import { Card } from 'style';

const Details: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();

  const companyData = useFetch(getCompany(companyId));
  const { showContent, showLoader, showNoContent, showError } = useShowContent(companyData);
  const { payload: company } = companyData;

  return (
    <Card height={'auto'}>
      <Heading>Company details</Heading>
      {showLoader && <Loader />}
      {(showError || showNoContent) && <Error>Cannot fetch the data</Error>}
      {showContent && company && (
        <>
          <CardItem name={'Name'} value={company.name} />
          <CardItem name={'Address'} value={company.address} />
          <CardItem name={'VRN no.:'} value={company.vatId} />
          <CardItem name={'TIN no: '} value={company.tinId} />
        </>
      )}
    </Card>
  );
};

export default Details;
