import { RouteModel } from 'types';

import Reservations from './views/Reservations/Reservations';
import SingleReservation from './views/SingleReservation/SingleReservation';

export const reservationRoutes: RouteModel[] = [
  {
    name: 'Reservations',
    path: '/',
    exact: true,
    component: Reservations,
    protected: true
  },
  {
    name: 'Reservation',
    path: '/:reservationId',
    exact: true,
    component: SingleReservation,
    protected: true
  }
];
