import { object, string } from 'yup';

import { message } from 'utils';

export const loginSchema = object().shape({
  email: string().email(message.email).required(message.required),
  password: string()
    .matches(/^(?=.*\d)(?=.*[a-z])[\w~@#$%^&*+=`|{}:;!.?"()[\]-]{8,25}$/, message.passwordMatch)
    .required(message.required)
});
