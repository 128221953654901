import React from 'react';
import { Formik, Form } from 'formik';
import { useHistory, useParams } from 'react-router-dom';

import { Modal, FormField, Button } from 'components';
import { useSubmit, useToast } from 'components/hooks';
import { putRestaurantToken, TokenDataDto } from 'api';

import { Paragraph, Flex, PositionBox } from 'style';

interface Props {
  successCallback: () => void;
}

const EditRestaurantToken: React.FC<Props> = ({ successCallback }) => {
  const history = useHistory();

  const { success, error } = useToast();
  const { companyId } = useParams<{ companyId: string }>();

  const { onSubmit, onSubmitSuccess, onSubmitError } = useSubmit(putRestaurantToken(companyId));
  onSubmitSuccess(() => {
    success('Token has been updated');
    successCallback();
  });
  onSubmitError(error('Failed'));

  const initialValue: TokenDataDto = {
    posterToken: ''
  };

  const handleClose = () => history.push(`/company/${companyId}`);

  return (
    <Modal isOpen={true} title={'Update token'}>
      <Formik initialValues={initialValue} onSubmit={onSubmit}>
        <Form>
          <Paragraph marginBottom={'24px'}>Your token is invalid. Please provide a new one.</Paragraph>
          <FormField name={'posterToken'} placeholder={'Restaurant token'} label={'Restaurant token'} type={'text'} />

          <PositionBox position={'top-right'} margin={'24px 0 0 0'}>
            <Flex>
              <Button buttonType={'secondary'} onClick={handleClose}>
                Close
              </Button>
              <Button type={'submit'}>Save</Button>
            </Flex>
          </PositionBox>
        </Form>
      </Formik>
    </Modal>
  );
};

export default EditRestaurantToken;
