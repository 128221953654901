import React from 'react';

import { Button, Heading } from 'components';

import { InfoIcon, CloseIcon } from 'style';
import { Wrapper, StyledFlex, WrapperFlex } from 'components/ui/Alert/alert.styles';

interface Props {
  text: string;
  buttonText: string;
  handleButtonClick: () => void;
  handleClose?: () => void;
}

const Alert: React.FC<Props> = ({ text, buttonText, handleButtonClick, handleClose }) => {
  return (
    <Wrapper>
      <WrapperFlex direction={'row'} justify={'flex-start'} align={'flex-start'} gap={1}>
        <Button buttonType={'wrapper'}>
          <InfoIcon />
        </Button>
        <StyledFlex direction={'column'} align={'flex-start'} margin={'4px 10px'}>
          <Heading margin={'0'}>{text}</Heading>
          <Button type={'button'} buttonType={'wrapper'} onClick={handleButtonClick}>
            {buttonText}
          </Button>
        </StyledFlex>
      </WrapperFlex>
      {handleClose && (
        <Button buttonType={'wrapper'} onClick={handleClose}>
          <CloseIcon />
        </Button>
      )}
    </Wrapper>
  );
};

export default Alert;
