import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Heading, Button, CardItem, Loader, Error } from 'components';
import { getFinancialDetails } from 'api';
import { useFetch, useShowContent, usePermissions } from 'components/hooks';

import { Card, PositionBox } from 'style';

const Financial: React.FC = () => {
  const history = useHistory();
  const { companyId } = useParams<{ companyId: string }>();
  const { companyPermissions } = usePermissions();

  const financialDetailsData = useFetch(getFinancialDetails(companyId));
  const { showContent, showLoader, showNoContent, showError } = useShowContent(financialDetailsData);
  const { payload: financialDetails } = financialDetailsData;

  const bankCheckRedirect = () => history.push(`/company/${companyId}/bank-check`);

  const canReadBankCheck = companyPermissions.can('read', 'BankCheck');

  return (
    <Card height={'auto'}>
      <Heading>Company financial details</Heading>
      {showLoader && <Loader />}
      {(showError || showNoContent) && <Error>Cannot fetch the data</Error>}
      {showContent && financialDetails && (
        <>
          <CardItem name={'In bank'} moneyValue={+financialDetails.inBank} currency={'usd'} />
          <CardItem name={'Cash'} moneyValue={+financialDetails.cash} currency={'usd'} />
          <CardItem name={'Loans'} moneyValue={+financialDetails.loans} currency={'usd'} />
          <CardItem name={'Total balance'} moneyValue={+financialDetails.totalBalance} currency={'usd'} />
        </>
      )}
      {canReadBankCheck && (
        <PositionBox margin={'15px 0 0 0'}>
          <Button onClick={bankCheckRedirect}>Bank check</Button>
        </PositionBox>
      )}
    </Card>
  );
};

export default Financial;
