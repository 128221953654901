import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import Transaction from 'form/transaction/transaction';
import { useToast } from 'components/hooks';
import { getSingleTransaction, putTransaction } from 'api';
import { useFetch, useSubmit } from 'components/hooks';
import { mapTransactionData, mapTransactionDto } from 'utils';
import { UpdateSingleItem } from 'types';
import { Transaction as TransactionInterface } from 'models';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  transactionId: number;
  updateTransaction?: (args: UpdateSingleItem<TransactionInterface>) => void;
  successCallback?: () => void;
}

const EditTransaction: React.FC<Props> = ({ isOpen, handleClose, updateTransaction, transactionId, successCallback }) => {
  const { success, error } = useToast();
  const { companyId } = useParams<{ companyId: string }>();

  const [progress, setProgress] = useState<number>(0);

  const handleProgress = (progressEvent: any) => {
    const progress = progressEvent.loaded / progressEvent.total;
    setProgress(progress * 100);
  };

  const singleTransactionData = useFetch(getSingleTransaction(companyId, transactionId));
  const { payload: singleTransaction } = singleTransactionData;

  const { onSubmit, onSubmitSuccess, onSubmitError, mapData } = useSubmit(putTransaction(companyId, transactionId, handleProgress));
  onSubmitError(error('Cannot update the transaction'));
  onSubmitSuccess(() => {
    handleClose();
    successCallback && successCallback();
    success('Transaction has been updated');
  });
  mapData((data) => {
    return mapTransactionData(data);
  });

  const transactionDto = useMemo(() => {
    if (singleTransaction && isOpen) {
      return mapTransactionDto(singleTransaction);
    }
  }, [singleTransaction, isOpen]);

  return (
    <>
      {transactionDto && singleTransaction && (
        <Transaction
          isOpen={isOpen}
          isEdit={true}
          handleClose={handleClose}
          initialValues={transactionDto}
          handleSubmit={onSubmit}
          transactionId={transactionId}
          progress={progress}
        />
      )}
    </>
  );
};

export default EditTransaction;
