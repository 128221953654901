import { object, string, number, mixed, SchemaOf, date, boolean, array } from 'yup';

import { renderValidationSchema } from './account-builder/account-builder';
import { AccountValues, Exchange, PaymentSplit, PaymentSplitType } from './types';
import { message, currenciesOneOf, bankOneOf, accountOneOf } from 'utils';
import { accountExchangeSchema } from './builders/exchange';

export const exchangedSchema: SchemaOf<Exchange> = object().shape({
  originalAmount: number().positive(message.positive).required(message.required),
  originalCurrency: mixed().oneOf(currenciesOneOf, message.currency).required(message.currency),
  exchangeRate: number().positive(message.positive).required(message.required)
});

export const splitDataSchema: SchemaOf<PaymentSplit> = object().shape({
  companyId: number().positive(message.required).required(message.required),
  amount: number().positive(message.positive).required(message.required),
  bank: mixed().oneOf(bankOneOf).required(message.currency)
});

export const cashFlowSchema: SchemaOf<any> = object().shape({
  date: date().required(message.required),
  description: string().required(message.required),

  exchange: boolean(),
  exchangeData: object<any>().when(['account', 'exchange'], (account: AccountValues, exchange: any) => {
    if (account === 'exchange') return accountExchangeSchema;
    if (exchange) return exchangedSchema;

    return object();
  }),

  splitType: mixed().oneOf<PaymentSplitType>(['pay', 'loan', null]).nullable(),
  splitData: array<any>().when('splitType', {
    is: (value: PaymentSplitType) => value !== null,
    then: array()
      .of(splitDataSchema)
      .test('sum', 'Sum should equal total amount', (rows = [], { parent }) => {
        const total = rows.reduce((total, row) => total + (row.amount || 0), 0);

        return total === parent.amount;
      }),
    otherwise: array<any>().nullable()
  }),

  file: mixed(),
  amount: number().positive(message.positive).required(message.required),
  currency: mixed()
    .oneOf(currenciesOneOf, message.currency)
    // .not([ref<CurrencyValue>('exchangeData.originalCurrency')], message.sameCurrencies)
    .required(message.required),
  bank: mixed().oneOf(bankOneOf, message.bank),
  comment: string(),

  vat: boolean().required(message.required),
  account: mixed().oneOf<AccountValues>(accountOneOf).required(message.required),
  accountData: object<any>().when('account', (account: AccountValues) => {
    return renderValidationSchema(account);
  })
});
