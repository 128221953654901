import React, { useEffect, useMemo } from 'react';
import { FilterProps } from 'react-table';

import { Select, Button } from 'components';
import { Option } from 'types';
import { Transaction } from 'models';

import { Paragraph } from 'style';
import { Wrapper } from '../filters.styles';

type SelectFilterValue = 'paid' | 'outstanding' | 'all';

const items: Option<SelectFilterValue>[] = [
  { name: 'Paid', value: 'paid' },
  { name: 'Outstanding', value: 'outstanding' },
  { name: 'All', value: 'all' }
];

const SelectTransactionStatus = ({ column: { filterValue, setFilter } }: FilterProps<Transaction>) => {
  useEffect(() => {
    setFilter(undefined);
  }, []);

  const handleSelectChange = (value: SelectFilterValue) => {
    if (value === 'paid') setFilter(true);
    if (value === 'outstanding') setFilter(false);
    if (value === 'all') setFilter(undefined);
  };

  const status = useMemo(() => {
    if (filterValue === true) return 'paid';
    if (filterValue === false) return 'outstanding';
    if (filterValue === undefined) return 'all';
  }, [filterValue]);

  return (
    <Wrapper>
      <Select<SelectFilterValue>
        items={items}
        value={filterValue}
        onChange={handleSelectChange}
        size={'small'}
        placeholder={'Status'}
        hideRadioButtons={false}
        tableSelect={true}
        customButton={
          <Button buttonType={'wrapper'}>
            <Paragraph color={'scale3'} weight={'medium'}>
              Status: {status}
            </Paragraph>
          </Button>
        }
      />
    </Wrapper>
  );
};

export default SelectTransactionStatus;
