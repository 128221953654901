import { object, date, number, string, mixed, SchemaOf } from 'yup';

import { message } from 'utils';
import { EmployeeDto } from 'api';

export const employeeSchema: SchemaOf<EmployeeDto> = object().shape({
  name: string().required(message.required),
  position: string().nullable(),
  contractStart: date().required(message.required),
  contractEnd: date().required(message.required),
  baseTzs: number().default(0),
  baseUsd: number().default(0),
  file: mixed().nullable()
});
