import React from 'react';

import SingleBankField from '../SingleBankField/SingleBankField';
import { bankFields } from './bank.fields';

const BankFields: React.FC = () => {
  return (
    <>
      {bankFields.map(({ bank, fields }) => (
        <SingleBankField key={bank} heading={bank} fields={fields} />
      ))}
    </>
  );
};

export default BankFields;
