import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import AddLoan from '../../modals/AddLoan/AddLoan';
import { AddNewButton, Empty, Error, Heading, Loader, Table } from 'components';
import { useFetch, useShowContent } from 'components/hooks';
import { getCompanyLoans } from 'api';
import { Loan } from 'models';
import { columns } from '../Borrowings/borrowings.columns';

import { Card, Flex } from 'style';

const Loans: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();

  const [isAddLoanOpen, setAddLoanOpen] = useState<boolean>(false);

  const loansData = useFetch(getCompanyLoans(companyId));
  const { showLoader, showContent, showNoContent, showError } = useShowContent(loansData);
  const { payload: loans, refresh: refreshLoans } = loansData;

  const handleAddLoanOpen = (isOpen: boolean) => () => setAddLoanOpen(isOpen);

  return (
    <>
      <Card>
        <Flex margin={'0 0 10px 0'} justify={'space-between'}>
          <Heading margin={'0'}>Loans</Heading>
          <AddNewButton onClick={handleAddLoanOpen(true)}>Add loan</AddNewButton>
        </Flex>
        {showLoader && <Loader />}
        {showNoContent && <Empty>No data</Empty>}
        {showError && <Error>Fetch error</Error>}
        {showContent && loans && <Table<Loan> columns={columns} data={loans} showGlobalFilter={true} />}
      </Card>
      <AddLoan isOpen={isAddLoanOpen} handleClose={handleAddLoanOpen(false)} loansRefresh={refreshLoans} lender={'company'} />
    </>
  );
};

export default Loans;
