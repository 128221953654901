import React from 'react';

import SingleBankField from '../SingleBankField/SingleBankField';
import { cashBankFields } from '../BankFields/bank.fields';

const CashBankFields: React.FC = () => {
  return (
    <>
      {cashBankFields.map(({ bank, fields }) => (
        <SingleBankField key={bank} heading={bank} fields={fields} />
      ))}
    </>
  );
};

export default CashBankFields;
