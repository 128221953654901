import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import SupplierManagement from '../SupplierManagement/SupplierManagement';
import { useSubmit, useToast } from 'components/hooks';
import { SupplierDto, putSupplier } from 'api';
import { Supplier } from 'models';
import { UpdateSingleItem } from 'types';

interface Props {
  item: Supplier;
  isOpen: boolean;
  handleClose: () => void;
  updateSupplier: (args: UpdateSingleItem<Supplier[]>) => void;
}

const EditSupplier: React.FC<Props> = ({ item, isOpen, handleClose, updateSupplier }) => {
  const { success, error } = useToast();
  const { companyId } = useParams<{ companyId: string }>();

  const initialValues: SupplierDto | undefined = useMemo(() => {
    if (item) {
      const { name, address, phone, vatId, tinId, zrbId, bankAccount, bankName, branchName, description } = item;
      return { name, address, phone, vatId, tinId, zrbId, bankAccount, bankName, branchName, description };
    }
  }, [item]);

  const { onSubmit, onSubmitSuccess, onSubmitError } = useSubmit(putSupplier(companyId, item.id));
  onSubmitError(error('Cannot update supplier'));
  onSubmitSuccess((supplier) => {
    updateSupplier({ updatedItem: supplier });
    success(`${supplier.name} has been updated`);
    handleClose();
  });

  return (
    <SupplierManagement
      isOpen={isOpen}
      handleClose={handleClose}
      handleSubmit={onSubmit}
      buttonText={'Edit supplier'}
      initialValues={initialValues}
      title={'Edit supplier'}
    />
  );
};

export default EditSupplier;
