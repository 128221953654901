import React from 'react';

import { Formatter } from 'components';
import { CurrenciesValues } from 'models';
import { currenciesOneOf, displayAmount } from 'utils';

import { Cell } from '../summary.styles';

interface Props {
  value: CurrenciesValues;
  canReadTaxInPln: boolean;
}

const CurrencyCells: React.FC<Props> = ({ value, canReadTaxInPln }) => {
  return (
    <>
      {currenciesOneOf.map((currency) => {
        const amountProps = displayAmount(value[currency], currency);
        if (!canReadTaxInPln && currency === 'pln') return null;
        return (
          <Cell key={currency} columnsNumber={canReadTaxInPln ? 4 : 3}>
            <Formatter align={'right'} {...amountProps} />
          </Cell>
        );
      })}
    </>
  );
};

export default CurrencyCells;
