import React, { HTMLProps, useEffect } from 'react';
import { useField } from 'formik';

import { ErrorParagraph, InputAlertIcon, Label } from 'style';
import { TextArea, Wrapper } from '../formField.styles';

export interface TextareaProps extends HTMLProps<HTMLTextAreaElement> {
  name: string;
  label?: string;
  onFieldChange?: () => void;
  onFieldError?: () => void;
}

const TextareaForm: React.FC<TextareaProps> = ({ name, label, onFieldChange, onFieldError, ...rest }) => {
  const [field, meta, helpers] = useField({ name });

  const { error, touched } = meta;

  const showError = error && touched;

  useEffect(() => {
    if (showError) {
      onFieldError && onFieldError();
    }
  }, [showError]);

  return (
    <Wrapper>
      {label && <Label htmlFor={field.name || name}>{label}</Label>}
      <TextArea {...(rest as any)} {...field} />
      {showError && (
        <ErrorParagraph>
          <InputAlertIcon />
          {error}
        </ErrorParagraph>
      )}
    </Wrapper>
  );
};

export default TextareaForm;
