import React from 'react';

import { FormField } from 'components';
import { usePermissions } from 'components/hooks';
import { RadioOption } from 'types';
import { currencySelectItems } from 'utils';

import { Paragraph, HideWrapper } from 'style';
import { FormWrapper, StyledGrid, ParagraphWrapper } from '../payment.styles';

interface Props {
  radioOptions: RadioOption[];
  editable: boolean;
}

const BankPlForm: React.FC<Props> = ({ radioOptions, editable }) => {
  const { companyPermissions } = usePermissions();

  const disabledForm = !companyPermissions.can('update', 'ReservationDetails') || !editable;

  return (
    <FormWrapper>
      <ParagraphWrapper>
        <Paragraph marginBottom={'8px'}>Bank PL</Paragraph>
      </ParagraphWrapper>
      <StyledGrid>
        <FormField name={'bankPlAmount'} type={'money'} prefix={' '} placeholder={'Bank PL amount'} disabled={disabledForm} />
        <FormField name={'bankPlCurrency'} type={'select'} items={currencySelectItems} alignPosition={'right'} disabled={disabledForm} />
        <HideWrapper hideFrom={'mobile'}>
          <FormField name={'reportBankPl'} type={'radio'} options={radioOptions} disabled={disabledForm} justify={'flex-end'} value={false} />
        </HideWrapper>
      </StyledGrid>
    </FormWrapper>
  );
};

export default BankPlForm;
