import React from 'react';
import { useSelect, useMultipleSelection } from 'downshift';

import { InputError, Checkbox } from 'components';
import { itemToString } from 'utils';
import { Option } from 'types';

import {
  StyledSelectWrapper,
  StyledSelect,
  StyledSelectOptions,
  StyledSelectItem,
  StyledRow,
  StyledLabel,
  CustomIconWrapper,
  SelectInsideWrapper,
  CheckboxWrapper
} from '../Select/select.styles';
import { Paragraph, SelectorIcon } from 'style';

export type SelectSize = 'small' | 'medium' | 'large';

type SelectProps = Omit<React.HTMLAttributes<HTMLSelectElement>, 'onChange'>;
type Align = 'left' | 'center' | 'right';

export interface SelectElementProps extends SelectProps {
  items: Option[];
  onChange: (value: any) => void;
  placeholder?: string;
  label?: string;
  value?: any[];
  size?: SelectSize;
  customButton?: React.ReactNode;
  hideRadioButtons?: boolean;
  disabled?: boolean;
  closeOnChange?: boolean;
  selectType?: 'no-padding' | 'context-menu';
  alignPosition?: Align;
  errorMessage?: string;
  multiple?: boolean;
  width?: string;
  showSelectedItemsCount?: boolean;
}

const MultipleSelect: React.FC<SelectElementProps> = ({
  placeholder,
  items,
  onChange,
  label,
  value,
  disabled,
  hideRadioButtons,
  customButton,
  size,
  selectType,
  alignPosition = 'left',
  errorMessage,
  width,
  showSelectedItemsCount,
  ...rest
}) => {
  const currentValues = items.filter((item) => value && value.includes(item.value));
  const defaultValues = items.filter((item) => item.isDefault);

  const initialValue = value ? currentValues : defaultValues;

  const prepareValues = (item: Option) => item.value;

  const { getDropdownProps, addSelectedItem, removeSelectedItem, selectedItems } = useMultipleSelection<Option>({
    selectedItems: initialValue,
    onSelectedItemsChange: ({ selectedItems }) => onChange(selectedItems?.map(prepareValues)),
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
          return {
            ...changes,
            activeIndex: undefined
          };
        default:
          return changes;
      }
    }
  });

  const { isOpen, selectedItem, getToggleButtonProps, getMenuProps, getItemProps, highlightedIndex, selectItem } = useSelect({
    items,
    itemToString,
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useSelect.stateChangeTypes.MenuKeyDownEnter:
        case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true
          };
      }
      return changes;
    },
    onStateChange: ({ type, selectedItem }) => {
      switch (type) {
        case useSelect.stateChangeTypes.MenuKeyDownEnter:
        case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          if (selectedItem) {
            if (isItemSelected(selectedItem)) {
              removeSelectedItem(selectedItem);
            } else {
              addSelectedItem(selectedItem);
            }
            // @ts-ignore
            selectItem(null);
          }
          break;
        default:
          break;
      }
    }
  });

  const itemDisplay = () => {
    if (selectedItems.length === 0) {
      return placeholder;
    } else if (showSelectedItemsCount) {
      return 'Wybrano ' + selectedItems.length;
    } else {
      const values = selectedItems.map((item) => item.name);
      return values.join(', ');
    }
  };

  const isItemSelected = (item: Option) => selectedItems.indexOf(item) > -1;

  return (
    <StyledSelectWrapper width={width}>
      {label && (
        <StyledRow>
          <StyledLabel>{label}</StyledLabel>
        </StyledRow>
      )}
      {customButton && <CustomIconWrapper {...getToggleButtonProps(getDropdownProps({ preventKeyAction: isOpen }))}>{customButton}</CustomIconWrapper>}
      <SelectInsideWrapper>
        {!customButton && (
          <StyledSelect
            {...getToggleButtonProps(getDropdownProps({ preventKeyAction: isOpen }))}
            isOpen={isOpen}
            className='no-select'
            size={size}
            disabled={disabled}
            selectType={selectType}
            isItemSelected={!!selectedItem}
            hasError={!!errorMessage}
            {...rest}
          >
            <Paragraph>{itemDisplay()}</Paragraph>
            <SelectorIcon className={'chevron'} />
          </StyledSelect>
        )}

        <StyledSelectOptions isOpen={isOpen} {...getMenuProps()} alignPosition={alignPosition}>
          {isOpen &&
            items.map((item, index) => (
              <StyledSelectItem
                key={item.value}
                {...getItemProps({ item, index })}
                isSelected={isItemSelected(item)}
                highlighted={highlightedIndex === index}
                hideRadioButtons={hideRadioButtons}
                disabled={item.blocked}
                selectType={selectType}
              >
                <CheckboxWrapper>
                  <Checkbox checked={isItemSelected(item)} onChange={() => false} aria-readonly={true} />
                </CheckboxWrapper>
                {item.name}
              </StyledSelectItem>
            ))}
        </StyledSelectOptions>
        {errorMessage && <InputError message={errorMessage} />}
      </SelectInsideWrapper>
    </StyledSelectWrapper>
  );
};

export default MultipleSelect;
