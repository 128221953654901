import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { number, object, SchemaOf } from 'yup';

import { FormField } from 'components';
import { useFetch, useShowContent } from 'components/hooks';
import { getCars } from 'api';
import { message } from 'utils';

import { Flex } from 'style';

export const petrolSchema: SchemaOf<PetrolValues> = object({
  carId: number().required(message.required)
});

export interface PetrolValues {
  carId: number;
}

const Petrol: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();

  const carsData = useFetch(getCars(companyId));
  const { showLoader, showNoContent } = useShowContent(carsData);
  const { payload: cars } = carsData;

  const options = useMemo(() => {
    if (cars) {
      return cars.map(({ name, id }) => ({ name, value: id }));
    }
  }, [cars]);

  const placeholder = showNoContent ? 'No cars to select' : 'Select a car';
  const disabled = showLoader || showNoContent;

  return (
    <Flex direction={'column'} margin={'8px 0'} gap={8}>
      <FormField name={'accountData.carId'} type={'select'} items={options || []} width={'100%'} placeholder={placeholder} disabled={disabled} />
    </Flex>
  );
};

export default Petrol;
