import React from 'react';

import { Formatter } from 'components';
import { displayAmount } from 'utils';
import { Tax } from 'models';
import { CurrencyValue } from 'types';

import { Cell } from '../summary.styles';

interface Props {
  key: keyof Tax;
  value: number;
  canReadTaxInPln: boolean;
}

const SingleValueCells: React.FC<Props> = ({ key, value, canReadTaxInPln }) => {
  const isSummary = key === 'summaryTzs';
  const currency: CurrencyValue = key === 'infrastructureTax' ? 'usd' : 'tzs';

  const SizedCell: React.FC = ({ children }) => <Cell columnsNumber={canReadTaxInPln ? 4 : 3}>{children}</Cell>;

  return (
    <>
      {canReadTaxInPln && <SizedCell />}
      <SizedCell />
      <SizedCell />
      <SizedCell>
        <Formatter weight={isSummary ? 'medium' : 'book'} {...displayAmount(value, currency)} />
      </SizedCell>
    </>
  );
};

export default SingleValueCells;
