import styled from 'styled-components';

import { Flex } from 'style';

const Wrapper = styled(Flex)`
  overflow-x: scroll;
  white-space: initial;
  padding: 10px 0;
`;

export { Wrapper };
