import * as path from 'path';

import { CashFlowDto } from 'form/transaction/cash-flow-form/types';
import { SingleTransaction } from 'models';
import { LoanDataValues } from 'form/transaction/cash-flow-form/builders/loan/loan-builder';
import { BankValue } from 'types';

export const mapTransactionData = (data: CashFlowDto) => {
  const convertedDate = new Date(data.date).setUTCHours(12, 0, 0, 0);

  const isExchanged = data.exchange;
  const transactionData = { ...data, date: new Date(convertedDate).toISOString() };
  delete transactionData.exchange;

  if (!isExchanged && data.account !== 'exchange') {
    delete transactionData.exchangeData;
  }

  return transactionData;
};

export const mapTransactionDto = (transaction: SingleTransaction): CashFlowDto => {
  const lenderType = () => {
    if (transaction.employeeLoan) {
      return 'employee';
    } else if (transaction.companyLoan) {
      return 'company';
    } else if (transaction.shareholderLoan) {
      return 'shareholder';
    } else {
      return 'company';
    }
  };

  const loanData = (): LoanDataValues => {
    if (transaction.employeeLoan) {
      return {
        employeeId: transaction.employeeLoan.employeeId,
        ratesNumber: transaction.employeeLoan.ratesNumber
      };
    } else if (transaction.companyLoan) {
      if (transaction.companyLoan?.fromCompanyId === transaction.companyId) {
        //* new
        return {
          companyId: transaction.companyLoan.toCompanyId,
          endDate: new Date(transaction.companyLoan.endDate),
          provision: transaction.companyLoan.provision,
          bankFrom: bankFrom()
        };
      } else {
        //* pay
        return { companyLoanId: transaction.companyLoanId };
      }
    } else if (transaction.shareholderLoan) {
      if (transaction.type === 'income') {
        //* new
        return {
          shareholderId: transaction?.userId || transaction?.shareholderLoan?.userId,
          endDate: new Date(transaction.shareholderLoan.endDate),
          provision: transaction.shareholderLoan.provision
        };
      } else {
        //* pay
        return { shareholderLoanId: transaction.shareholderLoanId };
      }
    } else {
      return {};
    }
  };

  const bankFrom = (): BankValue => {
    if (transaction.companyLoanId && transaction.companyLoan?.fromCompanyId === transaction.companyId) {
      return transaction?.child?.bank;
    } else {
      return transaction?.parent?.bank;
    }
  };

  const loanType = (): 'pay' | 'new' | null => {
    if (transaction.shareholderLoanId || transaction.companyLoanId) {
      //* shareholder loan
      if (transaction.shareholderLoanId) {
        if (transaction.type === 'income') {
          return 'new';
        } else {
          return 'pay';
        }
      } else {
        if (transaction.companyLoan?.fromCompanyId === transaction.companyId) {
          return 'new';
        } else {
          return 'pay';
        }
        //* shareholder loan
      }
    } else if (transaction.employeeLoanId) {
      return 'new';
    } else {
      return 'new';
    }
  };

  const cashFlowDto: CashFlowDto = {
    date: new Date(transaction.date),
    description: transaction.description,
    exchange: Boolean(transaction.exchangeRate && transaction.originalAmount && transaction.originalCurrency),
    exchangeData: {
      originalAmount: transaction.account === 'exchange' ? transaction.amount : transaction.originalAmount,
      originalCurrency: transaction.account === 'exchange' ? transaction.currency : transaction.originalCurrency,
      exchangeRate: transaction.exchangeRate
    },
    splitType: null,
    splitData: [],
    amount: transaction.account === 'exchange' ? transaction.child.amount : transaction.amount,
    currency: transaction.account === 'exchange' ? transaction.child.currency : transaction.currency,
    bank: transaction.bank,
    comment: transaction.comment,
    file: transaction?.filePath ? path.basename(transaction.filePath) : null,
    vat: transaction.vat,
    account: transaction.account,

    accountData: {
      type: loanType(),
      lender: lenderType(),
      loanData: loanData(),
      ratesNumber: transaction.employeeLoan?.ratesNumber || undefined,
      endDate: transaction.companyLoan?.endDate || transaction.shareholderLoan?.endDate || undefined,
      provision: transaction.companyLoan?.provision || transaction.shareholderLoan?.provision || undefined,
      companyLoanId: transaction?.companyLoanId || undefined,
      userId: transaction?.userId || transaction?.shareholderLoan?.userId || undefined,
      carId: transaction?.carId || undefined,
      salaryId: transaction?.salaryId || undefined,
      employeeLoanId: transaction?.employeeLoanId || undefined,
      shareholderId: transaction?.account === 'partner-booking' ? transaction?.userId : undefined,
      shareholderLoanId: transaction?.shareholderLoanId || undefined,
      supplierId: transaction?.supplierId || undefined,
      reservationId: transaction?.reservationId || undefined,
      generatorId: transaction?.generatorId || undefined,
      locationId: transaction?.locationId || undefined,
      employeeId: transaction?.employeeId || undefined,
      companyId: transaction?.companyId || undefined
    }
  };

  return cashFlowDto;
};
