import { object, string, ref } from 'yup';

import { message } from 'utils';

export const confirmSchema = object().shape({
  token: string().required(message.required),
  password: string()
    .matches(/^(?=.*\d)(?=.*[a-z])[\w~@#$%^&*+=`|{}:;!.?"()[\]-]{8,25}$/, message.passwordMatch)
    .required(message.required),
  repeatedPassword: string().oneOf([ref('password'), null], 'Passwords must match')
});
