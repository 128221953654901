import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Heading, CardItem, Formatter, Loader, Error } from 'components';
import { EditSupplier } from 'pages/Company/views/Supplier/modals';
import { useFetch, useShowContent } from 'components/hooks';
import { getSupplier } from 'api';

import { Card, Paragraph } from 'style';

interface Props {
  setSupplierName: (name: string) => void;
  isEditModalOpen: boolean;
  handleCloseModal: () => void;
}

const SupplierData: React.FC<Props> = ({ setSupplierName, isEditModalOpen, handleCloseModal }) => {
  const { companyId, supplierId } = useParams<{ companyId: string; supplierId: string }>();
  const [refreshSupplierData, setRefreshSupplierData] = useState(new Date());

  const supplierData = useFetch(getSupplier(companyId, supplierId), { dependencies: [refreshSupplierData] });
  const { showLoader, showContent, showNoContent, showError } = useShowContent(supplierData);
  const { payload: supplier } = supplierData;

  const onUpdateSupplier = () => setRefreshSupplierData(new Date());

  useEffect(() => {
    if (supplier) {
      setSupplierName(supplier.name);
    }
  }, [supplier]);

  return (
    <Card height={'auto'}>
      <Heading>Supplier data</Heading>
      {showLoader && <Loader />}
      {(showError || showNoContent) && <Error>Fetch error</Error>}
      {showContent && supplier && (
        <>
          <CardItem name={'Name: '}>
            <Paragraph color={'scale3'}>{supplier.name}</Paragraph>
          </CardItem>
          <CardItem name={'Address: '}>
            <Paragraph color={'scale3'}>{supplier.address}</Paragraph>
          </CardItem>
          <CardItem name={'Phone: '}>
            <Formatter value={supplier?.phone || ''} type={'phone'} align={'right'} color={'scale3'} />
          </CardItem>
          <CardItem name={'VRN no.: '}>
            <Paragraph color={'scale3'}>{supplier.vatId}</Paragraph>
          </CardItem>
          <CardItem name={'Tin no: '}>
            <Paragraph color={'scale3'}>{supplier.tinId}</Paragraph>
          </CardItem>
          <CardItem name={'ZRB no: '}>
            <Paragraph color={'scale3'}>{supplier.zrbId}</Paragraph>
          </CardItem>
          <CardItem name={'Bank account: '}>
            <Formatter value={supplier?.bankAccount || ''} type={'bank'} align={'right'} color={'scale3'} />
          </CardItem>
          <CardItem name={'Bank name: '}>
            <Paragraph color={'scale3'}>{supplier.bankName}</Paragraph>
          </CardItem>
          <CardItem name={'Branch name: '}>
            <Paragraph color={'scale3'}>{supplier.branchName}</Paragraph>
          </CardItem>

          <CardItem name={'Description: '} multiline>
            <Paragraph color={'scale3'}>{supplier.description}</Paragraph>
          </CardItem>
        </>
      )}

      {supplier && <EditSupplier item={supplier} isOpen={isEditModalOpen} handleClose={handleCloseModal} updateSupplier={onUpdateSupplier} />}
    </Card>
  );
};

export default SupplierData;
