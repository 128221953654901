import styled from 'styled-components';

import { Grid, theme } from 'style';

const StyledGrid = styled(Grid)`
  ${theme.mq.standard} {
    justify-items: center;
  }
`;

export { StyledGrid };
