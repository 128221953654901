import React, { useEffect, useRef, useState } from 'react';
import Div100vh from 'react-div-100vh';
import { useLocation } from 'react-router-dom';

import { Menu, Header } from 'components';

import { colors } from 'style';
import { GridWrapper, Wrapper, ContentWrapper, Content } from './view.styles';

interface Props {
  children: React.ReactNode;
  showHeader?: boolean;
  showMenu?: boolean;
  background?: keyof typeof colors.background;
  noVerticalPadding?: boolean;
  width?: string;
}

const View: React.FC<Props> = ({ children, width, showHeader = true, showMenu = true, background, noVerticalPadding }) => {
  const headerRef = useRef<HTMLElement>(null);
  const { pathname } = useLocation();
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);

  const [headerHeight, setHeaderHeight] = useState<number>(0);

  const handleMenuOpen = (isOpen: boolean) => () => setMenuOpen(isOpen);

  useEffect(() => {
    if (isMenuOpen) {
      handleMenuOpen(false)();
    }
  }, [pathname]);

  useEffect(() => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.clientHeight);
    }
  }, [headerRef]);

  return (
    <GridWrapper columns={'auto'} showMenu={showMenu}>
      {showMenu && <Menu isOpen={isMenuOpen} handleClose={handleMenuOpen(false)} />}
      <Div100vh>
        <Wrapper background={background}>
          {showHeader && <Header handleMenuOpen={handleMenuOpen(true)} showMenu={showMenu} ref={headerRef} />}
          <ContentWrapper>
            <Content headerHeight={headerHeight} noVerticalPadding={noVerticalPadding} width={width}>
              {children}
            </Content>
          </ContentWrapper>
        </Wrapper>
      </Div100vh>
    </GridWrapper>
  );
};

export default View;
