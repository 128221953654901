import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import NotificationForm from './components/NotificationForm/NotificationForm';
import { getNotifications, NotificationsState } from 'api';
import { useFetch, useShowContent } from 'components/hooks';
import { PageHeader, Loader, Error } from 'components';

import { Grid } from 'style';
import { StyledCard } from './notifications.styles';

const initialValues: NotificationsState = {
  notifications: {
    expenseMoreThan: { isActive: false },
    incomeMoreThan: { isActive: false },
    salaryMoreThan: { isActive: false },
    entityAdded: { isActive: false },
    entityDeleted: { isActive: false },
    loanPaid: { isActive: false }
  }
};

const Notifications: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();

  const notificationsData = useFetch(getNotifications(companyId));
  const { showLoader, showError } = useShowContent(notificationsData);
  const { payload: notifications, refresh } = notificationsData;

  /* map an array to an object  */
  const formValues = useMemo(() => {
    if (notifications) {
      notifications.map(({ type, isActive, value, currency }) => {
        initialValues.notifications[type] = { isActive, value, currency };
      });

      return initialValues;
    }
  }, [notifications]);

  return (
    <>
      <PageHeader heading={'Notifications'} description={'Specify when you will be notified'} />
      <Grid>
        <StyledCard>
          {showLoader && <Loader />}
          {showError && <Error>Failed to fetch</Error>}
          {formValues && <NotificationForm initialValues={formValues} refresh={refresh} />}
        </StyledCard>
      </Grid>
    </>
  );
};

export default Notifications;
