import React from 'react';

import { Button } from 'components/index';

import { CancelIcon, Paragraph } from 'style';
import { Wrapper } from './selected.styles';

interface Props {
  name: string;
  handleDelete: () => void;
}

const Selected: React.FC<Props> = ({ name, handleDelete }) => {
  return (
    <Wrapper>
      <Paragraph fontSize={16}>{name}</Paragraph>
      <Button buttonType={'wrapper'} onClick={handleDelete}>
        <CancelIcon />
      </Button>
    </Wrapper>
  );
};

export default Selected;
