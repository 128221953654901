import fetchMiddleware from 'api/api.middleware';
import { IdParam } from 'types';
import { Supplier, Transaction } from 'models';
import { SupplierDto, DeleteSupplierResponseDto } from './api.suppliers.dto';

export const getSuppliers = (companyId: IdParam) => () => fetchMiddleware<Supplier[]>({ method: 'get', url: `/companies/${companyId}/suppliers` });

export const getSupplier = (companyId: IdParam, supplierId: IdParam) => () =>
  fetchMiddleware<Supplier>({ method: 'get', url: `/companies/${companyId}/suppliers/${supplierId}` });

export const getSupplierInvoices = (companyId: IdParam, supplierId: IdParam) => () =>
  fetchMiddleware<Transaction[]>({ method: 'get', url: `/companies/${companyId}/suppliers/${supplierId}/invoices` });

export const postSupplier = (companyId: IdParam) => (data: SupplierDto) =>
  fetchMiddleware<Supplier>({ method: 'post', url: `/companies/${companyId}/suppliers`, data });

export const putSupplier = (companyId: IdParam, supplierId: IdParam) => (data: SupplierDto) =>
  fetchMiddleware<Supplier>({ method: 'put', url: `/companies/${companyId}/suppliers/${supplierId}`, data });

export const deleteSupplier = (companyId: IdParam, supplierId: IdParam) =>
  fetchMiddleware<DeleteSupplierResponseDto>({
    method: 'delete',
    url: `/companies/${companyId}/suppliers/${supplierId}`,
    data: {}
  });
