import styled from 'styled-components';

import { borders, colors } from 'style';

const Wrapper = styled.div<{ width?: string }>`
  width: ${({ width }) => width || '100%'};
  display: flex;
  flex-direction: column;
`;

const TextArea = styled.textarea`
  width: 100%;
  min-height: 104px;
  max-height: 160px;
  font-family: Inter, sans-serif;
  border-radius: 8px;
  resize: vertical;
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding: 1.2rem 1.6rem;
  border: ${borders.default};
  color: ${colors.gray.scale2};

  &::placeholder {
    line-height: 2.8rem;
    font-size: 16px;
    color: ${colors.gray.scale3};
  }

  &:active,
  &:focus {
    border: ${borders.input.active};
  }
`;

export { Wrapper, TextArea };
