import { RouteModel } from 'types';

import Invoices from './views/Invoices/Invoices';

export const invoicesRoutes: RouteModel[] = [
  {
    name: 'Invoices',
    path: '/',
    exact: true,
    component: Invoices,
    protected: true
  }
];
