import React, { useEffect } from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { useField } from 'formik';

import { Input, InputError } from 'components';

import { Flex, Label } from 'style';

type DateProps = Omit<ReactDatePickerProps, 'onChange'>;

export interface DatePickerProps extends DateProps {
  name: string;
  onFieldChange?: () => void;
  onFieldError?: () => void;
  label?: string;
  placeholder?: string;
  fullWidth?: boolean;
  width?: string;
  withAsterisk?: boolean;
}

const DateForm: React.FC<DatePickerProps> = ({
  name,
  onFieldChange,
  onFieldError,
  width,
  placeholder = 'YY/dd/MM',
  fullWidth,
  label,
  withAsterisk,
  ...rest
}) => {
  const [field, meta, helpers] = useField({ name });

  const { error, touched } = meta;
  const { setValue } = helpers;
  const showError = error && touched;

  const handleSetValue = (value: any) => {
    setValue(value);
    onFieldChange && onFieldChange();
  };

  const onDateSelect = (date: Date) => {
    if (!date) return;
    date.setHours(12);
    handleSetValue(date);
  };

  useEffect(() => {
    if (showError) {
      onFieldError && onFieldError();
    }
  }, [showError]);

  const selected = field.value ? new Date(field.value) : null;

  return (
    <Flex direction={'column'} align={'stretch'} gap={0} width={width}>
      {label && (
        <Label htmlFor={field.name}>
          {label}
          {withAsterisk && <span style={{ color: 'red' }}>*</span>}
        </Label>
      )}
      <DatePicker
        {...rest}
        {...field}
        onChange={onDateSelect}
        selected={selected}
        onInputError={onFieldError}
        className={showError ? 'datepicker-error' : ''}
        dateFormat={'dd/MM/yyyy'}
        peekNextMonth={true}
        dropdownMode='select'
        placeholderText={placeholder}
        customInput={<Input placeholder={placeholder} inputType={fullWidth ? 'date-full-width' : 'date'} width={width || '130px'} />}
        // showMonthYearPicker={true}
        showFourColumnMonthYearPicker={true}
      />
      {showError && error && <InputError message={error} />}
    </Flex>
  );
};

export default DateForm;
