import React from 'react';
import { useFormikContext } from 'formik';

import { FormField, Heading } from 'components';
import { NotificationType } from 'models';
import { NotificationsState } from 'api';
import { currencySelectItems } from 'utils';

import { Flex } from 'style';
import { FlexWrapper } from 'pages/Company/views/Notifications/notifications.styles';

const TYPE: NotificationType = 'salaryMoreThan';

const SalaryMoreThan: React.FC = () => {
  const { values } = useFormikContext<NotificationsState>();

  const disabled = !values.notifications[TYPE].isActive;
  return (
    <FlexWrapper align={'stretch'} direction={'column'}>
      <Heading margin={'0'}>Salary amount is more than</Heading>
      <Flex>
        <FormField type={'toggle'} name={`notifications[${TYPE}].isActive`} />
        <FormField type={'money'} placeholder={'Value'} prefix={''} name={`notifications[${TYPE}].value`} disabled={disabled} />
        <FormField
          type={'select'}
          placeholder={'Currency'}
          items={currencySelectItems}
          name={`notifications[${TYPE}].currency`}
          alignPosition={'right'}
          disabled={disabled}
        />
      </Flex>
    </FlexWrapper>
  );
};

export default SalaryMoreThan;
