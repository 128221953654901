import React from 'react';
import { Form } from 'formik';

import { FormField, Button } from 'components';

import { SimpleWrapper, Flex } from 'style';

interface Props {
  handlePageBack: () => void;
}

const InsertData: React.FC<Props> = ({ handlePageBack }) => {
  return (
    <SimpleWrapper>
      <Form>
        <Flex direction={'column'} gap={12}>
          <FormField type={'text'} name={'cloudbedsClientId'} label={'Client ID'} placeholder={'Client ID'} />
          <FormField type={'text'} name={'cloudbedsClientSecret'} label={'Client secret'} placeholder={'Client secret'} />
        </Flex>
        <Flex justify={'space-between'} margin={'24px 0 12px 0'}>
          <Button buttonType={'secondary'} onClick={handlePageBack}>
            Back
          </Button>
          <Button type={'submit'}>Save</Button>
        </Flex>
      </Form>
    </SimpleWrapper>
  );
};

export default InsertData;
