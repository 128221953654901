import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import States from './States/States';
import { Table, DeleteModal } from 'components';
import { deleteInvoice, getInvoices, putInvoiceStatus } from 'api';
import { useFetch, useShowContent, useCall, useDelete, useToast, useEdit } from 'components/hooks';
import { prepareColumns, DropdownValue } from './invoices.columns';
import { AddInvoice, EditInvoice } from '../modals';
import { Invoice } from 'models';
import { PAGE_ITEMS } from 'utils/table';

import { Card } from 'style';

interface Props {
  isAddInvoiceOpen: boolean;
  closeAddInvoice: () => void;
}

const InvoicesTable: React.FC<Props> = ({ isAddInvoiceOpen, closeAddInvoice }) => {
  const { success, error } = useToast();
  const { companyId } = useParams<{ companyId: string }>();
  const { itemToDelete, setItemToDelete, isDeletePopupOpen, closeDeletePopup } = useDelete<Invoice>();
  const { itemToEdit, setItemToEdit, isEditPopupOpen, closeEditPopup } = useEdit<Invoice>();

  const [refresh, setRefresh] = useState(new Date());

  const invoicesData = useFetch(getInvoices(companyId), { dependencies: [refresh] });
  const { showContent, showLoader, showNoContent, showError } = useShowContent(invoicesData);
  const { payload: invoices, deleteSingleItem, updateSingleItem, addSingleItem } = invoicesData;

  const handleDeleteSuccess = ({ id }: { id: number }) => {
    success('Invoice has been deleted');
    deleteSingleItem({ itemId: id });
    closeDeletePopup();
  };

  const { submit, onCallSuccess, onCallError } = useCall(deleteInvoice);
  onCallError(error('Cannot delete invoice'));
  onCallSuccess(handleDeleteSuccess);

  const handleStatusSuccess = (item: Invoice) => {
    success('Invoice has been updated');
    updateSingleItem({ updatedItem: item });
    closeDeletePopup();
  };

  const { submit: submitStatus, onCallSuccess: onStatusSuccess, onCallError: onStatusError } = useCall(putInvoiceStatus(companyId));
  onStatusError(error('Cannot update invoice status'));
  onStatusSuccess(handleStatusSuccess);

  const handleDeleteInvoice = async () => {
    if (itemToDelete) {
      await submit(companyId, itemToDelete.id);
    }
  };

  const changeStatus = async (itemId: number, isPaid: boolean) => {
    await submitStatus(itemId, { isPaid });
  };

  const handleDropdownSelect = async ({ type, item }: DropdownValue) => {
    type === 'delete' && setItemToDelete(item);
    type === 'status' && (await changeStatus(item.id, !item.isPaid));
    type === 'edit' && setItemToEdit(item);
  };

  const columns = prepareColumns(handleDropdownSelect);

  const onInvoiceChange = () => setRefresh(new Date());

  const dateSortedInvoices = useMemo(() => invoices?.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()) || [], [invoices]);

  return (
    <>
      <Card>
        <States showLoader={showLoader} showNoContent={showNoContent} showError={showError} />
        {showContent && invoices && (
          <Table<Invoice> columns={columns} data={dateSortedInvoices} showGlobalFilter showColumnsFilter pageItems={PAGE_ITEMS} haveDropdown showPagination />
        )}
      </Card>

      <AddInvoice isOpen={isAddInvoiceOpen} handleClose={closeAddInvoice} onAddInvoice={onInvoiceChange} />
      {itemToEdit && <EditInvoice invoiceId={itemToEdit.id} isOpen={isEditPopupOpen} handleClose={closeEditPopup} onEditInvoice={onInvoiceChange} />}
      <DeleteModal isOpen={isDeletePopupOpen} handleClose={closeDeletePopup} handleDelete={handleDeleteInvoice} itemName={itemToDelete?.name} />
    </>
  );
};

export default InvoicesTable;
