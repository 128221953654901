import styled, { css } from 'styled-components';

import { AvatarSize } from './Avatar';
import { AvatarIcon, colors, font } from 'style';

const sizes = (size: AvatarSize) => {
  switch (size) {
    case 'xs':
      return css`
        width: 16px;
        height: 16px;
        font-size: 9px;
      `;
    case 's':
      return css`
        width: 24px;
        height: 24px;
        font-size: 12px;
      `;
    case 'm':
      return css`
        width: 32px;
        height: 32px;
        font-size: 16px;
      `;
    case 'l':
      return css`
        width: 48px;
        height: 48px;
        font-size: 18px;
      `;
    case 'xl':
      return css`
        width: 64px;
        height: 64px;
        font-size: 20px;
      `;
  }
};

const notification = (size: AvatarSize) => css`
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 3px;
    right: 3px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${colors.blue.base};

    ${
      size === 'm' &&
      css`
        width: 6px;
        height: 6px;
        bottom: 0;
        right: 0;
      `
    }

    ${
      size === 's' &&
      css`
        width: 5px;
        height: 5px;
        bottom: 0;
        right: 0;
      `
    }

    ${
      size === 'xs' &&
      css`
        width: 3px;
        height: 3px;
        bottom: 0;
        right: 0;
      `
    }
`;

type AvatarItemsProps = {
  size: AvatarSize;
  notifications?: boolean;
};

const Icon = styled(AvatarIcon)<AvatarItemsProps>`
  ${({ size }) => sizes(size)};
  ${({ size, notifications }) => notifications && notification(size)};
`;

const InitialsAvatar = styled.div<AvatarItemsProps>`
  background-color: ${colors.background.blue};
  display: grid;
  place-items: center;
  border-radius: 50%;
  color: ${colors.blue.base};
  font-weight: ${font.weight.medium};

  ${({ size }) => sizes(size)};
  ${({ size, notifications }) => notifications && notification(size)};
`;

const IconWrapper = styled.div<AvatarItemsProps>`
  display: inline-block;
  ${({ size, notifications }) => notifications && notification(size)};
`;

const ImageWrapper = styled.div<AvatarItemsProps>`
  ${({ size, notifications }) => notifications && notification(size)};
`;

const ImageAvatar = styled.img<AvatarItemsProps>`
  border-radius: 100%;
  position: relative;

  ${({ size }) => sizes(size)};
`;

export { Icon, InitialsAvatar, IconWrapper, ImageWrapper, ImageAvatar };
