import React from 'react';
import { Formik, Form } from 'formik';
import { useParams } from 'react-router-dom';

import ExpenseMoreThan from './expense-more-than/expense-more-than';
import IncomeMoreThan from './income-more-than/income-more-than';
import SalaryMoreThan from './salary-more-than/salary-more-than';
import EntityAdded from './entity-added/entity-added';
import EntityDeleted from './entity-deleted/entity-deleted';
import LoanPaid from './loan-paid/loan-paid';
import { Button } from 'components';
import { useSubmit, useToast } from 'components/hooks';
import { putNotifications, NotificationsState } from 'api';
import { validationSchema } from './form.validation';

import { PositionBox } from 'style';
import { NotificationType } from 'models';

interface Props {
  initialValues: NotificationsState;
  refresh: () => void;
}

const NotificationForm: React.FC<Props> = ({ initialValues, refresh }) => {
  const { success, error } = useToast();
  const { companyId } = useParams<{ companyId: string }>();

  const { onSubmit, onSubmitSuccess, onSubmitError, mapData } = useSubmit(putNotifications(companyId));
  onSubmitSuccess(() => {
    refresh();
    success('Notifications have been saved');
  });
  onSubmitError(error('Failed to set notifications'));
  mapData((data) => {
    const notificationsArray = [];

    for (const objectKey in data.notifications) {
      const key = objectKey as NotificationType;
      notificationsArray.push({ type: key, ...data.notifications[key] });
    }

    return notificationsArray;
  });

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize={true} validationSchema={validationSchema}>
      {({ values, errors }) => {
        return (
          <Form>
            <ExpenseMoreThan />
            <IncomeMoreThan />
            <SalaryMoreThan />
            <EntityAdded />
            <EntityDeleted />
            <LoanPaid />
            <PositionBox position={'top-right'} margin={'30px 0'}>
              <Button type={'submit'}>Save</Button>
            </PositionBox>
          </Form>
        );
      }}
    </Formik>
  );
};

export default NotificationForm;
