import { object, string, date, number, mixed, array, SchemaOf } from 'yup';

import { PettyCashDto, PettyCashProduct } from 'api';
import { CurrencyValue } from 'types';
import { currenciesOneOf, message } from 'utils';

export const pettyCashProductSchema: SchemaOf<PettyCashProduct> = object().shape({
  name: string().required(message.required),
  amount: number().min(1, message.min(1)).positive(message.positive).required(message.required),
  quantity: number().min(1, message.min(1)).positive(message.positive).required(message.required)
});

export const pettyCashSchema: SchemaOf<PettyCashDto> = object().shape({
  currency: mixed().oneOf<CurrencyValue>(currenciesOneOf).required(message.currency),
  date: date().required(message.required),
  supplierId: number().positive(message.positive).required(message.required),
  items: array().of(pettyCashProductSchema)
});
