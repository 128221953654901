import React from 'react';

import { FormField } from 'components';
import { bankSelectItems, currencySelectItems } from 'utils';
import { CurrencyValue } from 'types';

import { Flex, Grid } from 'style';

const NewLoanEmployeeForm: React.FC = () => {
  const allowedCurrencies: CurrencyValue[] = ['tzs', 'usd'];
  const loanCurrencySelectItems = currencySelectItems.filter(({ value }) => value && allowedCurrencies.includes(value));

  return (
    <Flex direction={'column'} width={'100%'} margin={'0 0 0 0'}>
      <FormField name={'date'} type={'date'} fullWidth={true} width={'100%'} placeholder={'Start date'} label={'Start date'} />
      <FormField name={'accountData.loanData.ratesNumber'} type={'number-format'} placeholder={'Number'} label={'Number of monthly installments'} />
      <Grid columns={'3fr 1fr'} gap={1} rowGap={1} columnGap={1}>
        <FormField name={'amount'} type={'money'} prefix={''} placeholder={'Amount'} label={'Amount'} />
        <FormField name={'currency'} type={'select'} items={loanCurrencySelectItems} label={'Currency'} />
      </Grid>
      <FormField name={'bank'} type={'select'} items={bankSelectItems} placeholder={'Paid from'} label={'Paid from'} width={'100%'} />
      <FormField name={'comment'} type={'textarea'} placeholder={'Comment'} label={'Comment'} />
    </Flex>
  );
};

export default NewLoanEmployeeForm;
