import { NotificationType } from 'models';
import { CurrencyValue, BankValue, UserRole } from 'types';
import { AccountType, AccountValues } from 'form/transaction/cash-flow-form/types';

export const message = {
  required: 'This field is required',
  passwordMatch: 'Password must contain special character and capital letter',
  email: 'Email is not correct',
  positive: 'This value has to be positive',
  currency: 'PLN/ZNZ/TZS/USD',
  sameCurrencies: 'Choose the pair of two different currencies',
  bank: 'Bank has to be one of pl/znz/cash',
  sharesSummary: 'Sum of shares should equal 100',
  min: (value: number) => `This value should be greater than ${value}`,
  max: (value: number) => `This value should be less than ${value}`
};

export const bankOneOf: BankValue[] = ['pl', 'znz', 'cash'];
export const currenciesOneOf: CurrencyValue[] = ['pln', 'usd', 'eur', 'tzs'];
export const accountTypeOneOf: AccountType[] = ['income', 'expense', 'both'];
export const permissionsOneOf: UserRole[] = ['shareholder', 'employee', 'control'];
export const notificationTypeOneOf: NotificationType[] = ['expenseMoreThan', 'incomeMoreThan', 'salaryMoreThan', 'entityAdded', 'entityDeleted', 'loanPaid'];
export const accountOneOf: AccountValues[] = [
  // 'advertising',
  'decoration-furniture',
  'diesel-generators',
  // 'donation',
  'electricity',
  'excursion-commission',
  'exchange',
  // 'flights-hotel',
  // 'food-beverage',
  // 'garbage',
  // 'gas',
  // 'grounds',
  // 'guest-entertainment',
  'housekeeping',
  'income-cash',
  'income-guests',
  'income-restaurant',
  'kitchen-laundry',
  // 'land-lease',
  // 'linen',
  // 'maintenance',
  // 'management-welfare',
  'marketing',
  // 'building-construction',
  // 'non-food-supply',
  'outcome-cash',
  'petrol',
  'professional-charges',
  'salaries-management',
  'salaries-staff',
  // 'shop-massages',
  'staff-food',
  // 'staff-welfare',
  // 'stationary-printing',
  'swimming-pool',
  // 'technical-equipment',
  // 'telephone-internet',
  // 'tours-transfers',
  // 'transport-parking',
  // 'vehicle-maintenance',
  // 'water',
  'loan',
  'insurance',
  'refund',
  'partner-booking',
  'income-shareholders',
  'utilities',
  'fees-taxes',
  'bank-charges',
  'backoffice'
];
