import React from 'react';
import { useParams } from 'react-router-dom';

import { Table, Heading, Loader, Error, Empty } from 'components';
import { useFetch, useShowContent } from 'components/hooks';
import { getSupplierInvoices } from 'api';
import { prepareColumns, DropdownValue } from './supplier-columns';
import { Transaction } from 'models';

import { Card } from 'style';

const SupplierInvoices: React.FC = () => {
  const { companyId, supplierId } = useParams<{ companyId: string; supplierId: string }>();

  const supplierInvoicesData = useFetch(getSupplierInvoices(companyId, supplierId));
  const { showContent, showLoader, showNoContent, showError } = useShowContent(supplierInvoicesData);
  const { payload: supplierInvoices } = supplierInvoicesData;

  const handleDropdownSelect = (value: DropdownValue) => {
    console.log(value);
  };

  const columns = prepareColumns({ handleDropdownSelect });

  return (
    <Card>
      <Heading>Invoices</Heading>
      {showLoader && <Loader />}
      {showNoContent && <Empty>No data</Empty>}
      {showError && <Error>Failed to fetch</Error>}
      {showContent && supplierInvoices && <Table<Transaction> columns={columns} data={supplierInvoices} />}
    </Card>
  );
};

export default SupplierInvoices;
