import React from 'react';
import { Form, Formik } from 'formik';

import { Button, FormField, Heading } from 'components';

import { Flex, SimpleWrapper } from 'style';

interface PasswordDto {
  currentPassword: string;
  newPassword: string;
  repeatedPassword: string;
}

const PasswordForm: React.FC = () => {
  const initialValues: PasswordDto = {
    currentPassword: '',
    newPassword: 'TestPassword123',
    repeatedPassword: 'TestPassword123'
  };

  return (
    <SimpleWrapper>
      <Formik initialValues={initialValues} onSubmit={() => {}}>
        <Form>
          <Flex direction={'column'} gap={8} justify={'flex-start'} align={'flex-start'}>
            <Heading type={'h4'}>Edit password</Heading>
            <FormField name={'currentPassword'} type={'password'} placeholder={'Current Password'} />
            <FormField name={'newPassword'} type={'password'} placeholder={'New password'} />
            <FormField name={'repeatedPassword'} type={'password'} placeholder={'Confirm new password'} />
            <Flex justify={'flex-end'} width={'100%'}>
              <Button type={'submit'}>Save</Button>
            </Flex>
          </Flex>
        </Form>
      </Formik>
    </SimpleWrapper>
  );
};

export default PasswordForm;
