import styled, { css } from 'styled-components';

import { theme } from 'style';
import { StyledButton } from '../Button/button.styles';

export const DatePickerWrapper = styled.div<{ protrusionWidth?: number; isOpen?: boolean; align?: 'left' | 'right' }>`
  width: 100%;
  position: absolute;
  top: 40px;

  ${({ isOpen }) =>
    isOpen
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}

  ${({ protrusionWidth }) =>
    protrusionWidth &&
    protrusionWidth > 0 &&
    css`
      //transform: translateX(${-protrusionWidth + 'px'});
    `}
  
  ${theme.mq.tablet} {
    width: 350px;

    right: ${({ align }) => (align === 'right' ? 0 : 'auto')};
    left: ${({ align }) => (align === 'left' ? 0 : 'auto')};
  }
`;

export const Wrapper = styled.div`
  position: relative;
  z-index: 10;
`;

export const FilterButton = styled(StyledButton).attrs({ buttonType: 'secondary' })`
  gap: 10px;
  width: 100%;

  ${theme.mq.tabletS} {
    width: auto;
  }
`;
