import React from 'react';
import { useParams } from 'react-router-dom';

import SupplierManagement from '../SupplierManagement/SupplierManagement';
import { useSubmit, useToast } from 'components/hooks';
import { postSupplier } from 'api';
import { Supplier } from 'models';
import { AddSingleItem } from 'types';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  addSupplier: (args: AddSingleItem<Supplier>) => void;
}

const AddSupplier: React.FC<Props> = ({ isOpen, handleClose, addSupplier }) => {
  const { success, error } = useToast();
  const { companyId } = useParams<{ companyId: string }>();

  const { onSubmit, onSubmitSuccess, onSubmitError } = useSubmit(postSupplier(companyId));
  onSubmitError(error('Cannot add supplier'));
  onSubmitSuccess((payload) => {
    addSupplier({ payload });
    success('New supplier has been added');
    handleClose();
  });

  return (
    <SupplierManagement isOpen={isOpen} handleClose={handleClose} handleSubmit={onSubmit} buttonText={'Add supplier'} title={'New supplier'} elevation={1} />
  );
};

export default AddSupplier;
