import styled from 'styled-components';

import { Grid, theme } from 'style';

const AccountsWrapper = styled(Grid)`
  width: 100%;

  ${theme.mq.standard} {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: minmax(268px, auto);
  }
`;

export { AccountsWrapper };
