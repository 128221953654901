import styled from 'styled-components';

import { theme } from 'style';

const StyledHeader = styled.header`
  width: 100%;
  padding: 32px 32px 0 32px;

  ${theme.mq.hdReady} {
    margin-bottom: 24px;
    padding: 16px 32px 0 32px;
  }
`;

export { StyledHeader };
