import { object, date, number, SchemaOf } from 'yup';

import { message } from 'utils';
import { PostLoanDto } from 'api';

export const loanSchema: SchemaOf<PostLoanDto> = object().shape({
  date: date().required(message.required),
  ratesNumber: number().positive(message.positive).required(message.required),
  amount: number().positive(message.positive).required(message.required)
});
