import React, { useContext } from 'react';
import { useFormikContext } from 'formik';

import { Button } from 'components';
import { PettyCashDto } from 'api';
import { CashFlowContext } from 'form/transaction/context/cash-flow.context';

import { Flex, PositionBox } from 'style';

const SubmitButtons: React.FC = () => {
  const { resetForm } = useFormikContext<PettyCashDto>();
  const { handleFormChange } = useContext(CashFlowContext);

  const handleClose = () => {
    handleFormChange('cash-flow')();
    resetForm();
  };

  return (
    <PositionBox position={'top-right'} margin={'24px 0 12px 0'}>
      <Flex>
        <Button buttonType={'secondary'} onClick={handleClose}>
          Cancel
        </Button>
        <Button type={'submit'}>Generate</Button>
      </Flex>
    </PositionBox>
  );
};

export default SubmitButtons;
