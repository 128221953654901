import { date, mixed, object, SchemaOf } from 'yup';

import { GenerateTaxReportDto } from 'api';
import { BankValue } from 'types';
import { bankOneOf, message } from 'utils';

type Bank = BankValue | 'all';

const bankValues: Bank[] = [...bankOneOf, 'all'];

export const reportSchema: SchemaOf<GenerateTaxReportDto> = object().shape({
  startDate: date().required(message.required),
  endDate: date().required(message.required),
  bank: mixed().oneOf<Bank>(bankValues).required(message.required)
});
