import styled from 'styled-components';

import { Justify } from './Radio';

import { Flex, colors, theme } from 'style';

const StyledRadio = styled.input.attrs({ type: 'radio' })`
  appearance: none;
  background-color: transparent;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 20px;
  width: 20px;
  position: relative;
  transition: all 0.25s ease;
  vertical-align: middle;
  margin-right: 1rem;
  border: 2px solid ${colors.blue.base};

  transition-property: background;

  &:focus {
    outline: none;
  }
  &:checked {
    background-color: ${colors.blue.base};
    border: none;
    transform: scale(0.55);
  }
  &:checked:after {
    content: '';
    position: absolute;
    width: calc(100% + 17px);
    height: calc(100% + 17px);
    border: 3px solid ${colors.blue.base};
    border-radius: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &:disabled + label {
    opacity: 0.6;
  }
`;

const RadioWrapper = styled.div<{ radioMargin?: string; verticalOptions?: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: ${({ verticalOptions }) => (verticalOptions ? 'row-reverse' : 'row')};
  margin: ${({ radioMargin }) => radioMargin || '1rem 0'};
  gap: 1rem;

  ${theme.mq.standard} {
    flex-direction: row;
    gap: 0;
  }
`;

const StyledLabel = styled.label`
  margin-bottom: 0;
  font-size: 14px;
  color: ${colors.gray.scale2};
  font-weight: 400;
  display: inline-block;
  cursor: pointer;
`;

interface WrapperProps {
  showLabel: boolean;
  isVertical?: boolean;
  gap?: number;
  justify?: Justify;
}

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: ${({ isVertical }) => (isVertical ? 'column' : 'row')};
  gap: ${({ gap }) => `${gap}px`};
  align-items: ${({ isVertical }) => (isVertical ? 'flex-start' : 'center')};
  justify-content: ${({ showLabel, isVertical }) => (showLabel && !isVertical ? 'space-between' : 'flex-end')};

  ${theme.mq.standard} {
    justify-content: ${({ justify, showLabel, isVertical }) => justify || (showLabel && !isVertical ? 'space-between' : 'flex-start')};
  }
`;

const StyledOptionsWrapper = styled(Flex)<{ verticalOptions?: boolean }>`
  flex-direction: ${({ verticalOptions }) => (verticalOptions ? 'column' : 'row')};
  align-items: ${({ verticalOptions }) => (verticalOptions ? 'flex-end' : 'flex-start')};
  flex-wrap: wrap;

  ${theme.mq.standard} {
    flex-direction: ${({ verticalOptions }) => (verticalOptions ? 'column' : 'row')};
    align-items: flex-start;
  }
`;

export { StyledRadio, RadioWrapper, Wrapper, StyledOptionsWrapper, StyledLabel };
