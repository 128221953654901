import React, { useEffect } from 'react';
import { useField } from 'formik';

import { Checkbox } from 'components';

export interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  onFieldChange?: () => void;
  onFieldError?: () => void;
  multiple?: boolean;
  checked?: boolean;
}

const CheckboxForm: React.FC<CheckboxProps> = ({ name, label, onFieldChange, onFieldError, multiple, value, checked, ...rest }) => {
  const [field, meta, helpers] = useField({ name });

  const { error, touched } = meta;
  const { setValue } = helpers;

  const showError = error && touched;

  const handleSetValue = (value: any) => {
    setValue(value);
    onFieldChange && onFieldChange();
  };

  const handleCheckboxChange = () => {
    if (multiple) {
      if (field?.value?.includes(value)) {
        handleSetValue(field.value.filter((arrValue: any) => arrValue !== value));
      } else {
        handleSetValue(Array.isArray(field?.value) ? [...field.value, value] : [value]);
      }
    } else {
      handleSetValue(!field.value);
    }
  };

  useEffect(() => {
    if (showError) {
      onFieldError && onFieldError();
    }
  }, [showError]);

  const isChecked = multiple ? checked : field.value;

  return <Checkbox label={label} error={error} checked={isChecked} field={{ ...field, onChange: handleCheckboxChange }} {...rest} />;
};

export default CheckboxForm;
