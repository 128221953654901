import { useState } from 'react';
import { getCurrentMonthRange } from 'utils';

type DateRange = [Date, Date];

interface ReturnData {
  dateRange: DateRange;
  handleDateRange: (range: DateRange) => void;
}

const useDateRange = (): ReturnData => {
  const [firstMonthDate, lastMonthDate] = getCurrentMonthRange();

  const [dateRange, setDateRange] = useState<DateRange>([firstMonthDate, lastMonthDate]);

  const handleDateRange = (range: [Date, Date]) => setDateRange(range);

  return {
    dateRange,
    handleDateRange
  };
};

export default useDateRange;
