import React from 'react';
import { Form, Formik, FormikHelpers } from 'formik';

import { Button, Error, FormField, Loader, Modal } from 'components';
import { EmployeeDto } from 'api';
import { employeeSchema } from './employee.validation';
import { currencies } from 'types';

import { Flex, SimpleWrapper } from 'style';
import { usePermissions } from 'components/hooks';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (values: EmployeeDto, helpers: FormikHelpers<EmployeeDto>) => void;
  initialValues?: EmployeeDto;
  buttonText?: string;
  isLoading?: boolean;
  showError?: boolean;
  title: 'New employee' | 'Edit employee';
}

const emptyValues: EmployeeDto = {
  name: '',
  position: '',
  contractStart: new Date(),
  contractEnd: new Date(),
  baseTzs: 0,
  baseUsd: 0,
  file: null
};

const EmployeeManagement: React.FC<Props> = ({ isOpen, handleClose, handleSubmit, initialValues, buttonText, showError, isLoading, title }) => {
  const values: EmployeeDto = initialValues || emptyValues;
  const { companyPermissions } = usePermissions();

  const closeWithReset = (resetForm: () => void) => () => {
    handleClose();
    resetForm();
  };

  const showContent = !isLoading && !showError;
  const showBaseUsd = companyPermissions.can('manage', 'EmployeeSalaryUsd');

  return (
    <Formik initialValues={values} onSubmit={handleSubmit} enableReinitialize validationSchema={employeeSchema}>
      {({ resetForm }) => (
        <Modal isOpen={isOpen} handleClose={closeWithReset(resetForm)} title={title} dropdown={true}>
          <SimpleWrapper>
            <Form>
              {isLoading && <Loader />}
              {showError && <Error>Cannot fetch employee</Error>}
              {showContent && (
                <>
                  <Flex direction={'column'} gap={8}>
                    <FormField name={'name'} type={'text'} placeholder={'Name'} label={'Name'} />
                    <FormField name={'position'} type={'text'} placeholder={'Position'} label={'Position'} />
                    <Flex>
                      <FormField name={'contractStart'} type={'date'} placeholder={'Start of contract'} label={'Start of contract'} width={'100%'} />
                      <FormField name={'contractEnd'} type={'date'} placeholder={'End of contract'} label={'End of contract'} width={'100%'} />
                    </Flex>
                    <FormField name={'baseTzs'} type={'money'} placeholder={'Base pay'} label={'Base pay (TZS)'} prefix={''} suffix={` ${currencies['tzs']}`} />
                    <FormField
                      name={'baseUsd'}
                      type={'money'}
                      placeholder={'Base pay'}
                      label={'Base pay (USD)'}
                      prefix={''}
                      suffix={` ${currencies['usd']}`}
                      hidden={!showBaseUsd}
                    />
                    <FormField name={'file'} type={'file'} label={'Employee data PDF'} />
                  </Flex>
                  <Flex justify={'flex-end'} margin={'24px 0'}>
                    <Button buttonType={'secondary'} onClick={closeWithReset(resetForm)}>
                      Cancel
                    </Button>
                    <Button type={'submit'}>{buttonText || 'Save'}</Button>
                  </Flex>
                </>
              )}
            </Form>
          </SimpleWrapper>
        </Modal>
      )}
    </Formik>
  );
};

export default EmployeeManagement;
