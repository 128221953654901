import React from 'react';
import { Column } from 'react-table';

import { Formatter } from 'components';
import { displayAmount } from 'utils';
import { UserSharesDto } from 'api';

import { Paragraph } from 'style';

export const columns: Column<UserSharesDto>[] = [
  {
    accessor: 'name',
    Header: 'Name',
    Cell: ({ value }: { value: string }) => <Paragraph weight={'medium'}>{value}</Paragraph>
  },
  {
    Header: 'Shares',
    accessor: 'shares',
    Cell: ({ value }: { value: number }) => <Formatter value={value} prefix={' '} suffix={'%'} />
  },
  {
    Header: 'Balance',
    accessor: 'balance',
    Cell: ({ value }: { value: number }) => <Formatter {...displayAmount(value, 'usd')} />
  }
];
