import { currencies, CurrencyValue, Option } from 'types';
import { colors } from 'style';

export const isEmptyObject = (obj: Record<string, unknown>): boolean => obj && Object.keys(obj).length === 0 && obj.constructor === Object;

export const isObject = (obj: any): boolean => {
  return typeof obj === 'object' && obj !== null;
};

export const removeEmpty = (obj: Record<string, unknown>) => {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
};

export const parseDate = (date: Date) => {
  const dateMonth = date.getMonth() + 1;
  const month = dateMonth < 10 ? '0' + dateMonth : dateMonth;
  return `${date.getDate()}/${month}/${date.getFullYear().toString().slice(-2)}`;
};

export const itemToString = (item: Option | null) => (item ? item.name : '');

export const getInitials = (name: string) => {
  let nameWords: string[] = [];
  if (name) {
    nameWords = name.split(/\s/);
  }
  if (nameWords.length === 1) {
    return nameWords[0].slice(0, 2);
  } else {
    return nameWords.reduce((acc, word) => (acc += word.slice(0, 1)), '').slice(0, 2);
  }
};

export const isArray = (arr: any) => Object.prototype.toString.call(arr) === '[object Array]';

export const displayAmount = (value: number | string, currency: CurrencyValue) => {
  const mappedValue = +value;
  const mappedCurrency = currencies[currency];
  const isPositive = mappedValue >= 0;

  const displayValue = isPositive ? mappedValue : -1 * mappedValue;
  const prefix = isPositive ? ' ' : '(';
  const suffix = isPositive ? ` ${mappedCurrency}` : ` ${mappedCurrency})`;

  const customColor = mappedValue === 0 ? colors.gray.scale3 : isPositive ? colors.green : colors.red;

  return { prefix, suffix, customColor, value: displayValue };
};
