import React from 'react';

import { Modal, Button, Heading } from 'components';

import { Flex, PositionBox } from 'style';
import { Wrapper } from './delete-modal.styles';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  handleDelete: () => void;
  itemName?: string;
}

const DeleteModal: React.FC<Props> = ({ isOpen, handleClose, handleDelete, itemName }) => {
  const name = itemName ? ` ${itemName}` : '';
  return (
    <Modal isOpen={isOpen} handleClose={handleClose} hideHeader={true} showPadding={true}>
      <PositionBox position={'center'}>
        <Wrapper>
          <Heading margin={'10px 0'}>Are you sure you want to remove {name}?</Heading>
          <Flex justify={'center'} margin={'2rem 0 0 0'}>
            <Button buttonType={'secondary'} onClick={handleClose}>
              Cancel
            </Button>
            <Button onClick={handleDelete} buttonType={'remove'}>
              Remove
            </Button>
          </Flex>
        </Wrapper>
      </PositionBox>
    </Modal>
  );
};

export default DeleteModal;
