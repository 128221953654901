import React from 'react';

import ProfileForm from './components/ProfileForm/ProfileForm';
import PasswordForm from './components/PasswordForm/PasswordForm';
import { View, PageHeader, AddNewButton } from 'components';

import { StyledGrid } from './settings.styles';

const Settings: React.FC = () => {
  return (
    <View>
      <PageHeader heading={'Settings'} customButton={<AddNewButton onClick={() => {}}>Add tour operator</AddNewButton>} />
      <StyledGrid>
        <div>
          <ProfileForm />
          <PasswordForm />
        </div>
      </StyledGrid>
    </View>
  );
};

export default Settings;
