import React from 'react';
import { HideWrapper, VisibleWrapper } from 'style';
import { DateFilter } from 'components/index';

interface Props {
  resolution: 'mobile' | 'desktop';
  dateRange?: [Date, Date];
  handleDateChange?: (dates: [Date, Date]) => void;
}

const DateSelector: React.FC<Props> = ({ handleDateChange, dateRange, resolution }) => {
  if (resolution === 'mobile') {
    return (
      <>
        {/* display from mobile to tablet */}
        {handleDateChange && resolution === 'mobile' && (
          <HideWrapper hideFrom={'standard'} fullWidth={true}>
            <DateFilter onDateChange={handleDateChange} dateRange={dateRange} align={'left'} />
          </HideWrapper>
        )}
      </>
    );
  }

  return (
    <>
      {/* display from tablet to desktop */}
      {handleDateChange && resolution === 'desktop' && (
        <VisibleWrapper visibleFrom={'standard'}>
          <DateFilter onDateChange={handleDateChange} dateRange={dateRange} />
        </VisibleWrapper>
      )}
    </>
  );
};

export default DateSelector;
