import React from 'react';

import { SpinnerIcon } from 'style';
import { LoaderWrapper } from './loader.styles';

export interface Props {
  accent?: boolean;
  height?: string;
}

const Loader: React.FC<Props> = ({ accent, height = '200px' }) => {
  return (
    <LoaderWrapper accent={accent} height={height}>
      <SpinnerIcon />
    </LoaderWrapper>
  );
};

export default Loader;
