import React from 'react';

import { Placement } from 'types';

import { Wrapper } from './empty.styles';

interface Props {
  children?: React.ReactNode;
  height?: string;
  placement?: Placement;
}

const Empty: React.FC<Props> = ({ children, height = '200px', placement }) => {
  return (
    <Wrapper height={height} position={placement || 'center'}>
      {children}
    </Wrapper>
  );
};

export default Empty;
