import React from 'react';
import { useParams } from 'react-router-dom';

import SalaryManagement from '../SalaryManagement/SalaryManagement';
import { usePermissions, useSubmit, useToast } from 'components/hooks';
import { postSalary } from 'api';
import { downloadFile } from 'utils';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  successCallback: () => void;
}

const AddSalary: React.FC<Props> = ({ isOpen, handleClose, successCallback }) => {
  const { error, success } = useToast();
  const { companyId, employeeId } = useParams<{ companyId: string; employeeId: string }>();
  const { companyPermissions } = usePermissions();

  const canGeneratePayslip = companyPermissions.can('generate', 'Payslip');

  const { onSubmit, onSubmitSuccess, onSubmitError, mapData } = useSubmit(postSalary(companyId, employeeId));
  onSubmitError(error('Salary cannot be added'));
  onSubmitSuccess((file, values, headers) => {
    success('Salary has been added');
    handleClose();
    successCallback();

    if (!canGeneratePayslip) return;

    const fileName = headers['x-file-name'];
    downloadFile(file, fileName);
  });
  mapData((data) => {
    const salaryData = { ...data };
    delete salaryData.loansTzs;
    delete salaryData.loansUsd;
    delete salaryData.baseTzs;
    delete salaryData.baseUsd;

    return salaryData;
  });

  return <SalaryManagement isOpen={isOpen} handleClose={handleClose} handleSubmit={onSubmit} title={'New salary'} />;
};

export default AddSalary;
