import { AccountValues, CashFlowDto, LoanLender, LoanType, PaymentSplit } from './types';
import { PettyCashDto } from 'api';

export const emptySplit: PaymentSplit = { companyId: 0, amount: 0, bank: 'cash' };
export const emptyPaySplit = (companyId: string): PaymentSplit => ({ companyId: +companyId, amount: 0, bank: 'cash' });

type DefaultParams = {
  account?: AccountValues;
  type?: LoanType;
  lender?: LoanLender;
  loanLenderId?: number;
  pettyCashValues: Partial<PettyCashDto & { account?: AccountValues }> | null;
  savedFormValues?: CashFlowDto | null;
};

const getTotalValueOfPettyCash = (pettyCashItems: PettyCashDto['items']): number =>
  pettyCashItems.map(({ amount, quantity }) => amount * quantity).reduce((a, b) => a + b);

export const emptyValues = ({ account, type, lender, loanLenderId, pettyCashValues, savedFormValues }: DefaultParams): CashFlowDto =>
  Object.assign({}, savedFormValues, {
    date: new Date(),

    description: '',
    exchange: false,
    exchangeData: {
      originalAmount: pettyCashValues?.items ? getTotalValueOfPettyCash(pettyCashValues.items) : 1,
      exchangeRate: 1,
      originalCurrency: 'tzs'
    },

    splitType: null,
    splitData: [],
    amount: pettyCashValues?.items ? getTotalValueOfPettyCash(pettyCashValues.items) : 1,
    currency: pettyCashValues?.currency || 'tzs',
    bank: 'znz',
    comment: '',
    file: null,

    vat: false,
    account: pettyCashValues?.account || account || null,
    accountData: {
      type: type || 'new',
      lender: lender || 'company',
      loanData: {
        employeeId: lender === 'employee' ? loanLenderId : undefined,
        companyId: lender === 'company' ? loanLenderId : undefined,
        shareholderId: lender === 'shareholder' ? loanLenderId : undefined
      },
      supplierId: pettyCashValues?.supplierId || null
    }
  });
