import React, { useEffect } from 'react';
import { Form, useFormikContext } from 'formik';

import { Button, FormField, Loader } from 'components';
import { useQuery } from 'components/hooks';
import { ConfirmDto } from 'api';

import { Flex } from 'style';

const PasswordResetForm: React.FC = () => {
  const { query } = useQuery();
  const { setFieldValue, isSubmitting } = useFormikContext<ConfirmDto>();

  useEffect(() => {
    if (query.token) {
      setFieldValue('token', query.token);
    }
  }, []);

  return (
    <div>
      {isSubmitting && <Loader />}
      {!isSubmitting && (
        <Form>
          <Flex direction={'column'} gap={12}>
            <FormField name={'password'} type={'password'} placeholder={'Password'} label={'Password'} />
            <FormField name={'confirmPassword'} type={'password'} placeholder={'Confirm Password'} label={'Confirm Password'} />
            <Button fullWidth={true} type={'submit'}>
              Save
            </Button>
          </Flex>
        </Form>
      )}
    </div>
  );
};

export default PasswordResetForm;
