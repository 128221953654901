export const PHONE_FORMAT = '### #### ###';
export const BANK_ACCOUNT = '## #### #### #### ####';

export const dateFormat = (dateString: Date | string, withDay?: boolean) => {
  const date = new Date(dateString);

  const day = date.getDate();
  const month = date.getUTCMonth() + 1;
  const year = date.getUTCFullYear();

  const displayMonth = month < 10 ? `0${month}` : month;

  return withDay ? `${day}.${displayMonth}.${year}` : `${displayMonth}.${year}`;
};
