import styled from 'styled-components';

import { Align } from './Formatter';

import { colors, font } from 'style';

type WrapperProps = {
  align: Align;
  color?: keyof typeof colors.gray;
  customColor?: string;
  weight?: keyof typeof font.weight;
  size?: keyof typeof font.size.paragraph;
};

const TextWrapper = styled.div<WrapperProps>`
  text-align: ${({ align }) => align};
  font-size: ${({ size }) => size && font.size.paragraph[size]};
  color: ${({ customColor, color }) => (customColor ? customColor : color && colors.gray[color])};
  font-weight: ${({ weight }) => (weight ? font.weight[weight] : font.weight.book)};
`;

export { TextWrapper };
