import styled from 'styled-components';

import { borders, colors, Grid } from 'style';

const Wrapper = styled.div<{ background?: boolean }>`
  margin-top: 16px;
  background-color: ${({ background }) => background && '#f6fbff'};
  padding: 5px 5px 5px 0;

  &:first-child {
    margin-top: 0;
  }
`;

const BalanceWrapper = styled.div`
  align-self: flex-end;
  justify-self: center;
  font-size: 13px;
  color: ${colors.gray.scale3};

  &:first-child {
    justify-self: flex-start;
  }

  &:last-child {
    justify-self: flex-end;
  }
`;

const StyledGrid = styled(Grid)`
  gap: 2px;
`;

const SummaryBalanceWrapper = styled(BalanceWrapper)`
  border-top: ${borders.default};
  padding-top: 3px;
`;

export { Wrapper, StyledGrid, BalanceWrapper, SummaryBalanceWrapper };
