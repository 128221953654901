import styled, { css } from 'styled-components';

import { borders, colors, DownIcon } from 'style';

const TableColumnHeading = styled.th<{ alignCellsLeft?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 4.8rem;
  text-align: left;
  padding: 0 1rem;

  ${({ alignCellsLeft }) =>
    alignCellsLeft &&
    css`
      justify-content: flex-start;
    `}

  border-bottom: ${borders.default};
  color: ${colors.gray.scale3};
  font-weight: 500;

  &:first-of-type {
    padding: 0 8px;
    text-align: center;
    align-items: flex-start;
  }
`;

const StyledSpan = styled.span`
  margin-top: 5px;
  margin-left: 5px;
`;

const UpIcon = styled(DownIcon)`
  transform: rotate(180deg);
`;

export { TableColumnHeading, StyledSpan, UpIcon };
