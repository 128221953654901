import React, { useState } from 'react';

import LogsTable from './components/LogsTable/LogsTable';
import { PageHeader } from 'components';
import { useQuery, useDebounce } from 'components/hooks';

import { Grid } from 'style';

const Logs: React.FC = () => {
  const { query, setQuery } = useQuery();
  const search = query.search as string;

  const [searchQuery, setSearchQuery] = useState<string>(search || '');

  const handleInputChange = (value: string) => {
    setSearchQuery(value);
    setQuery('search', value);
  };

  const searchDebounced = useDebounce<string>(searchQuery, 1500);

  return (
    <>
      <PageHeader heading={'Logs'} inputValue={searchQuery} handleInputChange={handleInputChange} />
      <Grid>
        <LogsTable searchQuery={searchDebounced} />
      </Grid>
    </>
  );
};

export default Logs;
