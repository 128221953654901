import { Column, Row } from 'react-table';
import { AnyMongoAbility } from '@casl/ability';

import { tableDropdown } from 'utils/table';
import { Option } from 'types';
import { User } from 'models';

export interface DropdownValue {
  type: 'delete' | 'edit';
  item: User;
}

interface DropdownArguments {
  handleDropdownSelect: (value: DropdownValue) => void;
  ability: AnyMongoAbility;
}

const prepareDropdownItems = (item: User, ability: AnyMongoAbility): Option<DropdownValue>[] => {
  const editItem = ability.can('update', 'Users') ? [{ name: 'Edit', value: { type: 'edit', item } }] : [];
  const deleteItem = ability.can('delete', 'Users') ? [{ name: 'Remove', value: { type: 'delete', item }, type: 'delete' }] : [];

  return [...editItem, ...deleteItem] as Option<DropdownValue>[];
};

export const prepareColumns = ({ handleDropdownSelect, ability }: DropdownArguments): Column<User>[] => [
  {
    accessor: 'name',
    Header: 'Name',
    Cell: ({ value: name, row }: { value: string; row: any }) => {
      const surname = row.original.surname;
      return `${name} ${surname}`;
    }
  },
  { Header: 'Email', accessor: 'email' },
  {
    Header: '',
    id: 'dropdown',
    width: 100,
    Cell: ({ row }: { row: Row<User> }) => {
      const options = prepareDropdownItems(row.original, ability);

      const canEditUsers = ability.can('update', 'Users');

      return canEditUsers ? tableDropdown<DropdownValue>({ options, handleDropdownSelect }) : null;
    }
  }
];
