import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';

import { Button, FormField, Heading, InputError } from 'components';
import { useFetch, useShowContent } from 'components/hooks';
import { getUsers, CompanyDto, Shareholder } from 'api';
import { User } from 'models';

import { CancelIcon, Flex } from 'style';
import { Wrapper } from './partners.styles';

const emptyShareholder: Shareholder = {
  userId: 0,
  shares: 0
};

interface Props {
  isEdit?: boolean;
}
const Partners: React.FC<Props> = ({ isEdit }) => {
  const { values, setFieldValue, errors } = useFormikContext<CompanyDto>();

  const usersData = useFetch(getUsers);
  const { showError, showLoader } = useShowContent(usersData);
  const { payload: users, refresh } = usersData;

  const shareholders = useMemo(() => {
    if (users) {
      if (isEdit) {
        return users.map(({ id, name, surname }) => ({ name: `${name} ${surname}`, value: id }));
      } else {
        const filterSelected = (user: User) => !values.shareholders.some(({ userId }) => userId === user.id);
        return users.filter(filterSelected).map(({ id, name, surname }) => ({ name: `${name} ${surname}`, value: id }));
      }
    } else {
      return [];
    }
  }, [users, values.shareholders]);

  const handleAddShareholder = () => {
    const shareholders = [...values.shareholders, emptyShareholder];
    setFieldValue('shareholders', shareholders);
  };

  const removeShareholderProvider = (shareholderId: number) => () => {
    setFieldValue(
      'shareholders',
      values.shareholders.filter(({ userId }) => userId !== shareholderId)
    );
  };

  return (
    <>
      <Flex gap={8} direction={'column'} align={'flex-start'}>
        <Heading margin={'0'}>Partners</Heading>
        {values.shareholders.map((shareholder, index) => {
          return (
            <div key={index}>
              {showError && (
                <Button buttonType={'secondary'} onClick={refresh}>
                  Refresh
                </Button>
              )}
              {!showError && (
                <Wrapper columns={'6fr 2fr 2fr'} gap={4}>
                  <FormField
                    name={`shareholders[${index}].userId`}
                    type={'select'}
                    items={shareholders}
                    disabled={showLoader}
                    placeholder={'Company/Person'}
                    width={'100%'}
                  />
                  <FormField name={`shareholders[${index}].shares`} type={'percentage'} placeholder={'amount %'} suffix={'%'} />
                  <Button buttonType='borderless' className='inline' onClick={removeShareholderProvider(shareholder.userId)}>
                    <CancelIcon />
                  </Button>
                </Wrapper>
              )}
            </div>
          );
        })}
        {typeof errors.shareholders === 'string' && <InputError message={errors.shareholders} />}
        <Button onClick={handleAddShareholder} disabled={!shareholders.length}>
          {values.shareholders.length === 0 ? 'Add partners' : 'Add more partners'}
        </Button>
      </Flex>
    </>
  );
};

export default Partners;
