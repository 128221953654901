import React from 'react';
import { Column, Row } from 'react-table';

import { Formatter } from 'components';
import { displayAmount } from 'utils';
import { Loan } from 'models';

import { StatusParagraph } from 'style';

export const prepareColumns = (): Column<Loan>[] => {
  return [
    { Header: 'Loan Date', accessor: 'date', Cell: ({ value }: { value: string }) => new Date(value).toLocaleDateString() },
    { Header: 'Rates number', accessor: 'ratesNumber' },
    {
      Header: 'Amount',
      accessor: 'amount',
      Cell: ({ value, row }: { value: number; row: Row<Loan> }) => <Formatter {...displayAmount(value, row.original.currency)} />
    },
    {
      Header: 'Left to pay',
      accessor: 'paidAmount',
      Cell: ({ value, row }: { value: number; row: Row<Loan> }) => {
        const leftToPay = row.original.amount - value;
        return <Formatter {...displayAmount(leftToPay, row.original.currency)} />;
      }
    },
    {
      Header: 'Status',
      id: 'status',
      Cell: ({ row }: { row: Row<Loan> }) => {
        const leftToPay = row.original.amount - row.original.paidAmount;
        const isPayed = leftToPay <= 0;

        return <StatusParagraph active={isPayed}>{isPayed ? 'Paid' : 'Outstanding'}</StatusParagraph>;
      }
    }
  ];
};
