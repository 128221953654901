import React, { useEffect, useState } from 'react';

import { Loader } from 'components';

import { StyledButton } from './button.styles';

export type ButtonTypes = 'primary' | 'secondary' | 'secondary-accent' | 'borderless' | 'remove' | 'wrapper';
export type ButtonSizes = 'large' | 'medium' | 'small';

export interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  onClick?: (e?: any) => void;
  type?: 'button' | 'submit' | 'reset';
  buttonType?: ButtonTypes;
  disabled?: boolean;
  children: React.ReactNode;
  size?: ButtonSizes;
  fullWidth?: boolean;
  loading?: boolean;
  svgMargin?: boolean;
}

const Button: React.FC<Props> = ({
  onClick,
  type = 'button',
  disabled,
  buttonType = 'primary',
  children,
  size = 'medium',
  fullWidth,
  loading,
  svgMargin = true,
  ...props
}) => {
  const [iconPosition, setIconPosition] = useState<'leading' | 'trailing' | null>(null);

  useEffect(() => {
    if (React.Children.count(children) <= 1) return;
    React.Children.map(children, (child, index) => {
      typeof child === 'string' && index > 0 ? setIconPosition('leading') : setIconPosition('trailing');
    });
  }, [children]);

  return (
    <StyledButton
      onClick={onClick}
      type={type}
      disabled={disabled || loading}
      buttonType={buttonType}
      size={size}
      iconPosition={iconPosition}
      fullWidth={fullWidth}
      svgMargin={svgMargin}
      {...props}
    >
      {loading ? <Loader /> : children}
    </StyledButton>
  );
};

export default Button;
