import styled, { css } from 'styled-components';

import { colors, Flex, font, Paragraph, PositionBox, theme } from 'style';

const Wrapper = styled.section<{ isOpen: boolean }>`
  width: 100%;
  height: 100vh;
  position: fixed;
  padding: 24px 24px;
  transform: translateX(${({ isOpen }) => (isOpen ? '0' : '-100%')});
  transition: transform 0.3s ease;
  background-color: #fff;
  z-index: 50;

  ${theme.mq.tablet} {
    width: 300px;
    padding: 24px;
  }

  ${theme.mq.hdReady} {
    position: sticky;
    top: 0;

    width: 300px;
    transform: translateX(0);
  }
`;

const StyledPositionBox = styled(PositionBox)`
  ${theme.mq.hdReady} {
    display: none;
  }
  position: fixed;
  right: 28px;
`;

const NavLinkFlex = styled(Flex)<{ isActive: boolean }>`
  a {
    width: 100%;
    display: grid;
    grid-template-columns: 10% auto;
    flex-direction: row;
    padding: 6px;
    align-items: center;
    gap: 15px;
    border-radius: 8px;
    transition: background-color 0.2s ease;

    ${theme.mq.tablet} {
      padding: 10px 12px;
      height: 44px;
    }

    ${({ isActive }) =>
      isActive &&
      css`
        background-color: ${colors.background.blue};

        p {
          font-weight: ${font.weight.bold};
        }

        svg {
          path {
            fill: ${colors.blue.base};
          }
        }

        &:focus {
          outline: none;
          border: none;
        }
      `}
  }

  svg {
    color: ${colors.gray.scale3};
  }
`;

const StyledParagraph = styled(Paragraph)`
  font-size: 12px;
  text-transform: uppercase;
`;

const StyledSection = styled.section`
  margin: 8px 0;

  ${theme.mq.tablet} {
    margin: 24px 0;
  }
`;

export { Wrapper, StyledPositionBox, NavLinkFlex, StyledParagraph, StyledSection };
