import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';

import { FormField, Formatter, InputError } from 'components';
import { Currency, RadioOption } from 'types';
import { CashFlowDto, AccountValues } from 'form/transaction/cash-flow-form/types';
import { currencySelectItems } from 'utils';

import { Flex, Grid } from 'style';
import { multiplyOrDivide } from './exchanged.utils';

const Exchanged: React.FC = () => {
  const {
    values: { exchangeData, account, exchange, amount, currency },
    setFieldValue,
    errors
  } = useFormikContext<CashFlowDto>();

  const options: RadioOption[] = [
    { name: 'Yes', value: true },
    { name: 'No', value: false }
  ];

  const hideExchangedAccounts: (AccountValues | undefined)[] = ['exchange', 'salaries-staff', 'salaries-management', 'loan'];
  const showExchanged = account && !hideExchangedAccounts.includes(account);

  const resetExchangeData = (exchange: boolean) => {
    if (exchange) setFieldValue('exchangeData.originalAmount', amount);
    if (!exchange) {
      setFieldValue('exchangeData', {});
    }
  };

  const currenciesToSelectFrom = currencySelectItems.map((item) => (item.value === exchangeData?.originalCurrency ? { ...item, blocked: true } : item));

  useEffect(() => {
    if (exchangeData?.exchangeRate && account !== 'exchange') {
      const shouldMultiplyOrExchange = multiplyOrDivide[exchangeData.originalCurrency.toUpperCase() as Currency][currency.toUpperCase() as Currency];

      let value;
      if (shouldMultiplyOrExchange === 'divide') {
        value = exchangeData.originalAmount / exchangeData.exchangeRate;
      } else {
        value = exchangeData.originalAmount * exchangeData.exchangeRate;
      }

      setFieldValue('amount', value);
    } else {
      setFieldValue('amount', amount);
    }
  }, [exchangeData?.exchangeRate, exchangeData?.originalAmount, exchangeData?.originalCurrency]);

  useEffect(() => {
    if (account && (account === 'salaries-staff' || account === 'salaries-management')) {
      setFieldValue('exchange', false);
    }
  }, [account]);

  useEffect(() => {
    if (exchange && exchangeData?.originalCurrency === currency) {
      setFieldValue('currency', currenciesToSelectFrom.find(({ blocked }) => !blocked)?.value || 'usd');
    }
  }, [exchangeData?.originalCurrency, currenciesToSelectFrom, exchange]);

  useEffect(() => {
    if (exchange && currenciesToSelectFrom && currency === currenciesToSelectFrom.find(({ blocked }) => blocked)?.value) {
      setFieldValue('currency', currenciesToSelectFrom?.find(({ blocked }) => !blocked)?.value || 'usd');
    }
  }, [currenciesToSelectFrom, exchange]);

  return (
    <>
      {showExchanged && <FormField type={'radio'} name={'exchange'} options={options} mainLabel={'Exchanged'} onFieldChange={resetExchangeData} />}
      {exchange && (
        <Flex direction={'column'} gap={8} margin={'12px 0 16px 0'} align={'stretch'}>
          <Grid columns={'3fr 1fr'}>
            <FormField name={'exchangeData.originalAmount'} type={'money'} prefix={''} placeholder={'Original amount'} label={'Original amount'} />
            <FormField name={'exchangeData.originalCurrency'} type={'select'} items={currencySelectItems} label={'Currency'} />
          </Grid>
          <FormField name={'exchangeData.exchangeRate'} type={'number-format'} placeholder={'Exchange Rate'} label={'Exchange Rate'} />
          <Grid columns={'3fr 1fr'} align={'center'}>
            <Formatter value={amount?.toFixed(2)} type={'money'} prefix={'none'} />
            {currenciesToSelectFrom && <FormField name={'currency'} type={'select'} items={currenciesToSelectFrom} hideError />}
          </Grid>
          {errors?.currency && <InputError message={errors.currency} />}
        </Flex>
      )}
    </>
  );
};

export default Exchanged;
