import React from 'react';

import { Empty, Error, Loader } from 'components';

interface Props {
  showLoader: boolean;
  showNoContent: boolean;
  showError: boolean;
}

const States: React.FC<Props> = ({ showLoader, showNoContent, showError }) => {
  return (
    <>
      {showLoader && <Loader />}
      {showNoContent && <Empty>No data</Empty>}
      {showError && <Error>Failed to load</Error>}
    </>
  );
};

export default States;
