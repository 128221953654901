import React from 'react';
import { Column, Row } from 'react-table';

import { Formatter } from 'components';
import { dateFormat, displayAmount } from 'utils';
import { Option } from 'types';
import { Transaction } from 'models';

export interface DropdownValue {
  type: 'delete' | 'edit' | 'status';
  id: number;
}

interface DropdownArguments {
  handleDropdownSelect: (value: DropdownValue) => void;
}

const prepareDropdownItems = (itemId: number): Option<DropdownValue>[] => [
  { name: 'Edit', value: { type: 'edit', id: itemId } },
  { name: 'Change status', value: { type: 'status', id: itemId } },
  { name: 'Remove', value: { type: 'delete', id: itemId }, type: 'delete' }
];

const handleTotalAmount = (rows: Row<Transaction>[]) => {
  return rows.reduce((summary, row) => row.values.amount + Number(summary), 0);
};

export const prepareColumns = ({ handleDropdownSelect }: DropdownArguments): Column<Transaction>[] => {
  return [
    {
      Header: 'Date',
      accessor: 'date',
      Cell: ({ value }: { value: string }) => dateFormat(value)
    },
    { Header: 'Description', accessor: 'description', minWidth: 250 },
    { Header: 'Account', accessor: 'account' },
    {
      Header: 'Amount',
      accessor: 'amount',
      Cell: (data: any) => {
        const amountProps = displayAmount(data.value, data.row.original.currency);
        return <Formatter weight={'medium'} {...amountProps} />;
      }
    }
    // {
    //   Header: '',
    //   id: 'dropdown',
    //   width: 100,
    //   Cell: ({ row }: { row: Row<Transaction> }) => {
    //     const options = prepareDropdownItems(row.original.id);
    //
    //     return tableDropdown<DropdownValue>({ options, handleDropdownSelect });
    //   }
    // }
  ];
};
