import styled, { keyframes } from 'styled-components';
import { ReactComponent as LoaderIcon } from 'assets/icons/loader.svg';

export { ReactComponent as AttachmentIcon } from 'assets/icons/attachment.svg';
export { ReactComponent as AvatarIcon } from 'assets/icons/avatar.svg';
export { ReactComponent as AssignmentIcon } from 'assets/icons/assignment.svg';
export { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg';
export { ReactComponent as BadgeClosedIcon } from 'assets/icons/badge-closed.svg';
export { ReactComponent as CashFlowIcon } from 'assets/icons/cashflow.svg';
export { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
export { ReactComponent as CancelIcon } from 'assets/icons/cancel.svg';
export { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
export { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
export { ReactComponent as DashboardIcon } from 'assets/icons/dashboard.svg';
export { ReactComponent as DirectoryIcon } from 'assets/icons/directory.svg';
export { ReactComponent as DownIcon } from 'assets/icons/down.svg';
export { ReactComponent as HomeIcon } from 'assets/icons/home.svg';
export { ReactComponent as HamburgerIcon } from 'assets/icons/hamburger.svg';
export { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
export { ReactComponent as InputAlertIcon } from 'assets/icons/input-alert.svg';
export { ReactComponent as MoreVerticalIcon } from 'assets/icons/more_vertical.svg';
export { ReactComponent as NotificationIcon } from 'assets/icons/notifications.svg';
export { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
export { ReactComponent as RestaurantIcon } from 'assets/icons/restaurant.svg';
export { ReactComponent as ReservationIcon } from 'assets/icons/reservations.svg';
export { ReactComponent as ShowPasswordIcon } from 'assets/icons/eye.svg';
export { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
export { ReactComponent as SelectorIcon } from 'assets/icons/selector.svg';
export { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg';
export { ReactComponent as SuppliersIcon } from 'assets/icons/suppliers.svg';
export { ReactComponent as TaxIcon } from 'assets/icons/tax.svg';
export { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';
export { ReactComponent as UsersIcon } from 'assets/icons/users.svg';
export { ReactComponent as UsersCircleIcon } from 'assets/icons/users_circle.svg';
export { ReactComponent as SideArrowIcon } from 'assets/icons/side-arrow.svg';
export { ReactComponent as RefreshIcon } from 'assets/icons/refresh.svg';
export { ReactComponent as TextSnippetIcon } from 'assets/icons/text-snippet.svg';
export { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';

const SpinnerKeyframes = keyframes`
  0% {
    stroke-dashoffset: 0;
    stroke-dasharray: 150.6 100.4;
  }
  50% {
    stroke-dasharray: 1 250;
  }
  100% {
    stroke-dashoffset: 502;
    stroke-dasharray: 150.6 100.4;
  }
`;

export const SpinnerIcon = styled(LoaderIcon)`
  animation: 2s linear ${SpinnerKeyframes} infinite;
`;
