import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useFormikContext } from 'formik';

import ProgressBar from './progress-bar/progress-bar';
import { FormField, Button } from 'components';
import { usePermissions, useCall, useToast } from 'components/hooks';
import { getTransactionFile } from 'api';
import { downloadFile, bankSelectItems, onlyZanzibarBankItems } from 'utils';
import { CashFlowDto } from '../types';
import { CashFlowContext } from 'form/transaction/context/cash-flow.context';

import { Flex, Label } from 'style';

interface Props {
  transactionId?: number;
  progress?: number;
}

const TransactionInfo: React.FC<Props> = ({ transactionId, progress }) => {
  const { error } = useToast();
  const { values, isSubmitting } = useFormikContext<CashFlowDto>();
  const { companyPermissions } = usePermissions();
  const { companyId } = useParams<{ companyId: string }>();
  const { handleSetValues, handleFormChange } = useContext(CashFlowContext);

  const isFile = values.file !== null;

  const { submit, onCallSuccess, onCallError } = useCall(getTransactionFile(companyId, transactionId));
  onCallError(error('Cannot download this file'));
  onCallSuccess((data, headers) => {
    const fileName = headers['x-file-name'];
    downloadFile(data, fileName);
  });

  const handleFileDownload = async () => {
    if (transactionId) {
      await submit();
    }
  };

  const showTransactionInfo = values.account !== 'loan';
  const showExchangeFields = values.account !== 'exchange';

  const generateFileClick = () => {
    handleSetValues(values);
    handleFormChange('petty-cash')();
  };

  const canGeneratePettyCash = companyPermissions.can('generate', 'PettyCash');
  const canShowOtherBanks = companyPermissions.can('create', 'ExchangedTransaction');

  const showProgressBar = typeof values.file === 'object' && isSubmitting;
  const canDownloadFile = typeof values.file === 'string';

  const bankOptions = canShowOtherBanks ? bankSelectItems : onlyZanzibarBankItems;

  return (
    <>
      {showTransactionInfo && (
        <Flex direction={'column'} gap={8} align={'flex-start'} width={'100%'} margin={'8px 0 0 0'}>
          <FormField name={'date'} type={'date'} placeholder={'Date'} fullWidth={true} width={'100%'} label={'Date'} />
          {showExchangeFields && <FormField name={'bank'} type={'select'} items={bankOptions} placeholder={'Paid from'} label={'Paid from'} width={'100%'} />}
          <FormField name={'comment'} type={'textarea'} placeholder={'Comment'} label={'Comment'} />
          <Flex width={'100%'} justify={'space-between'}>
            {!isFile && <Label>File</Label>}
            <Flex>
              <FormField
                name={'file'}
                type={'file'}
                dropzoneType={'simple'}
                fullWidth={false}
                handleFileDownload={canDownloadFile ? handleFileDownload : undefined}
              />
              {!isFile && canGeneratePettyCash && <Button onClick={generateFileClick}>Generate file</Button>}
            </Flex>
          </Flex>
          {showProgressBar && progress !== undefined && <ProgressBar progress={progress} />}
        </Flex>
      )}
    </>
  );
};

export default TransactionInfo;
