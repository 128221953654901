import React from 'react';

import { Props } from './Heading';

import { DisplayHeading, Heading1, Heading2, Heading3, Heading4, Heading5, Heading6 } from './heading.styles';

export const headingRenderer = ({ type = 'h5', children, ...props }: Props): JSX.Element => {
  switch (type) {
    case 'display':
      return <DisplayHeading {...props}>{children}</DisplayHeading>;
    case 'h1':
      return <Heading1 {...props}>{children}</Heading1>;
    case 'h2':
      return <Heading2 {...props}>{children}</Heading2>;
    case 'h3':
      return <Heading3 {...props}>{children}</Heading3>;
    case 'h4':
      return <Heading4 {...props}>{children}</Heading4>;
    case 'h5':
      return <Heading5 {...props}>{children}</Heading5>;
    case 'h6':
      return <Heading6 {...props}>{children}</Heading6>;
    default:
      return <Heading2 {...props}>{children}</Heading2>;
  }
};
