import React from 'react';
import { Formik, Form, FormikHelpers } from 'formik';

import { Button, Error, FormField, Loader, Modal } from 'components';
import { TourOperatorDto } from 'api';
import { operatorSchema } from './operator.validation';

import { Flex, SimpleWrapper } from 'style';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (values: TourOperatorDto, helpers: FormikHelpers<TourOperatorDto>) => void;
  initialValues?: TourOperatorDto;
  title: 'New Tour Operator' | 'Edit Tour Operator';
  buttonText?: string;
  isLoading?: boolean;
  showError?: boolean;
}

const emptyValues: TourOperatorDto = {
  name: '',
  provision: 0
};

const OperatorManagement: React.FC<Props> = ({ isOpen, handleClose, handleSubmit, initialValues, buttonText, isLoading, title, showError }) => {
  const values: TourOperatorDto = initialValues || emptyValues;

  const closeWithReset = (resetForm: () => void) => () => {
    handleClose();
    resetForm();
  };

  const showContent = !isLoading && !showError;

  return (
    <Formik initialValues={values} onSubmit={handleSubmit} validationSchema={operatorSchema} enableReinitialize={true}>
      {({ resetForm }) => (
        <Modal isOpen={isOpen} handleClose={closeWithReset(resetForm)} title={title}>
          <SimpleWrapper>
            <Form>
              {isLoading && <Loader />}
              {showError && <Error>Cannot fetch operator</Error>}
              {showContent && (
                <>
                  <Flex direction={'column'} gap={8}>
                    <FormField type={'text'} name={'name'} placeholder={'Name'} />
                    <FormField type={'percentage'} name={'provision'} placeholder={'Provision'} />
                  </Flex>
                  <Flex justify={'flex-end'} margin={'24px 0 0 0'}>
                    <Button buttonType={'secondary'} onClick={closeWithReset(resetForm)}>
                      Cancel
                    </Button>
                    <Button type={'submit'}>{buttonText || 'Add'}</Button>
                  </Flex>
                </>
              )}
            </Form>
          </SimpleWrapper>
        </Modal>
      )}
    </Formik>
  );
};

export default OperatorManagement;
