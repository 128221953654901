import React, { useContext, useEffect, useMemo } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';

import Accounts from './accounts/accounts';
import Exchanged from './exchanged/exchanged';
import Amount from './amount/amount';
import TransactionInfo from './transaction-info/transaction-info';
import PaymentSplit from './payment-split/payment-split';
import SubmitButtons from './submit-buttons/submit-buttons';
import { Modal } from 'components';
import { emptyValues } from './cash-flow.values';
import { CashFlowDto, LoanLender, LoanType, RepayLoanObject } from './types';
import { cashFlowSchema } from './cash-flow-form.validation';
import { CashFlowContext } from '../context/cash-flow.context';
import { Supplier } from 'models';
import { CompanyCan as Can } from 'permissions';

import { StyledGrid, Column } from './cash-flow-form.styles';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (values: CashFlowDto, helpers: FormikHelpers<CashFlowDto>) => void;
  initialValues?: CashFlowDto;
  isEdit?: boolean;
  transactionId?: number;
  progress?: number;
  onOpenAddSupplierModal: () => void;

  type?: LoanType;
  lender?: LoanLender | RepayLoanObject;
  loanLenderId?: number;
  suppliers: Supplier[];
}

const CashFlowForm: React.FC<Props> = ({
  isOpen,
  handleClose,
  initialValues = null,
  isEdit,
  transactionId,
  handleSubmit,
  type,
  lender,
  loanLenderId,
  progress,
  onOpenAddSupplierModal,
  suppliers
}) => {
  const { savedFormValues, handleSetValues, setDefaultLoanData, pettyCashValues, disseminatePettyCashValues } = useContext(CashFlowContext);

  const loanAccount = type && 'loan';

  const editValues = useMemo(() => initialValues || emptyValues({ pettyCashValues }), [initialValues]);
  const addValues = emptyValues({ account: loanAccount, type, lender, loanLenderId, pettyCashValues, savedFormValues });
  const values: CashFlowDto = useMemo(() => (isEdit ? editValues : addValues), [isEdit, isOpen, editValues, addValues, pettyCashValues]);

  const closeWithReset = (resetForm: () => void) => () => {
    handleClose();
    resetForm();
    handleSetValues(null);
    setDefaultLoanData(null);
    disseminatePettyCashValues(null);
  };

  useEffect(() => {
    /* this form can be used in a several places - e.g add transaction, create or pay loans */
    /* if type and lender are in props it means that we operate only on loans account */
    /* if so, set loan account to the initial values and disable some form fields */
    setDefaultLoanData({ type, lender, loanLenderId });
  }, [type, lender, loanLenderId, isOpen]);

  const title = isEdit ? 'Edit transaction' : 'New transaction';

  return (
    <Formik
      initialValues={values}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validationSchema={cashFlowSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ resetForm }) => {
        return (
          <Modal isOpen={isOpen} handleClose={closeWithReset(resetForm)} fullMobile={true} width={'750px'} title={title}>
            <Form>
              <StyledGrid>
                <div>
                  <Accounts suppliers={suppliers} onOpenAddSupplierModal={onOpenAddSupplierModal} />
                  <Amount />
                  <Can I={'create'} a={'ExchangedTransaction'}>
                    <Exchanged />
                  </Can>
                  <TransactionInfo transactionId={transactionId} progress={progress} />
                </div>
                <Column>
                  {!isEdit && <PaymentSplit />}
                  <SubmitButtons handleClose={closeWithReset(resetForm)} isEdit={isEdit} />
                </Column>
              </StyledGrid>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default CashFlowForm;
